import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import { changeSessions } from '../actions/sessionsActions';
import * as OpTypes from '../types/sessionOperationTypes';

function* getOpenDaySessions(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    yield put(changeSessions(response.data));
  }
}

export function* getOpenDaySessionsSaga() {
  yield takeLatest(OpTypes.GET_OPEN_DAY_SESSIONS, getOpenDaySessions);
}

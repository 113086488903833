import { Clear } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import { DatePicker, LocalizationProvider, SingleInputTimeRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import { IPaginationRequest } from 'common/models/IPaginationRequest';
import dayjs, { Dayjs } from 'dayjs';
import { changeShowBackdrop } from 'modules/app/operations/actions/appActions';
import { ClaimActionType, IUserFilters } from 'modules/manage-users/operations/models';

import { getFilteredUsers } from 'modules/manage-users/operations/actions/userOperationActions';

import {
  changeActiveSessionInEdit,
  changeSessionCreateStatus,
  changeSessionUpdateStatus,
  createSession,
  getUnavailableSessionDates,
  updateOpenDaySession
} from 'modules/sessions/operations/actions/sessionsOperationActions';
import { SessionCreationStatus, SessionUpdateStatus } from 'modules/sessions/operations/enums/sessionEnum';

import { ROUTES } from 'common/constants/routesConstants';
import { useCheckPermissions } from 'common/hooks/useCheckPermissions';
import useScreenSize from 'common/hooks/useScreenSize';
import { ITag } from 'modules/manage-app/operations/models/tagModel';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { validateTeam } from 'modules/sessions/operations/helpers/validation';
import { IFormValidation } from 'modules/shared/models/shared-models';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { IGlobalState } from 'startup/store/globalState';
import { getTags } from '../../../manage-app/operations/actions/tagOperationActions';
import {
  ISessionForm,
  ISessionTeamRequestDto,
  castIOpenDayToSessionForm,
  getDefaultSessionForm
} from '../../operations/models/sessionsModel';
import styles from './SessionDetails.module.scss';
import { TagDetails } from './TagDetails';
import { TeamDetails } from './TeamDetails';

interface IProps {
  closeHandler: () => void;
}

export const SessionDetails: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const [formValidation, setFormValidation] = useState<IFormValidation>({ isValid: false, validationErrors: {} });
  const [isTimeValid, setIsTimeValid] = useState(true);
  const [teams, setTeams] = useState<ISessionTeamRequestDto[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);
  const [sessionForm, setSessionForm] = useState<ISessionForm>({ ...getDefaultSessionForm() });
  const previousFormState = useRef<ISessionForm>();

  const { isMobile } = useScreenSize();
  const creationStatus = useSelector((state: IGlobalState) => state.sessionsState.sessionsOperation.creationStatus);
  const updateStatus = useSelector((state: IGlobalState) => state.sessionsState.sessionsOperation.updateStatus);

  const { locations, openDay, unavailableSessions, loading, teamCaptains } = useSelector((state: IGlobalState) => {
    return {
      ...state.commonState.common,
      ...state.sessionsState.sessionsOperation,
      ...state.appState.app,
      ...state.sessionsState.sessions
    };
  });

  const canManageOpenDay = useCheckPermissions([
    { actionType: ClaimActionType.ADD, claimType: ClaimTypes.MANAGE_OPEN_DAYS }
  ]);

  const errors = formValidation.validationErrors ?? {};

  const handleClose = () => {
    clearForm();
    dispatch(changeActiveSessionInEdit());
    props.closeHandler();
  };

  const clearForm = () => {
    setSessionForm(getDefaultSessionForm());
  };

  const handleSubmit = async () => {
    let formdata = JSON.parse(JSON.stringify(sessionForm));
    formdata.startTime = dayjs(formdata.startTime).format('HH:mm:ss');
    formdata.endTime = dayjs(formdata.endTime).format('HH:mm:ss');
    formdata.teams = teams.filter((team) => !(team.isDeleted && !team.id));
    formdata.tags = [...tags];
    if (openDay) {
      openDay.tags
        .filter((tag) => !formdata.tags?.find((t: ITag) => t.id === tag.id))
        .forEach((tag: ITag) => formdata.tags?.push({ ...tag, deleted: true }));
      dispatch(updateOpenDaySession(openDay.id, formdata));
    } else {
      dispatch(createSession(formdata));
    }
  };

  const handleFormChange = (prop: string, val: any) => {
    if (prop === 'sessionTime') {
      sessionForm.startTime = val[0];
      sessionForm.endTime = val[1];
      const startTimeMilliSeconds = sessionForm.startTime?.toDate().getTime();
      const endTimeMilliSeconds = sessionForm.endTime?.toDate().getTime();
      if (endTimeMilliSeconds && startTimeMilliSeconds) {
        const isValidTime = endTimeMilliSeconds > startTimeMilliSeconds;
        setIsTimeValid(isValidTime);
      }
    }
    const _state = { ...sessionForm, [prop as keyof ISessionForm]: val };
    setValidationTouched(prop);
    setSessionForm(_state);
  };

  const setValidationTouched = (prop: string) => {
    setFormValidation({
      ...formValidation,
      validationErrors: { ...formValidation.validationErrors, [prop]: { touched: true } }
    });
  };

  useEffect(() => {
    checkValidationErrors();
  }, [sessionForm, unavailableSessions, teams]);

  useEffect(() => {
    if (locations?.length) {
      let sessionForm = { ...getDefaultSessionForm() };
      if (openDay) {
        previousFormState.current = castIOpenDayToSessionForm(openDay);
        sessionForm = castIOpenDayToSessionForm(openDay);
      }
      if (sessionForm.locationId == -1) {
        sessionForm.locationId = locations.find((l) => l.name === 'JHB')?.id ?? locations[0].id;
      }
      setSessionForm(sessionForm);
      setTags(sessionForm.tags ?? []);

      const teamsMap: ISessionTeamRequestDto[] = [...(openDay?.teams ?? [])]
        .sort((a, b) => a.teamCaptain?.name?.localeCompare(b.teamCaptain?.name ?? '') ?? 0)
        .map((team) => ({
          candidateIds: [],
          name: team.name,
          teamCaptainId: team.teamCaptain?.id,
          id: team.id
        }));
      setTeams(teamsMap);
    }
  }, [openDay, locations]);

  useEffect(() => {
    dispatch(getFilteredUsers({ pageSize: undefined } as IPaginationRequest<IUserFilters>));
    dispatch(getTags());
    dispatch(changeShowBackdrop(false));
    dispatch(getUnavailableSessionDates());
    return () => {
      dispatch(changeShowBackdrop(true));
    };
  }, []);

  useEffect(() => {
    if ([SessionCreationStatus.Success, SessionCreationStatus.Failure].includes(creationStatus)) {
      dispatch(changeSessionCreateStatus(SessionCreationStatus.Idle));
      creationStatus === SessionCreationStatus.Success && handleClose();
    }

    if ([SessionUpdateStatus.Success, SessionUpdateStatus.Failure].includes(updateStatus)) {
      dispatch(changeSessionUpdateStatus(SessionUpdateStatus.Idle));
      updateStatus === SessionUpdateStatus.Success && handleClose();
    }

    dispatch(getUnavailableSessionDates());
  }, [creationStatus, updateStatus]);

  const checkValidationErrors = (): void => {
    const _formValidation: IFormValidation = {
      isValid: true,
      validationErrors: { ...formValidation.validationErrors }
    };
    let validate = () => !!sessionForm.userFriendlyName?.length;
    validateField(_formValidation, 'userFriendlyName', validate, 'Session name is required.');
    validateField(_formValidation, 'locationId', () => !!sessionForm.locationId, 'Session location is required.');
    validateField(_formValidation, 'dateTime', () => !!sessionForm.dateTime, 'Session date is required.');
    if (sessionForm.dateTime) {
      let sessionDate = sessionForm.dateTime.set('hour', sessionForm.endTime?.hour() ?? 0);
      sessionDate = sessionDate.set('minute', sessionForm.endTime?.minute() ?? 0);
      validate = () => !checkIfDayUnavailable(sessionDate);
      validateField(_formValidation, 'dateTime', validate, 'Session date is unavailable.');

      if (!checkIfDayUnavailable(sessionDate)) {
        validate = () => true;
        validateField(_formValidation, 'dateTime', validate, 'Session date cannot be in the past.');
      }
    }

    (teams ?? []).forEach((team, index) => {
      const teamError = validateTeam(team, teams ?? []);
      const isAvailable = !!teamCaptains.find((captain) => captain.id === team.teamCaptainId);
      validateField(_formValidation, `team_${index}_name`, () => teamError?.name !== 'name', teamError?.message);
      validateField(
        _formValidation,
        `team_${index}_captain`,
        () => teamError?.name !== 'captain' && isAvailable,
        teamError?.message
      );
      teamError?.name && setValidationTouched(`team_${index}_${teamError?.name}`);
    });

    setFormValidation(_formValidation);
  };

  const validateField = (
    _formValidation: IFormValidation,
    key: string,
    validation: () => boolean,
    message: string | undefined
  ) => {
    const field = _formValidation.validationErrors[key] ?? {};
    field.touched = formValidation.validationErrors[key]?.touched ?? false;
    field.message = undefined;
    if (!validation()) {
      field.message = message;
      _formValidation.isValid = false;
    }
    _formValidation.validationErrors[key] = field;
  };

  const checkIfDayUnavailable = (date: Dayjs | undefined): boolean => {
    if (!date || creationStatus == SessionCreationStatus.Adding) return false;
    const isDateSelectedEditDate = openDay ? dayjs(openDay.date).isSame(date, 'd') : false;
    return unavailableSessions?.findIndex((d) => dayjs(d).isSame(date, 'd')) > -1 && !isDateSelectedEditDate;
  };

  const SubmitButtons = (
    <Stack spacing={2} direction="row" sx={{ marginTop: '50px', marginBottom: '10px' }}>
      <EntelectButton variant={ButtonType.Secondary} handleClick={handleClose} label="Cancel" />
      <EntelectButton
        variant={ButtonType.Primary}
        handleClick={handleSubmit}
        label="Save"
        disabled={!formValidation.isValid || !canManageOpenDay || loading}
      />
    </Stack>
  );

  return (
    <div>
      <h1 className={styles.openDayHeading}>
        <IconButton component={RouterLink} to={ROUTES.OpenDays}>
          <ArrowBackIcon className={styles.backArrow} />
        </IconButton>
        Save {process.env.REACT_APP_SESSION_NAME}
      </h1>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl className={styles.openDayWidth}>
                <div className={styles.formHeading}>Details</div>
                <TextField
                  label="Name"
                  variant="outlined"
                  type="text"
                  required
                  value={sessionForm.userFriendlyName}
                  onChange={(e) => handleFormChange('userFriendlyName', e.target.value)}
                  error={!!errors.userFriendlyName?.touched && !!errors.userFriendlyName?.message}
                  helperText={!!errors.userFriendlyName?.touched && errors.userFriendlyName?.message}
                  InputProps={{
                    endAdornment: sessionForm.userFriendlyName ? (
                      <InputAdornment position="end">
                        <Clear onClick={() => handleFormChange('userFriendlyName', '')}></Clear>
                      </InputAdornment>
                    ) : (
                      <></>
                    )
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={styles.openDayWidth}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={sessionForm.dateTime}
                    onChange={(e: any) => handleFormChange('dateTime', e)}
                    shouldDisableDate={checkIfDayUnavailable}
                  />
                </LocalizationProvider>
                {!!errors.dateTime?.message && <small className={styles.error}>*{errors.dateTime?.message}</small>}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={styles.openDayWidth}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <SingleInputTimeRangeField
                    label="Time From - To"
                    value={[sessionForm.startTime ?? null, sessionForm.endTime ?? null]}
                    onChange={(e: any) => handleFormChange('sessionTime', e)}
                    shouldDisableTime={() => !isTimeValid}
                    disabled={true}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl className={styles.openDayWidth}>
                <InputLabel id="location-select-label">Location</InputLabel>
                <Select
                  label="Location"
                  value={sessionForm.locationId}
                  error={!sessionForm.locationId}
                  onChange={(e) => handleFormChange('locationId', e.target.value)}
                  disabled={true}
                >
                  {locations.map((location) => (
                    <MenuItem key={`l_${location.id}`} value={location?.id}>
                      {location?.name}
                    </MenuItem>
                  ))}
                </Select>
                {!sessionForm.locationId && <p className={styles.error}>*Session Location is required</p>}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <TeamDetails teams={teams} setTeams={setTeams} errors={errors} setValidationTouched={setValidationTouched} />
        </Grid>
        <Grid item xs={12} md={4}>
          <TagDetails tags={tags} setTags={setTags} />
          {isMobile && SubmitButtons}
        </Grid>
      </Grid>
      <div className={styles.submitButtonslayout}>{!isMobile && SubmitButtons}</div>
    </div>
  );
};

export default SessionDetails;

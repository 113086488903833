import { getOpenDayCalibrations } from 'modules/calibration/operations/actions';
import { CalibrationType } from 'modules/calibration/operations/enums';
import { ICandidateCalibration } from 'modules/calibration/operations/models';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { CalibrationCandidateCard } from '../CalibrationCandidateCard/CalibrationCandidateCard';

export interface IProps {
  allCardsExpanded: boolean;
  displayedCalibrations: ICandidateCalibration[];
}

export const InterviewView: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const [calibrations, setCalibrations] = useState<ICandidateCalibration[]>([]);
  const { openDayId } = useParams();

  useEffect(() => {
    if (props.displayedCalibrations.filter((cfc) => cfc.interviewTypeCode == CalibrationType.Interview).length) {
      setCalibrations(props.displayedCalibrations);
    }
  }, [props.displayedCalibrations]);

  useEffect(() => {
    if (openDayId) {
      dispatch(getOpenDayCalibrations(+openDayId, CalibrationType.Interview));
    }
  }, []);

  useEffect(() => {
    if (calibrations?.length) {
      setCalibrations(calibrations);
    }
  }, [calibrations]);
  return (
    <>
      {calibrations?.map((calibration) => (
        <CalibrationCandidateCard
          calibrationType={CalibrationType.Interview}
          allCardsExpanded={props.allCardsExpanded}
          key={calibration?.id}
          candidateCalibration={calibration}
        ></CalibrationCandidateCard>
      ))}
    </>
  );
};

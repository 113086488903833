import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeShowBackdrop } from 'modules/app/operations/actions/appActions';
import { getSessionById } from 'modules/sessions/operations/actions/sessionsOperationActions';
import { useParams } from 'react-router-dom';
import { IGlobalState } from '../../../startup/store/globalState';
import CandidateBulkImportView from '../components/CandidateBulkImportView/CandidateBulkImportView';

const CandidatesImportContainer: React.FC = () => {
  const id = Number(useParams().sessionId);
  const dispatch = useDispatch();
  const { openDay } = useSelector((state: IGlobalState) => state.sessionsState.sessionsOperation);
  useEffect(() => {
    dispatch(changeShowBackdrop(false));
    id && dispatch(getSessionById(`${id}`));
  }, [id]);

  return openDay && <CandidateBulkImportView />;
};

export default CandidatesImportContainer;

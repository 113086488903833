import { ArrowDropDown } from '@mui/icons-material';
import { Chip, Popover } from '@mui/material';
import { IFeedbackTag } from 'modules/feedback/operations/models/feedbackModel';
import { useState } from 'react';
import styles from './FeedbackTag.module.scss';

interface IProps {
  tag: IFeedbackTag;
}

export const SelectedTag: React.FC<IProps> = ({ tag }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const tagName = (tag.tag && tag.tag.name) || '';
  const value = isNaN(parseInt(tag.rating)) ? tag.rating : tag.rating + '/' + (tag.tag?.rating?.end || 10);
  const displayValue = value ? <b>{value}</b> : '';
  const label = (
    <>
      ({displayValue}) {tagName}
    </>
  );
  return (
    <>
      <Chip
        onClick={(e: any) => setAnchorEl(e.currentTarget)}
        icon={<ArrowDropDown />}
        label={label}
        variant="outlined"
        size="small"
        className={styles.displayChip}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className={styles.popover}
      >
        <p className={styles.title}>
          <small>{tagName}</small>
        </p>
        <p className="textCenter">{displayValue}</p>
      </Popover>
    </>
  );
};

import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { HttpMethod } from '../../../../common/enums/appEnums';
import { CandidateCreationStatus, CandidateUpdatingStatus } from '../enums/candidateEnums';
import { ICandidateImport } from '../models/candidateImportModel';
import { CandidateDTO } from '../models/candidateModel';
import * as Types from '../types/candidatesOperationTypes';

export const getCandidates = (openDayId: number, parameters: string): IActionRequest<string> => {
  return {
    type: Types.GET_CANDIDATES,
    httpMethod: HttpMethod.Get,
    url: `/candidate/session/${openDayId}`,
    payload: parameters
  };
};

export const filterCandidates = (parameters: string): IActionRequest<string> => {
  return {
    type: Types.FILTER_CANDIDATES,
    httpMethod: HttpMethod.Get,
    url: `/candidate`,
    payload: parameters
  };
};

export const getCandidate = (parameters: string): IActionRequest<string> => {
  return {
    type: Types.GET_CANDIDATE,
    httpMethod: HttpMethod.Get,
    url: `/candidate/` + parameters
  };
};

export const createCandidate = (payload: FormData): IActionRequest<FormData> => {
  return {
    type: Types.CREATE_CANDIDATE,
    httpMethod: HttpMethod.Post,
    url: `/candidate`,
    payload: payload
  };
};

export const removeCandidateFromSession = (id: number, sessionId: number): IActionRequest<string> => {
  return {
    type: Types.REMOVE_CANDIDATE_FROM_SESSION,
    httpMethod: HttpMethod.Delete,
    url: `/candidate/${id}/session/${sessionId}`
  };
};

export const updateCandidate = (id: number, payload: CandidateDTO): IActionRequest<CandidateDTO> => {
  return {
    type: Types.UPDATE_CANDIDATE,
    httpMethod: HttpMethod.Put,
    url: `/candidate/${id}`,
    payload: payload
  };
};

export const updateCandidatePhoto = (id: number, payload: FormData, queryParam: any = {}): IActionRequest<FormData> => {
  const queryString = '?' + new URLSearchParams(queryParam).toString();
  return {
    type: Types.UPDATE_CANDIDATE_PHOTO,
    httpMethod: HttpMethod.Put,
    url: `/candidate/${id}/photo${queryString}`,
    payload: payload
  };
};

export const changeCandidateCreateStatus = (status: CandidateCreationStatus): IActionType<CandidateCreationStatus> => {
  return {
    type: Types.CHANGE_CANDIDATE_CREATION_STATUS,
    payload: status
  };
};

export const changeCandidateUpdateStatus = (status: CandidateUpdatingStatus): IActionType<CandidateUpdatingStatus> => {
  return {
    type: Types.CHANGE_CANDIDATE_UPDATING_STATUS,
    payload: status
  };
};

export const getCandidateRecruiters = (searchString: string): IActionRequest<string> => {
  return {
    type: Types.GET_CANDIDATE_RECRUITERS,
    httpMethod: HttpMethod.Get,
    url: `/candidate/searchRecruiter`,
    payload: searchString
  };
};

export const getGenders = (): IActionRequest<undefined> => {
  return {
    type: Types.GET_GENDERS,
    httpMethod: HttpMethod.Get,
    url: `/lookups/genders`
  };
};

export const getEthnicities = (): IActionRequest<undefined> => {
  return {
    type: Types.GET_ETHNICITIES,
    httpMethod: HttpMethod.Get,
    url: `/lookups/aics`
  };
};

export const changeSelectedCandidate = (): IActionType<undefined> => {
  return {
    type: Types.CHANGE_SELECTED_CANDIDATE
  };
};

export const bulkImportCandidates = (payload: FormData, openDayId: number | undefined): IActionRequest<FormData> => {
  return {
    type: Types.BULK_IMPORT_CANDIDATES,
    httpMethod: HttpMethod.Post,
    url: `/candidate/bulk-import/session/${openDayId}`,
    onSuccess: bulkImportCandidatesSucceed,
    onFailure: bulkImportCandidatesFailed,
    payload: payload
  };
};

export const bulkImportCandidatesSucceed = (payload: any): IActionType<any> => {
  return {
    type: Types.BULK_IMPORT_CANDIDATES_SUCCEED,
    payload
  };
};

export const bulkImportCandidatesFailed = (payload: any): IActionType<any> => {
  return {
    type: Types.BULK_IMPORT_CANDIDATES_FAILED,
    payload
  };
};

export const bulkImportCandidatesClearErrors = (): IActionType<undefined> => {
  return {
    type: Types.BULK_IMPORT_CANDIDATES_CLEAR_ERRORS
  };
};

export const saveBulkImportCandidates = (
  openDayId: number,
  payload: ICandidateImport[]
): IActionRequest<ICandidateImport[]> => {
  return {
    type: Types.SAVE_BULK_IMPORT_CANDIDATES,
    httpMethod: HttpMethod.Post,
    url: `/candidate/save-bulk-import/${openDayId}`,
    onSuccess: saveBulkImportCandidatesSucceed,
    onFailure: saveBulkImportCandidatesFailed,
    payload: payload
  };
};

export const saveBulkImportCandidatesSucceed = (payload: any): IActionType<any> => {
  return {
    type: Types.SAVE_BULK_IMPORT_CANDIDATES_SUCCEED,
    payload
  };
};

export const saveBulkImportCandidatesFailed = (payload: any): IActionType<any> => {
  return {
    type: Types.SAVE_BULK_IMPORT_CANDIDATES_FAILED,
    payload
  };
};

export const saveBulkImportCandidatesClearStatus = (): IActionType<undefined> => {
  return {
    type: Types.SAVE_BULK_IMPORT_CANDIDATES_CLEAR_STATUS
  };
};

export const clearBulkImportCandidates = (): IActionType<undefined> => {
  return {
    type: Types.CLEAR_BULK_IMPORT_CANDIDATES
  };
};

import SearchIcon from '@mui/icons-material/Search';
import { Container, FormControlLabel, InputAdornment, OutlinedInput, Switch, Typography } from '@mui/material';
import FilterButton, { IGenericFilter } from 'common/components/FilterComponent/FilterButton';
import { FilterTypeEnum } from 'common/enums/filterTypeEnum';
import useScreenSize from 'common/hooks/useScreenSize';
import { IPaginationRequest } from 'common/models/IPaginationRequest';
import { getLocations } from 'common/operations/actions/commonOperationActions';
import { getCandidateStatuses } from 'modules/manage-app/operations/actions/candidateStatusActions';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serialize } from '../../../../common/helpers/serializationHelpers';
import { IGlobalState } from '../../../../startup/store/globalState';
import { changeCandidateSearchType } from '../../operations/actions/candidatesActions';
import { filterCandidates, getEthnicities, getGenders } from '../../operations/actions/candidatesOperationActions';
import { ICandidateFilters } from '../../operations/models/candidatesModel';
import ImportCandidateButton from '../ImportCandidateButton/ImportCandidateButton';
import styles from './CandidateSearchBar.module.scss';

interface IProps {
  showBulkImport: (show: boolean) => void;
  clearSearch: boolean;
  clearFilters: boolean;
  filters: ICandidateFilters;
  setTableView: (val: boolean) => void;
}

export enum CandidatesSearchType {
  SEARCH = 'search',
  FILTER = 'filter'
}

const CandidateSearchBar: React.FC<IProps> = (props) => {
  const { showBulkImport, clearSearch, clearFilters } = props;
  const { isMobile } = useScreenSize();
  const [searchTerm, setSearchTerm] = useState<string | null>('');
  const firstRender = useRef(true);
  const [filters, setFilters] = useState<ICandidateFilters>(props.filters);
  const [genericFilters, setGenericFilters] = useState<IGenericFilter[]>([]);
  const [tableView, setTableView] = useState(false);
  const { totalRecords, locations, genders, ethnicities, candidateStatuses } = useSelector((state: IGlobalState) => {
    return {
      ...state.candidatesState.candidates,
      ...state.candidateStatusState,
      locations: state.commonState.common.locations,
      candidateStatuses: state.candidateStatusState.statuses
    };
  });

  const dispatch = useDispatch();

  useEffect(() => {
    !locations.length && dispatch(getLocations());
    !genders.length && dispatch(getGenders());
    !ethnicities.length && dispatch(getEthnicities());
    !candidateStatuses?.length && dispatch(getCandidateStatuses());
  }, []);

  useEffect(() => {
    const filterTypes: IGenericFilter[] = [
      {
        label: 'Status',
        reduxData: candidateStatuses.map((cs) => cs.name),
        selectedOptions: [],
        filterType: FilterTypeEnum.SELECT
      },
      {
        label: 'Location',
        reduxData: locations.map((l) => l.name),
        selectedOptions: [],
        filterType: FilterTypeEnum.SELECT
      },
      {
        label: 'Gender',
        reduxData: genders.map((s) => s.name),
        selectedOptions: [],
        filterType: FilterTypeEnum.SELECT
      },
      {
        label: 'Ethnicities',
        reduxData: ethnicities.map((s) => s.name),
        selectedOptions: [],
        filterType: FilterTypeEnum.SELECT
      }
    ];
    setGenericFilters(filterTypes);
  }, [candidateStatuses, locations, genders, ethnicities]);

  useEffect(() => {
    setSearchType();
    fetchCandidates();
  }, [searchTerm, filters]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (clearSearch) {
      setSearchTerm('');
    }
    if (clearFilters) {
      setFilters(props.filters);
    }
  }, [clearSearch, clearFilters]);

  const setSearchType = () => {
    const activeFilters: number = getFilterCount();
    const activeSearchType =
      activeFilters > 0 && searchTerm && searchTerm !== ''
        ? CandidatesSearchType.SEARCH
        : activeFilters > 0
          ? CandidatesSearchType.FILTER
          : searchTerm && searchTerm.length > 0
            ? CandidatesSearchType.SEARCH
            : undefined;

    dispatch(changeCandidateSearchType(activeSearchType));
  };

  const getFilterCount = () => {
    return filters.statuses.concat(filters.locations).concat(filters.genders).concat(filters.aics).length;
  };

  const fetchCandidates = () => {
    const request: IPaginationRequest<ICandidateFilters> = {};
    request.searchTerm = searchTerm as string;
    request.filters = filters;
    dispatch(filterCandidates(request ? serialize(request) : ''));
  };

  const onTableViewChanged = (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    setTableView(value);
    props.setTableView(value);
  };

  const onFilterApplied = (genericFilters: IGenericFilter[]) => {
    const newFilters: ICandidateFilters = {
      aics: genericFilters.find((f) => f.label === 'Ethnicities')?.selectedOptions ?? [],
      genders: genericFilters.find((f) => f.label === 'Gender')?.selectedOptions ?? [],
      locations: genericFilters.find((f) => f.label === 'Location')?.selectedOptions ?? [],
      statuses: genericFilters.find((f) => f.label === 'Status')?.selectedOptions ?? [],
      sessionIds: filters.sessionIds
    };
    setGenericFilters(genericFilters);
    setFilters(newFilters);
  };

  return (
    <Fragment key={'right'}>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          borderRadius: '4px',
          marginBottom: '16px',
          marginInline: 0
        }}
      >
        <Container
          maxWidth={false}
          disableGutters
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            width: 'auto',
            justifyContent: 'space-between',
            gap: '8px',
            borderRadius: '4px',
            marginInline: 0
          }}
        >
          <div className={styles.searchContainer}>
            <OutlinedInput
              sx={{
                width: '100%',
                backgroundColor: 'white'
              }}
              type={'text'}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
              placeholder={'Search candidates'}
            />
            {!isMobile && (
              <FormControlLabel
                control={<Switch checked={tableView} onChange={onTableViewChanged} color="success" />}
                label={
                  <Typography
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      fontWeight: 'bold',
                      flexShrink: 0,
                      fontSize: 14,
                      marginBlock: 0
                    }}
                  >
                    Table View
                  </Typography>
                }
                labelPlacement="start"
              />
            )}
            <Typography
              component={'h4'}
              sx={{
                display: { xs: 'none', sm: 'block' },
                fontWeight: 'bold',
                flexShrink: 0,
                fontSize: 14
              }}
            >
              Total Candidates: {totalRecords}
            </Typography>

            <FilterButton filterTypes={genericFilters} onFilterClicked={onFilterApplied} />
          </div>
          {!isMobile && <ImportCandidateButton showBulkImport={showBulkImport} />}

          <Typography
            component={'h4'}
            sx={{
              display: { xs: 'block', sm: 'none' },
              fontWeight: 'bold',
              fontSize: 14
            }}
          >
            Total Candidates: {totalRecords}
          </Typography>
        </Container>
      </Container>
    </Fragment>
  );
};

export default CandidateSearchBar;

import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import { changeTeamCaptains } from '../actions/sessionsActions';
import { ISessionTeamCaptain } from '../models/sessionsModel';
import * as OpTypes from '../types/sessionOperationTypes';

function* getTeamCaptains(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    const data: ISessionTeamCaptain[] = response.data as ISessionTeamCaptain[];
    yield put(changeTeamCaptains(data));
  }
}

export function* getTeamCaptainsSaga() {
  yield takeLatest(OpTypes.GET_TEAM_CAPTAINS, getTeamCaptains);
}

import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import { changeSessionUpdateStatus } from '../actions/sessionsOperationActions';
import { SessionUpdateStatus } from '../enums/sessionEnum';
import * as OpTypes from '../types/sessionOperationTypes';

function* updateSession(action: any): any {
  yield put(changeSessionUpdateStatus(SessionUpdateStatus.Updating));

  const { success } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);

  if (success) {
    yield put(changeSessionUpdateStatus(SessionUpdateStatus.Success));
  } else {
    yield put(changeSessionUpdateStatus(SessionUpdateStatus.Failure));
  }
}

export function* updateSessionSaga() {
  yield takeLatest(OpTypes.UPDATE_SESSION, updateSession);
}

import { MenuItem, Popover } from '@mui/material';
import React, { useState } from 'react';
import styles from './ContextMenu.module.scss';

interface IOption {
  label: string;
  value: any;
  icon?: React.ReactNode;
  enabled?: boolean;
}

interface IProps {
  options: IOption[];
  onChange: (value: any) => void;
  component: React.ReactNode;
}

const ContextMenu: React.FC<IProps> = ({ options, onChange, component }: IProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (value: any) => {
    handleClose();
    onChange(value);
  };

  return (
    <>
      <div onClick={(event) => setAnchorEl(event.currentTarget)} style={{ cursor: 'pointer' }}>
        {component}
      </div>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        className={styles.popover}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            onClick={() => handleOptionClick(option.value)}
            className={styles.menuItem}
            disabled={!option.enabled}
          >
            {option.icon}
            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default ContextMenu;

import { getOpenDayCalibrations } from 'modules/calibration/operations/actions';
import { CalibrationType } from 'modules/calibration/operations/enums';
import { ICandidateCalibration } from 'modules/calibration/operations/models';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { IGlobalState } from 'startup/store/globalState';
import { CalibrationCandidateCard } from '../CalibrationCandidateCard/CalibrationCandidateCard';

export interface IProps {
  allCardsExpanded: boolean;
  displayedCalibrations: ICandidateCalibration[];
}

export const PreCalibrationView: React.FC<IProps> = (props: IProps) => {
  const { calibrations: fetchedCalibrations, createCalibrationsSuccess } = useSelector((state: IGlobalState) => {
    return {
      ...state.calibrationState.openDayCalibration,
      ...state.calibrationState
    };
  });

  const dispatch = useDispatch();
  const { allCardsExpanded } = props;
  const [calibrations, setCalibrations] = useState<ICandidateCalibration[]>([]);
  const { openDayId } = useParams();

  useEffect(() => {
    setCalibrations(props.displayedCalibrations);
  }, [createCalibrationsSuccess, props.displayedCalibrations]);

  useEffect(() => {
    if (createCalibrationsSuccess && openDayId) {
      dispatch(getOpenDayCalibrations(+openDayId, CalibrationType.PreCalibration));
    }

    if (openDayId && fetchedCalibrations.length == 0) {
      setCalibrations(fetchedCalibrations);
    }
  }, [createCalibrationsSuccess]);

  return (
    <>
      {calibrations?.map((calibration) => (
        <CalibrationCandidateCard
          calibrationType={CalibrationType.PreCalibration}
          allCardsExpanded={allCardsExpanded}
          key={calibration?.id}
          candidateCalibration={calibration}
        />
      ))}
    </>
  );
};

import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { HttpMethod } from '../../../../common/enums/appEnums';
import { IFeedbackCategory } from '../models/feedbackCategoryModel';
import * as Types from '../types';

export const getFeedbackCategories = (): IActionRequest<undefined> => {
  return {
    type: Types.GET_FEEDBACK_CATEGORIES,
    httpMethod: HttpMethod.Get,
    url: `/feedbackCategories`,
    onSuccess: getFeedbackCategoriesSucceed
  };
};

export const getFeedbackCategoriesSucceed = (payload: IFeedbackCategory[]): IActionType<IFeedbackCategory[]> => ({
  type: Types.GET_FEEDBACK_CATEGORIES_SUCCEED,
  payload
});

export const saveFeedbackCategory = (payload: IFeedbackCategory): IActionRequest<IFeedbackCategory> => {
  return {
    type: Types.SAVE_FEEDBACK_CATEGORY,
    httpMethod: payload.id ? HttpMethod.Put : HttpMethod.Post,
    url: `/feedbackCategories`,
    onSuccess: saveFeedbackCategorySuccess,
    payload: payload
  };
};

export const saveFeedbackCategorySuccess = (payload: any): IActionType<any> => ({
  type: Types.SAVE_FEEDBACK_CATEGORY_SUCCEED,
  payload
});

export const removeFeedbackCategory = (id: number): IActionRequest<undefined> => {
  return {
    type: Types.REMOVE_FEEDBACK_CATEGORY,
    httpMethod: HttpMethod.Delete,
    url: `/feedbackCategories/${id}`,
    onSuccess: removeFeedbackCategorySuccess
  };
};

export const removeFeedbackCategorySuccess = (payload: any): IActionType<any> => ({
  type: Types.REMOVE_FEEDBACK_CATEGORY_SUCCEED,
  payload
});

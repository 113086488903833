import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import * as candidateActions from '../actions/candidatesOperationActions';
import * as OpTypes from '../types/candidatesOperationTypes';

function* bulkImportCandidates(action: any): any {
  yield put(candidateActions.bulkImportCandidatesClearErrors());
  const { response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* bulkImportCandidatesSaga() {
  yield takeLatest(OpTypes.BULK_IMPORT_CANDIDATES, bulkImportCandidates);
}

function* saveBulkImportCandidates(action: any): any {
  yield put(candidateActions.bulkImportCandidatesClearErrors());
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* saveBulkImportCandidatesSaga() {
  yield takeLatest(OpTypes.SAVE_BULK_IMPORT_CANDIDATES, saveBulkImportCandidates);
}

import { HttpMethod } from 'common/enums/appEnums';
import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { ICandidateCalibration, ICandidateCalibrationRequest } from '../models';
import * as Types from '../types/index';

export const createOpenDayCalibrations = (openDayId: number): IActionRequest<number> => {
  return {
    type: Types.CREATE_OPEN_DAY_CALIBRATIONS,
    httpMethod: HttpMethod.Post,
    url: `/calibrations/session/${openDayId}`,
    onFailure: createOpenDayCalibrationsFailed,
    onSuccess: createOpenDayCalibrationsSucceeded,
    payload: openDayId
  };
};

export const createOpenDayCalibrationsSucceeded = (payload: number): IActionType<number> => {
  return {
    type: Types.CREATE_OPEN_DAY_CALIBRATIONS_SUCCEEDED,
    payload
  };
};

export const createOpenDayCalibrationsFailed = (payload: number): IActionType<number> => {
  return {
    type: Types.CREATE_OPEN_DAY_CALIBRATIONS_FAILED,
    payload
  };
};

export const getOpenDayCalibrations = (sessionId: number, interviewType?: string): IActionRequest<number> => {
  const interviewTypeParam = interviewType ? `/interview_type/${interviewType}` : '';
  return {
    type: Types.GET_OPEN_DAY_CALIBRATIONS,
    httpMethod: HttpMethod.Get,
    url: `/calibrations/session/${sessionId}${interviewTypeParam}`,
    onFailure: getOpenDayCalibrationsFailed,
    onSuccess: getOpenDayCalibrationsSucceeded,
    payload: sessionId
  };
};

export const retrievedOpenDayCalibrations = (
  payload: ICandidateCalibration[]
): IActionType<ICandidateCalibration[]> => {
  return {
    type: Types.OPEN_DAY_CALIBRATIONS_RETRIEVED,
    payload
  };
};

export const getOpenDayCalibrationsSucceeded = (payload: number): IActionType<number> => {
  return {
    type: Types.GET_OPEN_DAY_CALIBRATIONS_SUCCEEDED,
    payload
  };
};

export const getOpenDayCalibrationsFailed = (payload: number): IActionType<number> => {
  return {
    type: Types.GET_OPEN_DAY_CALIBRATIONS_FAILED,
    payload
  };
};

export const saveCalibrationOffer = (
  payload: ICandidateCalibrationRequest
): IActionRequest<ICandidateCalibrationRequest> => {
  return {
    type: Types.SAVE_CALIBRATION_OFFER,
    httpMethod: HttpMethod.Put,
    url: `/calibrations`,
    onFailure: saveCalibrationOfferFailed,
    onSuccess: saveCalibrationOfferSucceeded,
    payload
  };
};

export const saveCalibrationOfferSucceeded = (): IActionType<boolean> => {
  return {
    type: Types.SAVE_CALIBRATION_OFFER_SUCCESS,
    payload: true
  };
};

export const saveCalibrationOfferFailed = (payload: string): IActionType<string> => {
  return {
    type: Types.SAVE_CALIBRATION_OFFER_FAILED,
    payload
  };
};

export const updateCalibrationOffer = (payload: ICandidateCalibration): IActionType<ICandidateCalibration> => {
  return { type: Types.UPDATE_CALIBRATION_OFFER, payload };
};

export const changeUpdateOperation = (payload: boolean | undefined): IActionType<boolean | undefined> => {
  return {
    type: Types.UPDATE_OPERATION_CHANGED,
    payload
  };
};

export const getCandidateCalibrations = (sessionId: number, candidateId: number): IActionRequest<number> => {
  return {
    type: Types.GET_CANDIDATE_CALIBRATIONS,
    httpMethod: HttpMethod.Get,
    url: `/calibrations/session/${sessionId}/candidate/${candidateId}`,
    onFailure: getCandidateCalibrationsFailed,
    onSuccess: getCandidateCalibrationsSucceeded,
    payload: sessionId
  };
};

export const getCandidateCalibrationsSucceeded = (payload: number): IActionType<number> => {
  return {
    type: Types.GET_CANDIDATE_CALIBRATIONS_SUCCEEDED,
    payload
  };
};

export const getCandidateCalibrationsFailed = (payload: number): IActionType<number> => {
  return {
    type: Types.GET_CANDIDATE_CALIBRATIONS_FAILED,
    payload
  };
};

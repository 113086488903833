import produce from 'immer';
import { ICommon, commonDefault } from '../models/commonModel';
import * as Types from '../types/commonTypes';

export default function commonReducer(state: ICommon = commonDefault, action: any): ICommon {
  switch (action.type) {
    case Types.CHANGED_LOCATIONS: {
      return produce(state, (draft) => {
        draft.locations = action.payload;
      });
    }
    case Types.CHANGED_INSTITUTIONS: {
      return produce(state, (draft) => {
        draft.institutions = action.payload;
      });
    }
    case Types.CHANGED_QUALIFICATIONS: {
      return produce(state, (draft) => {
        draft.qualifications = action.payload;
      });
    }
    default:
      return state;
  }
}

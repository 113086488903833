import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { HttpMethod } from '../../../../common/enums/appEnums';
import * as Types from '../../../manage-users/operations/types';

export const getAllClaims = (): IActionRequest<undefined> => {
  return {
    type: Types.FETCH_ALL_CLAIMS,
    httpMethod: HttpMethod.Get,
    url: `/claim`,
    onSuccess: fetchedAllClaims
  };
};

export const fetchedAllClaims = (payload: any): IActionType<any> => ({
  type: Types.FETCHED_ALL_CLAIMS,
  payload
});

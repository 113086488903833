import { IActionType } from 'common/operations/types/actionTypes';
import * as Types from '../types/appTypes';

export const changeHttpActionStarted = (payload: string = 'all'): IActionType<string> => {
  return {
    type: Types.HTTP_ACTION_STARTED,
    payload
  };
};

export const changeHttpActionEnded = (payload: string = 'all'): IActionType<string> => {
  return {
    type: Types.HTTP_ACTION_ENDED,
    payload
  };
};

export const changeHttpError = (error?: string): IActionType<string> => {
  return {
    type: Types.HTTP_ERROR_CHANGED,
    payload: error
  };
};

export const changeLoading = (value: boolean): IActionType<boolean> => {
  return {
    type: Types.LOADING_CHANGED,
    payload: value
  };
};

export const changeShowBackdrop = (showBackdrop: boolean): IActionType<boolean> => {
  return {
    type: Types.SHOW_BACKDROP_CHANGED,
    payload: showBackdrop
  };
};

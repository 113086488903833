import { getOpenDayCalibrationSaga } from './getCalibrationSaga';
import { getCandidateyCalibrationSaga } from './getCandidateCalibrationsSaga';
import { saveCalibrationOfferSaga } from './saveCalibrationSaga';
import { createCalibrationSaga } from './createCalibrationsSaga';

export const calibrationSagas = [
  getOpenDayCalibrationSaga(),
  saveCalibrationOfferSaga(),
  getCandidateyCalibrationSaga(),
  createCalibrationSaga()
];

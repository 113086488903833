import { BasicModelState } from 'common';
import { ICandidateStatusState } from '../models/candidateStatusState';
import { IFeedbackCategoryState } from '../models/feedbackCategoryState';
import { ITagState } from '../models/tagState';
import candidateStatusReducer from './candidateStatusReducer';
import ethnicitiesReducer from './ethnicitiesReducer';
import feedbackCategoryReducer from './feedbackCategoryReducer';
import institutionsReducer from './institutionsReducer';
import qualificationsReducer from './qualificationsReducer';
import tagOperationReducer from './tagsReducerOperations';

export function tagsStateReducer(state: ITagState, action: any): ITagState {
  return tagOperationReducer(state, action);
}

export function feedbackCategoriesStateReducer(state: IFeedbackCategoryState, action: any): IFeedbackCategoryState {
  return feedbackCategoryReducer(state, action);
}

export function candidateStatusStateReducer(state: ICandidateStatusState, action: any): ICandidateStatusState {
  return candidateStatusReducer(state, action);
}

export function institutionsStateReducer(state: BasicModelState, action: any): BasicModelState {
  return institutionsReducer(state, action);
}

export function qualificationsStateReducer(state: BasicModelState, action: any): BasicModelState {
  return qualificationsReducer(state, action);
}

export function ethnicitiesStateReducer(state: BasicModelState, action: any): BasicModelState {
  return ethnicitiesReducer(state, action);
}

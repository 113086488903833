import { IInstitution } from '../models/institutionModel';
import { ILocation } from '../models/locationModel';
import { IQualification } from '../models/qualificationModel';
import { IActionType } from '../types/actionTypes';
import * as Types from '../types/commonTypes';

export const changeLocations = (payload: ILocation[]): IActionType<ILocation[]> => {
  return {
    type: Types.CHANGED_LOCATIONS,
    payload: payload
  };
};

export const changeInstitutions = (payload: IInstitution[]): IActionType<IInstitution[]> => {
  return {
    type: Types.CHANGED_INSTITUTIONS,
    payload: payload
  };
};

export const changeQualifications = (payload: IQualification[]): IActionType<IQualification[]> => {
  return {
    type: Types.CHANGED_QUALIFICATIONS,
    payload: payload
  };
};

export const onActionSuccess = (payload: any): IActionType<any> => {
  return {
    type: Types.ACTION_REQUEST_SUCCESS,
    payload: payload
  };
};

export const onActionFailed = (payload: any): IActionType<any> => {
  return {
    type: Types.ACTION_REQUEST_FAILED,
    payload: payload
  };
};

import { AddBoxOutlined, Clear, Search } from '@mui/icons-material';
import { Box, InputAdornment, Pagination, Tab, Tabs } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Container from '@mui/system/Container';
import FilterButton, { IGenericFilter } from 'common/components/FilterComponent/FilterButton';
import GuardedButton from 'common/components/GuardedButton/GuardedButton';
import { FilterTypeEnum } from 'common/enums/filterTypeEnum';
import { serialize } from 'common/helpers/serializationHelpers';
import useScreenSize from 'common/hooks/useScreenSize';
import { IPaginationRequest } from 'common/models/IPaginationRequest';
import { getLocations } from 'common/operations/actions/commonOperationActions';
import dayjs from 'dayjs';
import { IOpenDay } from 'modules/feedback/operations/models/feedbackModel';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import { getOpenDaySessions, getTeamCaptains } from 'modules/sessions/operations/actions/sessionsOperationActions';
import { ISessionFilters } from 'modules/sessions/operations/models/sessionsModel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import SessionsCard from '../SessionsCard/SessionsCard';
import styles from './SessionsList.module.scss';

interface IProps {
  tab: number;
  setTab: (tab: number) => void;
  openAddSessionHandler: () => void;
}

export const SessionsList: React.FC<IProps> = ({ tab, setTab, openAddSessionHandler }) => {
  const dispatch = useDispatch();
  const [openDaysRequest, setOpenDaysRequest] = useState<IPaginationRequest<ISessionFilters>>({
    pageSize: 5
  });
  const { isMobile } = useScreenSize();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [genericFilters, setGenericFilters] = useState<IGenericFilter[]>([
    {
      label: 'Location',
      reduxData: [],
      selectedOptions: [],
      filterType: FilterTypeEnum.SELECT
    },
    {
      label: 'Date',
      reduxData: [],
      selectedOptions: [],
      filterType: FilterTypeEnum.DATE_PICKER
    },
    {
      label: 'Assigned Attendees',
      reduxData: [],
      selectedOptions: [],
      filterType: FilterTypeEnum.SELECT
    }
  ]);
  const [sortBy, setSortBy] = useState<string>('dateTime');
  const [isAsc, setIsAsc] = useState<boolean>(false);
  const { openDays, locations, teamCaptains, sessionsOperation, loggedInUserId } = useSelector(
    (state: IGlobalState) => {
      return {
        openDays: state.sessionsState.sessions.sessions,
        locations: state.commonState.common.locations,
        teamCaptains: state.sessionsState.sessions.teamCaptains,
        sessionsOperation: state.sessionsState.sessionsOperation,
        loggedInUserId: state.authState.authUser?.id
      };
    }
  );

  useEffect(() => {
    if (locations.length > 0) {
      let newState = [...genericFilters];
      let index = newState.findIndex((x) => x.label == 'Location');
      newState[index] = {
        ...newState[index],
        reduxData: locations?.map((l) => l.name)
      };
      setGenericFilters(newState);
      dispatch(getTeamCaptains());
    }
  }, [locations]);

  useEffect(() => {
    let newState = [...genericFilters];
    let index = newState.findIndex((x) => x.label == 'Assigned Attendees');
    newState[index] = {
      ...newState[index],
      reduxData: teamCaptains?.map((x) => x.name)
    };
    setGenericFilters(newState);
  }, [teamCaptains]);

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  useEffect(() => {
    fetchOpenDays();
  }, [tab]);

  const fetchOpenDays = () => {
    const request: IPaginationRequest<ISessionFilters> = { ...openDaysRequest };
    request.searchTerm = searchTerm;
    request.sortBy = sortBy;
    request.ascending = isAsc;
    request.filters = {
      dates: genericFilters[1].selectedOptions,
      locations: genericFilters[0]?.selectedOptions,
      attendees: tab === 1 && loggedInUserId ? [loggedInUserId] : getTeamCaptainIds(genericFilters[2]?.selectedOptions)
    };
    dispatch(getOpenDaySessions(request ? serialize(request) : ''));
  };

  const getTeamCaptainIds = (names: String[]) => {
    let teamCaptainList = teamCaptains.filter((teamCaptain) => names.includes(teamCaptain.name));
    return teamCaptainList.map((x) => x.id);
  };

  const fetchDataFromApi = (filters: IGenericFilter[]) => {
    let newState = [...genericFilters];
    newState.forEach((newfilter, i) => {
      newState[i] = {
        ...newState[i],
        selectedOptions: filters[i].selectedOptions
      };
    });
    setGenericFilters(newState);
  };

  useEffect(() => {
    fetchOpenDays();
  }, [
    searchTerm,
    genericFilters,
    openDaysRequest,
    sessionsOperation.deletionStatus,
    sessionsOperation.creationStatus,
    sessionsOperation.updateStatus,
    sortBy,
    isAsc
  ]);

  const handleTabChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
    let { pageNo, ...currentRequest } = openDaysRequest;
    setOpenDaysRequest(currentRequest);
    setTab(value);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setOpenDaysRequest({ ...openDaysRequest, pageNo: value });
  };

  const handleSortByChange = (sortCol: string) => {
    if (sortBy === sortCol) {
      setIsAsc(!isAsc);
    } else {
      setSortBy(sortCol);
      setIsAsc(true);
    }
  };

  const renderList = (days: IOpenDay[]) => {
    const dateSorter = (a: IOpenDay, b: IOpenDay) => dayjs(b.date).diff(dayjs(a.date));

    return (
      <>
        {!isMobile && (
          <div className={styles.feedbackList}>
            <span
              className={`flexBasis15 ${styles.sortableCol}`}
              onClick={() => handleSortByChange('userFriendlyName')}
            >
              {process.env.REACT_APP_SESSION_NAME} Name
            </span>
            <span className={`flexBasis15 ${styles.sortableCol}`} onClick={() => handleSortByChange('dateTime')}>
              Date
            </span>
            <span className="flexBasis10">Location</span>
            <span className="flexBasis10">No Of Teams</span>
            <span className="flexBasis15">Assigned Attendees</span>
            <span className="flexBasis10"></span>
          </div>
        )}

        {
          // Make a copy of the days array because is frozen to prevent mutation of the redux state
          days.sort(dateSorter).map((day) => (
            <SessionsCard key={day.id} openDay={day} openAddSessionHandler={openAddSessionHandler} />
          ))
        }
        <Container className={styles.paginationContainer}>
          {openDays.totalPages > 1 && (
            <Container sx={{ marginBlock: 2 }} className={styles.paginationContainer}>
              <Pagination
                count={openDays.totalPages}
                page={openDays.pageNo}
                onChange={handlePageChange}
                shape="rounded"
              />
            </Container>
          )}
        </Container>
      </>
    );
  };

  return (
    <>
      <Box className={styles.w100}>
        <Box className={styles.paddingBottom1rem}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab label="All Sessions" className={styles.tab} />
            <Tab label="My Sessions" className={styles.tab} />
          </Tabs>
        </Box>
        <div className={styles.wrapper}>
          <Container className={styles.searchContainer} disableGutters={true}>
            <div className={styles.searchBar}>
              <OutlinedInput
                className={`${styles.searchInput} ${styles.w100}`}
                type="text"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                endAdornment={
                  <InputAdornment position="end" sx={{ paddingRight: '1rem' }}>
                    {searchTerm == '' ? <Search /> : <Clear onClick={() => setSearchTerm('')}></Clear>}
                  </InputAdornment>
                }
                placeholder={`Find ${process.env.REACT_APP_SESSION_NAME}`}
              />
            </div>
            <div className={styles.filterButton}>
              <FilterButton filterTypes={genericFilters} onFilterClicked={fetchDataFromApi}></FilterButton>
            </div>

            <div className={isMobile ? styles.mobileAddButton : ''}>
              <GuardedButton
                variant={'contained'}
                className={styles.addButton}
                sx={{
                  width: { xs: '100%', sm: 'auto' }
                }}
                startIcon={<AddBoxOutlined />}
                onClick={openAddSessionHandler}
                errorProps={{ disabled: true }}
                label={`New ${process.env.REACT_APP_SESSION_NAME}`}
                scopes={[
                  {
                    actionType: ClaimActionType.ADD,
                    claimType: ClaimTypes.MANAGE_OPEN_DAYS
                  }
                ]}
              />
            </div>
          </Container>
        </div>

        <div>{renderList(openDays.data.slice())}</div>
      </Box>
    </>
  );
};

export default SessionsList;

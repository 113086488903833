import { IRole } from './IRole';
import { IClaimModel } from './claimModel';

export interface IRoleState {
  roles: Array<IRole>;
  operationSuccess?: boolean | null;
}

export const defaultRoleState: IRoleState = {
  roles: []
};

import { ChevronRight, KeyboardArrowRight } from '@mui/icons-material';
import { Card, CardContent, Chip } from '@mui/material';
import ConfirmDialog from 'common/components/ConfirmDialog/ConfirmDialog';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import GuardedDelete from 'common/components/GuardedDelete/GuardedDelete';
import GuardedEdit from 'common/components/GuardedEdit/GuardedEdit';
import { ROUTES } from 'common/constants/routesConstants';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import { useCheckPermissions } from 'common/hooks/useCheckPermissions';
import dayjs from 'dayjs';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import { deleteOpenDaySession } from 'modules/sessions/operations/actions/sessionsOperationActions';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { formatDate, getWeekDay } from '../../../../common/helpers/dateHelpers';
import useScreenSize from '../../../../common/hooks/useScreenSize';
import { IOpenDay } from '../../operations/models/feedbackModel';
import styles from './OpenDayCard.module.scss';

interface IProps {
  openDay: IOpenDay;
}

export const OpenDayCard: React.FC<IProps> = ({ openDay }) => {
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const dateDiff = dayjs().startOf('D').diff(dayjs(openDay.date).startOf('D'), 'days');
  const isInThePast = dayjs(openDay.date)
    .set('hour', +openDay.endTime?.split(':')[0] ?? 0)
    .isBefore(dayjs());
  const openDayDateLocked = isInThePast || openDay.status === 'Completed';

  const handleDialogClose = (result: boolean) => {
    setIsDialogOpen(false);
    if (result) {
      dispatch(deleteOpenDaySession(openDay.id));
    }
  };

  const hasViewFeedbackCalibrationPermission = useCheckPermissions([
    {
      actionType: ClaimActionType.VIEW_ONLY,
      claimType: ClaimTypes.FEEDBACK_CALIBRATION
    }
  ]);

  const renderDesktop = () => {
    return (
      <Card
        className={`${styles.openDayCard} ${
          dateDiff > 0 ? styles.statusComplete : dateDiff == 0 ? styles.statusToday : styles.statusFuture
        }`}
      >
        <CardContent className={styles.cardContent}>
          <span className="flexBasis25">
            <b>{openDay.name}</b>
          </span>
          <span className="flexBasis15">{formatDate(openDay.date)}</span>
          <span className="flexBasis10">
            <Chip label={openDay.location.name} variant="outlined" size="small" className={styles.chip} />
          </span>
          <span className="flexBasis15 textCenter">{openDay.noOfCandidates || 0}</span>
          <span className="flexBasis10">{openDay.status}</span>
          <span className="flexBasis25">
            <EntelectButton
              label="FEEDBACK"
              endIcon={KeyboardArrowRight}
              handleClick={() => navigate(`${ROUTES.OpenDays}/${openDay.id}`)}
              variant={ButtonType.Success}
              className={styles.actionLink}
            />
            {dateDiff >= 0 && hasViewFeedbackCalibrationPermission && (
              <EntelectButton
                label="CALIBRATION "
                endIcon={KeyboardArrowRight}
                handleClick={() => {
                  navigate(`/calibration/${openDay.id}`);
                }}
                variant={ButtonType.Success}
                className={styles.actionLink}
              />
            )}
          </span>
          <span className="flexBasis10">
            <GuardedEdit
              onClick={() => navigate(`${ROUTES.OpenDays}/edit/${openDay.id}`)}
              editStyle="outlined"
              scopes={[
                {
                  actionType: ClaimActionType.EDIT,
                  claimType: ClaimTypes.MANAGE_OPEN_DAYS
                }
              ]}
              renderErrorItem={() => <></>}
            />
            <GuardedDelete
              onClick={() => setIsDialogOpen(true)}
              editStyle="outlined"
              disabled={openDayDateLocked}
              scopes={[
                {
                  actionType: ClaimActionType.EDIT,
                  claimType: ClaimTypes.MANAGE_OPEN_DAYS
                }
              ]}
              renderErrorItem={() => <></>}
            />
            <ConfirmDialog
              message={`You are about to delete ${process.env.REACT_APP_SESSION_NAME}: "${openDay.name}".\n This cannot be undone.`}
              title={`Delete ${process.env.REACT_APP_SESSION_NAME}`}
              isOpen={isDialogOpen}
              positiveLabel="Yes, Delete"
              negativeLabel="No, Cancel"
              handleClose={handleDialogClose}
            ></ConfirmDialog>
          </span>
        </CardContent>
      </Card>
    );
  };

  const renderMobile = () => {
    return (
      <div className={`${styles.cardMobile}`} onClick={() => navigate(`${ROUTES.OpenDays}/${openDay.id}`)}>
        <div className={`${styles.leftSection} ${dateDiff == 0 ? styles.colorBlue : styles.colorGreen}`}>
          <span>{getWeekDay(openDay.date, true)}</span>
          <br />
          <span>
            <b>{formatDate(openDay.date, true)}</b>
          </span>
          <br />
        </div>
        <div className={styles.rightSection}>
          {openDay.name}
          <br />
          <Chip
            label={`${openDay.location.name} - ${openDay.location.description}`}
            variant="outlined"
            size="small"
            className={styles.chip}
          />
          <br />
          <small>{openDay.status}</small>
        </div>
        <div style={{ paddingRight: '0.5rem', fontWeight: 700 }}>
          <ChevronRight sx={{ height: 18, width: 18, cursor: 'pointer' }} />
        </div>
      </div>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default OpenDayCard;

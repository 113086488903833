import { Grid, Typography } from '@mui/material';
import { TagRequired } from 'modules/manage-app/enums/tags';
import { getTagData } from 'modules/manage-app/helpers/tagHelper';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../../common/styles/gridTable.module.scss';
import { IGlobalState } from '../../../../startup/store/globalState';
import { getTags } from '../../operations/actions/tagOperationActions';
import DeleteTag from './DeleteTag';
import SaveTag from './SaveTag';

const Tags = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTags());
  }, []);

  const { tags } = useSelector((state: IGlobalState) => ({ ...state.tagState }));

  return (
    <>
      <SaveTag tags={tags} />
      <h2>Feedback Tags</h2>
      <Grid className={styles.header} container spacing={2}>
        <Grid item xs={4}>
          TAG NAME
        </Grid>
        <Grid item xs={3}>
          SELECTION OPTIONS
        </Grid>
        <Grid item xs={2}>
          DEFAULT SELECTION
        </Grid>
        <Grid item xs={1}>
          REQUIRED
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      {tags.map((tag, key) => {
        const data = getTagData(tag);
        return (
          <Grid key={`tags_${key}`} className={styles.row} container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography>
                <span />
                <span>{tag.name}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography>
                <span>Selection Screen</span>
                <span>{data.selection}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography>
                <span>Default Selection</span>
                <span>{data.default}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <Typography>
                <span>REQUIRED</span>
                <span>{TagRequired[tag.required ? 1 : 0]}</span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} className={styles.actionButtons}>
              <SaveTag tags={tags} tag={tag} />
              <DeleteTag tag={tag} />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default Tags;

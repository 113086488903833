import { IActionType } from 'common/operations/types/actionTypes';
import { mapTag } from 'modules/manage-app/helpers/tagHelper';
import { ITag } from '../models/tagModel';
import * as Types from '../types';

export const changeTags = (tags: ITag[] = []): IActionType<ITag[]> => {
  return {
    type: Types.CHANGED_TAGS,
    payload: tags.map(mapTag)
  };
};

import getConfiguration from 'modules/shared/helpers/configuration';
import { debounce, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import * as Types from '../types';

function* getAll(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

function* save(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status201Created, HttpStatus.Status200OK]);
}

function* remove(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* getQualifications() {
  yield debounce(getConfiguration().apiDebounceMs, Types.GET_QUALIFICATIONS, getAll);
}

export function* saveQualification() {
  yield takeLatest(Types.SAVE_QUALIFICATION, save);
}

export function* removeQualification() {
  yield takeLatest(Types.REMOVE_QUALIFICATION, remove);
}

import produce from 'immer';
import IApp, { appDefault, IHttpAction } from '../models/appModel';
import * as OpTypes from '../types/appTypes';

export default function appReducer(state: IApp = appDefault, action: any): IApp {
  switch (action.type) {
    case OpTypes.HTTP_ACTION_STARTED: {
      return produce(state, (draft) => {
        draft.httpAction[action.payload as keyof IHttpAction] = true;
        draft.loading = true;
      });
    }
    case OpTypes.HTTP_ACTION_ENDED: {
      return produce(state, (draft) => {
        if (!action.payload || action.payload === 'all') {
          Object.keys(draft.httpAction).forEach((key) => {
            draft.httpAction[key as keyof IHttpAction] = false;
          });
          return;
        }
        draft.httpAction[action.payload as keyof IHttpAction] = false;
      });
    }
    case OpTypes.HTTP_ERROR_CHANGED: {
      return produce(state, (draft) => {
        draft.error = action.payload;
      });
    }
    case OpTypes.LOADING_CHANGED: {
      return produce(state, (draft) => {
        draft.loading = Object.values(draft.httpAction).includes(true);
      });
    }
    case OpTypes.SHOW_BACKDROP_CHANGED: {
      return produce(state, (draft) => {
        draft.showBackdrop = action.payload;
      });
    }
    default:
      return state;
  }
}

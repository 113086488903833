import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { feedbackActions } from '../actions/index';
import * as OpTypes from '../types/feedbackOperationTypes';

function* getCompletedOpenDays(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    yield put(feedbackActions.changeCompletedOpenDays(response.data));
  }
}

export function* getCompletedOpenDaysSaga() {
  yield takeLatest(OpTypes.GET_COMPLETED_OPEN_DAYS, getCompletedOpenDays);
}

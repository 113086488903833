import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import { changeFeedBackDeleteStatus } from '../actions/feedbackOperationActions';
import { FeedbackDeletionStatus } from '../enums/feedbackEnums';
import * as OpTypes from '../types/feedbackOperationTypes';

function* deleteTeamFeedbackPost(action: any): any {
  yield put(changeFeedBackDeleteStatus(FeedbackDeletionStatus.Deleting));
  const { success } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);

  if (success) {
    yield put(changeFeedBackDeleteStatus(FeedbackDeletionStatus.Success));
  } else {
    yield put(changeFeedBackDeleteStatus(FeedbackDeletionStatus.Failure));
  }
}

export function* deleteTeamFeedbackPostSaga() {
  yield takeLatest(OpTypes.DELETE_TEAM_FEEDBACK_POST, deleteTeamFeedbackPost);
}

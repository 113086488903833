import { IPaginationRequest } from 'common/models/IPaginationRequest';
import { IPaginatedResponse } from 'common/models/paginatedResponse';
import dayjs, { Dayjs } from 'dayjs';
import { ILocation, IOpenDay, ITeam } from 'modules/feedback/operations/models/feedbackModel';
import { ITag } from 'modules/manage-app/operations/models/tagModel';

export default interface ISessions {
  sessions: IPaginatedResponse<IOpenDay>;
  teamCaptains: ISessionTeamCaptain[];
  sessionById: IOpenDay | null;
  summaries: IPaginatedResponse<IOpenDaySummary>;
  sessionTeams: ITeam[];
}

export interface IOpenDaySummary {
  id: string;
  name: string;
  date: string;
  location: ILocation;
  status: string | null;
  noOfCandidates: number;
  candidatesPresent: number;
  candidatesAbsent: number;
  offersSent: number;
}

export interface ISessionFilters {
  locations?: string[];
  dates?: string[];
  attendees?: number[];
  statuses?: string[];
}

export interface ISessionForm {
  userFriendlyName?: string;
  dateTime?: Dayjs;
  sessionTime?: Dayjs[];
  startTime?: Dayjs;
  endTime?: Dayjs;
  locationId?: any;
  teams?: ISessionTeamRequestDto[];
  candidateIds?: number[];
  tags?: ITag[];
}

export interface ISessionTeamCaptain {
  id: number;
  name: string;
}

export interface ISessionTeamCandidateResponseDto {
  id: string;
  name: string;
}

export const getDefaultSessionForm = (): ISessionForm => ({
  userFriendlyName: '',
  dateTime: dayjs().add(1, 'day'),
  sessionTime: [],
  startTime: dayjs().set('hour', 8).set('minute', 0).set('second', 0),
  endTime: dayjs().set('hour', 15).set('minute', 0).set('second', 0),
  locationId: -1,
  teams: [],
  candidateIds: [],
  tags: []
});

export const castIOpenDayToSessionForm = (openDay: IOpenDay): ISessionForm => ({
  userFriendlyName: openDay.name,
  dateTime: dayjs(openDay.date),
  sessionTime: [],
  startTime: dayjs(openDay.startTime, 'HH:mm:ss'),
  endTime: dayjs(openDay.endTime, 'HH:mm:ss'),
  locationId: openDay.location.id,
  tags: [...(openDay.tags ?? [])].sort((a, b) => a.priority - b.priority)
});

export const getDefaultSession = (): IPaginatedResponse<IOpenDay> => ({
  pageNo: 0,
  pageSize: 0,
  totalRecords: 0,
  totalRecordsOnPage: 0,
  totalPages: 0,
  data: []
});

export const getDefaultSessionSummaries = (): IPaginatedResponse<IOpenDaySummary> => ({
  pageNo: 0,
  pageSize: 0,
  totalRecords: 0,
  totalRecordsOnPage: 0,
  totalPages: 0,
  data: []
});

export const sessionsDefault: ISessions = {
  sessions: getDefaultSession(),
  teamCaptains: [],
  sessionById: null,
  summaries: getDefaultSessionSummaries(),
  sessionTeams: []
};

export interface ISessionTeamRequestDto {
  id?: number;
  name?: string;
  teamCaptainId?: number;
  candidateIds?: number[];
  isDeleted?: boolean;
}

export const defaultSessionFilter: IPaginationRequest<ISessionFilters> = {
  searchTerm: '',
  sortBy: 'dateTime',
  ascending: false,
  filters: {}
};

import { ROUTES } from 'common/constants/routesConstants';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { getCandidates } from 'modules/candidates/operations/actions/candidatesOperationActions';
import { getTags } from 'modules/manage-app/operations/actions/tagOperationActions';
import { getUsers } from 'modules/manage-users/operations/actions/userActions';
import { getSessionById } from 'modules/sessions/operations/actions/sessionsOperationActions';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { IGlobalState } from '../../../startup/store/globalState';
import Feedback from '../components/Feedback/Feedback';
import {
  changeFeedBackDeleteStatus,
  changeFeedBackUpdateStatus,
  getFeedbackPosts,
  getTeamFeedbackPosts
} from '../operations/actions/feedbackOperationActions';
import { FeedbackDeletionStatus, FeedbackUpdateStatus } from '../operations/enums/feedbackEnums';

export const FeedbackContainer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const sessionId = useParams().sessionId;

  const { updateStatus, deletionStatus, openDay, creationStatus } = useSelector((state: IGlobalState) => ({
    ...state.feedbackState.feedbackOperation,
    openDay: state.sessionsState.sessionsOperation.openDay
  }));

  const queryParams = new URLSearchParams(window.location.search);
  const urlMemberId = queryParams.get('memberId');
  const urlTeamId = queryParams.get('teamId');

  const updateOpenDay = useCallback(() => {
    if (sessionId) {
      dispatch(getSessionById(sessionId));
      dispatch(getCandidates(+sessionId, ''));
      dispatch(getTags(+sessionId));
    }
  }, [sessionId]);

  const handleCloseFeedback = () => {
    navigate(ROUTES.OpenDays);
  };

  useEffect(() => {
    updateOpenDay();
  }, [sessionId, creationStatus, deletionStatus]);

  useEffect(() => {
    sessionId && dispatch(getSessionById(sessionId));
  }, [creationStatus, deletionStatus]);

  useEffect(() => {
    dispatch(getUsers({ pageSize: undefined, filters: { statuses: true } }));
  }, []);

  useEffect(() => {
    let hasBeenUpdated = false;
    if ([FeedbackUpdateStatus.Success, FeedbackUpdateStatus.Failure].includes(updateStatus)) {
      snackbar.snackbar({
        message: updateStatus,
        variant: updateStatus === FeedbackUpdateStatus.Success ? 'success' : 'error'
      });
      dispatch(changeFeedBackUpdateStatus(FeedbackUpdateStatus.Idle));
      hasBeenUpdated = true;
    }

    if ([FeedbackDeletionStatus.Success, FeedbackDeletionStatus.Failure].includes(deletionStatus)) {
      snackbar.snackbar({
        message: deletionStatus,
        variant: deletionStatus === FeedbackDeletionStatus.Success ? 'success' : 'error'
      });
      dispatch(changeFeedBackDeleteStatus(FeedbackDeletionStatus.Idle));
      hasBeenUpdated = true;
    }

    if (hasBeenUpdated) {
      urlTeamId && dispatch(getTeamFeedbackPosts(urlTeamId, openDay?.id as number));
      urlMemberId && dispatch(getFeedbackPosts(urlMemberId, openDay?.id as number));
    }
  }, [updateStatus, deletionStatus]);

  return openDay ? <Feedback handleCloseFeedback={() => handleCloseFeedback()} openDay={openDay} /> : <></>;
};

export default FeedbackContainer;

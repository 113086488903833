import { IBasicModel } from 'common/models';
import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../enums/appEnums';
import { ApiSagaRequest } from '../../saga/apiSaga';
import { commonActions } from '../actions';
import * as OpTypes from '../types/commonOperationTypes';

function* getLocations(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    yield put(commonActions.changeLocations(response.data as IBasicModel[]));
  }
}

export function* getLocationsSaga() {
  yield takeLatest(OpTypes.GET_LOCATIONS, getLocations);
}

import { HttpMethod } from 'common/enums/appEnums';
import * as Types from '../types/commonOperationTypes';

// locations

export const getLocations = (): Types.IGetLocationsActionType => {
  return {
    type: Types.GET_LOCATIONS,
    httpMethod: HttpMethod.Get,
    url: `/lookups/locations`,
    onSuccess: getLocationsSucceed,
    onFailure: getLocationsFailed,
    payload: null
  };
};

export const getLocationsSucceed = (payload: any): Types.IGetLocationsSucceedActionType => {
  return {
    type: Types.GET_LOCATIONS_SUCCEED,
    payload
  };
};

export const getLocationsFailed = (payload: any): Types.IGetLocationsFailedActionType => {
  return {
    type: Types.GET_LOCATIONS_FAILED,
    payload
  };
};

// institutions

export const getInstitutions = (): Types.IGetInstitutionsActionType => {
  return {
    type: Types.GET_INSTITUTIONS,
    httpMethod: HttpMethod.Get,
    url: `/lookups/institutions`,
    onSuccess: getInstitutionsSucceed,
    onFailure: getInstitutionsFailed,
    payload: null
  };
};

export const getInstitutionsSucceed = (payload: any): Types.IGetInstitutionsSucceedActionType => {
  return {
    type: Types.GET_INSTITUTIONS_SUCCEED,
    payload
  };
};

export const getInstitutionsFailed = (payload: any): Types.IGetInstitutionsFailedActionType => {
  return {
    type: Types.GET_INSTITUTIONS_FAILED,
    payload
  };
};

// qualifications

export const getQualifications = (): Types.IGetQualificationsActionType => {
  return {
    type: Types.GET_QUALIFICATIONS,
    httpMethod: HttpMethod.Get,
    url: `/lookups/qualifications`,
    onSuccess: getQualificationsSucceed,
    onFailure: getQualificationsFailed,
    payload: null
  };
};

export const getQualificationsSucceed = (payload: any): Types.IGetQualificationsSucceedActionType => {
  return {
    type: Types.GET_QUALIFICATIONS_SUCCEED,
    payload
  };
};

export const getQualificationsFailed = (payload: any): Types.IGetQualificationsFailedActionType => {
  return {
    type: Types.GET_QUALIFICATIONS_FAILED,
    payload
  };
};

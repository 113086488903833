import { FormControl, FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { useCheckPermissions } from 'common/hooks/useCheckPermissions';
import styles from 'common/styles/colors.module.scss';
import { hasEditFeedbackCalibration } from 'modules/manage-app/helpers/permissionsHelper';
import { useEffect, useState } from 'react';

export interface IProps {
  offer?: string;
  onOfferChanged: (offer: string) => void;
  showOfferChanges?: boolean;
  offerChanges?: string;
}
export const CalibrationOfferView: React.FC<IProps> = (props: IProps) => {
  const { offer, onOfferChanged, showOfferChanges, offerChanges } = props;
  const [defaultOffer, setDefaultOffer] = useState<string>('');
  const canUpdateOffer = useCheckPermissions(hasEditFeedbackCalibration);

  useEffect(() => {
    if (offer) {
      setDefaultOffer(offer);
    }
  }, [offer]);

  return (
    <>
      <FormControl>
        <RadioGroup value={defaultOffer} row aria-labelledby="offer-group-radio-btn" name="row-offer-radio-btns-group">
          <FormControlLabel
            onClick={(e) => {
              e.stopPropagation();
              if (canUpdateOffer) onOfferChanged('YES');
            }}
            disabled={!canUpdateOffer}
            value={'YES'}
            control={<StyledPositiveRadio />}
            label="Yes"
          />
          <FormControlLabel
            onClick={(e) => {
              e.stopPropagation();
              if (canUpdateOffer) onOfferChanged('NO');
            }}
            disabled={!canUpdateOffer}
            value={'NO'}
            control={<StyledNegativeRadio></StyledNegativeRadio>}
            label="No"
          />
          <FormControlLabel
            onClick={(e) => {
              e.stopPropagation();
              if (canUpdateOffer) onOfferChanged('MAYBE');
            }}
            disabled={!canUpdateOffer}
            value={'MAYBE'}
            control={<StyledNeutralRadio />}
            label="Maybe"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};
const StyledPositiveRadio = styled(Radio)({
  color: styles.entelectGreen,
  '&.Mui-checked': {
    color: styles.entelectGreen
  }
});
const StyledNegativeRadio = styled(Radio)({
  color: styles.negativeButton,
  '&.Mui-checked': {
    color: styles.negativeButton
  }
});
const StyledNeutralRadio = styled(Radio)({
  color: styles.warning,
  '&.Mui-checked': {
    color: styles.warning
  }
});

import { HttpMethod } from 'common/enums/appEnums';
import { serialize } from 'common/helpers/serializationHelpers';
import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { IOpenDay } from 'modules/feedback/operations/models/feedbackModel';
import {
  SessionCreationStatus,
  SessionDeletionStatus,
  SessionUpdateStatus,
  TeamToSessionStatus
} from '../enums/sessionEnum';
import { ISessionForm, ISessionTeamRequestDto, defaultSessionFilter } from '../models/sessionsModel';
import * as Types from '../types/sessionOperationTypes';

export const createSession = (payload: ISessionForm): IActionRequest<ISessionForm> => {
  return {
    type: Types.CREATE_SESSION,
    httpMethod: HttpMethod.Post,
    url: `/sessions`,
    payload: payload
  };
};

export const getTeamCaptains = (): IActionRequest<string> => {
  return {
    type: Types.GET_TEAM_CAPTAINS,
    httpMethod: HttpMethod.Get,
    url: `/lookups/teamCaptains`
  };
};

export const getOpenDaySessions = (parameters: string): IActionRequest<string> => {
  return {
    type: Types.GET_OPEN_DAY_SESSIONS,
    httpMethod: HttpMethod.Get,
    url: `/sessions`,
    payload: parameters || serialize(defaultSessionFilter)
  };
};

export const getOpenDaySessionSummaries = (parameters: string): IActionRequest<string> => {
  return {
    type: Types.GET_OPEN_DAY_SESSION_SUMMARIES,
    httpMethod: HttpMethod.Get,
    url: `/sessions/summaries`,
    payload: parameters || serialize(defaultSessionFilter)
  };
};

export const deleteOpenDaySession = (id: number): IActionRequest<string> => {
  return {
    type: Types.DELETE_SESSION,
    httpMethod: HttpMethod.Delete,
    url: `/sessions/${id}`
  };
};

export const updateOpenDaySession = (id: number, payload: ISessionForm): IActionRequest<ISessionForm> => {
  return {
    type: Types.UPDATE_SESSION,
    httpMethod: HttpMethod.Put,
    url: `/sessions/${id}`,
    payload: payload
  };
};

export const addTeamToSession = (
  sessionId: string,
  payload: ISessionTeamRequestDto
): IActionRequest<ISessionTeamRequestDto> => {
  return {
    type: Types.ADD_TEAM_TO_SESSION,
    httpMethod: HttpMethod.Post,
    url: `/sessions/${sessionId}/teams`,
    payload: payload
  };
};

export const getSessionById = (id: string): IActionRequest<string> => {
  return {
    type: Types.GET_SESSION_BY_ID,
    httpMethod: HttpMethod.Get,
    url: `/sessions/${id}`,
    onSuccess: getSessionByIdSucceed
  };
};

export const changeSessionCreateStatus = (status: SessionCreationStatus): IActionType<SessionCreationStatus> => {
  return {
    type: Types.CHANGE_SESSION_CREATION_STATUS,
    payload: status
  };
};

export const changeSessionDeleteStatus = (status: SessionDeletionStatus): IActionType<SessionDeletionStatus> => {
  return {
    type: Types.CHANGE_SESSION_DELETION_STATUS,
    payload: status
  };
};

export const changeSessionUpdateStatus = (status: SessionUpdateStatus): IActionType<SessionUpdateStatus> => {
  return {
    type: Types.CHANGE_SESSION_UPDATE_STATUS,
    payload: status
  };
};

export const changeActiveSessionInEdit = (session?: IOpenDay): IActionType<IOpenDay | undefined> => {
  return {
    type: Types.CHANGE_ACTIVE_SESSION_IN_EDIT,
    payload: session
  };
};

export const changeAddTeamToSessionStatus = (status: TeamToSessionStatus): IActionType<TeamToSessionStatus> => {
  return {
    type: Types.CHANGE_ADD_TEAM_TO_SESSION_STATUS,
    payload: status
  };
};

export const getSessionByIdSucceed = (payload?: IOpenDay): IActionType<IOpenDay> => {
  return {
    type: Types.GET_SESSION_BY_ID_SUCCEED,
    payload
  };
};

export const getUnavailableSessionDates = (): IActionRequest<undefined> => {
  return {
    type: Types.GET_UNAVAILABLE_SESSION_DATES,
    httpMethod: HttpMethod.Get,
    url: `/sessions/existing`,
    onSuccess: getUnavailableSessionDatesSucceed
  };
};

export const getUnavailableSessionDatesSucceed = (payload: string[]): IActionType<string[]> => {
  return {
    type: Types.GET_UNAVAILABLE_SESSION_DATES_SUCCEED,
    payload
  };
};

export const getOpenDaySessionTeams = (sessionId: number): IActionRequest<number> => {
  return {
    type: Types.GET_OPEN_DAY_SESSION_TEAMS,
    httpMethod: HttpMethod.Get,
    url: `/sessions/${sessionId}/teams`
  };
};

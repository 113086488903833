import { Announcement, ExpandMore } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import CardMedia from '@mui/material/CardMedia';
import { getCandidateCalibrations, saveCalibrationOffer } from 'modules/calibration/operations/actions';
import { CalibrationType } from 'modules/calibration/operations/enums';
import { ICandidateCalibration } from 'modules/calibration/operations/models';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import * as appStorageService from '../../../../common/helpers/appStorageService';
import { CalibrationOfferView } from '../CalibrationOfferView/CalibrationOfferView';
import CandidateImageModal from '../CandidateImageModal/CandidateImageModal';
import CalibrationContent from '../FeedbackModal/CalibrationContent';
import styles from './CalibrationCandidateCard.module.scss';

export interface IProps {
  candidateCalibration: ICandidateCalibration;
  allCardsExpanded: boolean;
  calibrationType: CalibrationType;
}
export const CalibrationCandidateCard: React.FC<IProps> = (props: IProps) => {
  const { candidateCalibration, allCardsExpanded, calibrationType } = props;
  const { selectedOpenDay, updateOperationSuccess, candidateId } = useSelector((state: IGlobalState) => ({
    selectedOpenDay: state.sessionsState.sessions.sessionById,
    updateOperationSuccess: state.calibrationState.updateOperationSuccess,
    candidateId: state.calibrationState.openDayCalibration.candidateId
  }));

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showImageModal, setShowImageModal] = React.useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    handleChange(allCardsExpanded);
  }, [allCardsExpanded]);

  useEffect(() => {
    if (updateOperationSuccess && candidateId === candidateCalibration?.candidate?.id) {
      handleChange(isExpanded);
    }
  }, [updateOperationSuccess]);

  const handleChange = (expand: boolean) => {
    setIsExpanded(expand);
    if (expand) {
      dispatch(getCandidateCalibrations(+(selectedOpenDay?.id ?? ''), candidateCalibration?.candidate?.id ?? 0));
    }
  };

  const handleOfferSelected = (offer: string) => {
    if (candidateCalibration) {
      const { id, candidate, sessionId, interviewTypeCode } = candidateCalibration;
      if (candidate?.id) {
        const saveCalibrationRequest = {
          id: id,
          sessionId: sessionId,
          candidateId: candidate.id,
          offerTypeCode: offer.toUpperCase(),
          interviewTypeCode: interviewTypeCode.toUpperCase()
        };
        dispatch(saveCalibrationOffer(saveCalibrationRequest as any));
      }
    }
  };

  const showOfferChanges = () => {
    if (candidateCalibration && calibrationType === CalibrationType.PostCalibration) {
      return candidateCalibration?.offerTypeCode !== undefined;
    }
    return false;
  };

  const draftPath = `${location.pathname}/${candidateCalibration?.id}`;
  const hasDrafts = !!appStorageService.get<any>(draftPath)?.feedbackComment?.length;
  const qualifications = candidateCalibration?.candidate?.candidateQualifications;
  const qualification = qualifications ? qualifications[qualifications.length - 1] : undefined;
  const backgroundColor =
    calibrationType === CalibrationType.PreCalibration
      ? styles.activePreCalibrationAccordion
      : calibrationType === CalibrationType.Interview
        ? styles.activeInterviewAccordion
        : styles.activePostCalibrationAccordion;
  return (
    <div className={styles.candidateCardContainer}>
      <MuiAccordion
        expanded={isExpanded}
        sx={{ border: 'none' }}
        onChange={(_, isExpanded) => handleChange(isExpanded)}
      >
        <MuiAccordionSummary
          className={styles.candidateAccordionSummary}
          sx={{ backgroundColor }}
          expandIcon={<ExpandMore />}
        >
          <span className={`${styles.candidateHeaderLeft} ${styles.candidateName}`}>
            <CardMedia
              component="img"
              className={`${styles.candidatePhoto} mr-05`}
              src={candidateCalibration?.candidate?.photo}
              onClick={() => setShowImageModal(true)}
            />
            &nbsp;&nbsp;&nbsp;
            {candidateCalibration?.candidate?.name}
          </span>
          <span className={`${styles.candidateHeaderLeft}`}>{candidateCalibration?.candidate?.team?.name}</span>
          <span className={`${styles.candidateHeaderLeft}`}>
            {candidateCalibration?.candidate?.team?.teamCaptain.name}
          </span>
          <span className={`${styles.candidateHeaderLeft}`}>
            {qualification?.institutionName} / {qualification?.qualificationName}
          </span>
          {calibrationType === CalibrationType.PostCalibration || calibrationType === CalibrationType.Interview ? (
            <span className={`${styles.candidateHeaderLeft}`}>{candidateCalibration?.interviewer?.displayName}</span>
          ) : (
            <span></span>
          )}
          <span className={`${styles.candidateHeaderLeft}`}>
            {!isExpanded && (
              <CalibrationOfferView
                showOfferChanges={showOfferChanges()}
                onOfferChanged={(offer) => handleOfferSelected(offer)}
                offer={candidateCalibration?.offerTypeCode ?? ''}
              />
            )}
            {!isExpanded && hasDrafts && (
              <Tooltip title="You have unsaved feedback comments">
                <Announcement className="colorPrimary" />
              </Tooltip>
            )}
          </span>
        </MuiAccordionSummary>
        <MuiAccordionDetails className={styles.candidateAccordionDetails}>
          {isExpanded && (
            <CalibrationContent
              key={candidateCalibration?.id}
              calibrationType={calibrationType}
              handleClose={() => handleChange(false)}
              candidateCalibration={candidateCalibration}
              compact={true}
            />
          )}
        </MuiAccordionDetails>
      </MuiAccordion>
      {showImageModal && (
        <CandidateImageModal
          handleClose={() => setShowImageModal(false)}
          photoUrl={candidateCalibration?.candidate?.photo || ''}
        />
      )}
    </div>
  );
};

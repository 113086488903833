import { FormControl, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { IRatingConfig } from '../../operations/models/tagModel';

interface IProps {
  onChange: (rating: IRatingConfig) => void;
  rating?: IRatingConfig;
  disabled?: boolean;
}

const TagRatingSelection = (props: IProps) => {
  const [rating, setRating] = useState(
    props.rating ||
      ({
        start: 1,
        end: 10,
        startDescription: '',
        endDescription: ''
      } as IRatingConfig)
  );

  const onChange = (e: any) => {
    const tempRating = { ...rating, [e.target.name]: e.target.value };
    setRating(tempRating);
    props.onChange(tempRating);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              name="start"
              onChange={onChange}
              value={rating.start}
              disabled={props.disabled}
              type="number"
              label="Rating From*"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              name="end"
              onChange={onChange}
              value={rating.end}
              disabled={props.disabled}
              type="number"
              label="Rating To*"
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              name="startDescription"
              onChange={onChange}
              value={rating.startDescription}
              label="Rating From Description*"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <TextField
              name="endDescription"
              onChange={onChange}
              value={rating.endDescription}
              label="Rating To Description*"
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default TagRatingSelection;

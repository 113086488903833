import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { HttpMethod } from '../../../../common/enums/appEnums';
import {
  AddCandidatesToTeamStatus,
  FeedbackCreationStatus,
  FeedbackDeletionStatus,
  FeedbackUpdateStatus,
  RemoveCandidateFromTeamStatus
} from '../enums/feedbackEnums';
import { AddCandidatesToTeamRequest, IFeedbackPost, ITeamFeedbackPost } from '../models/feedbackModel';
import * as Types from '../types/feedbackOperationTypes';

export const getFeedbackPosts = (candidateId: string, sessionId: string | number): IActionRequest<void> => {
  return {
    type: Types.GET_FEEDBACK_POSTS,
    httpMethod: HttpMethod.Get,
    url: `/feedback/candidate/${candidateId}/session/${sessionId}`
  };
};

export const getTeamFeedbackPosts = (teamId: string, sessionId: number | string): IActionRequest<void> => {
  return {
    type: Types.GET_TEAM_FEEDBACK_POSTS,
    httpMethod: HttpMethod.Get,
    url: `/teamfeedback/team/${teamId}/session/${sessionId}`
  };
};

export const submitFeedbackPost = (feedbackPost: IFeedbackPost): IActionRequest<IFeedbackPost> => {
  return {
    type: Types.SUBMIT_FEEDBACK_POST,
    httpMethod: HttpMethod.Post,
    url: `/feedback`,
    payload: feedbackPost
  };
};

export const submitTeamFeedbackPost = (teamFeedbackPost: ITeamFeedbackPost): IActionRequest<ITeamFeedbackPost> => {
  return {
    type: Types.SUBMIT_FEEDBACK_POST,
    httpMethod: HttpMethod.Post,
    url: `/teamfeedback`,
    payload: teamFeedbackPost
  };
};

export const deleteFeedbackPost = (id: string): IActionRequest<string> => {
  return {
    type: Types.DELETE_FEEDBACK_POST,
    httpMethod: HttpMethod.Delete,
    url: `/feedback/${id}`,
    payload: id
  };
};

export const deleteTeamFeedbackPost = (id: string): IActionRequest<string> => {
  return {
    type: Types.DELETE_TEAM_FEEDBACK_POST,
    httpMethod: HttpMethod.Delete,
    url: `/teamfeedback/${id}`,
    payload: id
  };
};

export const getOpenDays = (parameters: string): IActionRequest<string> => {
  return {
    type: Types.GET_OPEN_DAYS,
    httpMethod: HttpMethod.Get,
    url: `/sessions`,
    payload: parameters
  };
};

export const getCompletedOpenDays = (parameters: string): IActionRequest<string> => {
  return {
    type: Types.GET_COMPLETED_OPEN_DAYS,
    httpMethod: HttpMethod.Get,
    url: `/sessions`,
    payload: parameters
  };
};

export const updateAFeedbackPost = (id: string, payload: IFeedbackPost): IActionRequest<IFeedbackPost> => {
  return {
    type: Types.UPDATE_FEEDBACK_POST,
    httpMethod: HttpMethod.Put,
    url: `/feedback/${id}`,
    payload: payload
  };
};

export const updateATeamFeedbackPost = (id: string, payload: ITeamFeedbackPost): IActionRequest<ITeamFeedbackPost> => {
  return {
    type: Types.UPDATE_FEEDBACK_POST,
    httpMethod: HttpMethod.Put,
    url: `/teamfeedback/${id}`,
    payload: payload
  };
};

export const changeFeedBackCreateStatus = (status: FeedbackCreationStatus): IActionType<FeedbackCreationStatus> => {
  return {
    type: Types.CHANGE_FEEDBACK_POST_CREATION_STATUS,
    payload: status
  };
};

export const changeFeedBackDeleteStatus = (status: FeedbackDeletionStatus): IActionType<FeedbackDeletionStatus> => {
  return {
    type: Types.CHANGE_FEEDBACK_POST_DELETION_STATUS,
    payload: status
  };
};

export const changeTeamFeedBackDeleteStatus = (status: FeedbackDeletionStatus): IActionType<FeedbackDeletionStatus> => {
  return {
    type: Types.CHANGE_TEAM_FEEDBACK_POST_DELETION_STATUS,
    payload: status
  };
};

export const changeFeedBackUpdateStatus = (status: FeedbackUpdateStatus): IActionType<FeedbackUpdateStatus> => {
  return {
    type: Types.CHANGE_FEEDBACK_POST_UPDATE_STATUS,
    payload: status
  };
};

export const addCandidatesToTeam = (
  payload: AddCandidatesToTeamRequest
): IActionRequest<AddCandidatesToTeamRequest> => {
  return {
    type: Types.ADD_CANDIDATES_TO_TEAM,
    httpMethod: HttpMethod.Post,
    url: `/candidate/teams`,
    payload: payload
  };
};

export const changeAddCandidatesToTeamStatus = (
  status: AddCandidatesToTeamStatus
): IActionType<AddCandidatesToTeamStatus> => {
  return {
    type: Types.CHANGE_ADD_CANDIDATES_TO_TEAM_STATUS,
    payload: status
  };
};

export const removeCandidateFromTeam = (id: string, openDayId: number): IActionRequest<string> => {
  return {
    type: Types.REMOVE_CANDIDATE_FROM_TEAM,
    httpMethod: HttpMethod.Delete,
    url: `/candidate/teams/${id}/session/${openDayId}`,
    payload: id
  };
};

export const changeRemoveCandidateFromTeamStatus = (
  status: RemoveCandidateFromTeamStatus
): IActionType<RemoveCandidateFromTeamStatus> => {
  return {
    type: Types.CHANGE_REMOVE_CANDIDATE_FROM_TEAM_STATUS,
    payload: status
  };
};

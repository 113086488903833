import { addTeamToSessionSaga } from './addTeamToSessionSaga';
import { createSessionSaga } from './createSessionSaga';
import { deleteSessionSaga } from './deleteSessionSaga';
import { getOpenDaySessionSummariesSaga } from './getOpenDaySessionSummariesSaga';
import { getOpenDaySessionTeamsSaga } from './getOpenDaySessionTeamsSaga';
import { getOpenDaySessionsSaga } from './getOpenDaySessionsSaga';
import { getSessionByIdSaga } from './getSessionByIdSaga';
import { getTeamCaptainsSaga } from './getTeamCaptainsSaga';
import { getUnavailableSessionsSaga } from './getUnavailableSessionsSaga';
import { updateSessionSaga } from './updateSessionSaga';

export const sessionsSagas = [
  createSessionSaga(),
  getTeamCaptainsSaga(),
  getOpenDaySessionsSaga(),
  getOpenDaySessionTeamsSaga(),
  deleteSessionSaga(),
  updateSessionSaga(),
  addTeamToSessionSaga(),
  getSessionByIdSaga(),
  getUnavailableSessionsSaga(),
  getOpenDaySessionSummariesSaga()
];

import getConfiguration from 'modules/shared/helpers/configuration';
import { debounce, put } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { feedbackActions } from '../actions/index';
import * as OpTypes from '../types/feedbackOperationTypes';

function* getTeamFeedbackPost(action: any): any {
  yield put(feedbackActions.changeTeamFeedbackPosts([]));
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);

  if (success) {
    yield put(feedbackActions.changeTeamFeedbackPosts(response.data));
  }
}

export function* getTeamFeedbackPostsSaga() {
  yield debounce(getConfiguration().apiDebounceMs, OpTypes.GET_TEAM_FEEDBACK_POSTS, getTeamFeedbackPost);
}

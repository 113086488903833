import { AddBoxOutlined, ArrowUpward, Close } from '@mui/icons-material';
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Switch
} from '@mui/material';
import useScreenSize from 'common/hooks/useScreenSize';
import { useEffect, useState } from 'react';
import { numberToStringWithOrdinal } from '../../../../common/helpers/stringHelpers';
import { ITagOption } from '../../operations/models/tagModel';

interface IProps {
  disabled?: boolean;
  onChange: (options: ITagOption[]) => void;
  options?: ITagOption[];
}

const defaultOption: ITagOption = { id: 0, description: '', default: false, priority: 0 };
const defaultOptions: ITagOption[] = [...new Array(3)].map(() => defaultOption);

const TagSelectionList = ({ options, onChange, disabled }: IProps) => {
  const { isMobile } = useScreenSize();
  const initialOptions = (options?.length ? [...options] : defaultOptions)
    .sort((a, b) => a.priority - b.priority)
    .map((option, priority) => ({ ...option, priority }));
  const [tagOptions, setTagOptions] = useState(initialOptions);

  const addOption = () => setTagOptions([...tagOptions, defaultOption]);

  const removeOption = (key: number) => {
    const tempOptions = [...tagOptions];
    tempOptions.splice(key, 1);
    setTagOptions(tempOptions);
  };

  const updateOption = (key: number, name: string, value: any) => {
    const tempOptions = [...tagOptions];
    let option = tempOptions[key];
    option = { ...option, [name]: value };
    tempOptions.splice(key, 1, option);
    setTagOptions(tempOptions);
  };

  const isNewOption = (optionId: number) => {
    return optionId == 0;
  };

  const enableField = (optionId: number) => {
    return !disabled || isNewOption(optionId);
  };

  const onMoveUp = (index: number) => {
    const tempOptions = [...tagOptions];
    tempOptions[index] = { ...tempOptions[index], priority: index - 1 };
    tempOptions[index - 1] = { ...tempOptions[index - 1], priority: index };
    setTagOptions(tempOptions.sort((a, b) => a.priority - b.priority));
  };

  useEffect(() => {
    onChange(tagOptions);
  }, [tagOptions]);

  return (
    <>
      {tagOptions.map((option, index) => (
        <Grid key={`selection_` + index} container marginTop={2}>
          <Grid item xs={1}>
            {index > 0 && (
              <IconButton
                sx={{ marginLeft: isMobile ? -1.5 : 0, marginTop: 1 }}
                size="small"
                onClick={() => onMoveUp(index)}
              >
                <ArrowUpward />
              </IconButton>
            )}
          </Grid>
          <Grid item xs={9}>
            <FormControl fullWidth>
              <InputLabel>{`${numberToStringWithOrdinal(index + 1)} Option to Show`}</InputLabel>
              <OutlinedInput
                value={option.description}
                disabled={!enableField(option.id)}
                onChange={(e) => updateOption(index, 'description', e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton disabled={!enableField(option.id)} onClick={() => removeOption(index)} edge="end">
                      <Close />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              value={!!option.default}
              sx={{ mt: 1, ml: 1 }}
              control={
                <Switch
                  color="primary"
                  onChange={(e, v) => updateOption(index, 'default', v)}
                  checked={!!option.default}
                />
              }
              label={isMobile ? undefined : 'Default'}
              labelPlacement={isMobile ? undefined : 'start'}
            />
          </Grid>
        </Grid>
      ))}

      <Button sx={{ mt: 2 }} onClick={addOption} color="primary" variant="text" startIcon={<AddBoxOutlined />}>
        ADD ANOTHER OPTION
      </Button>

      {tagOptions.filter((option) => option.default).length > 1 && (
        <Alert sx={{ mt: 2 }} severity="error">
          Only one can be set as the default to continue
        </Alert>
      )}
    </>
  );
};

export default TagSelectionList;

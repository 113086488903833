import { getAllClaimsSaga } from './claimsSaga';
import { filterUsersSaga } from './filterUsersSaga';
import { getRolesSaga } from './rolesSaga';
import { searchADUsersSaga } from './searchADUsersSaga';
import { deleteUserSaga, getUsersSaga, saveUserSaga } from './usersSaga';

export const usersSaga = [getUsersSaga(), saveUserSaga(), filterUsersSaga(), searchADUsersSaga(), deleteUserSaga()];
export const claimsSaga = [getAllClaimsSaga()];
export const rolesSaga = [getRolesSaga()];
export const userManagementSaga = [...usersSaga, ...claimsSaga, ...rolesSaga];

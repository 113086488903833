import { Box, Button, Card, CardActions, CardContent, CardHeader, Modal, Typography } from '@mui/material';
import { IBasicModel } from 'common';
import GuardedDelete from 'common/components/GuardedDelete/GuardedDelete';
import { manageAppClaims } from 'modules/manage-app/helpers/permissionsHelper';
import { getAll, remove } from 'modules/manage-app/operations/actions/ethnicityActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { IGlobalState } from '../../../../startup/store/globalState';
import styles from '../../styles/ManageApp.module.scss';

export const DeleteModal = (props: { item: IBasicModel }) => {
  const [open, setOpen] = useState(false);
  const { snackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loading, success } = useSelector((state: IGlobalState) => {
    return { ...state.ethnicitiesState, ...state.appState.app };
  });

  useEffect(() => {
    if (!open || success == undefined) {
      return;
    }

    if (success) {
      snackbar({
        message: 'Ethnicity was successfully removed',
        variant: 'success',
        action: true
      });
      dispatch(getAll());
      setOpen(false);
    }
  }, [success]);

  const removeItem = async () => {
    props.item.id && dispatch(remove(props.item.id));
  };

  return (
    <>
      <GuardedDelete
        onClick={() => setOpen(true)}
        scopes={manageAppClaims}
        renderErrorItem={() => <></>}
        editStyle="outlined"
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box className={styles.modalBox}>
          <Card className={styles.modal}>
            <CardHeader title="Remove Ethnicity" />
            <CardContent>
              <Typography>Are you sure you want to remove this Ethnicity? This cannot be undone.</Typography>
            </CardContent>
            <CardActions sx={{ float: 'right' }}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                CANCEL
              </Button>
              <Button disabled={loading} color="error" variant="contained" onClick={removeItem}>
                REMOVE
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { changeCandidateCreateStatus } from '../actions/candidatesOperationActions';
import { candidatesActions } from '../actions/index';
import { CandidateCreationStatus } from '../enums/candidateEnums';
import * as OpTypes from '../types/candidatesOperationTypes';

function* createCandidate(action: any): any {
  yield put(changeCandidateCreateStatus(CandidateCreationStatus.Adding));

  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status201Created]);

  if (success) {
    yield put(candidatesActions.addedCandidate(response.data));
    yield put(changeCandidateCreateStatus(CandidateCreationStatus.Success));
  } else {
    yield put(changeCandidateCreateStatus(CandidateCreationStatus.Failure));
  }
}

export function* createCandidateSaga() {
  yield takeLatest(OpTypes.CREATE_CANDIDATE, createCandidate);
}

import { LinearProgress } from '@mui/material';
import './LoadingScreen.css';

const LinearLoadingScreen = () => {
  return (
    <div>
      <LinearProgress color="primary" />
    </div>
  );
};

export default LinearLoadingScreen;

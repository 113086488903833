import Resizer from 'react-image-file-resizer';

const maxWidth = 360;
const maxHeight = 300;

export const resizeImage = (file: File) =>
  new Promise<File>((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      100,
      0,
      (uri) => {
        resolve(uri as File);
      },
      'file'
    );
  });

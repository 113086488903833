import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import * as UserOperationTypes from '../../../manage-users/operations/types';
import { userOpActions } from '../actions';

function* getFilteredUsers(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);
  if (success) {
    yield put(userOpActions.changeUsersOnFiltered(response.data));
  }
}
export function* filterUsersSaga() {
  yield takeLatest(UserOperationTypes.GET_FILTERED_USERS, getFilteredUsers);
}

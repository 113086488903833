import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IconButton, IconButtonProps } from '@mui/material';
import { IClaimSearch } from 'modules/manage-users/operations/models';
import React, { ReactElement } from 'react';
import { GuardedView } from '../GuardedView/GuardedView';

interface IProps extends IconButtonProps {
  editStyle: string;
  scopes: IClaimSearch[];
  renderErrorItem: (props?: any) => ReactElement;
}

const GuardedDelete: React.FC<IProps> = (props) => {
  const { editStyle, scopes, renderErrorItem, ...iconButtonProps } = props;
  return (
    <GuardedView scopes={scopes} renderErrorItem={renderErrorItem}>
      <IconButton {...iconButtonProps}>{editStyle === 'filled' ? <DeleteIcon /> : <DeleteOutlinedIcon />}</IconButton>
    </GuardedView>
  );
};

export default GuardedDelete;

import produce from 'immer';
import { ITag } from '../models/tagModel';
import { ITagState } from '../models/tagState';
import * as ActionTypes from '../types';

export default function tagOperationReducer(
  state: ITagState = { tags: [], operationSuccess: null },
  action: any
): ITagState {
  switch (action.type) {
    case ActionTypes.CHANGED_TAGS: {
      return produce(state, (draft) => {
        draft.tags = ((action.payload as ITag[]) ?? []).sort((a, b) => a.priority - b.priority);
      });
    }
    case ActionTypes.GET_TAGS: {
      return produce(state, (draft) => {
        draft.operationSuccess = null;
      });
    }
    case ActionTypes.SAVE_TAG_SUCCEED: {
      return produce(state, (draft) => {
        let index = state.tags.findIndex((obj) => obj.id === action.payload.id);
        if (index !== -1) {
          draft.tags[index] = action.payload;
        }
        draft.operationSuccess = true;
      });
    }
    case ActionTypes.REMOVE_TAG_SUCCEED: {
      return produce(state, (draft) => {
        draft.operationSuccess = true;
      });
    }
    default:
      return state;
  }
}

import { ClaimActionType } from '../../modules/manage-users/operations/models';

export const HTTP_FAILURE_MESSAGE = "Sorry, we couldn't complete your request. Try again later.";

export const CLAIM_ACTION_TYPES = [
  { key: ClaimActionType.ADD, value: 'Add' },
  { key: ClaimActionType.EDIT, value: 'Edit' },
  { key: ClaimActionType.VIEW_ONLY, value: 'View only' }
];

export const MAX_TEAM_NAME_LENGTH = 25;

export const teamFeedbackCategory = 'Team feedback';

import { Card, CardContent, MenuItem, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ConfirmDialog from 'common/components/ConfirmDialog/ConfirmDialog';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import dayjs from 'dayjs';
import { removeCandidateFromSession } from 'modules/candidates/operations/actions/candidatesOperationActions';
import { ICandidate } from 'modules/candidates/operations/models/candidatesModel';
import { IOpenDay } from 'modules/feedback/operations/models/feedbackModel';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './CandidateForm.module.scss';

interface IOpenDaySectionProps {
  selectedCandidate: ICandidate;
  openDay: IOpenDay;
}

export const OpenDaySection: React.FC<IOpenDaySectionProps> = ({ openDay, selectedCandidate }) => {
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const teamId = openDay.teams.find((team) => team.candidates.find((c) => c.id === selectedCandidate.id))?.id ?? -1;

  const handleDelete = (confirmed: boolean) => {
    if (confirmed) {
      dispatch(removeCandidateFromSession(selectedCandidate.id, openDay.id));
    }
    setOpenDeleteModal(false);
  };

  return (
    <Card>
      <ConfirmDialog
        message={`You are about to remove ${selectedCandidate.name} from this ${process.env.REACT_APP_SESSION_NAME}. This action is not reverseable. Are you sure you want to continue?`}
        title={`Remove Candidate from ${process.env.REACT_APP_SESSION_NAME}?`}
        isOpen={openDeleteModal}
        positiveLabel="Remove Candidate"
        negativeLabel="Cancel"
        handleClose={handleDelete}
      />
      <CardContent>
        <Typography>
          <b>{`${process.env.REACT_APP_SESSION_NAME}`}</b>
        </Typography>
        <br />
        <Grid item container rowGap={3} columnGap={2}>
          <Grid item xs={5}>
            <TextField className={styles.jfInput} variant="outlined" label="Name" disabled value={openDay.name} />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={styles.jfInput}
              variant="outlined"
              label="Date"
              disabled
              value={openDay.date ? dayjs(openDay.date).format('DD MMMM YYYY') : ''}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className={styles.jfInput}
              variant="outlined"
              label="Time From - To"
              disabled
              value={openDay.id ? `${openDay.startTime.substring(0, 5)} - ${openDay.endTime.substring(0, 5)}` : ''}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              select
              label={`Current Team`}
              sx={{ width: '100%' }}
              value={teamId}
              placeholder="Current Team"
              disabled
            >
              <MenuItem value={-1} key={0}>
                No Team Allocated
              </MenuItem>
              {openDay.teams.map((team) => (
                <MenuItem value={team.id} key={team.id}>
                  {`${team.name} (${team.teamCaptain.name})`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid xs={3}>
            <EntelectButton
              variant={ButtonType.DangerLink}
              handleClick={() => setOpenDeleteModal(true)}
              label={`Remove from ${process.env.REACT_APP_SESSION_NAME}`}
              className="mt-05"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

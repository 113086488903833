import { ClaimTypes } from '../enums/claimTypes';
import { ClaimActionType, IUserModel } from '../models';

export const isInterviewer = (user: IUserModel): boolean => {
  const calibrationClaims = user.userClaims
    .concat(user.userRole.userRoleClaims)
    .filter((f) => f.claim.claimType == ClaimTypes.FEEDBACK_CALIBRATION)
    .map((userClaim) => userClaim.claim.actionType);
  return (
    user.isTeamCaptain &&
    calibrationClaims.includes(ClaimActionType.ADD) &&
    calibrationClaims.includes(ClaimActionType.EDIT)
  );
};

import { Navigate, Outlet } from 'react-router-dom';

interface GuardedRouteProps {
  isRouteAccessible?: boolean;
  redirectRoute?: string;
}

const GuardedRoute = ({ isRouteAccessible = false, redirectRoute = '/' }: GuardedRouteProps) => {
  if (isRouteAccessible) return <Outlet />;
  return <Navigate to={redirectRoute} replace />;
};

export default GuardedRoute;

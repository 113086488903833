import { ISessionsState, sessionsStateDefault } from '../models/sessionsState';
import sessionsOperationReducer from './sessionsOperationReducer';
import sessionsReducer from './sessionsReducer';

export default function sessionsStateReducer(state = sessionsStateDefault, action: any): ISessionsState {
  return {
    sessions: sessionsReducer(state.sessions, action),
    sessionsOperation: sessionsOperationReducer(state.sessionsOperation, action)
  };
}

import produce from 'immer';
import { defaultClaimsState, IClaimState } from '../models/IClaimState';
import { FETCHED_ALL_CLAIMS } from '../types';

export default function claimReducer(state: IClaimState = defaultClaimsState, action: any): IClaimState {
  switch (action.type) {
    case FETCHED_ALL_CLAIMS:
      return produce(state, (draft) => {
        draft.claims = action.payload;
      });
    default:
      return state;
  }
}

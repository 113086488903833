import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export const formatShortDate = (inputDateStr: string | null | undefined): string | null => {
  if (!inputDateStr) {
    return null;
  }

  const inputDate = new Date(inputDateStr.replace(/,/g, ''));
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const formattedDate = `${inputDate.getDate().toString().padStart(2, '0')} ${
    months[inputDate.getMonth()]
  } ${inputDate.getFullYear()}`;

  return formattedDate;
};

export const formatDate = (inputDateStr: string | null | undefined, short: boolean = false): string | null => {
  if (!inputDateStr) {
    return null;
  }
  return dayjs(inputDateStr).format(short ? 'DD MMM YYYY' : 'DD MMMM YYYY');
};

export const getWeekDay = (inputDateStr: string | null | undefined, short: boolean = false): string | null => {
  if (!inputDateStr) {
    return null;
  }

  const inputDate = new Date(inputDateStr.replace(/,/g, ''));
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const dayOfWeek = daysOfWeek[inputDate.getDay()];
  const formattedDate = `${short ? dayOfWeek.substring(0, 3) : dayOfWeek}`;

  return formattedDate;
};

export const getTime = (isoString: string | null | undefined): string | null => {
  if (!isoString) {
    return null;
  }

  let localTimezoneDate = convertDateTimezone(isoString);

  return new Date(localTimezoneDate)
    .toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    })
    .replace(/^0+/, '');
};

const convertDateTimezone = (utcDate: string) => {
  dayjs.extend(utc);
  return dayjs.utc(utcDate).local().format();
};

export const getShortTime = (time: string) => {
  return time.substring(0, 5);
};

import produce from 'immer';
import { defaultRoleState, IRoleState } from '../models/IRoleState';
import { FETCHED_ROLES } from '../types';

export default function roleReducer(state: IRoleState = defaultRoleState, action: any): IRoleState {
  switch (action.type) {
    case FETCHED_ROLES:
      return produce(state, (draft) => {
        draft.roles = action.payload;
      });
    default:
      return state;
  }
}

import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import { feedbackActions } from '../actions';
import { changeFeedBackUpdateStatus } from '../actions/feedbackOperationActions';
import { FeedbackUpdateStatus } from '../enums/feedbackEnums';
import * as OpTypes from '../types/feedbackOperationTypes';

function* updateFeedbackPost(action: any): any {
  yield put(changeFeedBackUpdateStatus(FeedbackUpdateStatus.Updating));
  const { success } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);

  if (success) {
    yield put(feedbackActions.editFeedbackPost(action.payload));
    yield put(changeFeedBackUpdateStatus(FeedbackUpdateStatus.Success));
  } else {
    yield put(changeFeedBackUpdateStatus(FeedbackUpdateStatus.Failure));
  }
}

export function* updateFeedbackPostSaga() {
  yield takeLatest(OpTypes.UPDATE_FEEDBACK_POST, updateFeedbackPost);
}

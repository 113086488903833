import { Button, ButtonProps } from '@mui/material';
import { IClaimSearch } from 'modules/manage-users/operations/models';
import React from 'react';
import { GuardedView } from '../GuardedView/GuardedView';

interface IProps extends ButtonProps {
  scopes: IClaimSearch[];
  errorProps: {};
  label: string;
}

const GuardedButton: React.FC<IProps> = (props) => {
  const { scopes, errorProps, label, ...buttonProps } = props;
  return (
    <GuardedView scopes={scopes} errorProps={errorProps}>
      <Button {...buttonProps}>{label}</Button>
    </GuardedView>
  );
};

export default GuardedButton;

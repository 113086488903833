import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    tenantId: `${process.env.REACT_APP_AZURE_AD_TENANT_ID}`, // Your tenant ID goes here
    clientId: `${process.env.REACT_APP_AZURE_AD_CLIENT_ID}`, // Your client (application) ID goes here
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`, // Replace the last part with your tenant ID
    redirectUri: `${process.env.REACT_APP_AZURE_AD_REDIRECT_URI}`, // Must be the same in Azure AD portal, can be replace with an environment variable: process.env.REACT_APP_REDIRECT_URL
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
        }
      }
    }
  }
};
export const protectedResources = {
  api: {
    path: '/api/v1',
    scopes: [`${process.env.REACT_APP_AZURE_AD_CLIENT_ID}/Resources.Read`, `User.ReadBasic.All`, `User.Read`]
  }
};
// export const appRoles = {
//     Admin: "Admin",
// };

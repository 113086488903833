import { Close } from '@mui/icons-material';
import { Grid, Slider } from '@mui/material';
import { useSnackbar } from 'common/hooks/useSnackbar';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import styles from './ImageCropper.module.scss';

interface IProps {
  imageSrc: string;
  onImgCrop: (croppedImg: any) => void;
  onRemoveImg: (imgSrc: Blob | MediaSource | null) => void;
  hasEditPermission?: boolean;
}

const ImageCropper: React.FC<IProps> = ({ imageSrc, onImgCrop, onRemoveImg, hasEditPermission = false }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const snackBarHook = useSnackbar();

  const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(imageSrc, croppedAreaPixels);
      onImgCrop(croppedImage);
    } catch (e) {
      console.error(e);
      snackBarHook.snackbar({
        message: 'Error cropping image. Please try a different one',
        duration: 4000,
        action: true
      });
    }
  }, [imageSrc, croppedAreaPixels]);

  useEffect(() => {
    if (croppedAreaPixels) {
      showCroppedImage();
    }
  }, [croppedAreaPixels]);

  return (
    <Grid>
      <div className={styles.mainDiv}>
        <Grid item xs={12} className={styles.superContainer}>
          <div className={styles.subContainer}>
            <div className={styles.cropContainer}>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                zoomWithScroll={false}
                aspect={8 / 10}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={false}
                style={{
                  cropAreaStyle: {
                    color: 'white',
                    width: '100%',
                    maxHeight: '300px',
                    maxWidth: '260px'
                  },
                  containerStyle: { padding: 0, width: '100%' }
                }}
              />
            </div>
            {hasEditPermission && <Close className={styles.closeButton} onClick={() => onRemoveImg(null)} />}
          </div>
        </Grid>
        <div className={styles.sliderController}>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e, zoom) => setZoom(Number(zoom))}
            classes={{ root: 'slider' }}
          />{' '}
          <br />
        </div>
      </div>
    </Grid>
  );
};

export const createImage = (url: string) =>
  new Promise((resolve, reject) => {
    const image: HTMLImageElement = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image: any = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  // calculate bounding box of the rotated image
  const [bBoxWidth, bBoxHeight] = [image.width, image.height, rotation];

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) {
        resolve(URL.createObjectURL(file));
      } else {
        reject('File cannot be null.');
      }
    }, 'image/jpeg');
  });
}

export default ImageCropper;

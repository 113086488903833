import dayjs, { Dayjs } from 'dayjs';
import { ICreatedBy } from '../../../../common/models/ICreatedBy';
import { IPaginatedResponse } from '../../../../common/models/paginatedResponse';

export interface IReportState {
  reports: IPaginatedResponse<IReport>;
  createReportSuccess?: boolean | null;
  reportTypes: IReportType[];
  deleteReportSuccess?: { report: IReport; succcess: boolean } | null;
  downloadedBlob?: Blob;
}

export interface IReport {
  id: number;
  name: string;
  reportTypeCode: string;
  path: string;
  createdBy: ICreatedBy;
  createdAt: string;
}

export const reportDefault: IPaginatedResponse<IReport> = {
  data: [],
  pageNo: 0,
  pageSize: 0,
  totalPages: 0,
  totalRecords: 0,
  totalRecordsOnPage: 0
};

export interface IReportRequest {
  name?: string;
  type?: string;
}

export interface IReportFilter {
  createdByList: string[];
  types: string[];
}

export interface IReportFilterRequest {
  createdBy?: string;
  type?: string;
}

export interface IReportSortRequest {
  fieldName?: string;
  isAscending?: boolean;
  asc: number;
  desc: number;
  off: number;
  isSorted: boolean;
}

export interface IReportGenerateRequest {
  reportTypeCode: string;
  startDate: Dayjs;
  endDate: Dayjs;
  sessionId: number;
}

export interface IReportDownloadRequest {
  reportId: string;
  startDate: Dayjs;
  endDate: Dayjs;
}

export interface IReportType {
  code?: string;
  name: string;
}

export interface IThingCode {
  code?: string;
  name: string;
}

export const getDefaultReportGenerateRequest = (): IReportGenerateRequest => ({
  reportTypeCode: '',
  startDate: dayjs().set('hour', 8).set('minute', 0).set('second', 0),
  endDate: dayjs().set('hour', 15).set('minute', 0).set('second', 0),
  sessionId: 0
});

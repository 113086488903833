import { Middleware } from '@reduxjs/toolkit';
import { IGlobalState } from './globalState';

export const stateMiddleware: Middleware = ({ getState }) => {
  return (next) => async (action) => {
    const returnValue = next(action);
    const state = getState() as IGlobalState;

    if (state.authState.isAuthenticated) {
      const serializedState = JSON.stringify(state.appState);
      localStorage.setItem('appState', serializedState);
    } else {
      localStorage.removeItem('appState');
    }

    return returnValue;
  };
};

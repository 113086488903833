import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CardMedia, Grid, IconButton } from '@mui/material';
import { CellDoubleClickedEvent, ColDef, ColumnApi, ColumnGroup } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import CandidateProfileDialog from 'common/components/CandidateProfileModal/CandidateProfileModal';
import { ROUTES } from 'common/constants/routesConstants';
import { getCandidates } from 'modules/candidates/operations/actions/candidatesOperationActions';
import { ICandidate } from 'modules/candidates/operations/models/candidatesModel';
import { getOpenDays } from 'modules/feedback/operations/actions/feedbackOperationActions';
import { getTags } from 'modules/manage-app/operations/actions/tagOperationActions';
import { ITag } from 'modules/manage-app/operations/models/tagModel';
import { getUsers } from 'modules/manage-users/operations/actions/userActions';
import { getSessionById } from 'modules/sessions/operations/actions/sessionsOperationActions';
import { LegacyRef, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { IGlobalState } from 'startup/store/globalState';
import CandidateImageModal from '../components/CandidateImageModal/CandidateImageModal';
import FeedbackModal from '../components/FeedbackModal/FeedbackModal';
import { getCandidateCalibrations, getOpenDayCalibrations } from '../operations/actions';
import { CalibrationType } from '../operations/enums';
import {
  IMatrixData,
  defaultColumnDef,
  getCalibrationColumns,
  getCandidateColumns,
  getFeedbackPostsColumn,
  getFeedbackTagsColumns
} from '../operations/helpers/matrixHelper';
import { ColumnDef, ICandidateCalibration } from '../operations/models';
import styles from './CalibrationMatrixContainer.module.scss';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY ?? '');

export const CalibrationMatrixContainer: React.FC = () => {
  const gridRef: LegacyRef<any> = useRef();
  const dispatch = useDispatch();
  const openDayId = useParams().openDayId;

  const {
    updateOperationSuccess,
    openDayCalibration,
    sessionById: openDay,
    users,
    tags,
    candidates
  } = useSelector((state: IGlobalState) => {
    return {
      ...state.candidatesState.candidates,
      ...state.userManagementState.userState,
      ...state.sessionsState.sessions,
      ...state.feedbackState.feedback,
      ...state.calibrationState,
      ...state.tagState,
      ...state.appState.app
    };
  });
  const { calibrations } = openDayCalibration;
  const [rowData, setRowData] = useState([] as IMatrixData[]);
  const [calibrationMap, setCalibrationMap] = useState<{ [name: string]: ICandidateCalibration[] }>({});
  const [columnDefs, setColumnDefs] = useState<ColumnDef[]>([]);
  const [selectedCalibration, setSelectedCalibration] = useState<
    [CalibrationType, ICandidateCalibration] | undefined
  >();
  const [selectedCandidate, setSelectedCandidate] = useState<ICandidate | undefined>(undefined);
  const [photoUrl, setPhotoUrl] = useState<string | undefined>(undefined);

  const ImageRenderer = ({ data }: any) => {
    if (!data?.photo) return <></>;

    return (
      <CardMedia
        component="img"
        className={styles.imgStyle}
        src={data?.photo}
        onClick={() => setPhotoUrl(data?.photo)}
      />
    );
  };

  useEffect(() => {
    dispatch(getOpenDays(''));
    dispatch(getUsers({ pageSize: undefined, filters: { statuses: true } }));
  }, []);

  useEffect(() => {
    if (openDayId) {
      dispatch(getSessionById(`${openDayId}`));
      dispatch(getCandidates(+openDayId, ''));
      setCalibrationMap({});
      setColumnDefs([]);
      setRowData([]);
      dispatch(getTags(+openDayId));
    }
  }, [openDayId]);

  useEffect(() => {
    openDay && dispatch(getOpenDayCalibrations(openDay.id, CalibrationType.PostCalibration));
  }, [openDay]);

  useEffect(() => {
    const tagMap: { [id: number]: ITag } = {};
    const map = (calibrations || [])
      .filter((f) => f.sessionId == openDay?.id)
      .reduce(
        (curMap, cur) => {
          if (!curMap[cur.interviewTypeCode]) {
            curMap[cur.interviewTypeCode] = [];
          }
          curMap[cur.interviewTypeCode].push(cur);
          cur.tags.forEach((tag) => tag.tag && (tagMap[tag.tag?.id ?? 0] = tag.tag));
          return curMap;
        },
        {} as { [name: string]: ICandidateCalibration[] }
      );
    setCalibrationMap({ ...calibrationMap, ...map });
  }, [calibrations]);

  useEffect(() => {
    if (calibrations.length == 0 || columnDefs.length > 0) return;
    const userIds =
      calibrations
        ?.map((f) => f.feedbackResponseDTOS?.map((f) => f.userId))
        .flat()
        .filter((f) => f) ?? [];
    const feedbackUsers = users.filter((f) => userIds.includes(f.id));

    const tempColDef = [getCandidateColumns(ImageRenderer)]
      .concat(getCalibrationColumns(tags))
      .concat([getFeedbackPostsColumn(feedbackUsers)])
      .concat([getFeedbackTagsColumns(tags, feedbackUsers)]);
    setColumnDefs(tempColDef);
  }, [calibrations]);

  useEffect(() => {
    const data = calibrationMap[CalibrationType.PostCalibration]
      ?.map((obj) => {
        const candidateCalibrationMap = Object.values(CalibrationType).reduce((acc, cur) => {
          if (calibrationMap[cur]) {
            acc[cur] = calibrationMap[cur].find((f) => f.candidate.id == obj.candidate.id);
          }
          return acc;
        }, {} as any);
        return {
          ...obj.candidate,
          ...obj,
          ...candidateCalibrationMap
        } as IMatrixData;
      })
      .sort((a, b) => a.candidate.name.localeCompare(b.candidate.name));
    setRowData(data);
  }, [calibrationMap]);

  useEffect(() => {
    if (updateOperationSuccess) {
      dispatch(getOpenDayCalibrations(+(openDayId ?? ''), CalibrationType.PostCalibration));
    }
  }, [updateOperationSuccess]);

  const defaultColDef = useMemo<ColDef>(() => defaultColumnDef, []);

  const handleDoubleClick = ({ data, colDef }: CellDoubleClickedEvent) => {
    const type = colDef?.type as string;

    if (Object.values(CalibrationType).includes(type as CalibrationType)) {
      dispatch(getCandidateCalibrations(+(openDayId ?? ''), data?.candidate?.id || 0));
      setSelectedCalibration([type as CalibrationType, data]);
    }

    if (type == 'Candidate') {
      const candidate = candidates.find((f) => f.id == data?.candidate?.id);
      setSelectedCandidate(candidate);
    }
  };

  const onColumnVisible = ({ columnApi }: { columnApi: ColumnApi }) => {
    const calibrationsTypes = Object.values(CalibrationType);
    columnApi
      .getCenterDisplayedColumnGroups()
      .map((m) => (m as ColumnGroup).getGroupId() as CalibrationType)
      .filter((f) => calibrationsTypes.includes(f) && !calibrationMap[f])
      .forEach(async (f, i) => {
        await new Promise((resolve) => setTimeout(resolve, 1500 * i));
        openDay && dispatch(getOpenDayCalibrations(+openDay?.id, f));
      });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={4} className={styles.backButton}>
          <IconButton sx={{ padding: '0px' }} component={RouterLink} to={`${ROUTES.Calibration}/${openDayId}`}>
            <ArrowBackIcon className={styles.backArrow} />
          </IconButton>
        </Grid>
        <Grid item xs={4}>
          <h2 className="textCenter m-0">Matrix: {openDay?.name}</h2>
        </Grid>
      </Grid>
      <br />
      {photoUrl && <CandidateImageModal handleClose={() => setPhotoUrl(undefined)} photoUrl={photoUrl} />}
      {selectedCalibration && (
        <FeedbackModal
          handleClose={() => setSelectedCalibration(undefined)}
          candidateCalibration={selectedCalibration[1]}
          calibrationType={selectedCalibration[0]}
        />
      )}
      {selectedCandidate && (
        <CandidateProfileDialog candidate={selectedCandidate} handleClose={() => setSelectedCandidate(undefined)} />
      )}
      <div className="ag-theme-alpine" style={{ height: '80vh' }}>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          onColumnVisible={onColumnVisible}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
          enableBrowserTooltips={true}
          defaultColGroupDef={{ marryChildren: true }}
          onCellDoubleClicked={handleDoubleClick}
          allowContextMenuWithControlKey={true}
          sideBar={true}
          rowGroupPanelShow="never"
          pivotMode={false}
          rowGroupPanelSuppressSort={true}
          pivotPanelShow="never"
          rowDragEntireRow={true}
          allowDragFromColumnsToolPanel={true}
          tooltipHideDelay={1500}
          tooltipShowDelay={200}
        />
      </div>
    </>
  );
};

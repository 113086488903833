export const serialize = (obj: any, prefix: string | void): string => {
  if (!obj) return '';
  let str: string[] = [],
    p;
  for (p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      var k = prefix ? prefix + (!Array.isArray(obj) ? '.' + p : '') : p,
        v = obj[p];
      if (v != null) {
        str.push(
          v !== null && typeof v === 'object' ? serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v)
        );
      }
    }
  }
  return str.filter((s) => s).join('&');
};

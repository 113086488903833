import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid } from '@mui/material';
import { ICandidateCalibration } from 'modules/calibration/operations/models';
import { IFeedbackPost, ITeamFeedbackPost } from 'modules/feedback/operations/models/feedbackModel';
import { ITag } from 'modules/manage-app/operations/models/tagModel';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../../startup/store/globalState';
import styles from './FeedbackModal.module.scss';

interface IProps {
  candidateCalibration: ICandidateCalibration;
  showContent: boolean;
}

const FeedbackPostsToggleView: React.FC<IProps> = (props: IProps) => {
  const { tags } = useSelector((state: IGlobalState) => ({
    ...state.tagState
  }));
  const { candidateCalibration } = props;
  const [showContent, setShowContent] = useState<boolean>(props.showContent);
  const [filteredTags, setFilteredTags] = useState<ITag[]>([]);

  useEffect(() => {
    if (tags?.length && candidateCalibration) {
      const posts = candidateCalibration?.feedbackResponseDTOS ?? [];
      const postedTags = posts.flatMap((post) => (post.tags ?? []).map((t) => t.tag?.id));
      const filteredTags = tags.filter((tag) => postedTags.includes(tag.id));
      setFilteredTags(filteredTags);
    }
  }, [tags, candidateCalibration]);

  const FeedbackPostGrid = ({ post, team }: { post: IFeedbackPost | ITeamFeedbackPost; team?: boolean }) => {
    return (
      <Grid container spacing={0.5}>
        <Grid item xs={6}>
          <div className={styles.feedbackCard}>
            <b>{post.addedBy ?? 'Anonymous'}</b>
            <br />
            <span className={styles.feedbackNotes}>{post.note}</span>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={`${styles.feedbackCard} ${styles.feedbackCardTags}`}>
            <br />
            {(filteredTags || []).map((t) => {
              const tag = post?.tags?.find((f) => f.tag?.id == t.id) ?? ({} as any);
              const value = isNaN(parseInt(tag.rating)) ? tag.rating : tag.rating + '/' + (tag.tag?.rating?.end || 10);
              const displayValue = value ? <b>({value})</b> : '';
              const label = (
                <>
                  {displayValue} {t.name ?? ''}
                </>
              );
              return (
                <Chip
                  key={tag.id}
                  disabled={!value}
                  label={label}
                  variant="filled"
                  size="small"
                  className={styles.chip}
                />
              );
            })}
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Accordion className={styles.accordion} expanded={showContent} onChange={(_, v) => setShowContent(v)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <b>Feedback Posts</b>
      </AccordionSummary>
      <AccordionDetails>
        {showContent && (
          <Grid item xs={12}>
            <Grid
              container
              sx={{
                '& .MuiGrid-item': {
                  paddingTop: '0px'
                }
              }}
              spacing={1}
            >
              {!!candidateCalibration.feedbackResponseDTOS?.length && (
                <>
                  <h4 className={styles.feedbackType}>Candidate Feedback</h4>
                  <Grid item xs={12}>
                    {candidateCalibration.feedbackResponseDTOS?.map((post) => (
                      <FeedbackPostGrid key={`id${post.id}_`} post={post} />
                    ))}
                  </Grid>
                </>
              )}
              {!!candidateCalibration.teamFeedbackResponseDTOS?.length && (
                <>
                  <h4 className={styles.feedbackType}>Team Feedback</h4>
                  <Grid item xs={12}>
                    {candidateCalibration?.teamFeedbackResponseDTOS.map((post) => (
                      <FeedbackPostGrid key={`gd${post.id}_`} post={post} />
                    ))}
                  </Grid>
                </>
              )}

              {!candidateCalibration.feedbackResponseDTOS?.length &&
                !candidateCalibration.teamFeedbackResponseDTOS?.length && (
                  <p className="textCenter w-100">No Feedback Posts</p>
                )}
            </Grid>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FeedbackPostsToggleView;

import { Feature } from 'common/enums/appEnums';

export const GET_EXPORTS = `GET_EXPORTS${Feature.Export}`;
export const EXPORTS_CHANGED = `EXPORTS_CHANGED${Feature.Export}`;

export const RESET_GENERATE_EXPORT = `RESET_GENERATE_EXPORT${Feature.Export}`;
export const GENERATE_EXPORT_SUCCEEDED = `GENERATE_EXPORT_SUCCEEDED${Feature.Export}`;
export const GENERATE_EXPORT_FAILED = `GENERATE_EXPORT_SUCCEED${Feature.Export}`;
export const GENERATE_EXPORT = `GENERATE_EXPORT${Feature.Export}`;
export const DOWNLOAD_EXPORT = `DOWNLOAD_EXPORT${Feature.Export}`;

export const GET_EXPORT_TYPES = `GET_EXPORT_TYPES${Feature.Export}`;
export const CHANGED_EXPORT_TYPES = `CHANGED_EXPORT_TYPES${Feature.Export}`;

export const DELETE_EXPORT = `DELETE_EXPORT${Feature.Export}`;
export const DELETE_EXPORT_SUCCEEDED = `DELETE_EXPORT_SUCCEEDED${Feature.Export}`;
export const RESET_DELETE_EXPORT = `RESET_DELETE_EXPORT${Feature.Export}`;
export const DELETE_EXPORT_FAILED = `DELETE_EXPORT_FAILED${Feature.Export}`;

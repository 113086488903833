import { Feature } from '../../../../common/enums/appEnums';

export const CHANGED_CANDIDATES = `CHANGED_CANDIDATES${Feature.Candidates}`;
export const CHANGED_GENDERS = `CHANGED_GENDERS${Feature.Candidates}`;
export const CHANGED_ETHNICITIES = `CHANGED_ETHNICITIES${Feature.Candidates}`;
export const CHANGED_CANDIDATE_STATUSES = `CHANGED_CANDIDATE_STATUSES${Feature.Candidates}`;
export const ADDED_CANDIDATE = `ADDED_CANDIDATE${Feature.Candidates}`;
export const UPDATED_CANDIDATE = `UPDATED_CANDIDATE${Feature.Candidates}`;
export const CHANGED_CANDIDATE = `CHANGED_CANDIDATE${Feature.Candidates}`;
export const CHANGED_CANDIDATE_RECRUITERS = `CHANGED_CANDIDATE_RECRUITERS${Feature.Candidates}`;
export const CHANGED_CANDIDATE_VIEW = `CHANGED_CANDIDATE_VIEW${Feature.Candidates}`;
export const CHANGED_CANDIDATE_SEARCH = `CHANGED_CANDIDATE_SEARCH${Feature.Candidates}`;
export const CHANGED_CANDIDATES_SEARCH_FILTER = `CHANGED_CANDIDATE_SEARCH_FILTER${Feature.Candidates}`;

import { FilterListOutlined } from '@mui/icons-material';
import { Button, Container, Typography } from '@mui/material';
import { FilterTypeEnum } from 'common/enums/filterTypeEnum';
import { SelectOptionItem } from 'common/models/filterModels';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import styles from './FilterButton.module.scss';
import { FilterDrawer } from './FilterDrawer';

export interface IGenericFilter {
  label: string;
  reduxData: (string | SelectOptionItem)[];
  selectedOptions: string[];
  filterType: FilterTypeEnum;
}

interface IProps {
  filterTypes: IGenericFilter[];
  onFilterClicked: (filters: IGenericFilter[]) => void;
}

const FilterButton: React.FC<IProps> = (props) => {
  const [filters, setFilters] = useState(props.filterTypes);
  const [open, setOpen] = useState(false);
  const previousFilterState = useRef<IGenericFilter[]>();

  useEffect(() => {
    setFilters(props.filterTypes);
    previousFilterState.current = props.filterTypes;
  }, [props.filterTypes]);

  const toggleFilterDrawer = (isOpen: boolean) => {
    !isOpen && getFilterCount() == 0 && props.onFilterClicked(filters);
    setOpen(isOpen);
  };

  function getFilterCount() {
    let allFilters: any[] = [];
    filters.forEach((filter) => {
      allFilters = allFilters.concat(filter.selectedOptions);
    });

    return allFilters.length;
  }

  const FilterButtonContent = () => {
    const filterCount: number = getFilterCount();
    return (
      <Container disableGutters className={styles.filterContainer}>
        <FilterListOutlined />
        {filterCount > 0 ? (
          <Typography className={styles.filterCount} component={'span'}>
            {filterCount}
          </Typography>
        ) : (
          <></>
        )}
      </Container>
    );
  };

  return (
    <Fragment>
      <Button variant="contained" onClick={() => toggleFilterDrawer(true)} className={styles.filterButtonContainer}>
        <FilterButtonContent />
      </Button>
      <FilterDrawer
        open={open}
        filters={filters}
        onFilterClicked={props.onFilterClicked}
        toggleFilterDrawer={toggleFilterDrawer}
        setFilters={setFilters}
        setOpen={setOpen}
      />
    </Fragment>
  );
};

export default FilterButton;

import { ArrowBack, CheckCircle, Close, Error, WarningRounded } from '@mui/icons-material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import ConfirmDialog from 'common/components/ConfirmDialog/ConfirmDialog';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import { ROUTES } from 'common/constants/routesConstants';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import useScreenSize from 'common/hooks/useScreenSize';
import { IFormData } from 'common/models/formDataModels';
import dayjs from 'dayjs';
import { changeHttpError, changeShowBackdrop } from 'modules/app/operations/actions/appActions';
import {
  bulkImportCandidates,
  bulkImportCandidatesClearErrors,
  clearBulkImportCandidates,
  saveBulkImportCandidates,
  saveBulkImportCandidatesClearStatus
} from 'modules/candidates/operations/actions/candidatesOperationActions';
import { ICandidateImport } from 'modules/candidates/operations/models/candidateImportModel';
import { IOpenDay } from 'modules/feedback/operations/models/feedbackModel';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { IGlobalState } from 'startup/store/globalState';
import uploadIcon from '../../../../assets/uploadIcon.svg';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import styles from './CandidateBulkImportView.module.scss';

interface IUploadProps {
  openDay: IOpenDay | null;
  handleUploadCancel: () => void;
}

interface IVerificationProps {
  openDay: IOpenDay | null;
  candidates: ICandidateImport[];
  validCandidates: number;
  invalidCandidates: number;
  duplicateCandidates: number;
  allCandidatesCount: number;
  selectedIndicator: any;
  setSelectedIndicator: (indicator: any) => void;
  indicators: any[];
}

const CandidateBulkImportView: React.FC = () => {
  const { loading, openDay, temporaryCandidates, errorMessage, saveImportStatus } = useSelector(
    (state: IGlobalState) => {
      return {
        ...state.sessionsState.sessionsOperation,
        ...state.candidatesState.candidatesBulkImport,
        ...state.appState.app
      };
    }
  );

  const navigate = useNavigate();
  const [selectedIndicator, setSelectedIndicator] = useState<any>(0);
  const [filteredCandidates, setFilteredCandidates] = useState<ICandidateImport[]>([]);
  const [isCancelOpen, setCancelOpen] = useState<boolean>(false);
  const [isConfirmImportOpen, setConfirmImportOpen] = useState<boolean>(false);
  const [isBulkImportSuccessDialogOpen, setBulkImportSuccessDialogOpen] = useState<boolean>(false);
  const [displayView, setDisplayView] = useState<'upload-section' | 'verify-section'>('upload-section');
  const { snackbar } = useSnackbar();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeShowBackdrop(false));
    return () => {
      dispatch(changeShowBackdrop(true));
    };
  }, []);

  useEffect(() => {
    switch (selectedIndicator) {
      case 1:
        setFilteredCandidates([...temporaryCandidates.filter((x) => x.valid && !x.duplicate)]);
        break;
      case 2:
        setFilteredCandidates([...temporaryCandidates.filter((x) => !x.valid && !x.duplicate)]);
        break;
      case 3:
        setFilteredCandidates([...temporaryCandidates.filter((x) => x.duplicate)]);
        break;
      default:
        setFilteredCandidates([...temporaryCandidates]);
        break;
    }
    if (temporaryCandidates.length) {
      setDisplayView('verify-section');
    }
  }, [selectedIndicator, temporaryCandidates]);

  useEffect(() => {
    if (saveImportStatus === 'success') {
      setBulkImportSuccessDialogOpen(true);
      dispatch(saveBulkImportCandidatesClearStatus());
    }
  }, [saveImportStatus]);

  const handleConfirmCancelClose = (shouldCancel: any) => {
    setCancelOpen(false);
    if (shouldCancel) {
      setDisplayView('upload-section');
      dispatch(clearBulkImportCandidates());
    }
  };

  const navigateBack = (reset?: boolean) => {
    dispatch(clearBulkImportCandidates());
    if (displayView === 'verify-section' && !reset) {
      setDisplayView('upload-section');
    } else {
      navigate(`${ROUTES.OpenDayCandidates}/${openDay?.id}`);
    }
  };

  const handleUploadCancel = () => {
    if (displayView === 'verify-section') {
      setCancelOpen(true);
    } else {
      setDisplayView('upload-section');
    }
  };

  const handleConfirmUploadClose = (shouldImport: any) => {
    dispatch(saveBulkImportCandidatesClearStatus());
    setConfirmImportOpen(false);
    if (shouldImport) {
      dispatch(saveBulkImportCandidates(+(openDay?.id || 0), temporaryCandidates));
    }
  };

  const indicators: any[] = [
    { key: 0, value: 'View All' },
    { key: 1, value: 'Valid' },
    { key: 2, value: 'Invalid' },
    { key: 3, value: 'Duplicate' }
  ];

  const isImportDisabled = () => {
    return temporaryCandidates?.some((tc) => !tc.valid);
  };

  useEffect(() => {
    if (errorMessage) {
      snackbar({ message: errorMessage, variant: 'error', action: true });
      dispatch(changeHttpError());
    }
  }, [errorMessage]);

  return (
    <>
      <h2 className="textCenter m-0">
        <IconButton onClick={() => navigateBack()} className={styles.backButton}>
          <ArrowBack className={styles.backArrow} />
        </IconButton>
        Candidate Tracker Import
      </h2>
      <Grid item container xs={12} spacing={1} className={styles.bulkImportContainer}>
        {displayView === 'upload-section' ? (
          <UploadSection openDay={openDay} handleUploadCancel={handleUploadCancel} />
        ) : (
          <VerificationSection
            openDay={openDay}
            candidates={filteredCandidates}
            indicators={indicators}
            selectedIndicator={selectedIndicator}
            setSelectedIndicator={setSelectedIndicator}
            validCandidates={temporaryCandidates?.filter((x: ICandidateImport) => x.valid && !x.duplicate).length}
            invalidCandidates={temporaryCandidates?.filter((x: ICandidateImport) => !x.valid).length}
            duplicateCandidates={temporaryCandidates?.filter((x: ICandidateImport) => x.duplicate).length}
            allCandidatesCount={temporaryCandidates.length}
          />
        )}
        <Grid className={styles.verificationActions} item xs={12} display={'flex'} width={100} my={1}>
          <div>
            {filteredCandidates?.length > 0 ? (
              <EntelectButton label={'Cancel'} handleClick={() => setCancelOpen(true)} variant={ButtonType.Secondary} />
            ) : (
              <></>
            )}

            <ConfirmDialog
              message={`Are you sure you want to cancel?`}
              title=""
              isOpen={isCancelOpen}
              positiveLabel="Yes"
              negativeLabel="No"
              negativeButtonClass={styles.bulkImportNegativeButton}
              positiveButtonClass={styles.bulkImportPositiveButton}
              handleClose={handleConfirmCancelClose}
              dialogContentClass={styles.dialogContent}
              dialogActionAreaClass={styles.dialogActionArea}
              dialogActionButtonClass={styles.dialogActionButton}
            ></ConfirmDialog>
          </div>

          {displayView === 'verify-section' ? (
            <div style={{ marginLeft: '16px' }}>
              {filteredCandidates?.length > 0 ? (
                <EntelectButton
                  disabled={isImportDisabled() || loading}
                  label={'Import'}
                  handleClick={() => setConfirmImportOpen(true)}
                  variant={ButtonType.Primary}
                />
              ) : (
                ''
              )}
              <ConfirmDialog
                message={`Are you sure you want to import these ${temporaryCandidates.length} candidates into ${openDay?.name}?`}
                title=""
                isOpen={isConfirmImportOpen}
                positiveLabel="Yes"
                negativeLabel="No"
                negativeButtonClass={styles.bulkImportNegativeButton}
                positiveButtonClass={styles.bulkImportPositiveButton}
                handleClose={handleConfirmUploadClose}
                dialogContentClass={styles.dialogContent}
                dialogActionAreaClass={styles.dialogActionArea}
                dialogActionButtonClass={styles.dialogActionButton}
              ></ConfirmDialog>

              <Dialog
                open={isBulkImportSuccessDialogOpen}
                sx={{
                  '& .MuiDialog-paper': {
                    margin: '4px'
                  }
                }}
              >
                <DialogContent className={styles.dialogContent}>
                  All {temporaryCandidates.length} candidates have been successfully imported
                </DialogContent>
                <DialogActions className={styles.dialogActionArea}>
                  <EntelectButton
                    label={'View Candidates'}
                    handleClick={() => navigateBack(true)}
                    variant={ButtonType.Success}
                  />
                </DialogActions>
              </Dialog>
            </div>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  );
};

const UploadSection: React.FC<IUploadProps> = ({ openDay }) => {
  const [formData, setFormData] = useState<IFormData>({
    id: 0,
    file: ''
  });
  const inputRef = useRef<any>(null);
  const dispatch = useDispatch();

  const handleFormChange = (type: string, value: any) => {
    setFormData({
      ...formData,
      [type]: value
    });
  };

  const handleSubmit = async () => {
    const f_data = new FormData();
    f_data.append('file', await fileToBlob(formData.file[0]), formData.file[0].name);
    if (formData.id) {
      f_data.append('id', formData.id);
    }

    dispatch(bulkImportCandidates(f_data, openDay?.id));
    dispatch(clearBulkImportCandidates());
  };

  const fileToBlob = async (file: File | null) =>
    file ? new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type }) : new Blob(); // move this to global/common util

  const onDrop = useCallback((file: any) => {
    handleFormChange('file', file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleClearFile = () => {
    handleFormChange('file', null);
    dispatch(bulkImportCandidatesClearErrors());
    if (inputRef && inputRef.current) {
      inputRef.current.value = null;
    }
  };

  return (
    <Grid container item xs={12} spacing={2} className={styles.uploadSection}>
      <Grid container item xs={12}>
        <div className={styles.uploadActions}>
          <div className={styles.pagecontainer}>
            <div>
              <div {...getRootProps()} className={!isDragActive ? styles.uploaderContainer : styles.onHoverDragAndDrop}>
                <input {...getInputProps()} />
                <p>
                  <img className={styles.uploadIcon} src={uploadIcon} />
                </p>
                {isDragActive ? <p>Drop the file here ...</p> : <p>Drag and drop file here, or click to select file</p>}
              </div>
            </div>
          </div>
        </div>
      </Grid>
      {formData?.file?.length ? (
        <Grid item xs={12}>
          <div className={styles.uploadedFileContainer}>
            <InsertDriveFileOutlinedIcon className={styles.fileIcon} />
            <span className={styles.selectedFile}>
              <Typography>
                {formData?.file[0].name}
                <Close onClick={handleClearFile} fontSize="large" className={styles.closeIcon}></Close>
              </Typography>
              <div className={styles.progressBar}>
                <div className={styles.progress} style={{ width: `100%` }}>
                  <span className={styles.progressIndicator}>100%</span>
                </div>
              </div>
            </span>
          </div>
          <div className={styles.buttonUploadContainer}>
            <EntelectButton
              disabled={!formData?.file}
              className={!formData?.file ? styles.hidesection : ''}
              label={'Upload'}
              handleClick={handleSubmit}
              variant={ButtonType.Primary}
            />
          </div>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
};
const VerificationSection: React.FC<IVerificationProps> = ({
  openDay,
  candidates,
  selectedIndicator,
  setSelectedIndicator,
  indicators,
  validCandidates,
  invalidCandidates,
  duplicateCandidates,
  allCandidatesCount
}) => {
  const screenSize = useScreenSize();
  return (
    <div>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} className={styles.filterAndInfoSection}>
          <Grid item xs={4}>
            <TextField
              select
              fullWidth={screenSize.isMobile}
              className={styles.bulkImportDropDown}
              label="Filter By"
              value={selectedIndicator}
              onChange={(e) => setSelectedIndicator(e.target.value)}
              title="Filter By"
            >
              {indicators?.map((indicator) => (
                <MenuItem key={indicator.key} value={indicator.key}>
                  <span>
                    {indicator.key ? (
                      <>
                        <span className={`${styles.indicator} ${styles['indicator' + indicator.value]}`}></span>{' '}
                      </>
                    ) : (
                      ''
                    )}
                    {indicator.value}
                    {' ('}
                    {indicator.key === 1
                      ? validCandidates
                      : indicator.key === 2
                        ? invalidCandidates
                        : indicator.key === 3
                          ? duplicateCandidates
                          : allCandidatesCount}
                    {')'}
                  </span>
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <h3 className="textCenter m-0">
              {openDay?.name} - {moment(openDay?.date).format('D MMMM yyyy')}
            </h3>
          </Grid>
          <Grid item xs={4} className={styles.infoSection}>
            <span>
              Valid ({validCandidates}) [<span className={`${styles.indicator} ${styles['indicatorValid']}`}></span>]
            </span>
            <span>
              Invalid ({invalidCandidates}) [
              <span className={`${styles.indicator} ${styles['indicatorInvalid']}`}></span>]
            </span>
            <span>
              Duplicate ({duplicateCandidates}) [
              <span className={`${styles.indicator} ${styles['indicatorDuplicate']}`}></span>]
            </span>
          </Grid>
        </Grid>
        {candidates.length ? renderCandidateList(candidates) : ''}
      </Grid>
    </div>
  );
};

const renderCandidateList = (candidates: ICandidateImport[]) => (
  <Grid item container spacing={1} xs={12}>
    <Grid item xs={12} className={styles.candidateList}>
      <span className="flexBasis10">Full Name</span>
      <span className="flexBasis10">Gender</span>
      <span className="flexBasis10">AIC</span>
      <span className="flexBasis10">Location</span>
      <span className="flexBasis10">University</span>
      <span className="flexBasis10">Qualifications</span>
      <span className="flexBasis10">Job Title</span>
      <span className="flexBasis10">Interview Date</span>
      <span className="flexBasis20">Candidate Tracker</span>
      <span className="flexBasis10">Indicator</span>
    </Grid>

    {candidates.map((candidate, i) => candidateItemCard(candidate, i))}
  </Grid>
);

const candidateItemCard = (candidate: ICandidateImport, index: number) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const isCandidateValid = candidate?.valid && !candidate?.duplicate;
  const isCandidateDuplicate = candidate?.valid && candidate?.duplicate;

  const open = Boolean(anchorEl);

  const style = !candidate.valid
    ? styles.statusInvalid
    : candidate.duplicate
      ? styles.statusDuplicate
      : styles.statusValid;

  return (
    <Grid item key={`${candidate.url} + ${index}`} xs={12}>
      <span>
        <Card className={`${styles.candidateCard} ${style}`}>
          <CardContent className={styles.cardContent}>
            <span className="flexBasis10">
              <b>{candidate.fullname}</b>
            </span>
            <span className="flexBasis10">{candidate.gender}</span>
            <span className="flexBasis10">{candidate.aic}</span>
            <span className="flexBasis10">{candidate.location}</span>
            <span className="flexBasis10">{candidate.university}</span>
            <span className="flexBasis10">{candidate.degree}</span>
            <span className="flexBasis10">{candidate.jobtitle}</span>
            <span className="flexBasis10">
              {candidate.interviewdate ? dayjs(candidate.interviewdate).format('MMM D, YYYY') : ''}
            </span>
            <span className="flexBasis20 textEllipsis">
              <a href={candidate.url}>{candidate.url}</a>
            </span>
            <span className="flexBasis10">
              <Tooltip
                title={
                  isCandidateValid ? (
                    <ListItem>
                      <Typography className={styles.viewInfoListItem}>• Candidate Valid</Typography>
                    </ListItem>
                  ) : isCandidateDuplicate ? (
                    <ListItem>
                      <Typography className={styles.viewInfoListItem}>• Candidate Duplicate</Typography>
                    </ListItem>
                  ) : (
                    candidate?.errorMessages.map((errorMessage) => (
                      <ListItem key={errorMessage}>
                        <Typography className={styles.viewInfoListItem}>• {errorMessage}</Typography>
                      </ListItem>
                    ))
                  )
                }
                placement="left"
                arrow
              >
                <IconButton aria-label="view info">
                  {isCandidateValid ? (
                    <>
                      <CheckCircle className={styles.statusValidColor} />
                      <span className={`${styles.viewInfoFontSize} ${styles.statusValidColor}`}>View Info</span>
                    </>
                  ) : isCandidateDuplicate ? (
                    <>
                      <Error className={styles.statusDuplicateColor} />
                      <span className={`${styles.viewInfoFontSize} ${styles.statusDuplicateColor}`}>View Info</span>
                    </>
                  ) : (
                    <>
                      <WarningRounded className={styles.statusInValidColor} />
                      <span className={`${styles.viewInfoFontSize} ${styles.statusInValidColor}`}>View Info</span>
                    </>
                  )}
                </IconButton>
              </Tooltip>
            </span>
          </CardContent>
        </Card>
      </span>
    </Grid>
  );
};

export default CandidateBulkImportView;

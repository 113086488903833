import { put, takeLatest } from 'redux-saga/effects';
import { candidatesActions } from '../actions/index';
import * as OpTypes from '../types/candidatesOperationTypes';

function* changeSelected(action: any): any {
  yield put(candidatesActions.changeCandidate(action.payload));
}

export function* changeSelectedCandidateSaga() {
  yield takeLatest(OpTypes.CHANGE_SELECTED_CANDIDATE, changeSelected);
}

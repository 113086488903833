import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { HttpMethod } from '../../../../common/enums/appEnums';
import { ITag } from '../models/tagModel';
import * as Types from '../types';

export const getTags = (sessionId?: number): IActionRequest<undefined> => {
  const subUrl = sessionId ? `sessionId/${sessionId}` : 'default';
  return {
    type: Types.GET_TAGS,
    httpMethod: HttpMethod.Get,
    url: `/tags/${subUrl}`
  };
};

export const saveTag = (payload: ITag): IActionRequest<ITag> => {
  return {
    type: Types.SAVE_TAG,
    httpMethod: payload.id ? HttpMethod.Put : HttpMethod.Post,
    url: `/tags`,
    onSuccess: saveTagSuccess,
    payload: payload
  };
};

export const saveTagSuccess = (payload: any): IActionType<any> => ({
  type: Types.SAVE_TAG_SUCCEED,
  payload
});

export const removeTag = (id: number): IActionRequest<undefined> => {
  return {
    type: Types.REMOVE_TAG,
    httpMethod: HttpMethod.Delete,
    url: `/tags/${id}`,
    onSuccess: removeTagSuccess
  };
};

export const removeTagSuccess = (payload: any): IActionType<any> => ({
  type: Types.REMOVE_TAG_SUCCEED,
  payload
});

export interface IQualification {
  id: number;
  name: string;
}

export interface IInstitution {
  id: number;
  name: string;
  qualifications: IQualification[];
}

export interface IQualificationDisplay {
  first?: string;
  additionalText?: string;
  additionalCount?: number;
}

export const getDefaultInstitution = () => ({
  id: 0,
  name: '',
  qualifications: [getDefaultQualification()]
});

export const getDefaultQualification = () => ({
  id: 0,
  name: ''
});

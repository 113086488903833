import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { feedbackActions } from '../actions/index';
import * as OpTypes from '../types/feedbackOperationTypes';

function* getFeedbackPost(action: any): any {
  yield put(feedbackActions.changeFeedbackPosts([]));
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);

  if (success) {
    yield put(feedbackActions.changeFeedbackPosts(response.data));
  }
}

export function* getFeedbackPostsSaga() {
  yield takeLatest(OpTypes.GET_FEEDBACK_POSTS, getFeedbackPost);
}

// @ts-ignore
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { AxiosError } from 'axios';
import LinearLoadingScreen from 'modules/shared/components/LinearLoadingScreen';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from '../../../common/hooks/useSnackbar';
import { IGlobalState } from '../../../startup/store/globalState';
import { getToken } from '../../auth/operations/actions/authActions';
import MenuBar from '../components/MenuBar/MenuBar';
import AppRoutes from '../components/Routes/Routes';
import { changeHttpActionEnded } from '../operations/actions/appActions';
import styles from './AppContainer.module.scss';

export const AppContainer: React.FC = () => {
  const { instance, inProgress } = useMsal();
  const { error, isAuthenticated, showBackdrop, authUser, loading } = useSelector((state: IGlobalState) => {
    return { ...state.appState.app, ...state.authState };
  });
  const dispatch = useDispatch();
  const { snackbar } = useSnackbar();

  instance
    .handleRedirectPromise()
    .then((response) => {
      const account = response?.account;
      if (!account) {
        return;
      }
      instance.setActiveAccount(response.account);
    })
    .catch((err) => {
      console.log(err);
    });

  useEffect(() => {
    inProgress == InteractionStatus.None && instance.getActiveAccount() && dispatch(getToken());
  }, [inProgress]);

  useEffect(() => {
    dispatch(changeHttpActionEnded());
  }, []);

  useEffect(() => {
    const message = typeof error === 'string' ? error : (error as AxiosError)?.message;
    error &&
      snackbar({
        message: ((error as AxiosError)?.response?.data as any)?.message ?? message,
        variant: 'error',
        action: true
      });
  }, [error]);

  return (
    <>
      {isAuthenticated ? <MenuBar userName={authUser?.displayName || ''} /> : <></>}

      <div className={styles.loadingBarContainer}>
        <div className={styles.loadingBar}>{loading && <LinearLoadingScreen />}</div>
      </div>
      <div id="header-root" className={`${styles.headerRoot}`}></div>
      <div className={`${styles.appContainer} ${isAuthenticated && showBackdrop ? styles.background : null}`}>
        <AppRoutes />
      </div>
    </>
  );
};

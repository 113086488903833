import styled from '@emotion/styled';
import { ArrowDownwardOutlined, ArrowUpwardOutlined, FilterListOutlined, TableRowsOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, ButtonGroup, Container, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import { ROUTES } from 'common/constants/routesConstants';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import { formatDate } from 'common/helpers/dateHelpers';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { ICandidate } from 'modules/candidates/operations/models/candidatesModel';
import { getSessionById } from 'modules/sessions/operations/actions/sessionsOperationActions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { IGlobalState } from 'startup/store/globalState';
import { changeShowBackdrop } from '../../app/operations/actions/appActions';
import { getTags } from '../../manage-app/operations/actions/tagOperationActions';
import { ISessionTeamCaptain } from '../../sessions/operations/models/sessionsModel';
import { CalibrationFilterView } from '../components/CalibrationFilterView/CalibrationFilterView';
import { InterviewView } from '../components/InterviewView/InterviewView';
import { PostCalibrationView } from '../components/PostCalibrationView/PostCalibrationView';
import { PreCalibrationView } from '../components/PreCalibrationView/PreCalibrationView';
import { changeUpdateOperation, createOpenDayCalibrations } from '../operations/actions';
import { CalibrationType } from '../operations/enums';
import { CalibrationFields } from '../operations/enums/calibrationEnums';
import {
  SORT_CONFIG,
  filterCalibrations,
  flattenCandidatesFromOpenDay,
  flattenTeamCaptainsFromOpenDay
} from '../operations/helpers';
import {
  ICalibrationFilter,
  ICalibrationFilterRequest,
  ICalibrationSortRequest,
  ICandidateCalibration,
  IOfferCount
} from '../operations/models';
import {
  defaultCalibrationFilter,
  defaultCalibrationFilterRequest,
  defaultCalibrationSortRequest,
  defaultOfferCount
} from '../operations/models/defaultStates';
import styles from './CalibrationContainer.module.scss';

export const CalibrationContainer: React.FC = () => {
  const { selectedOpenDay, calibration, updateOperationSuccess, loading } = useSelector((state: IGlobalState) => {
    return {
      selectedOpenDay: state.sessionsState.sessions.sessionById,
      calibration: state.calibrationState.openDayCalibration,
      updateOperationSuccess: state.calibrationState.updateOperationSuccess,
      loading: state.appState.app.loading
    };
  });
  const [toggledView, setToggledView] = React.useState<string>(CalibrationType.PreCalibration);
  const [filterData, setFilterData] = React.useState<ICalibrationFilter>(defaultCalibrationFilter);
  const [filterRequest, setFilterRequest] = useState<ICalibrationFilterRequest>(defaultCalibrationFilterRequest);
  const [sortRequest, setSortRequest] = useState<ICalibrationSortRequest>(defaultCalibrationSortRequest);
  const [displayedCalibrations, setDisplayedCalibrations] = useState<ICandidateCalibration[]>([]);
  const [allCardsExpanded, setAllCardsExpanded] = useState<boolean>(false);
  const [offerCount, setOfferCount] = useState<IOfferCount>(defaultOfferCount);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { openDayId } = useParams();
  const navigate = useNavigate();
  const { snackbar } = useSnackbar();

  useEffect(() => {
    if (openDayId) {
      dispatch(getSessionById(openDayId));
      dispatch(changeShowBackdrop(false));
      dispatch(createOpenDayCalibrations(+openDayId));
      dispatch(getTags(+openDayId));
    }
    return () => {
      dispatch(changeShowBackdrop(true));
    };
  }, [openDayId]);

  useEffect(() => {
    let candidates = filterCalibrations(calibration?.calibrations ?? [], filterRequest) ?? [];
    const { fieldName, isAscending } = sortRequest;
    if (fieldName && SORT_CONFIG[fieldName]?.sorter) {
      candidates = SORT_CONFIG[fieldName].sorter(candidates, isAscending);
    }
    setDisplayedCalibrations(candidates);

    const offerCount: IOfferCount = {
      yes: candidates.filter((cfc) => cfc?.offerTypeCode?.toUpperCase() === 'YES')?.length,
      no: candidates.filter((cfc) => cfc?.offerTypeCode?.toUpperCase() === 'NO')?.length,
      maybe: candidates.filter((cfc) => cfc?.offerTypeCode?.toUpperCase() === 'MAYBE')?.length
    };
    setOfferCount(offerCount);
  }, [calibration, filterRequest, sortRequest]);

  useEffect(() => {
    if (selectedOpenDay?.id) {
      const candidates: ICandidate[] = flattenCandidatesFromOpenDay(selectedOpenDay);
      const captains: ISessionTeamCaptain[] = flattenTeamCaptainsFromOpenDay(selectedOpenDay);
      const filterObject: ICalibrationFilter = {
        ...filterData,
        candidates: candidates,
        captains: captains
      };
      setFilterData(filterObject);
    }
  }, [selectedOpenDay]);

  useEffect(() => {
    if (updateOperationSuccess) {
      snackbar({ message: 'Calibration has been updated', variant: 'success', action: true });
      dispatch(changeUpdateOperation(false));
    }
  }, [updateOperationSuccess]);

  useEffect(() => {
    setSortRequest({ ...sortRequest, fieldName: CalibrationFields.CandidateName });
  }, [toggledView]);

  const handleCalibrationFiltersChanged = (type: string, value: any) => {
    setFilterRequest({ ...filterRequest, [type]: value });
  };

  const handleCalibrationSortChanged = (field: CalibrationFields) => {
    const isAscending = sortRequest.fieldName != field || !sortRequest.isAscending;
    setSortRequest({ ...sortRequest, isAscending, fieldName: field });
  };

  const getSortIcon = (fieldName: CalibrationFields) => {
    if (sortRequest.fieldName === fieldName) {
      return (
        <div className={styles.sortingArrow}>
          {(sortRequest.isAscending && <ArrowDownwardOutlined fontSize="small" />) || (
            <ArrowUpwardOutlined fontSize="small" />
          )}
        </div>
      );
    }
    return <></>;
  };

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={1} className={styles.backButton}>
          <IconButton sx={{ padding: '0px' }} component={RouterLink} to={ROUTES.OpenDays}>
            <ArrowBackIcon className={styles.backArrow} />
          </IconButton>
        </Grid>
        <Grid item xs={7} md={6} lg={6}>
          <h2 className="textRight m-0">
            {selectedOpenDay ? selectedOpenDay.name : ''}
            <span className="textMuted">{selectedOpenDay ? ` - ${formatDate(selectedOpenDay.date, false)}` : ''}</span>
          </h2>
        </Grid>
        <Grid item xs={4} md={5} lg={5} className={styles.toggleWrapper}>
          <StyledButtonGroup className={styles.buttonGroup}>
            <button
              className={
                toggledView === CalibrationType.PreCalibration
                  ? styles.activePreCalibration
                  : styles.nonActivePreCalibration
              }
              onClick={() => {
                setFilterRequest(defaultCalibrationFilterRequest);
                setToggledView(CalibrationType.PreCalibration);
              }}
            >
              Pre-Calibration
            </button>
            <button
              className={toggledView === CalibrationType.Interview ? styles.activeInterview : styles.nonActiveInterview}
              onClick={() => {
                setFilterRequest(defaultCalibrationFilterRequest);
                setToggledView(CalibrationType.Interview);
              }}
            >
              Interview
            </button>
            <button
              className={
                toggledView === CalibrationType.PostCalibration
                  ? styles.activePostCalibration
                  : styles.nonActivePostCalibration
              }
              onClick={() => {
                setFilterRequest(defaultCalibrationFilterRequest);
                setToggledView(CalibrationType.PostCalibration);
              }}
            >
              Post-Calibration
            </button>
          </StyledButtonGroup>

          <Button variant={'contained'} className={`${styles.filterButtonContainer} ml-05`}>
            <Container disableGutters className={styles.filterContainer}>
              <FilterListOutlined onClick={() => setShowFilters(!showFilters)} />
            </Container>
          </Button>

          <EntelectButton
            startIcon={TableRowsOutlined}
            handleClick={() => navigate(`${ROUTES.CalibrationMatrix}/${openDayId}`)}
            className={`${styles.matrixButton} ml-05`}
            label="Matrix"
            variant={ButtonType.Primary}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.filtersWrapper}>
        {showFilters && filterData?.candidates && filterData?.captains ? (
          <CalibrationFilterView
            filterRequest={filterRequest}
            offerCount={offerCount}
            filterData={filterData}
            onFilterChanged={handleCalibrationFiltersChanged}
          />
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12} className={styles.candidatesListWrapper}>
        <div className={styles.candidatesList}>
          <span
            className={`${styles.candidateHeader}`}
            onClick={() => handleCalibrationSortChanged(CalibrationFields.CandidateName)}
          >
            Candidate
            {getSortIcon(CalibrationFields.CandidateName)}
          </span>
          <span
            className={`${styles.candidateHeader}`}
            onClick={() => handleCalibrationSortChanged(CalibrationFields.Team)}
          >
            Team
            {getSortIcon(CalibrationFields.Team)}
          </span>
          <span
            className={`${styles.candidateHeader}`}
            onClick={() => handleCalibrationSortChanged(CalibrationFields.TeamCaptain)}
          >
            Captain
            {getSortIcon(CalibrationFields.TeamCaptain)}
          </span>
          <span
            className={`${styles.candidateHeader}`}
            onClick={() => handleCalibrationSortChanged(CalibrationFields.University)}
          >
            Uni/Qualifications
            {getSortIcon(CalibrationFields.University)}
          </span>
          {toggledView === CalibrationType.PostCalibration || toggledView === CalibrationType.Interview ? (
            <span
              className={`${styles.candidateHeader}`}
              onClick={() => handleCalibrationSortChanged(CalibrationFields.Interviewer)}
            >
              Interviewer
              {getSortIcon(CalibrationFields.Interviewer)}
            </span>
          ) : (
            <span></span>
          )}

          <span className={`${styles.offerWrapper}`}>
            <span
              className={`${styles.candidateHeader} ${styles.offerHeader}`}
              onClick={() => handleCalibrationSortChanged(CalibrationFields.Offer)}
            >
              Offer
            </span>
            {getSortIcon(CalibrationFields.Offer)}
            <span
              className={styles.offerCounts}
              title={`Yes (${offerCount?.yes}) \nNo (${offerCount?.no}) \nMaybe (${offerCount?.maybe})`}
            >
              Y ({offerCount?.yes}) N ({offerCount?.no}) M ({offerCount?.maybe})
            </span>
          </span>
          <Button sx={{ marginTop: -1.5 }} disabled={loading} onClick={() => setAllCardsExpanded(!allCardsExpanded)}>
            {allCardsExpanded ? 'Collapse All' : 'Expand All'}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} className={styles.calibrationComponentWrapper}>
        {toggledView === CalibrationType.PreCalibration && (
          <PreCalibrationView allCardsExpanded={allCardsExpanded} displayedCalibrations={displayedCalibrations} />
        )}
        {toggledView === CalibrationType.Interview && (
          <InterviewView allCardsExpanded={allCardsExpanded} displayedCalibrations={displayedCalibrations} />
        )}
        {toggledView === CalibrationType.PostCalibration && (
          <PostCalibrationView allCardsExpanded={allCardsExpanded} displayedCalibrations={displayedCalibrations} />
        )}
        <br />
      </Grid>
    </Grid>
  );
};

const StyledButtonGroup = styled(ButtonGroup)({
  '& .MuiButtonGroup-grouped': {
    borderColor: styles.entelectBlue,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 50,
    borderTopRightRadius: 50,
    borderBottomRightRadius: 50
  }
});

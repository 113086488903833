import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Card, CardContent, Chip, Tooltip } from '@mui/material';
import ConfirmDialog from 'common/components/ConfirmDialog/ConfirmDialog';
import GuardedDelete from 'common/components/GuardedDelete/GuardedDelete';
import GuardedEdit from 'common/components/GuardedEdit/GuardedEdit';
import { ROUTES } from 'common/constants/routesConstants';
import useScreenSize from 'common/hooks/useScreenSize';
import dayjs from 'dayjs';
import { IOpenDay } from 'modules/feedback/operations/models/feedbackModel';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import {
  changeActiveSessionInEdit,
  deleteOpenDaySession
} from 'modules/sessions/operations/actions/sessionsOperationActions';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './SessionsCard.module.scss';

interface IProps {
  openDay: IOpenDay;
  openAddSessionHandler: () => void;
}

export const SessionsCard: React.FC<IProps> = ({ openDay, openAddSessionHandler }) => {
  const { isMobile } = useScreenSize();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setOpenDay = (openDay: IOpenDay) => {
    navigate(`${ROUTES.OpenDays}/${openDay.id}`);
  };

  const getAttendeesChips = (attendees: string[] | undefined) => {
    let nodes = attendees?.slice(0, 3).map((name: string, index: number) => {
      return <Chip label={name} key={index} variant="outlined" size="small" className={styles.attendeesChips} />;
    });

    if (attendees) {
      if (attendees?.length > 3) {
        nodes?.push(
          <Tooltip title={`${attendees.join(',')}`}>
            <span>+{attendees.length - 3}</span>
          </Tooltip>
        );
      }
    }
    return nodes;
  };

  const handleEditSession = () => {
    dispatch(changeActiveSessionInEdit(openDay));
    openAddSessionHandler();
  };

  const handleDialogClose = (result: boolean) => {
    setIsDialogOpen(false);

    if (result) {
      dispatch(deleteOpenDaySession(openDay.id));
    }
  };

  const isToday = dayjs(openDay.date).startOf('d').toDate().valueOf() == dayjs().startOf('d').toDate().valueOf();

  const renderDesktop = () => {
    return (
      <Card className={`${styles.openDayCard}`}>
        <CardContent className={styles.cardContent}>
          <span className="flexBasis20">
            <b>{openDay.name}</b>
          </span>
          <span className="flexBasis20">{dayjs(openDay.date).format('dddd, MMMM D, YYYY')}</span>
          <span className="flexBasis15">
            <Chip
              label={`${openDay.location?.name} - ${openDay.location?.description}`}
              variant="outlined"
              size="small"
              className={styles.chip}
            />
          </span>
          <span className="flexBasis15">{openDay?.teams?.length}</span>
          <span className="flexBasis15">{getAttendeesChips(openDay.teams.map((x) => x.teamCaptain?.name))}</span>
          <span className={`${styles.feedbackLink} flexBasis10`} onClick={() => setOpenDay(openDay)}>
            {isToday}
            {dayjs(openDay.date).startOf('d') >= dayjs().startOf('d') ? (
              <div>
                <GuardedEdit
                  className={styles.icons}
                  onClick={handleEditSession}
                  editStyle="outlined"
                  scopes={[
                    {
                      actionType: ClaimActionType.EDIT,
                      claimType: ClaimTypes.MANAGE_OPEN_DAYS
                    }
                  ]}
                  renderErrorItem={() => <></>}
                />
                <GuardedDelete
                  sx={isToday ? { color: '#dfdfdf !important', cursor: 'not-allowed' } : {}}
                  className={styles.icons}
                  onClick={() => setIsDialogOpen(!isToday)}
                  editStyle="outlined"
                  scopes={[
                    {
                      actionType: ClaimActionType.EDIT,
                      claimType: ClaimTypes.MANAGE_OPEN_DAYS
                    }
                  ]}
                  renderErrorItem={() => <></>}
                />
                <ConfirmDialog
                  message={`Are you sure you want to delete the ${process.env.REACT_APP_SESSION_NAME} "${openDay.name}" from the list? This cannot be undone.`}
                  title={`Delete ${process.env.REACT_APP_SESSION_NAME}`}
                  isOpen={isDialogOpen}
                  positiveLabel="Yes, Delete"
                  negativeLabel="No, Cancel"
                  handleClose={handleDialogClose}
                ></ConfirmDialog>
              </div>
            ) : (
              <CheckCircleOutlinedIcon className={styles.completedIcon} />
            )}
          </span>
        </CardContent>
      </Card>
    );
  };

  const renderMobile = () => {
    return (
      <div className={`${styles.cardMobile}`} onClick={() => setOpenDay(openDay)}>
        <div className={styles.flexRow}>
          <div className={`${styles.flexTitleRow} ${styles.sessionHeading}`}>{openDay.name}</div>
          {new Date(openDay.date) >= new Date() ? (
            <>
              <div>
                <GuardedEdit
                  className={styles.icons}
                  onClick={handleEditSession}
                  editStyle="outlined"
                  scopes={[
                    {
                      actionType: ClaimActionType.EDIT,
                      claimType: ClaimTypes.MANAGE_OPEN_DAYS
                    }
                  ]}
                  renderErrorItem={() => <></>}
                />
              </div>
              <div>
                <GuardedDelete
                  sx={
                    isToday ? { color: '#dfdfdf !important', cursor: 'not-allowed' } : { color: 'rgba(0, 0, 0, 0.54)' }
                  }
                  onClick={() => setIsDialogOpen(!isToday)}
                  editStyle="outlined"
                  scopes={[
                    {
                      actionType: ClaimActionType.EDIT,
                      claimType: ClaimTypes.MANAGE_OPEN_DAYS
                    }
                  ]}
                  renderErrorItem={() => <></>}
                />
                <ConfirmDialog
                  message={`Are you sure you want to delete the ${process.env.REACT_APP_SESSION_NAME} "${openDay.name}" from the list? This cannot be undone.`}
                  title={`Delete ${process.env.REACT_APP_SESSION_NAME}`}
                  isOpen={isDialogOpen}
                  positiveLabel="Yes, Delete"
                  negativeLabel="No, Cancel"
                  handleClose={handleDialogClose}
                ></ConfirmDialog>
              </div>
            </>
          ) : (
            <div>
              <CheckCircleOutlinedIcon sx={{ color: '#008D48' }} />
            </div>
          )}
        </div>
        <div>{dayjs(openDay.date).format('dddd, MMMM D, YYYY')}</div>
        <div>{openDay?.time}</div>
        <div>
          <Chip
            label={`${openDay.location?.name} - ${openDay.location?.description}`}
            variant="outlined"
            size="small"
            className={styles.chip}
          />
        </div>
        <div>Number of teams :{openDay?.teams?.length}</div>
        <div className={styles.flexRow}>
          <div className={styles.attendees}>Assigned Attendees:</div>
          <div>{getAttendeesChips(openDay!.assignedAttendees)}</div>
        </div>
      </div>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default SessionsCard;

import { Box, Button, Card, CardActions, CardContent, CardHeader, Modal, Typography } from '@mui/material';
import GuardedDelete from 'common/components/GuardedDelete/GuardedDelete';
import { ClaimActionType } from 'modules/manage-users/operations/enums/claimActionType';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { IFeedbackCategory } from '../../../../modules/manage-app/operations/models/feedbackCategoryModel';
import { IGlobalState } from '../../../../startup/store/globalState';
import { getFeedbackCategories, removeFeedbackCategory } from '../../operations/actions/feedbackCategoryActions';
import styles from '../../styles/ManageApp.module.scss';

const DeleteFeedbackCategory = (props: { category: IFeedbackCategory }) => {
  const [open, setOpen] = useState(false);
  const { snackbar } = useSnackbar();
  const { category } = props;
  const dispatch = useDispatch();
  const { loading, operationSuccess } = useSelector((state: IGlobalState) => {
    return { ...state.feedbackCategoryState, ...state.appState.app };
  });

  useEffect(() => {
    if (!open || operationSuccess == null) {
      return;
    }

    if (operationSuccess == true) {
      snackbar({
        message: 'Feedback category was successfully removed',
        variant: 'success',
        action: true
      });
      dispatch(getFeedbackCategories());
      setOpen(false);
      return;
    }

    if (operationSuccess == false) {
      const message = 'Feedback category could not be removed';
      snackbar({ message, variant: 'error', action: true });
      return;
    }
  }, [operationSuccess]);

  const deleteTag = async () => {
    dispatch(removeFeedbackCategory(category.id));
  };

  return (
    <>
      <GuardedDelete
        onClick={() => setOpen(true)}
        scopes={[
          {
            actionType: ClaimActionType.EDIT,
            claimType: ClaimTypes.MANAGE_APPLICATIONS
          }
        ]}
        renderErrorItem={() => <></>}
        editStyle="outlined"
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box className={styles.modalBox}>
          <Card className={styles.modal}>
            <CardHeader title="Remove Category" />
            <CardContent>
              <Typography>Are you sure you want to remove this Category? This cannot be undone.</Typography>
            </CardContent>
            <CardActions sx={{ float: 'right' }}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                CANCEL
              </Button>
              <Button disabled={loading} color="error" variant="contained" onClick={deleteTag}>
                REMOVE CATEGORY
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteFeedbackCategory;

import { DeleteOutline, Edit, MoreVert } from '@mui/icons-material';
import { Button, MenuItem, Select, TextareaAutosize } from '@mui/material';
import { useCheckPermissions } from 'common/hooks/useCheckPermissions';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTime } from '../../../../common/helpers/dateHelpers';
import useScreenSize from '../../../../common/hooks/useScreenSize';

import { useLocation } from 'react-router';
import * as appStorageService from '../../../../common/helpers/appStorageService';
import { IFormData } from '../../../../common/models/formDataModels';
import { IGlobalState } from '../../../../startup/store/globalState';
import { ICandidate } from '../../../candidates/operations/models/candidatesModel';
import {
  deleteFeedbackPost,
  deleteTeamFeedbackPost,
  updateAFeedbackPost,
  updateATeamFeedbackPost
} from '../../operations/actions/feedbackOperationActions';
import { FeedbackCategory, FeedbackUpdateStatus } from '../../operations/enums/feedbackEnums';
import { validateFeedbackNote } from '../../operations/helpers/feedbackHelpers';
import { IFeedbackPost, IFeedbackTag, ITeam, ITeamFeedbackPost } from '../../operations/models/feedbackModel';
import ContextMenu from '../ContextMenu/ContextMenu';
import DeleteDialog from '../DeleteDialog/DeleteDialog';
import { FeedbackTag } from '../FeedbackTag/FeedbackTag';
import { SelectedTag } from '../FeedbackTag/SelectedTag';
import styles from './FeedbackContent.module.scss';

interface IProps {
  team?: ITeam;
  candidate?: ICandidate;
  feedback: IFeedbackPost | ITeamFeedbackPost;
}

export const FeedbackPost: React.FC<IProps> = ({ candidate, feedback, team }: IProps) => {
  const screenSize = useScreenSize();
  const dispatch = useDispatch();
  const location = useLocation();

  const isMobile = screenSize.isMobile || screenSize.isTablet;
  const [editableFormData, setEditableFormData] = useState<IFormData>({ tags: [] });
  const [isEditValid, setIsEditValid] = useState<boolean>(false);
  const [deletePostId, setDeletePostId] = useState<string | null>(null);
  const [editPostId, setEditPostId] = useState<string | null>(null);

  const { tags, updateStatus, categories, authUser, openDay } = useSelector((state: IGlobalState) => ({
    ...state.tagState,
    ...state.feedbackState.feedbackOperation,
    ...state.feedbackCategoryState,
    ...state.appState.app,
    openDay: state.sessionsState.sessionsOperation.openDay,
    authUser: state.authState.authUser
  }));

  const hasAddManageUsersPermission = useCheckPermissions([
    {
      actionType: ClaimActionType.ADD,
      claimType: ClaimTypes.MANAGE_USERS
    }
  ]);

  const hasEditFeedbackPermission = useCheckPermissions([
    {
      actionType: ClaimActionType.EDIT,
      claimType: ClaimTypes.FEEDBACK
    }
  ]);

  // Exclude team category, and SuperUser only categories if not Super User
  const filteredCategories = categories?.filter(
    (x) => !(x.name.toLowerCase().trim() === 'team' || (x.onlySuperUsers && !hasAddManageUsersPermission))
  );

  const canEditFeedback = (userId: any): boolean => {
    return hasAddManageUsersPermission || (hasEditFeedbackPermission && authUser?.id == userId);
  };

  useEffect(() => {
    setIsEditValid(validateFeedbackNote(editableFormData));
  }, [editableFormData]);

  useEffect(() => {
    updateStatus == FeedbackUpdateStatus.Success && resetFormData();
  }, [updateStatus]);

  useEffect(() => {
    editPostId && handleCancelEdit();
  }, [candidate]);

  const handleOnChange = (property: string, value: any) => {
    setEditableFormData({ ...editableFormData, [property as keyof IFormData]: value });
  };

  const handleTagChange = (tagId: number, rating: string) => {
    const formTags = [...(editableFormData['tags'] as IFeedbackTag[])];
    const tagIndex = formTags.findIndex((f) => f.tagId == tagId);
    const tag: IFeedbackTag = tagIndex > -1 ? formTags.splice(tagIndex, 1)[0] : ({} as IFeedbackTag);
    handleOnChange('tags', [...formTags, { ...tag, tagId, rating }]);
  };

  const transformToDto = (formData: IFormData) => {
    const dto: IFeedbackPost & ITeamFeedbackPost = {
      candidateId: candidate?.id ?? 0,
      teamId: team?.id ?? 0,
      date: new Date().toISOString(),
      note: formData['note'] as string,
      tags: formData['tags'] as IFeedbackTag[],
      userId: authUser?.id,
      sessionId: openDay?.id as number
    };

    if (formData['category']) {
      dto.category = {
        id: formData['category'] as number,
        name: categories.find((c) => c.id === formData['category'])?.name
      };
    }

    return dto;
  };

  const transFormToFormData = () => {
    const tags = (feedback.tags || []).map((tag) => ({
      ...tag,
      tagId: (tag.tagId || tag?.tag?.id) ?? 0
    }));
    const formData: IFormData = {
      ...feedback,
      tags,
      candidateId: candidate?.id,
      teamId: team?.id,
      category: feedback.category?.id == undefined ? FeedbackCategory.None : feedback?.category.id
    };
    return formData;
  };

  const resetFormData = () => {
    const resetFormData = { tags: [], category: categories?.find((c) => c.default)?.id };
    appStorageService.save(location.pathname + location.search, resetFormData);
  };

  const renderFeedbackTagsSection = (form: IFormData, isEdit: boolean) => {
    return (
      <div className={styles.feedbackTagChips}>
        {(tags || []).map((tag) => (
          <FeedbackTag
            key={tag.id}
            label={tag.name}
            value={form.tags.find((x: IFeedbackTag) => x.tagId === tag.id)?.rating}
            variant="outlined"
            size="small"
            tag={tag}
            handleChange={(value: string) => handleTagChange(tag.id as any, value)}
          />
        ))}
      </div>
    );
  };

  const renderEditCardState = () => {
    return (
      <>
        {!team && (
          <div className={styles.noteCaptureHeader}>
            <Select
              className={styles.feedbackCategorySelection}
              value={editableFormData.category ?? FeedbackCategory.None}
              onChange={(e) => handleOnChange('category', e.target.value)}
            >
              {filteredCategories.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        <div className={styles.editElementBorder}>
          <TextareaAutosize
            value={editableFormData['note'] || ''}
            className={`${styles.editFeedbackTextBox}`}
            placeholder="Type the feedback notes"
            onChange={(e) => handleOnChange('note', e.target.value)}
            minRows={3}
          />
        </div>
        <div className={`${styles.feedbackTags}`}>
          <div className={styles.tagHeader}>Feedback Tags</div>
          {renderFeedbackTagsSection(editableFormData, true)}
        </div>

        <div className={styles.editButtonGroup}>
          <Button
            variant="outlined"
            size="small"
            className={styles.buttonOutlined}
            onClick={handleCancelEdit}
            sx={{ marginLeft: 'auto' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            className={styles.buttonContained}
            style={{ marginLeft: '1rem' }}
            onClick={handleSaveEditedComment}
            disabled={!isEditValid}
          >
            Save
          </Button>
        </div>
      </>
    );
  };

  const handleCancelEdit = () => {
    resetFormData();
    setEditPostId(null);
  };

  const handleSaveEditedComment = () => {
    const dto = transformToDto(editableFormData);
    const id = editPostId!;
    candidate && dispatch(updateAFeedbackPost(id, dto));
    team && dispatch(updateATeamFeedbackPost(id, dto));
    resetFormData();
    setEditPostId(null);
  };

  const handleContextMenuSelection = (selection: string) => {
    if (selection === 'delete') {
      setDeletePostId(feedback.id ?? '');
    } else {
      setEditPostId(feedback.id ?? '');
      setEditableFormData(transFormToFormData());
    }
  };

  const handleDelete = () => {
    team && dispatch(deleteTeamFeedbackPost(deletePostId!));
    candidate && dispatch(deleteFeedbackPost(deletePostId!));
    setDeletePostId(null);
  };

  const renderEditPost = () => {
    if (!(feedback.canEdit || feedback.canRemove) || !canEditFeedback(feedback.userId)) return <></>;
    return (
      <div className={styles.editMenu}>
        <ContextMenu
          component={<MoreVert />}
          onChange={(value) => handleContextMenuSelection(value)}
          options={[
            {
              label: 'Edit',
              value: 'edit',
              icon: <Edit style={{ color: '#7a8598', margin: '0px 5px 1px 0px' }} />,
              enabled: feedback.canEdit && canEditFeedback(feedback.userId)
            },
            {
              label: 'Delete',
              value: 'delete',
              icon: <DeleteOutline style={{ color: '#7a8598', margin: '0px 5px 1px 0px' }} />,
              enabled: feedback.canRemove && canEditFeedback(feedback.userId)
            }
          ]}
        />
      </div>
    );
  };

  return (
    <>
      {deletePostId && <DeleteDialog handleClose={() => setDeletePostId(null)} handleSubmit={() => handleDelete()} />}
      <div key={feedback.id} className={team ? styles.teamFeedbackNoteContainer : styles.feedbackNoteContainer}>
        <div className={styles.feedbackNoteHeader}>
          <div className={styles.feedbackNoteUser}>
            <div className={styles.noteSubHeader}>
              <span style={{ fontWeight: 600, fontSize: '15px' }}>{feedback.addedBy}</span>
              {renderEditPost()}
              <span className={styles.time}>{getTime(feedback.date)}</span>
            </div>

            {editPostId !== feedback.id && !team && (
              <span className={styles.feedbackContainerHeader}>{feedback?.category?.name}</span>
            )}
          </div>
        </div>

        {editPostId == feedback.id ? (
          renderEditCardState()
        ) : (
          <>
            <div className={styles.feedbackNoteMessage}>{feedback.note}</div>
            <div className={styles.feedbackNoteTagsSection}>
              <div className={isMobile ? styles.feedbackNoteTagsMobile : styles.feedbackNoteTags}>
                <div>{feedback?.tags?.map((t) => <SelectedTag key={t.id} tag={t} />)}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default FeedbackPost;

import { feedbackStateDefault, IFeedbackState } from '../models/feedbackState';
import feedbackOperationReducer from './feedbackOperationReducer';
import feedbackReducer from './feedbackReducer';

export default function feedbackStateReducer(state = feedbackStateDefault, action: any): IFeedbackState {
  return {
    feedback: feedbackReducer(state.feedback, action),
    feedbackOperation: feedbackOperationReducer(state.feedbackOperation, action)
  };
}

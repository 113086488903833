import { Close } from '@mui/icons-material';
import { Box, Button, Modal, Typography } from '@mui/material';
import styles from './DeleteDialog.module.scss';

interface IProps {
  handleClose: () => void;
  handleSubmit: () => void;
}

const DeleteDialog: React.FC<IProps> = ({ handleClose, handleSubmit }: IProps) => {
  return (
    <Modal open={true} onClose={handleClose}>
      <Box className={styles.dialog}>
        <div className={styles.header}>
          <Typography variant="h6" component="h2" className={styles.title}>
            ARCHIVE COMMENT?
          </Typography>
          <Close className={styles.iconClose} onClick={handleClose} />
        </div>

        <div className={styles.content}>
          <Typography>Are you sure you want to archive this comment? This cannot be undone.</Typography>
        </div>

        <div className={styles.footer}>
          <Button variant="outlined" className={styles.buttonCancel} onClick={handleClose}>
            No, Cancel
          </Button>
          <Button variant="contained" className={styles.buttonArchive} onClick={handleSubmit}>
            Yes, Archive
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteDialog;

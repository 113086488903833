import { ArrowDownward, ArrowUpward, Edit } from '@mui/icons-material';
import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ROUTES } from 'common/constants/routesConstants';
import { useEffect, useState } from 'react';
import { ICandidate } from '../../operations/models/candidatesModel';
import styles from './index.module.scss';

interface IProps {
  candidates: ICandidate[];
}

const columnDefs: { headerName: string; field: string }[] = [
  { headerName: 'FULL NAME', field: 'name' },
  { headerName: 'UNIVERSITY', field: 'candidateQualifications.0.institutionName' },
  { headerName: 'QUALIFICATION', field: 'candidateQualifications.0.qualificationName' },
  { headerName: 'GENDER', field: 'gender' },
  { headerName: 'ETHNICITY', field: 'aic' },
  { headerName: 'LOCATION', field: 'location' }
];

const CandidateTableView: React.FC<IProps> = (props) => {
  const [candidates, setCandidates] = useState<ICandidate[]>(props.candidates);
  const [sortField, setSortField] = useState<string>('name');
  const [isAscending, setIsAscending] = useState<boolean>(true);

  useEffect(() => {
    onSort('name');
  }, []);

  const onSort = (field: string) => {
    if (field === sortField) {
      setIsAscending(!isAscending);
    } else {
      setSortField(field);
      setIsAscending(true);
    }
    const sortedCandidates = [...candidates].sort((a, b) => {
      const fieldA = field.split('.').reduce((obj, key) => obj[key == '0' ? 0 : key], a as any) as string;
      const fieldB = field.split('.').reduce((obj, key) => obj[key == '0' ? 0 : key], b as any) as string;
      return (fieldA ?? '').localeCompare(fieldB ?? '') * (isAscending ? 1 : -1);
    });
    setCandidates([...sortedCandidates]);
  };

  const getIcon = (field: string) => {
    const color = field === sortField ? 'success' : undefined;
    if (field === sortField && isAscending) {
      return <ArrowUpward sx={{ marginTop: 0.5, fontSize: 16 }} color={color} />;
    }
    return <ArrowDownward sx={{ marginTop: 0.5, fontSize: 16 }} color={color} />;
  };

  return (
    <>
      <TableContainer className={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              {columnDefs.map((columnDef) => (
                <TableCell key={columnDef.field} className="cursorPointer" onClick={() => onSort(columnDef.field)}>
                  {columnDef.headerName}
                  <span className="float-left">{getIcon(columnDef.field)}</span>
                </TableCell>
              ))}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {candidates.map((candidate) => {
              const qualification = (candidate.candidateQualifications ?? [])[0] ?? {};
              return (
                <TableRow key={candidate.id}>
                  <TableCell>{candidate.name}</TableCell>
                  <TableCell>{qualification.institutionName}</TableCell>
                  <TableCell>{qualification.qualificationName}</TableCell>
                  <TableCell>{candidate.gender}</TableCell>
                  <TableCell>{candidate.aic}</TableCell>
                  <TableCell>{candidate.location}</TableCell>
                  <TableCell>
                    <Link href={`${ROUTES.Candidate}/${candidate.id}`}>
                      <Edit />
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
    </>
  );
};

export default CandidateTableView;

import { BasicModelState, IBasicModel } from 'common';
import produce from 'immer';
import * as ActionTypes from '../types';

export default function institutionsReducer(state: BasicModelState = { data: [] }, action: any): BasicModelState {
  switch (action.type) {
    case ActionTypes.GET_INSTITUTIONS_SUCCEED: {
      return produce(state, (draft) => {
        draft.data = ((action.payload as IBasicModel[]) ?? []).sort((a, b) => a.name?.localeCompare(b.name ?? ''));
      });
    }
    case ActionTypes.SAVE_INSTITUTION_SUCCEED: {
      return produce(state, (draft) => {
        draft.success = true;
      });
    }
    case ActionTypes.SAVE_INSTITUTION:
    case ActionTypes.REMOVE_INSTITUTION: {
      return produce(state, (draft) => {
        draft.success = undefined;
      });
    }
    default:
      return state;
  }
}

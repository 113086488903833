import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { ICalibrationCandidate } from 'modules/candidates/operations/models/candidatesModel';
import { ITeam } from 'modules/feedback/operations/models/feedbackModel';
import { put, select, takeLatest } from 'redux-saga/effects';
import { changeUpdateOperation, updateCalibrationOffer } from '../actions';
import { mergeCalibrationsAndFeedback } from '../helpers';
import { ICandidateCalibration } from '../models';
import { getTodaysOpenDayCandidates, getTodaysOpenDayTeams } from '../selectors';
import * as types from '../types';

function* saveCalibrationOffer(action: any): any {
  let allSessionTeams: ITeam[] = yield select(getTodaysOpenDayTeams);
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  const candidates: ICalibrationCandidate[] = yield select(getTodaysOpenDayCandidates);
  yield put(changeUpdateOperation(success));
  if (success && response?.data && allSessionTeams) {
    const updatedCalibrationsWithFeedback: ICandidateCalibration = mergeCalibrationsAndFeedback(
      [response.data],
      candidates,
      allSessionTeams
    )[0];
    yield put(updateCalibrationOffer(updatedCalibrationsWithFeedback));
  }
}

export function* saveCalibrationOfferSaga() {
  yield takeLatest(types.SAVE_CALIBRATION_OFFER, saveCalibrationOffer);
}

import produce from 'immer';
import IFeedbackOperation, { feedbackOperationDefault } from '../models/feedbackOperationModel';
import * as Types from '../types/feedbackOperationTypes';

export default function feedbackOperationReducer(
  state: IFeedbackOperation = feedbackOperationDefault,
  action: any
): IFeedbackOperation {
  switch (action.type) {
    case Types.CHANGE_ADD_CANDIDATES_TO_TEAM_STATUS: {
      return produce(state, (draft) => {
        draft.addCandidatesToTeamStatus = action.payload;
      });
    }
    case Types.CHANGE_REMOVE_CANDIDATE_FROM_TEAM_STATUS: {
      return produce(state, (draft) => {
        draft.removeCandidateFromTeamStatus = action.payload;
      });
    }
    case Types.CHANGE_FEEDBACK_POST_CREATION_STATUS:
      return produce(state, (draft) => {
        draft.creationStatus = action.payload;
      });
    case Types.CHANGE_FEEDBACK_POST_DELETION_STATUS:
      return produce(state, (draft) => {
        draft.deletionStatus = action.payload;
      });

    case Types.CHANGE_TEAM_FEEDBACK_POST_DELETION_STATUS:
      return produce(state, (draft) => {
        draft.deletionStatus = action.payload;
      });
    case Types.CHANGE_FEEDBACK_POST_UPDATE_STATUS:
      return produce(state, (draft) => {
        draft.updateStatus = action.payload;
      });
    default:
      return state;
  }
}

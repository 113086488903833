import { Box as Container, Pagination } from '@mui/material';
import { getLocations } from 'common/operations/actions/commonOperationActions';
import { getTeamCaptains } from 'modules/sessions/operations/actions/sessionsOperationActions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import useScreenSize from '../../../../common/hooks/useScreenSize';
import OpenDayCard from '../OpenDayCard/OpenDayCard';
import styles from './OpenDayList.module.scss';

interface IProps {
  setPageNo: (pageNo: number) => void;
}

export const OpenDayList: React.FC<IProps> = ({ setPageNo }) => {
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();

  const { openDays } = useSelector((state: IGlobalState) => {
    return {
      ...state.feedbackState.feedback
    };
  });

  useEffect(() => {
    dispatch(getTeamCaptains());
    dispatch(getLocations());
  }, []);

  return (
    <>
      {!isMobile && (
        <div className={styles.feedbackList}>
          <span className="flexBasis25">{process.env.REACT_APP_SESSION_NAME} Name</span>
          <span className="flexBasis15">Date</span>
          <span className="flexBasis10">Location</span>
          <span className="flexBasis15 textCenter">No. of Candidates</span>
          <span className="flexBasis10">Status</span>
          <span className="flexBasis25"></span>
          <span className="flexBasis10"></span>
        </div>
      )}

      {openDays.data.map((day) => (
        <OpenDayCard key={day.id} openDay={day} />
      ))}
      <Container className={styles.paginationContainer}>
        {openDays.totalPages > 1 && (
          <Container sx={{ marginBlock: 2 }} className={styles.paginationContainer}>
            <Pagination
              count={openDays.totalPages}
              page={openDays.pageNo}
              onChange={(_, value) => setPageNo(value)}
              shape="rounded"
            />
          </Container>
        )}
      </Container>
    </>
  );
};

export default OpenDayList;

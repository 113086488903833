import { IInstitution } from './institutionModel';
import { ILocation } from './locationModel';
import { IQualification } from './qualificationModel';

export interface ICommon {
  locations: ILocation[];
  institutions: IInstitution[];
  qualifications: IQualification[];
}

export const commonDefault: ICommon = {
  locations: [],
  institutions: [],
  qualifications: []
};

import { Autocomplete, TextField } from '@mui/material';
import { searchUsers } from 'modules/manage-users/operations/actions/userOperationActions';
import { IUserSearchModel } from 'modules/manage-users/operations/models';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../../startup/store/globalState';

interface IProps {
  onChange: (user: IUserSearchModel | null) => void;
  label?: string;
  value?: string;
  disabled?: boolean;
}

const ActiveDirectoryUsers: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const recruiterOptions = useSelector((state: IGlobalState) => state.userManagementState.userState.adUsers) || [];
  const [searchValue, setSearchValue] = useState(props.value);

  useEffect(() => {
    if (!searchValue || searchValue?.length < 2) return;
    const debounceFn = setTimeout(() => dispatch(searchUsers(searchValue)), 800);
    return () => clearTimeout(debounceFn);
  }, [searchValue]);

  return (
    <Autocomplete
      options={(recruiterOptions || []).map((option: IUserSearchModel) => ({
        label: option.displayName,
        ...option
      }))}
      onChange={(e, v) => props.onChange(v)}
      onInputChange={(e, v) => setSearchValue(v)}
      value={searchValue as any}
      disabled={props.disabled}
      renderInput={(params) => <TextField {...params} label={props.label || 'User'} />}
    />
  );
};

export default ActiveDirectoryUsers;

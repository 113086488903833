import { IRequest } from '../../../common/api/apiRequest';
import { Feature } from '../../../common/enums/appEnums';

export const GET_LOCATIONS = `GET_LOCATIONS${Feature.App}`;

export interface IGetLocationsActionType extends IRequest {
  type: typeof GET_LOCATIONS;
  payload: null;
}

export const GET_LOCATIONS_SUCCEED = `GET_LOCATIONS_SUCCEED${Feature.App}`;

export interface IGetLocationsSucceedActionType {
  type: typeof GET_LOCATIONS_SUCCEED;
  payload: any;
}

export const GET_LOCATIONS_FAILED = `GET_LOCATIONS_FAILED${Feature.App}`;

export interface IGetLocationsFailedActionType {
  type: typeof GET_LOCATIONS_FAILED;
  payload: any;
}

type GetLocationsOpActionTypes =
  | IGetLocationsActionType
  | IGetLocationsSucceedActionType
  | IGetLocationsFailedActionType;

export const GET_INSTITUTIONS = `GET_INSTITUTIONS${Feature.App}`;

export interface IGetInstitutionsActionType extends IRequest {
  type: typeof GET_INSTITUTIONS;
  payload: null;
}

export const GET_INSTITUTIONS_SUCCEED = `GET_INSTITUTIONS_SUCCEED${Feature.App}`;

export interface IGetInstitutionsSucceedActionType {
  type: typeof GET_INSTITUTIONS_SUCCEED;
  payload: any;
}

export const GET_INSTITUTIONS_FAILED = `GET_INSTITUTIONS_FAILED${Feature.App}`;

export interface IGetInstitutionsFailedActionType {
  type: typeof GET_INSTITUTIONS_FAILED;
  payload: any;
}

type GetInstitutionsOpActionTypes =
  | IGetInstitutionsActionType
  | IGetInstitutionsSucceedActionType
  | IGetInstitutionsFailedActionType;

export const GET_QUALIFICATIONS = `GET_QUALIFICATIONS${Feature.App}`;

export interface IGetQualificationsActionType extends IRequest {
  type: typeof GET_QUALIFICATIONS;
  payload: null;
}

export const GET_QUALIFICATIONS_SUCCEED = `GET_QUALIFICATIONS_SUCCEED${Feature.App}`;

export interface IGetQualificationsSucceedActionType {
  type: typeof GET_QUALIFICATIONS_SUCCEED;
  payload: any;
}

export const GET_QUALIFICATIONS_FAILED = `GET_QUALIFICATIONS_FAILED${Feature.App}`;

export interface IGetQualificationsFailedActionType {
  type: typeof GET_QUALIFICATIONS_FAILED;
  payload: any;
}

type GetQualificationsOpActionTypes =
  | IGetQualificationsActionType
  | IGetQualificationsSucceedActionType
  | IGetQualificationsFailedActionType;

type CommonOpTypes = GetLocationsOpActionTypes | GetInstitutionsOpActionTypes | GetQualificationsOpActionTypes;

export default CommonOpTypes;

export enum CalibrationOfferType {
  YES = 1,
  NO = 2,
  MAYBE = 3
}

export enum CalibrationType {
  PreCalibration = 'PRE_INTERVIEW',
  Interview = 'INTERVIEW',
  PostCalibration = 'POST_INTERVIEW'
}

export const CALIBRATION_NAME: { [key: string]: string } = {
  [CalibrationType.PreCalibration]: 'Pre-Calibration',
  [CalibrationType.Interview]: 'Interview',
  [CalibrationType.PostCalibration]: 'Post-Calibration'
};

import { ILocation, IOpenDay } from 'modules/feedback/operations/models/feedbackModel';
import {
  ICalibrationFilter,
  ICalibrationFilterRequest,
  ICalibrationSortRequest,
  ICalibrationState,
  ICalibrationStatus,
  IOfferCount
} from '.';
import { CalibrationFields } from '../enums/calibrationEnums';

export const defaultLocation: ILocation = {
  name: '',
  description: '',
  id: 0
};

export const defaultCalibrationOpenDay: IOpenDay = {
  id: 0,
  name: '',
  date: '',
  startTime: '',
  endTime: '',
  location: defaultLocation,
  status: '',
  teams: [],
  tags: [],
  unassignedCandidates: []
};

export const defaultStatuses: ICalibrationStatus[] = [
  {
    id: 1,
    value: 'Yes'
  },
  {
    id: 2,
    value: 'No'
  },
  {
    id: 3,
    value: 'Maybe'
  }
];

export const defaultCalibrationFilter: ICalibrationFilter = {
  captains: [],
  candidates: [],
  statuses: defaultStatuses
};

export const defaultCalibrationFilterRequest: ICalibrationFilterRequest = {
  candidateId: 0,
  captainIds: [],
  statuses: []
};

export const defaultCalibrationSortRequest: ICalibrationSortRequest = {
  asc: 1,
  desc: -1,
  off: 0,
  isSorted: true,
  fieldName: CalibrationFields.CandidateName,
  isAscending: true
};

export const defaultCalibrationState: ICalibrationState = {
  openDayCalibration: {
    calibrationObj: {},
    calibrations: [],
    candidateCalibrations: []
  },
  createCalibrationsSuccess: null
};

export const defaultOfferCount: IOfferCount = {
  yes: 0,
  no: 0,
  maybe: 0
};

import { Clear, Close } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CandidateTrackerLink from 'common/components/CandidateTrackerLink/CandidateTrackerLink';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import { resizeImage } from 'common/helpers/imageHelpers';
import { useCheckPermissions } from 'common/hooks/useCheckPermissions';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import {
  VALIDATION_SCHEMA,
  fileToBlob,
  getFilteredRelocations
} from 'modules/candidates/operations/helpers/candidateHelpers';
import { IRecruiter } from 'modules/candidates/operations/models/candidatesModel';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import { getTeamCaptains } from 'modules/sessions/operations/actions/sessionsOperationActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScreenSize from '../../../../common/hooks/useScreenSize';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { IGlobalState } from '../../../../startup/store/globalState';
import {
  changeCandidateUpdateStatus,
  createCandidate,
  getCandidate,
  getCandidateRecruiters,
  updateCandidate,
  updateCandidatePhoto
} from '../../operations/actions/candidatesOperationActions';
import { CandidateUpdatingStatus } from '../../operations/enums/candidateEnums';
import { CandidateDTO, ICandidateForm, getDefaultCandidateForm } from '../../operations/models/candidateModel';
import { IInstitution, IQualification } from '../../operations/models/institutionModel';
import styles from './CandidateForm.module.scss';
import { ImageSection } from './ImageSection';
import OpenDayDetails from './OpenDayDetails';

interface IProps {
  closeHandler: () => void;
  cId: number | null;
}

const CandidateFormGrid: React.FC<IProps> = ({ closeHandler, cId }) => {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const screenSize = useScreenSize();
  const updatingStatus = useSelector((state: IGlobalState) => state.candidatesState.candidatesOperation.updatingStatus);
  const retruiterOptions = useSelector((state: IGlobalState) => state.candidatesState.candidates.recuiters) || [];
  let existingCandidate = useSelector((state: IGlobalState) => state.candidatesState.candidates.candidate);

  const { locations, genders, ethnicities, institutions, qualifications, candidateStatuses } = useSelector(
    (state: IGlobalState) => {
      return {
        locations: state.commonState.common.locations,
        genders: state.candidatesState.candidates.genders,
        ethnicities: state.candidatesState.candidates.ethnicities,
        institutions: state.commonState.common.institutions,
        qualifications: state.commonState.common.qualifications,
        teamCaptains: state.sessionsState.sessions.teamCaptains,
        candidateStatuses: state.candidateStatusState.statuses
      };
    }
  );

  const [picFile, setPicFile] = useState<any[]>([]);
  const [candidateForm, setCandidateForm] = useState<ICandidateForm>({
    ...getDefaultCandidateForm()
  });

  const [activeTab, setActiveTab] = useState(0);
  const [initialValue, setInitialValue] = useState<any>({ ...getDefaultCandidateForm() });
  const [uploadedImg, setUploadedImg] = useState<string>('');
  const hasEditCandidatePermission = useCheckPermissions([
    {
      actionType: ClaimActionType.EDIT,
      claimType: ClaimTypes.CANDIDATES
    }
  ]);

  useEffect(() => {
    if ([CandidateUpdatingStatus.Success, CandidateUpdatingStatus.Failure].includes(updatingStatus)) {
      snackbar.snackbar({
        message: updatingStatus,
        duration: 3000,
        variant: updatingStatus === CandidateUpdatingStatus.Success ? 'success' : 'error'
      });
      if (updatingStatus === CandidateUpdatingStatus.Success) {
        dispatch(changeCandidateUpdateStatus(CandidateUpdatingStatus.Idle));
        dispatch(getCandidate(`${cId}/edit`));
      }
    }
  }, [updatingStatus]);

  useEffect(() => {
    if (cId) {
      dispatch(getCandidate(`${cId}/edit`));
    }
  }, [cId]);

  useEffect(() => {
    if (existingCandidate && cId) {
      const candidate: any = { ...getDefaultCandidateForm(), ...existingCandidate };
      dispatch(getTeamCaptains());
      candidate.recruiterSearch = candidate.recruiterName;
      candidate.relocationId = candidate.relocationId || 0;
      candidate.institutions = [
        ...candidate.candidateQualifications.reduce((newArray: any, curr: any) => {
          const institute = newArray.find((x: any) => x.id === curr.institutionId);
          const i = newArray.indexOf(institute);
          if (i > -1) {
            const qualification = {
              id: curr.qualificationId
            };
            newArray[i].qualifications.push(qualification);
          } else {
            const institution = {
              id: curr.institutionId,
              qualifications: [
                {
                  id: curr.qualificationId
                }
              ]
            };
            newArray.push(institution);
          }
          return newArray;
        }, [])
      ];

      candidate.attendanceId = candidate.attendance ? 1 : 2;
      setInitialValue(candidate);

      if (candidate.photo) {
        fetch(candidate.photo, { mode: 'cors' })
          .then((r) => r.blob())
          .then((blob) => {
            handleUpload(blob);
          });
      }
    }
  }, [existingCandidate]);

  let identicalCallCounter = 0;

  const handleFormChange = (prop: any, val: any, institutionIndex: number = -1, qualificationIndex: number = -1) => {
    if (prop === 'institution' || prop === 'qualification') {
      const institutions = [...(candidateForm.institutions || [])];
      institutions.forEach((institution, index) => {
        if (institutionIndex === index) {
          if (prop === 'institution') {
            institution.name = val;
            institution.id = val;
          } else if (prop === 'qualification') {
            institution.qualifications.forEach((qualification: IQualification, index2: number) => {
              if (qualificationIndex === index2) {
                qualification.name = val;
                qualification.id = val;
              }
            });
          }
        }
        setCandidateForm({ ...candidateForm, institutions: [...institutions] });
      });
    } else {
      const _state = { ...candidateForm, [prop as keyof ICandidateForm]: val };
      setCandidateForm(_state);
    }
  };

  const handleRecruiterSearch = (e: any, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason !== 'reset') {
      const searchTxt = value || '';
      // perform lookup if containters no pipes '|': it's selection.
      if (!searchTxt) {
        identicalCallCounter = identicalCallCounter + 1;
      } else {
        identicalCallCounter = 0;
      }
      if (searchTxt.indexOf('|') === -1 && identicalCallCounter < 3) {
        dispatch(getCandidateRecruiters(`txt=${searchTxt}`));
      }
    }
  };

  const handleRecruiterSelection = (props: any, value: string | null) => {
    const recruiterSearch = `${value || ''}`;
    props.setFieldValue('recruiterName', recruiterSearch);
    props.setFieldValue('recruiterSearch', recruiterSearch);
  };

  const submitForm = async (candidateForm: ICandidateForm) => {
    const formData = new FormData();
    const files = [...picFile];
    const candidate_payload = {
      ...candidateForm,
      location: candidateForm.locationId,
      photo:
        'https://i0.wp.com/write.co.nz/wp-content/uploads/2015/12/original-image-by-jose-m-izquierdo-galiot-cc-by.jpg',
      recruiterPhoneNumber: '000-000-0000',
      institutions: candidateForm.institutions?.map((uni) => {
        return {
          id: uni.id,
          name: institutions.find((x) => x.id === uni.id)?.name || '',
          qualifications: uni.qualifications.map((qual) => {
            return { id: qual.id, name: qualifications.find((x) => x.id === qual.id)?.name || '' };
          })
        };
      }),
      openDayId: candidateForm.openDayId || 0,
      attendance: candidateForm.attendanceId == 1,
      inviteToNextOpenDay: candidateForm.inviteToNextOpenDay == 1,
      reasonForNotAttending: candidateForm.reason,
      aic: candidateForm.aicId
    } as CandidateDTO;
    formData.append('file', await fileToBlob(files[0]));
    formData.append('candidate_string', JSON.stringify(candidate_payload));
    if (!cId) {
      dispatch(createCandidate(formData));
    } else {
      candidate_payload.id = cId;
      dispatch(updateCandidate(cId, candidate_payload));
    }
  };

  const handleWillingToRelocateChange = (props: any, willing: boolean): void => {
    if (willing !== null) {
      props.setFieldValue('willingToRelocate', willing);
    }
  };

  const handleSubmit = (values: any) => {
    submitForm(values);
  };

  const handleUpload = (file: Blob | MediaSource | null) => {
    setPicFile([file]);
    const url = file ? URL.createObjectURL(file) : '';
    setUploadedImg(url);
  };
  const handlePicSave = async () => {
    if (cId) {
      const formData = new FormData();
      const files = [...picFile];
      const resizedImage = await resizeImage(files[0]);
      formData.append('file', await fileToBlob(resizedImage));
      dispatch(updateCandidatePhoto(cId, formData));
    }
  };
  return (
    <Box sx={{ flexGrow: 1, backgroundColor: '#fff' }}>
      <Grid container xs={12} className={styles.gridAlignment}>
        <Grid item xs={2} sm={1.5} md={0.5}>
          <IconButton sx={{ padding: '0px' }} onClick={closeHandler}>
            <ArrowBackIcon className={styles.backArrow} />
          </IconButton>
        </Grid>
        <Grid item xs={10} sm={10.5} md={11.5}>
          <Typography variant="h5" sx={{ color: '#5D5D5D', py: 1 }}>
            <b>{cId ? 'Edit Candidate' : 'Add New Candidate'}</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid container display={'block'}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValue}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={handleSubmit}
        >
          {(formProps: any) => (
            <Form>
              <Grid container item>
                <ImageSection
                  handleUpload={handleUpload}
                  uploadedImg={uploadedImg}
                  handlePicSave={handlePicSave}
                  hasEditCandidatePermission={hasEditCandidatePermission}
                />
                <Grid
                  item
                  sx={{
                    textAlign: 'center',
                    display: screenSize.isMobile ? 'none' : 'inline-flex'
                  }}
                >
                  {screenSize.isDesktop ? (
                    <Divider orientation="vertical" className={styles.verticalDivider}></Divider>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item container xs={12} sm={12} md={8} lg={9} sx={{ height: 'fit-content!important' }}>
                  {/* tab grid */}
                  <Grid item xs={12}>
                    <Tabs value={activeTab} onChange={(_, i) => setActiveTab(i)}>
                      <Tab label="Candidate Details" sx={{ textTransform: 'none' }}></Tab>
                      <Tab label={`${process.env.REACT_APP_SESSION_NAME} Details`} sx={{ textTransform: 'none' }}></Tab>
                    </Tabs>
                  </Grid>
                  {activeTab === 0 ? (
                    <Grid item container xs={12} sm={12} md={9} rowSpacing={1} marginY={1}>
                      <Grid item container xs={12} sm={12} md={12} spacing={1} rowSpacing={2}>
                        <Grid item xs={12}>
                          <Typography sx={{ textAlign: 'left' }}>
                            <b>Personal Information</b>
                            <span className="float-right mr-05">
                              <CandidateTrackerLink candidateTrackerLink={formProps.values.candidateTrackerLink} />
                            </span>
                          </Typography>
                        </Grid>
                        {/* name below */}
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            className={styles.jfInput}
                            variant="outlined"
                            label="Full name"
                            name="name"
                            disabled
                            value={formProps.values.name}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            helperText={<ErrorMessage name="name" />}
                            error={!!(formProps.errors.name && formProps.touched.name)}
                            sx={{
                              marginBottom: formProps.errors.name && formProps.touched.name ? '35px' : '0px'
                            }}
                            InputProps={{
                              endAdornment: formProps.values.name ? (
                                <InputAdornment position="end">
                                  <Clear onClick={() => formProps.setFieldValue('name', '')}></Clear>
                                </InputAdornment>
                              ) : (
                                <></>
                              )
                            }}
                          />
                        </Grid>
                        {/* location below */}
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            className={styles.jfInput}
                            label="Location"
                            name="locationId"
                            select
                            disabled={!hasEditCandidatePermission}
                            value={formProps.values.locationId}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            helperText={<ErrorMessage name="locationId" />}
                            error={!!(formProps.errors.locationId && formProps.touched.locationId)}
                            sx={{
                              marginBottom: formProps.errors.locationId && formProps.touched.locationId ? '35px' : '0px'
                            }}
                          >
                            {locations.map((location: any) => (
                              <MenuItem key={location.id} value={location.id}>
                                {location.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {/* willing to relocate below */}
                        <Grid item xs={12}>
                          <div className={styles.jfRelocationContainer}>
                            <Typography>Willing to Relocate</Typography>
                            <ToggleButtonGroup
                              className={styles.jdfSwitchGroup}
                              value={formProps.values.willingToRelocate}
                              exclusive
                              onChange={(e, val) => handleWillingToRelocateChange(formProps, val)}
                              sx={{
                                height: '24px',
                                '.MuiToggleButton-root': {
                                  color: '#002850',
                                  fontSize: '12px',
                                  fontWeight: 600,
                                  backgroundColor: 'white',
                                  border: '1px solid #002850',
                                  textTransform: 'capitalize'
                                },
                                '.MuiToggleButton-root.Mui-selected': {
                                  backgroundColor: '#002850',
                                  color: 'white'
                                }
                              }}
                            >
                              <ToggleButton
                                value={true}
                                sx={{
                                  borderRadius: '10px 0px 0px 10px'
                                }}
                                disabled={!hasEditCandidatePermission}
                              >
                                Yes
                              </ToggleButton>
                              <ToggleButton
                                value={false}
                                sx={{
                                  borderRadius: '0px 10px 10px 0px'
                                }}
                                disabled={!hasEditCandidatePermission}
                              >
                                No
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </div>
                        </Grid>
                        {/* relocation below */}
                        {formProps.values.willingToRelocate ? (
                          <Grid item xs={12}>
                            <TextField
                              className={styles.jfInput}
                              select
                              id="candidate-relocation-helper"
                              value={formProps.values.relocationId}
                              label="Relocate To* "
                              name="relocationId"
                              onChange={formProps.handleChange}
                              onBlur={formProps.handleBlur}
                              helperText={<ErrorMessage name="relocationId" />}
                              error={!!(formProps.errors.relocation && formProps.touched.relocationId)}
                              sx={{
                                marginBottom:
                                  formProps.errors.relocation && formProps.touched.relocationId ? '35px' : '0px'
                              }}
                            >
                              {getFilteredRelocations(locations || [])
                                .filter((x: any) => x.id != formProps.values.locationId)
                                .map((location: any) => (
                                  <MenuItem key={location.id} value={location.id}>
                                    {location.name}
                                  </MenuItem>
                                ))}
                            </TextField>
                            <FormHelperText sx={{ paddingX: 1 }}>
                              This cannot be the same as the current location.
                            </FormHelperText>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        {/* gender below */}
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            select
                            className={styles.jfInput}
                            name="gender"
                            label="Gender"
                            disabled={!hasEditCandidatePermission}
                            value={formProps.values.gender}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            helperText={<ErrorMessage name="gender" />}
                            error={!!(formProps.errors.gender && formProps.touched.gender)}
                            sx={{
                              marginBottom: formProps.errors.gender && formProps.touched.gender ? '35px' : '0px'
                            }}
                          >
                            {genders.map((gender: any) => (
                              <MenuItem key={gender.id} value={gender.name}>
                                {gender.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        {/* ethnicity below */}
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            className={styles.jfInput}
                            label="AIC"
                            name="aicId"
                            select
                            disabled={!hasEditCandidatePermission}
                            value={formProps.values.aicId}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            helperText={<ErrorMessage name="aicId" />}
                            error={!!(formProps.errors.aicId && formProps.touched.aicId)}
                            sx={{
                              marginBottom: formProps.errors.aicId && formProps.touched.aicId ? '35px' : '0px'
                            }}
                          >
                            {ethnicities.map((aic: any) => (
                              <MenuItem value={aic.id} key={aic.id}>
                                {aic.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                      {/* education history below */}
                      <Grid item container spacing={1} xs={12} sm={12} md={12}>
                        <Grid item xs={12}>
                          <Typography sx={{ textAlign: 'left' }}>
                            <b>Education history</b>
                          </Typography>
                        </Grid>
                        <FieldArray
                          name="institutions"
                          render={(arrayHelpers) => {
                            const formInstitutions: any[] = formProps.values.institutions || [];
                            return (
                              <>
                                {formInstitutions.map((edu: IInstitution, i0: number) => (
                                  <Grid container item xs={12} key={i0} spacing={1}>
                                    <Grid container item xs={12} sm={6} md={6}>
                                      <Grid item xs={12}>
                                        <TextField
                                          variant="outlined"
                                          label={`University`}
                                          name={`institutions.${i0}.id`}
                                          className={styles.jfInput}
                                          disabled={!hasEditCandidatePermission}
                                          onChange={formProps.handleChange}
                                          onBlur={formProps.handleBlur}
                                          select
                                          helperText={<ErrorMessage name={`institutions.${i0}.id`} />}
                                          error={
                                            formProps.errors.institutions &&
                                            formProps.errors.institutions[i0] &&
                                            formProps.touched.institutions &&
                                            formProps.touched.institutions[i0]
                                              ? !!Object.keys(formProps.errors.institutions[i0]).find(
                                                  (x: any) => x === 'id'
                                                )
                                              : false
                                          }
                                          sx={{
                                            marginBottom: (
                                              formProps.errors.institutions &&
                                              formProps.errors.institutions[i0] &&
                                              formProps.touched.institutions &&
                                              formProps.touched.institutions[i0]
                                                ? !!Object.keys(formProps.errors.institutions[i0]).find(
                                                    (x: any) => x === 'id'
                                                  )
                                                : false
                                            )
                                              ? '35px'
                                              : '0px'
                                          }}
                                          SelectProps={{
                                            IconComponent: () => null,
                                            value: edu.id
                                          }}
                                          InputProps={{
                                            endAdornment:
                                              i0 !== 0 ? (
                                                <InputAdornment position="end">
                                                  <Close
                                                    sx={{
                                                      cursor: 'pointer'
                                                    }}
                                                    onClick={() => arrayHelpers.remove(i0)}
                                                  ></Close>
                                                </InputAdornment>
                                              ) : edu.name ? (
                                                <InputAdornment position="end">
                                                  <Clear
                                                    sx={{
                                                      cursor: 'pointer'
                                                    }}
                                                    onClick={() => handleFormChange('institution', '', i0)}
                                                  ></Clear>
                                                </InputAdornment>
                                              ) : (
                                                <></>
                                              )
                                          }}
                                        >
                                          <MenuItem selected={true} value={0} disabled>
                                            {'Select University'}
                                          </MenuItem>
                                          {institutions.map((institution, index) => (
                                            <MenuItem key={index} value={institution.id}>
                                              {institution.name}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                    </Grid>
                                    <FieldArray
                                      name={`institutions.${i0}.qualifications`}
                                      render={(sub_arrayHelpers) => {
                                        const formQualifications =
                                          formProps.values.institutions[i0].qualifications || [];

                                        return (
                                          <Grid item container xs={12} sm={6} md={6} rowSpacing={1}>
                                            {formQualifications.map((degree: any, i1: number) => (
                                              <Grid item xs={12} key={i1}>
                                                <TextField
                                                  variant="outlined"
                                                  label="Qualifications"
                                                  disabled={!hasEditCandidatePermission}
                                                  className={styles.jfInput}
                                                  name={`institutions.${i0}.qualifications.${i1}.id`}
                                                  onChange={formProps.handleChange}
                                                  onBlur={formProps.handleBlur}
                                                  select
                                                  helperText={
                                                    <ErrorMessage name={`institutions.${i0}.qualifications.${i1}.id`} />
                                                  }
                                                  error={
                                                    formProps.errors.institutions &&
                                                    formProps.errors.institutions[i0] &&
                                                    formProps.errors.institutions[i0].qualifications &&
                                                    formProps.errors.institutions[i0].qualifications[i1] &&
                                                    formProps.touched.institutions &&
                                                    formProps.touched.institutions[i0] &&
                                                    formProps.touched.institutions[i0].qualifications &&
                                                    formProps.touched.institutions[i0].qualifications[i1]
                                                      ? !!Object.keys(
                                                          formProps.touched.institutions[i0].qualifications[i1]
                                                        ).find((x: any) => x === 'id')
                                                      : false
                                                  }
                                                  sx={{
                                                    marginBottom: (
                                                      formProps.errors.institutions &&
                                                      formProps.errors.institutions[i0] &&
                                                      formProps.errors.institutions[i0].qualifications &&
                                                      formProps.errors.institutions[i0].qualifications[i1] &&
                                                      formProps.touched.institutions &&
                                                      formProps.touched.institutions[i0] &&
                                                      formProps.touched.institutions[i0].qualifications &&
                                                      formProps.touched.institutions[i0].qualifications[i1]
                                                        ? !!Object.keys(
                                                            formProps.touched.institutions[i0].qualifications[i1]
                                                          ).find((x: any) => x === 'id')
                                                        : false
                                                    )
                                                      ? '35px'
                                                      : '0px'
                                                  }}
                                                  SelectProps={{
                                                    IconComponent: () => null,
                                                    value: degree.id
                                                  }}
                                                  InputProps={{
                                                    endAdornment:
                                                      i0 === 0 && i1 === 0 && degree.name ? (
                                                        <InputAdornment position="end">
                                                          <Clear
                                                            sx={{
                                                              cursor: 'pointer'
                                                            }}
                                                            onClick={() =>
                                                              handleFormChange('qualification', '', i0, i1)
                                                            }
                                                          ></Clear>
                                                        </InputAdornment>
                                                      ) : i1 !== 0 ? (
                                                        <InputAdornment position="end">
                                                          <Close
                                                            sx={{
                                                              cursor: 'pointer'
                                                            }}
                                                            onClick={() => sub_arrayHelpers.remove(i1)}
                                                          ></Close>
                                                        </InputAdornment>
                                                      ) : (
                                                        <></>
                                                      )
                                                  }}
                                                >
                                                  <MenuItem selected={true} value={0} disabled>
                                                    {'Select Qualification'}
                                                  </MenuItem>
                                                  {qualifications.map((qualification, index) => (
                                                    <MenuItem key={index} value={qualification.id}>
                                                      {qualification.name}
                                                    </MenuItem>
                                                  ))}
                                                </TextField>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        );
                                      }}
                                    />
                                  </Grid>
                                ))}
                              </>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item container spacing={1} xs={12} sm={12} md={12}>
                        <Grid item xs={12}>
                          <Typography sx={{ textAlign: 'left' }}>
                            <b>Other Information</b>
                          </Typography>
                        </Grid>
                        {/* job title below */}
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            className={styles.jfInput}
                            variant="outlined"
                            label="Job Title*"
                            name="jobTitle"
                            disabled={!hasEditCandidatePermission}
                            value={formProps.values.jobTitle}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            helperText={<ErrorMessage name="jobTitle" />}
                            error={!!(formProps.errors.jobTitle && formProps.touched.jobTitle)}
                            sx={{
                              marginBottom: formProps.errors.jobTitle && formProps.touched.jobTitle ? '30px' : '0px'
                            }}
                            InputProps={{
                              endAdornment: candidateForm.jobTitle ? (
                                <InputAdornment position="end">
                                  <Clear onClick={() => formProps.setFieldValue('jobTitle', '')}></Clear>
                                </InputAdornment>
                              ) : (
                                <></>
                              )
                            }}
                          ></TextField>
                        </Grid>
                        {/* recruiter name below */}
                        <Grid item xs={12} sm={6} md={6}>
                          <Autocomplete
                            id="recruiter-name"
                            options={retruiterOptions.map((option: IRecruiter) => `${option.recruiterName}`)}
                            disabled={!hasEditCandidatePermission}
                            value={formProps.values.recruiterSearch}
                            onChange={(e, v: any) => handleRecruiterSelection(formProps, v)}
                            onInputChange={handleRecruiterSearch}
                            sx={{
                              marginBottom:
                                formProps.errors.recruiterSearch && formProps.touched.recruiterSearch ? '35px' : '0px'
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onBlur={formProps.handleBlur}
                                name="recruiterName"
                                label="Responsible Recruiter"
                                error={!!(formProps.errors.recruiterName && formProps.touched.recruiterName)}
                                helperText={<ErrorMessage name="recruiterName" />}
                              />
                            )}
                          />
                          {/* <FormHelperText sx={{ color: "red" }}>
                              <small> {formProps.errors.recruiterName ? "" : "Recruiter is required"}</small>
                            </FormHelperText> */}
                        </Grid>
                        {/* additional comments below */}
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            label="Candidate Notes"
                            multiline
                            className={styles.jfInput}
                            variant="outlined"
                            name="comments"
                            value={formProps.values.comments}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            disabled={!hasEditCandidatePermission}
                            InputProps={{
                              endAdornment: candidateForm.location ? (
                                <InputAdornment position="end">
                                  <Clear onClick={() => formProps.setFieldValue('comments', '')}></Clear>
                                </InputAdornment>
                              ) : (
                                <></>
                              )
                            }}
                          />
                        </Grid>
                        {/* canidate tracker link below */}
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            className={styles.jfInput}
                            variant="outlined"
                            label="Candidate Tracking Link"
                            name="candidateTrackerLink"
                            disabled={true}
                            value={formProps.values.candidateTrackerLink}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            helperText={<ErrorMessage name="candidateTrackerLink" />}
                            error={!!(formProps.errors.candidateTrackerLink && formProps.touched.candidateTrackerLink)}
                            sx={{
                              marginBottom:
                                formProps.errors.candidateTrackerLink && formProps.touched.candidateTrackerLink
                                  ? '35px'
                                  : '0px'
                            }}
                            InputProps={{
                              endAdornment: candidateForm.location ? (
                                <InputAdornment position="end">
                                  <Clear onClick={() => handleFormChange('candidateTrackerLink', '')}></Clear>
                                </InputAdornment>
                              ) : (
                                <></>
                              )
                            }}
                          ></TextField>
                        </Grid>
                        {/* candidate status below */}
                        <Grid item xs={12} sm={12} md={12}>
                          <TextField
                            className={styles.jfInput}
                            select
                            name="status"
                            value={formProps.values.status}
                            label="Progress status"
                            disabled={true}
                            onChange={formProps.handleChange}
                            onBlur={formProps.handleBlur}
                            helperText={<ErrorMessage name="status" />}
                            error={!!(formProps.errors.status && formProps.touched.status)}
                            sx={{
                              marginBottom: formProps.errors.status && formProps.touched.status ? '35px' : '0px'
                            }}
                          >
                            {candidateStatuses.map((status: any) => (
                              <MenuItem key={status.id} value={status.id}>
                                {status.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <OpenDayDetails />
                  )}
                  <Grid item container xs={12} sm={12} md={9} rowSpacing={2} marginY={2}>
                    <Grid item container xs={12} sm={12} md={12} className={styles.candidateFormButtons} spacing={2}>
                      <Grid item xs={12} sm={12} md={4} order={{ xs: 1, sm: 3 }}>
                        <EntelectButton
                          variant={ButtonType.Primary}
                          type="submit"
                          disabled={!(formProps.dirty && formProps.isValid)}
                          label={'save'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} order={{ xs: 2, sm: 2 }}>
                        <EntelectButton
                          variant={ButtonType.Secondary}
                          handleClick={closeHandler}
                          label={'Cancel'}
                        ></EntelectButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Box>
  );
};

export default CandidateFormGrid;

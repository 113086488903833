import { getCandidateStatusesSaga, removeCandidateStatusSaga, saveCandidateStatusSaga } from './candidateStatusSaga';
import { getEthnicities, removeEthnicity, saveEthnicity } from './ethnicitiesSaga';
import {
  getFeedbackCategoriesSaga,
  removeFeedbackCategorySaga,
  saveFeedbackCategorySaga
} from './feedbackCategoriesSaga';
import { getInstitutions, removeInstitution, saveInstitution } from './institutionsSaga';
import { getQualifications, removeQualification, saveQualification } from './qualificationsSaga';
import { getTagsSaga, removeTagSaga, saveTagSaga } from './tagsSaga';

const tagsSaga = [getTagsSaga(), saveTagSaga(), removeTagSaga()];
const feedbackCategoriesSaga = [getFeedbackCategoriesSaga(), removeFeedbackCategorySaga(), saveFeedbackCategorySaga()];
const candidateStatusesSaga = [getCandidateStatusesSaga(), removeCandidateStatusSaga(), saveCandidateStatusSaga()];
const institutionsSaga = [getInstitutions(), saveInstitution(), removeInstitution()];
const qualificationsSaga = [getQualifications(), saveQualification(), removeQualification()];
const ethnicitiesSaga = [getEthnicities(), saveEthnicity(), removeEthnicity()];

export const manageAppSagas = tagsSaga
  .concat(feedbackCategoriesSaga)
  .concat(candidateStatusesSaga)
  .concat(institutionsSaga)
  .concat(qualificationsSaga)
  .concat(ethnicitiesSaga);

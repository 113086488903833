import { IPaginatedResponse } from 'common/models/paginatedResponse';
import { IActionType } from 'common/operations/types/actionTypes';
import { IOpenDay, ITeam } from 'modules/feedback/operations/models/feedbackModel';
import { IOpenDaySummary, ISessionTeamCaptain } from '../models/sessionsModel';
import * as Types from '../types/sessionsTypes';

export const changeSessions = (sessions: IPaginatedResponse<IOpenDay>): IActionType<IPaginatedResponse<IOpenDay>> => {
  return {
    type: Types.SESSIONS_CHANGED,
    payload: sessions
  };
};

export const changeSessionSummaries = (
  summaries: IPaginatedResponse<IOpenDaySummary>
): IActionType<IPaginatedResponse<IOpenDaySummary>> => {
  return {
    type: Types.SESSION_SUMMARIES_CHANGED,
    payload: summaries
  };
};

export const changeSessionById = (session: IOpenDay): IActionType<IOpenDay> => {
  return {
    type: Types.SESSIONS_CHANGED_BY_ID,
    payload: session
  };
};

export const changeTeamCaptains = (payload: ISessionTeamCaptain[]): IActionType<ISessionTeamCaptain[]> => {
  return {
    type: Types.CHANGED_TEAM_CAPTAINS,
    payload: payload
  };
};

export const changeSessionTeams = (teams: ITeam[]): IActionType<ITeam[]> => {
  return {
    type: Types.SESSION_TEAMS_CHANGED,
    payload: teams
  };
};

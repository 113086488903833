import { AddBoxOutlined, CloseOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  Switch,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { CLAIM_ACTION_TYPES } from 'common/constants/appContants';
import { signOut } from 'modules/auth/operations/actions/authActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import EntelectButton from '../../../../common/components/EntelectButton/EntelectButton';
import GuardedButton from '../../../../common/components/GuardedButton/GuardedButton';
import GuardedEdit from '../../../../common/components/GuardedEdit/GuardedEdit';
import { ButtonType } from '../../../../common/enums/buttonTypeEnum';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import ActiveDirectoryUsers from '../../../shared/components/ActiveDirectoryUsers';
import { getAllClaims } from '../../operations/actions/claimActions';
import { saveUser } from '../../operations/actions/userActions';
import { ClaimTypes } from '../../operations/enums/claimTypes';
import { ClaimActionType, IUserClaimModel } from '../../operations/models';
import { IRole } from '../../operations/models/IRole';
import { IUserDialogProps } from '../../operations/models/userDialogProps';
import { IUserModel, IUserSearchModel } from '../../operations/models/userModel';
import PermissionMatrix from './PermissionMatrix';
import styles from './UserDialog.module.scss';

enum UserRole {
  SUPER_USER = 'Super User',
  ADMIN_USER = 'Admin User',
  FEEDBACK_USER = 'Feedback User',
  RECRUITMENT_USER = 'Recruitment User'
}

const UserDialog = (props: IUserDialogProps) => {
  const { loading, operationSuccess, roles, loggedInUser } = useSelector((state: IGlobalState) => {
    return {
      loading: state.appState.app.loading,
      operationSuccess: state.userManagementState.userState.operationSuccess,
      roles: state.userManagementState.roleState.roles,
      loggedInUser: state.authState.authUser
    };
  });

  const dispatch = useDispatch();
  const { snackbar } = useSnackbar();
  const [open, setOpen] = useState(!!props.open);
  const [userModel, setUserModel] = useState(props.user || ({ active: true, isTeamCaptain: false } as IUserModel));
  const [updatedClaims, setUpdatedClaims] = useState([] as IUserClaimModel[]);

  useEffect(() => {
    if (open) dispatch(getAllClaims());
  }, [open]);

  useEffect(() => {
    if (props.user) {
      setUserModel(props.user);
      setUpdatedClaims(props.user.userClaims);
    }
  }, [props.user]);

  useEffect(() => {
    if (!open || operationSuccess == null) {
      return;
    }

    if (operationSuccess) {
      const message = userModel.id ? 'User was successfully updated.' : 'New User was successfully added.';
      snackbar({ message, variant: 'success', action: true });
      setUserModel({ active: true, isTeamCaptain: false } as IUserModel);
      setUpdatedClaims([]);

      setOpen(false);
      // if user has id and this is the user, then kick the user out of the system
      if (userModel.id && loggedInUser?.id === userModel.id && !userModel.active) {
        snackbar({
          message: 'You have deactivated your own account. Please contact the system admin to reactivate it.',
          variant: 'warning',
          action: true
        });
        dispatch(signOut());
      }
    }
  }, [operationSuccess]);

  useEffect(() => {
    updateForm('isTeamCaptain', userModel.isTeamCaptain);
  }, [userModel.userRole]);

  const updateForm = (key: string, value: string | number | boolean) => {
    setUserModel({ ...userModel, [key]: value });
  };

  const updateRole = (roleId: number) => {
    const role = roles.find((x) => x.id == roleId) as IRole;
    setUpdatedClaims([]);
    setUserModel((prevUserModel) => {
      // clear isTeamCaptain if role no loger allows it
      const isTeamCaptain = allowTeamCaption(role) && prevUserModel.isTeamCaptain;
      return { ...prevUserModel, userRole: role, userClaims: [], isTeamCaptain: isTeamCaptain };
    });
  };

  const handleSave = () => {
    dispatch(
      saveUser({
        ...userModel,
        userClaims: updatedClaims,
        active: !!userModel.active
      })
    );
  };

  const handleUserSelected = (searchUser: IUserSearchModel | null) => {
    searchUser && setUserModel({ ...userModel, ...searchUser });
  };

  const allowTeamCaption = (userRole: IRole | null) => {
    return userRole?.name !== UserRole.RECRUITMENT_USER;
  };

  const isFormValid =
    ['name', 'email'].every((prop) => (((userModel || {}) as any)[prop] || '').length) && userModel?.userRole?.id;

  return (
    <>
      {props.user?.id ? (
        <GuardedEdit
          editStyle="outlined"
          sx={{ marginTop: '2px' }}
          scopes={[
            {
              actionType: ClaimActionType.EDIT,
              claimType: ClaimTypes.MANAGE_USERS
            }
          ]}
          renderErrorItem={() => <></>}
          onClick={() => setOpen(true)}
        ></GuardedEdit>
      ) : (
        <GuardedButton
          label={'NEW USER'}
          className={styles.newUserButton}
          onClick={() => setOpen(true)}
          color="success"
          variant="contained"
          startIcon={<AddBoxOutlined />}
          errorProps={{ disabled: true }}
          scopes={[
            {
              actionType: ClaimActionType.ADD,
              claimType: ClaimTypes.MANAGE_USERS
            }
          ]}
        />
      )}

      <Modal open={open} onClose={() => setOpen(false)}>
        <Grid container item justifyContent="center">
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <Box>
              <br />
              <Card>
                <CardHeader
                  action={
                    <IconButton onClick={() => setOpen(false)}>
                      <CloseOutlined />
                    </IconButton>
                  }
                  title={props.user?.id ? 'Edit User' : 'Add New User'}
                />
                <CardContent className={styles.maxHeight80}>
                  <FormControl fullWidth>
                    <ActiveDirectoryUsers
                      value={userModel.displayName}
                      onChange={handleUserSelected}
                      label={'Full Name*'}
                      disabled={!!props.user?.id}
                    />
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 3 }}>
                    <TextField
                      value={userModel.email}
                      onChange={(e) => updateForm('email', e.target.value)}
                      label="Email"
                      type="email"
                      variant="outlined"
                      InputLabelProps={{ shrink: userModel?.email?.length > 0 }}
                      disabled
                      required
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormControlLabel
                      value={userModel.active}
                      sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                      control={
                        <Switch
                          color="primary"
                          onChange={(e, v) => updateForm('active', v)}
                          checked={userModel.active}
                        />
                      }
                      label="Active Status "
                      labelPlacement="start"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormControlLabel
                      value={userModel.isTeamCaptain}
                      sx={{
                        mt: 1,
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                      control={
                        <Switch
                          color="primary"
                          checked={userModel.isTeamCaptain}
                          onChange={(e, v) => updateForm('isTeamCaptain', v)}
                          disabled={!allowTeamCaption(userModel.userRole)}
                        />
                      }
                      label="Team Captain  "
                      labelPlacement="start"
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ mt: 3 }}>
                    <InputLabel id="demo-select-small-label">User Role*</InputLabel>
                    <Select
                      value={userModel?.userRole?.id}
                      label="User Role*"
                      onChange={(e) => updateRole(e.target.value as number)}
                    >
                      {roles.map((role: IRole) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br />
                  <br />
                  <Typography gutterBottom variant="h6" component="h6">
                    Overview of Access Allowed
                  </Typography>

                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Page Permissions</TableCell>
                        {CLAIM_ACTION_TYPES.map((actionHeader) => (
                          <TableCell key={actionHeader.key}>{actionHeader.value}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <PermissionMatrix
                      role={userModel?.userRole}
                      loadMatrix={open}
                      onChange={(claims) => setUpdatedClaims(claims)}
                      userClaims={userModel.userClaims}
                    />
                  </Table>

                  {loading ? <LinearProgress sx={{ marginInline: '5px' }} /> : <></>}
                  <br />
                  <CardActions sx={{ float: 'right' }}>
                    <EntelectButton label="CANCEL" variant={ButtonType.Secondary} handleClick={() => setOpen(false)} />
                    <EntelectButton
                      label={(props.user?.id ? 'UPDATE ' : 'ADD ') + 'USER'}
                      variant={ButtonType.Primary}
                      handleClick={handleSave}
                      disabled={!isFormValid}
                    />
                  </CardActions>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default UserDialog;

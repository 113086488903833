import { ROUTES } from 'common/constants/routesConstants';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { getLocations } from 'common/operations/actions/commonOperationActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IGlobalState } from '../../../startup/store/globalState';
import SessionDetails from '../components/SessionDetails/SessionDetails';
import {
  changeSessionCreateStatus,
  changeSessionUpdateStatus,
  getSessionById,
  getSessionByIdSucceed,
  getTeamCaptains
} from '../operations/actions/sessionsOperationActions';
import { SessionCreationStatus, SessionUpdateStatus } from '../operations/enums/sessionEnum';

export const SessionsDetailsContainer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionId = useParams().sessionId;
  const snackbar = useSnackbar();
  const { creationStatus, updateStatus, openDay } = useSelector(
    (state: IGlobalState) => state.sessionsState.sessionsOperation
  );

  useEffect(() => {
    if ([SessionCreationStatus.Success, SessionCreationStatus.Failure].includes(creationStatus)) {
      if (creationStatus === SessionCreationStatus.Success) {
        snackbar.snackbar({
          message: creationStatus,
          duration: 3000,
          variant: 'success'
        });
        navigate(openDay?.id ? `${ROUTES.OpenDayCandidates}/${openDay?.id}` : ROUTES.OpenDayCandidates);
      }
      dispatch(changeSessionCreateStatus(SessionCreationStatus.Idle));
    }
    if ([SessionUpdateStatus.Success, SessionUpdateStatus.Failure].includes(updateStatus)) {
      if (updateStatus === SessionUpdateStatus.Success) {
        snackbar.snackbar({
          message: updateStatus,
          duration: 3000,
          variant: 'success'
        });
        navigate(ROUTES.OpenDays);
      }
      dispatch(changeSessionUpdateStatus(SessionUpdateStatus.Idle));
    }
  }, [creationStatus, updateStatus]);

  const { locations, teamCaptains } = useSelector((state: IGlobalState) => {
    return {
      locations: state.commonState.common.locations,
      teamCaptains: state.sessionsState.sessions.teamCaptains
    };
  });

  useEffect(() => {
    dispatch(getSessionByIdSucceed());
  }, []);

  useEffect(() => {
    if (sessionId) {
      +sessionId && dispatch(getSessionById(sessionId));
      !teamCaptains?.length && dispatch(getTeamCaptains());
      !locations?.length && dispatch(getLocations());
    }
  }, [sessionId]);

  return <SessionDetails closeHandler={() => navigate(ROUTES.OpenDays)} />;
};

export default SessionsDetailsContainer;

import { IOpenDay } from 'modules/feedback/operations/models/feedbackModel';
import {
  SessionCreationStatus,
  SessionDeletionStatus,
  SessionUpdateStatus,
  TeamToSessionStatus
} from '../enums/sessionEnum';

export default interface ISessionsOperation {
  creationStatus: SessionCreationStatus;
  deletionStatus: SessionDeletionStatus;
  updateStatus: SessionUpdateStatus;
  addTeamToSessionStatus: TeamToSessionStatus;
  activeSessionInUpdate: IOpenDay | null;
  openDay: IOpenDay | null;
  unavailableSessions: string[];
}

export const sessionsOperationDefault: ISessionsOperation = {
  creationStatus: SessionCreationStatus.Idle,
  deletionStatus: SessionDeletionStatus.Idle,
  updateStatus: SessionUpdateStatus.Idle,
  addTeamToSessionStatus: TeamToSessionStatus.Idle,
  activeSessionInUpdate: null,
  openDay: null,
  unavailableSessions: []
};

import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import * as ClaimTypes from '../../../manage-users/operations/types';
import { claimActions } from '../actions';

function* getAllClaims(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  if (success) {
    yield put(claimActions.fetchedAllClaims(response.data));
  }
}

export function* getAllClaimsSaga() {
  yield takeLatest(ClaimTypes.FETCH_ALL_CLAIMS, getAllClaims);
}

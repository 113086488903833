import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Box, CircularProgress, Container, Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loginLogoLarge from '../../../assets/login-large.svg';
import loginLogoSmall from '../../../assets/login-small.svg';
import EntelectButton from '../../../common/components/EntelectButton/EntelectButton';
import { ButtonType } from '../../../common/enums/buttonTypeEnum';
import useScreenSize from '../../../common/hooks/useScreenSize';
import { IGlobalState } from '../../../startup/store/globalState';
import { signIn } from '../operations/actions/authActions';
import styles from './Auth.module.scss';

export const Verification: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const { inProgress, instance } = useMsal();
  const { isPending, isAuthenticated } = useSelector((state: IGlobalState) => state.authState);

  const onClickLogin = () => {
    dispatch(signIn());
  };

  const style = isMobile ? { width: '221px', height: '216px' } : { width: '688px', height: '818px' };
  const url = 'https://esadfs.entelect.co.za/adfs/portal/updatepassword';
  const loading = inProgress != InteractionStatus.None || isPending;
  if (isAuthenticated) return <></>;

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={8} sm={6} sx={{ height: isMobile ? 'auto' : '95vh' }}>
        <Box className={styles.authBox}>
          <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src={isMobile ? loginLogoSmall : loginLogoLarge}
              alt="Login Logo"
              style={{ ...{ maxWidth: '100%', height: 'auto' }, ...style }}
            />
          </Container>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <Container className={styles.authContainer}>
            {loading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress size={75} />
              </Box>
            )}

            <Typography variant="h5" gutterBottom sx={{ color: '#8abb4f', paddingTop: '2rem' }}>
              Welcome to Jedi Finder
            </Typography>
            <Typography variant="body1" gutterBottom>
              Manage {process.env.REACT_APP_SESSION_NAME} candidates
            </Typography>
            {!loading && (
              <>
                <br />
                <EntelectButton variant={ButtonType.Primary} handleClick={onClickLogin} label="Log In with Microsoft" />

                <br />
                <a href={url} target="_blank" rel="noopener noreferrer" style={{ paddingBottom: '2rem' }}>
                  Password Expired
                </a>

                <Divider className={styles.authDivider} />
                <Box className={styles.authDividerBox}>Or</Box>
                <Typography variant="body1" gutterBottom sx={{ margin: '3rem' }}>
                  Forgot your password? <a href="mailto:helpdesk@entelect.co.za">Email Help desk</a>
                </Typography>
              </>
            )}
          </Container>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Verification;

import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import GenericPaginationWrapper, {
  IGenericPagination
} from 'common/components/GenericPaginationWrapper/GenericPaginationWrapper';
import { IPaginationRequest } from 'common/models/IPaginationRequest';
import { useDispatch, useSelector } from 'react-redux';

import { IGlobalState } from '../../../startup/store/globalState';
import UserDialog from '../components/UserDialog/UserDialog';
import UserList from '../components/UserList/UserList';
import UserListFilterButton from '../components/UserListFilterButton/UserListFilterButton';
import { getAllClaims } from '../operations/actions/claimActions';
import { getRoles } from '../operations/actions/roleActions';
import { getUsers } from '../operations/actions/userActions';
import { getFilteredUsers } from '../operations/actions/userOperationActions';
import { UserDeletionStatus } from '../operations/enums/userDeletionStatus';
import { IUserFilters, IUserModel } from '../operations/models';
import styles from './ManageUsersContainer.module.scss';

export enum ChangeType {
  FILTER = 'filter',
  RESET = 'reset'
}

const defaultPageData: IGenericPagination = {
  pageNo: 0,
  totalPages: 0
};
const ManageUsersContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [displayedUsers, setDisplayedUsers] = useState<IUserModel[]>([]);
  const [paginationData, setPaginationData] = useState<IGenericPagination>(defaultPageData);
  const [changeType, setChangeType] = useState<ChangeType | undefined>(undefined);
  const [filters, setFilters] = useState<IUserFilters>({ statuses: true });

  const [userRequest] = useState<IPaginationRequest<IUserFilters>>({
    pageSize: 10,
    sortBy: 'id',
    ascending: false,
    filters: filters
  });

  const { users, usersPagination, filteredUsers, filteredUsersPagination, operationSuccess, userOperation } =
    useSelector((state: IGlobalState) => {
      return {
        ...state.userManagementState.userState
      };
    });

  const handleOnChange = (newState: ChangeType | undefined, filters: IUserFilters): void => {
    getAllUsers(undefined, newState, filters);
    setChangeType(newState);
    setFilters(filters);
  };

  const updateDisplayedUsers = () => {
    const activeUsers: IUserModel[] = changeType === ChangeType.FILTER ? filteredUsers : users;
    setDisplayedUsers(activeUsers);
    setPagination();
  };

  const getUsersOnPageChange = (pageNo: number) => {
    getAllUsers(pageNo, changeType, filters);
  };

  const setPagination = () => {
    const activePaginationData: any = changeType === ChangeType.FILTER ? filteredUsersPagination : usersPagination;
    const { pageNo, totalPages } = activePaginationData;
    if (activePaginationData) {
      setPaginationData({
        pageNo: pageNo,
        totalPages: totalPages
      });
    }
  };

  const getAllUsers = (pageNo?: number, changeType?: string, activeFilters?: IUserFilters) => {
    const request: IPaginationRequest<IUserFilters> = { ...userRequest };
    if (pageNo) request.pageNo = pageNo;
    request.filters = activeFilters && Object.keys(activeFilters).length > 0 ? activeFilters : filters;
    changeType === ChangeType.FILTER ? dispatch(getFilteredUsers(request)) : dispatch(getUsers(request));
  };

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getAllClaims());
    dispatch(getRoles());
  }, []);

  useEffect(() => {
    updateDisplayedUsers();
  }, [users, filteredUsers]);

  useEffect(() => {
    if (operationSuccess || userOperation.deletetionStatus == UserDeletionStatus.Success) {
      handleOnChange(changeType, filters);
    }
  }, [operationSuccess, userOperation.deletetionStatus]);

  return (
    <div>
      <Grid container spacing={2} p={1}>
        <Grid item xs={12} sm={8} md={10}>
          <Box className={styles.leftHeader}>
            <span className={styles.title}></span>
            <div className={styles.filterBtn}>
              <UserListFilterButton onChange={handleOnChange} />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Box>
            <UserDialog />
          </Box>
        </Grid>
      </Grid>

      <GenericPaginationWrapper
        paginationData={paginationData}
        onPageChange={getUsersOnPageChange}
        listComp={<UserList users={displayedUsers} />}
      />
    </div>
  );
};

export default ManageUsersContainer;

import { Search } from '@mui/icons-material';
import { Box as Container, InputAdornment, OutlinedInput, Pagination } from '@mui/material';
import FilterButton, { IGenericFilter } from 'common/components/FilterComponent/FilterButton';
import GuardedButton from 'common/components/GuardedButton/GuardedButton';
import { FilterTypeEnum } from 'common/enums/filterTypeEnum';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { IPaginationRequest } from 'common/models/IPaginationRequest';
import { IPaginatedResponse } from 'common/models/paginatedResponse';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import { getOpenDaySessionSummaries } from 'modules/sessions/operations/actions/sessionsOperationActions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import { serialize } from '../../../../common/helpers/serializationHelpers';
import useScreenSize from '../../../../common/hooks/useScreenSize';
import {
  getReportTypes,
  getReports,
  resetDeleteReportSuccess,
  resetGenerateReportSuccess
} from '../../operations/actions';
import { IReport, IReportFilter } from '../../operations/models';
import OpenDayCard from '../ExportCard/ExportCard';
import GenerateExportModal from '../GenerateExportModal/GenerateExportModal';
import styles from './ExportList.module.scss';

const defaultGenericFilters: IGenericFilter[] = [
  {
    label: 'Generated By',
    reduxData: [],
    selectedOptions: [],
    filterType: FilterTypeEnum.SELECT
  },
  {
    label: 'Type',
    reduxData: [],
    selectedOptions: [],
    filterType: FilterTypeEnum.SELECT
  }
];

export const ExportList: React.FC = () => {
  const dispatch = useDispatch();
  const { snackbar } = useSnackbar();
  const { isMobile } = useScreenSize();
  const [genericFilters, setGenericFilters] = useState<IGenericFilter[]>(defaultGenericFilters);

  const {
    deletionStatus: deletetionStatus,
    reports: fetchedReports,
    deleteReportSuccess,
    createReportSuccess
  } = useSelector((state: IGlobalState) => {
    return {
      ...state.sessionsState.sessionsOperation,
      ...state.reportState
    };
  });

  const [reportRequest, setReportRequest] = useState<IPaginationRequest<IReportFilter>>({ pageSize: 8 });
  const [filters, setFilters] = useState<IReportFilter>({
    createdByList: [],
    types: []
  });

  const [typeList, setTypeList] = useState<string[]>([]);
  const [ownerList, setOwnerList] = useState<string[]>([]);
  const [searchTerm, setSearch] = useState<string>('');
  const [showFeedbackModal, setShowFeedbackModal] = React.useState<boolean>(false);

  useEffect(() => {
    setTypeList([...new Set(fetchedReports.data?.map((l) => l.reportTypeCode))]);
    setOwnerList([...new Set(fetchedReports?.data.map((x) => x.createdBy?.displayName))]);
  }, [fetchedReports]);

  useEffect(() => {
    dispatch(getReportTypes());

    // Get all sessions ordered by Date desc, once
    const request = applyStatusFilter({
      sortBy: 'dateTime',
      ascending: false
    });
    dispatch(getOpenDaySessionSummaries(request));
  }, []);

  useEffect(() => {
    fetchReport();
  }, [reportRequest, searchTerm, filters, deletetionStatus]);

  useEffect(() => {
    let newState = [...genericFilters];
    if (typeList.length > 0) {
      let index = newState.findIndex((x) => x.label === 'Type');
      newState[index] = {
        ...newState[index],
        reduxData: typeList
      };
    }
    if (ownerList.length > 0) {
      let index = newState.findIndex((x) => x.label === 'Generated By');
      newState[index] = {
        ...newState[index],
        reduxData: ownerList
      };
    }

    setGenericFilters(newState);
  }, [typeList, ownerList]);

  const applyStatusFilter = (pageRequest: IPaginationRequest<IReportFilter>) => {
    if (!reportRequest) {
      return '';
    }

    const filterClone: IPaginationRequest<IReportFilter> = JSON.parse(JSON.stringify(pageRequest));
    return serialize(filterClone);
  };

  const fetchReport = () => {
    reportRequest.filters = filters;
    reportRequest.searchTerm = searchTerm as string;
    reportRequest.sortBy = 'createdAt';
    reportRequest.ascending = false;
    dispatch(getReports(applyStatusFilter(reportRequest)));
  };

  const fetchDataFromApi = (genericFilters: IGenericFilter[]) => {
    const nameFilters = genericFilters.find((f) => (f.label = 'Generated By'));
    const typeFilters = genericFilters.find((f) => f.label === 'Type');
    setFilters({
      ...filters,
      createdByList: nameFilters?.selectedOptions ?? [],
      types: typeFilters?.selectedOptions ?? []
    });
  };

  const openFeedbackModal = (e: React.MouseEvent) => {
    setShowFeedbackModal(true);
    e.stopPropagation();
  };

  useEffect(() => {
    if (deleteReportSuccess === null || deleteReportSuccess?.report === null || deleteReportSuccess === undefined) {
      return;
    }

    if (deleteReportSuccess?.succcess === true) {
      const message = `Export "${deleteReportSuccess.report.name}" was successfully deleted`;
      snackbar({ message, variant: 'success', action: true });
      dispatch(resetDeleteReportSuccess());
      fetchReport();
      return;
    }

    if (deleteReportSuccess?.succcess === false) {
      const message = `Export "${deleteReportSuccess.report.name}" could not be deleted`;
      snackbar({ message, variant: 'error', action: true });
      dispatch(resetDeleteReportSuccess());
      return;
    }
  }, [deleteReportSuccess]);

  useEffect(() => {
    if (createReportSuccess === null || createReportSuccess === undefined) {
      return;
    }

    if (createReportSuccess === true) {
      const message = 'Export was successfully generated.';
      snackbar({ message, variant: 'success', action: true });
      dispatch(resetGenerateReportSuccess());
      return;
    }

    if (createReportSuccess === false) {
      const message = 'Export failed to generate.';
      snackbar({ message, variant: 'error', action: true });
      dispatch(resetGenerateReportSuccess());
      return;
    }
  }, [createReportSuccess]);

  const renderList = (reportPage: IPaginatedResponse<IReport>) => {
    return (
      <>
        {!isMobile && (
          <div className={styles.reportList}>
            <span className="flexBasis20">Name</span>
            <span className="flexBasis10">Export Type</span>
            <span className="flexBasis10">Generated By</span>
            <span className="flexBasis15">Generated On</span>
            <span className="flexBasis15"></span>
          </div>
        )}

        {reportPage.data.map((day) => (
          <OpenDayCard key={day.id} report={day} />
        ))}
        <Container className={styles.paginationContainer}>
          {reportPage.totalPages > 1 && (
            <Container sx={{ marginBlock: 2 }} className={styles.paginationContainer}>
              <Pagination
                count={reportPage.totalPages}
                page={reportPage.pageNo}
                onChange={handlePageChange}
                shape="rounded"
              />
            </Container>
          )}
        </Container>
      </>
    );
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setReportRequest({ ...reportRequest, pageNo: value });
  };

  return (
    <>
      <div className={styles.wrapper}>
        <Container className={styles.searchContainer}>
          <div className={styles.searchBar}>
            <OutlinedInput
              sx={{ backgroundColor: 'white' }}
              fullWidth={true}
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              endAdornment={
                <InputAdornment position="end" sx={{ paddingRight: '1rem' }}>
                  <Search />
                </InputAdornment>
              }
              placeholder={'Find Export'}
            />
          </div>
          {genericFilters && (
            <div className={styles.filterButton}>
              <FilterButton filterTypes={genericFilters} onFilterClicked={fetchDataFromApi}></FilterButton>
            </div>
          )}

          {!isMobile && (
            <div className={styles.filterButton}>
              <GuardedButton
                variant={'contained'}
                sx={{
                  width: { xs: '100%', sm: 'auto' }
                }}
                onClick={(e) => openFeedbackModal(e)}
                errorProps={{ disabled: true }}
                color="success"
                label={'Generate Export'}
                scopes={[
                  {
                    actionType: ClaimActionType.VIEW_ONLY,
                    claimType: ClaimTypes.FEEDBACK_CALIBRATION
                  }
                ]}
              />
            </div>
          )}
        </Container>
        {showFeedbackModal && <GenerateExportModal handleClose={() => setShowFeedbackModal(false)} />}
        {renderList(fetchedReports)}
      </div>
    </>
  );
};

export default ExportList;

import { IPaginatedResponse } from 'common/models/paginatedResponse';
import { IActionType } from 'common/operations/types/actionTypes';
import { IFeedbackPost, IOpenDay, ITeamFeedbackPost } from '../models/feedbackModel';
import * as Types from '../types/feedbackTypes';

export const changeFeedback = (feedback: string): IActionType<string> => {
  return {
    type: Types.FEEDBACK_CHANGED,
    payload: feedback
  };
};

export const changeOpenDays = (openDays: IPaginatedResponse<IOpenDay>): IActionType<IPaginatedResponse<IOpenDay>> => {
  return {
    type: Types.OPEN_DAYS_CHANGED,
    payload: openDays
  };
};

export const changeCompletedOpenDays = (
  openDays: IPaginatedResponse<IOpenDay>
): IActionType<IPaginatedResponse<IOpenDay>> => {
  return {
    type: Types.COMPLETED_OPEN_DAYS_CHANGED,
    payload: openDays
  };
};

export const changeFeedbackPosts = (feedbackPosts: IFeedbackPost[]): IActionType<IFeedbackPost[]> => {
  return {
    type: Types.FEEDBACK_POSTS_CHANGED,
    payload: feedbackPosts
  };
};

export const changeTeamFeedbackPosts = (teamFeedbackPosts: ITeamFeedbackPost[]): IActionType<ITeamFeedbackPost[]> => {
  return {
    type: Types.TEAM_FEEDBACK_POSTS_CHANGED,
    payload: teamFeedbackPosts
  };
};

export const candidatesAddedToTeams = (payload: any): IActionType<any> => {
  return {
    type: Types.CANDIDATES_ADDED_TO_TEAM,
    payload
  };
};

export const candidateRemovedFromTeams = (payload: any): IActionType<any> => {
  return {
    type: Types.CANDIDATE_REMOVED_FROM_TEAM,
    payload: payload
  };
};

export const editFeedbackPost = (payload: any): IActionType<any> => {
  return {
    type: Types.FEEDBACK_POST_UPDATED,
    payload: payload
  };
};

export const changeFeedbackTab = (payload: number): IActionType<number> => {
  return {
    type: Types.CHANGE_FEEDBACK_TAB,
    payload: payload
  };
};

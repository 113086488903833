import getConfiguration from 'modules/shared/helpers/configuration';
import { debounce, put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { tagActions } from '../actions/index';
import * as OpTypes from '../types';

function* getTags(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  if (success) {
    yield put(tagActions.changeTags(response.data));
  }
}

function* saveTag(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status201Created, HttpStatus.Status200OK]);
}

function* removeTag(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

// Debounced getTags to deal with multiple calls
export function* getTagsSaga() {
  yield debounce(getConfiguration().apiDebounceMs, OpTypes.GET_TAGS, getTags);
}

export function* saveTagSaga() {
  yield takeLatest(OpTypes.SAVE_TAG, saveTag);
}

export function* removeTagSaga() {
  yield takeLatest(OpTypes.REMOVE_TAG, removeTag);
}

import produce from 'immer';
import { IReport, IReportState } from '../models';
import { defaultReportState } from '../models/defaultStates';
import * as Types from '../types';

export default function reportReducer(state: IReportState = defaultReportState, action: any): IReportState {
  switch (action.type) {
    case Types.GENERATE_EXPORT: {
      return produce(state, (draft) => {
        draft.createReportSuccess = null;
      });
    }
    case Types.GENERATE_EXPORT_SUCCEEDED: {
      return produce(state, (draft) => {
        draft.createReportSuccess = true;
      });
    }
    case Types.RESET_GENERATE_EXPORT: {
      return produce(state, (draft) => {
        draft.createReportSuccess = undefined;
      });
    }
    case Types.GENERATE_EXPORT_FAILED: {
      return produce(state, (draft) => {
        draft.createReportSuccess = false;
      });
    }
    case Types.EXPORTS_CHANGED: {
      return produce(state, (draft) => {
        draft.reports = action.payload;
      });
    }
    case Types.CHANGED_EXPORT_TYPES: {
      return produce(state, (draft) => {
        draft.reportTypes = action.payload;
      });
    }
    case Types.DELETE_EXPORT: {
      return produce(state, (draft) => {
        draft.deleteReportSuccess = null;
      });
    }
    case Types.DELETE_EXPORT_SUCCEEDED: {
      return produce(state, (draft) => {
        draft.deleteReportSuccess = { report: action.payload as IReport, succcess: true };
      });
    }
    case Types.RESET_DELETE_EXPORT: {
      return produce(state, (draft) => {
        draft.deleteReportSuccess = undefined;
      });
    }
    case Types.DELETE_EXPORT_FAILED: {
      return produce(state, (draft) => {
        draft.deleteReportSuccess = { report: action.payload as IReport, succcess: false };
      });
    }
    default:
      return state;
  }
}

import { Checkbox } from '@mui/material';
import styles from './EntelectCheckBox.module.scss';

interface IProps {
  handleClick: any;
  checked: boolean | undefined;
  disabled?: boolean | undefined;
  indeterminate?: boolean | undefined;
}

const EntelectCheckBox = (props: IProps) => {
  const checkedSyle = !props.disabled && {
    color: `${styles.black}`,
    '&.Mui-checked': {
      color: `${styles.checkBoxGreen}`
    }
  };
  return (
    <Checkbox
      sx={checkedSyle || {}}
      className={styles.checkBox}
      checked={props.checked ?? false}
      onChange={props.handleClick}
      disabled={props.disabled}
      indeterminate={props.indeterminate}
    />
  );
};

export default EntelectCheckBox;

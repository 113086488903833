import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
      dark: '#004ba0',
      light: '#63a4ff',
      contrastText: '#fff'
    },
    secondary: {
      main: '#dc004e',
      dark: '#9a0036',
      light: '#ff5c8d',
      contrastText: '#fff'
    },
    success: {
      main: '#4caf50',
      dark: '#357a38',
      light: '#81c784',
      contrastText: '#fff'
    },
    warning: {
      main: '#ff9800',
      dark: '#f57c00',
      light: '#ffb74d',
      contrastText: '#fff'
    },
    error: {
      main: '#f44336',
      dark: '#d32f2f',
      light: '#e57373',
      contrastText: '#fff'
    },
    info: {
      main: '#2196f3',
      dark: '#1976d2',
      light: '#64b5f6',
      contrastText: '#fff'
    }
  },
  typography: {
    h1: {
      color: '#7B7B7B',
      fontSize: '1.8rem',
      paddingBlock: 10
    }
  }
});

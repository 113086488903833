export enum ClaimTypes {
  CANDIDATES = 'Candidates',
  FEEDBACK = 'Feedback',
  FEEDBACK_CALIBRATION = 'Feedback - Calibration',
  FEEDBACK_CAPTAIN = 'Feedback - Captain',
  MANAGE_OPEN_DAYS = 'Manage Open Days',
  MANAGE_APPLICATIONS = 'Manage Applications',
  MANAGE_USERS = 'Manage Users',
  EXPORTING = 'Exporting'
}

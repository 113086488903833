import { AddBoxOutlined } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonTypeMap,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField
} from '@mui/material';
import { ClaimActionType } from 'modules/manage-users/operations/enums/claimActionType';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GuardedButton from '../../../../common/components/GuardedButton/GuardedButton';
import GuardedEdit from '../../../../common/components/GuardedEdit/GuardedEdit';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { TagRequired, TagRequiredOptions, TagType } from '../../../../modules/manage-app/enums/tags';
import { ITag } from '../../../../modules/manage-app/operations/models/tagModel';
import { IGlobalState } from '../../../../startup/store/globalState';
import { getTags, saveTag } from '../../operations/actions/tagOperationActions';
import styles from '../../styles/ManageApp.module.scss';
import TagRatingSelection from './TagRating';
import TagSelectionList from './TagSelection';

const initialTag = { name: '', priority: 0, required: TagRequired.Mandatory } as ITag;

interface IProps {
  tag?: ITag;
  tags: ITag[];
  handleOnSave?: (tag: ITag) => void;
  buttonDef?: {
    color: ButtonTypeMap['props']['color'];
    varient: ButtonTypeMap['props']['variant'];
  };
}

const SaveTag = (props: IProps) => {
  const [open, setOpen] = useState(false);
  const { snackbar } = useSnackbar();
  const [tag, setTag] = useState(props.tag || initialTag);
  const dispatch = useDispatch();

  const { loading, operationSuccess } = useSelector((state: IGlobalState) => {
    return { ...state.tagState, ...state.appState.app };
  });

  useEffect(() => {
    if (!open || operationSuccess == null) {
      return;
    }

    if (operationSuccess) {
      const message = tag.id ? 'Feedback tag was successfully updated.' : 'New feedback tag was successfully added.';
      snackbar({ message, variant: 'success', action: true });
      !tag.id && setTag(initialTag);
      dispatch(getTags());
      setOpen(false);
      return;
    }

    if (operationSuccess == false) {
      const message = 'Feedback tag could not be saved';
      snackbar({ message, variant: 'error', action: true });
    }
  }, [operationSuccess]);

  const updateForm = (name: string, value: any) => {
    const updatedTag: ITag = { ...tag, [name]: value };
    setTag(updatedTag);
  };

  const isInUse = tag.feedbackCount > 0;

  const selectedOptions = {
    [TagType.Description]: (
      <TagSelectionList
        disabled={isInUse}
        options={[...(tag?.options ?? [])].map((option) => ({
          ...option,
          default: option.default || tag.defaultSelected == option.id
        }))}
        onChange={(o) => updateForm('options', o)}
      />
    ),
    [TagType.Rating]: (
      <TagRatingSelection disabled={isInUse} rating={tag.rating} onChange={(r) => updateForm('rating', r)} />
    )
  };

  const isValid = (): boolean => {
    if (![tag.name, tag.type, tag.required].every((f) => !(f == undefined || f == null || f.toString().length == 0)))
      return false;

    if (tag.type == TagType.Description)
      return !!tag.options && tag.options.length > 1 && tag.options.filter((f) => f.default).length == 1;

    if (tag.type == TagType.Rating)
      return (
        !!tag.rating &&
        Object.keys(tag.rating)
          .filter((f) => f != 'id')
          .every((f) => `${(tag.rating as any)[f]}`.trim().length)
      );

    return false;
  };

  const submitTag = async () => {
    let defaultSelected = tag.defaultSelected;
    if (tag.type == TagType.Rating && tag.rating) {
      defaultSelected = Math.ceil((tag.rating.end - tag.rating.start) * 0.5);
    }

    if (tag.type == TagType.Description && tag.options) {
      defaultSelected = tag.options.findIndex((f) => f.default) || 0;
    }

    if (props.handleOnSave) {
      props.handleOnSave({ ...tag, defaultSelected });
      setOpen(false);
      !tag.id && setTag(initialTag);
      return;
    }

    dispatch(saveTag({ ...tag, defaultSelected }));
  };

  const isDuplicateTag = props.tags.some((t) => t.name.toLowerCase() == tag.name.toLowerCase() && t.id != tag.id);

  return (
    <>
      {tag?.id ? (
        <GuardedEdit
          editStyle="outlined"
          sx={{ marginTop: '2px' }}
          scopes={[
            {
              actionType: ClaimActionType.EDIT,
              claimType: ClaimTypes.MANAGE_APPLICATIONS
            }
          ]}
          renderErrorItem={() => <></>}
          onClick={() => setOpen(true)}
        ></GuardedEdit>
      ) : (
        <GuardedButton
          label="ADD FEEDBACK TAG"
          onClick={() => setOpen(true)}
          className={styles.addButton}
          color={props.buttonDef?.color ?? 'success'}
          variant={props.buttonDef?.varient ?? 'contained'}
          startIcon={<AddBoxOutlined />}
          errorProps={{ disabled: true }}
          scopes={[
            {
              actionType: ClaimActionType.ADD,
              claimType: ClaimTypes.MANAGE_APPLICATIONS
            }
          ]}
        />
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box className={styles.modalBox}>
          <Card className={styles.modal}>
            <CardHeader title="Add Feedback Tag" />
            {isInUse && <Alert severity="warning">This tag is currently in use.</Alert>}
            <CardContent>
              <FormControl fullWidth>
                <TextField
                  value={tag.name}
                  onChange={(e) => updateForm('name', e.target.value)}
                  label="Tag Name*"
                  variant="outlined"
                  error={isDuplicateTag}
                  helperText={isDuplicateTag ? 'Tag name already exists' : ''}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <InputLabel id="demo-select-small-label">Required*</InputLabel>
                <Select
                  value={tag.required ? 1 : 0}
                  label="Required"
                  onChange={(e) => updateForm('required', e.target.value)}
                >
                  {TagRequiredOptions.map((option, key) => (
                    <MenuItem key={`required_${key}`} value={option.value ? 1 : 0}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl disabled={isInUse} fullWidth sx={{ mt: 2 }}>
                <p>
                  <b>Selection Options</b>
                </p>
                <RadioGroup value={tag.type || 0} onChange={(e) => updateForm('type', e.target.value)}>
                  <FormControlLabel value="1" control={<Radio />} label="Descriptions - E.g. Good" />
                  <FormControlLabel value="2" control={<Radio />} label="Rating - E.g. 1 - 5" />
                </RadioGroup>
              </FormControl>

              {!!tag.type && selectedOptions[tag.type]}
            </CardContent>
            <CardActions sx={{ float: 'right' }}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                CANCEL
              </Button>
              <Button variant="contained" onClick={submitTag} disabled={!isValid() || loading || isDuplicateTag}>
                SAVE TAG
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default SaveTag;

import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import { changeUpdateOperation } from '../actions';
import * as types from '../types';

function* getCandidateCalibration(action: any): any {
  yield put(changeUpdateOperation(undefined));
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* getCandidateyCalibrationSaga() {
  yield takeLatest(types.GET_CANDIDATE_CALIBRATIONS, getCandidateCalibration);
}

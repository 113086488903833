import { ICandidateStatus } from 'modules/manage-app/operations/models/candidateStatusModel';
import { IInstitution, getDefaultInstitution } from './institutionModel';
import IRecruiter, { toRecruiterModel } from './recruiterModel';

export default interface ICandidate {
  id: number;
  name?: string;
  photo?: string;
  comments?: string;
  teamId?: number;
  gender?: string;
  aic?: string;
  emailAddress?: string;
  phoneNumber?: string;
  jobTitle?: string;
  recruiter: IRecruiter | undefined;
  status: number;
  openDayDate?: Date;
  qualifications?: string;
  location?: string;
  candidateTrackerLink?: string;
  institutions: IInstitution[];
  // these should be an object
  recruiterSearch?: string;
  recruiterName?: string;
  recruiterEmail?: string;
  recruiterPhoneNumber?: string;
}

export interface CandidateDTO {
  id?: number;
  name?: string;
  photo?: string;
  comments?: string;
  teamId?: number;
  location?: number;
  gender?: string;
  aic?: number;
  emailAddress?: string;
  phoneNumber?: string;
  jobTitle?: string;
  recruiterName?: string;
  recruiterEmail?: string;
  recruiterPhoneNumber?: string;
  status?: number;
  openDayId?: number;
  institutions?: any[];
  willingToRelocate: boolean;
  relocationId?: number;
  attendance: boolean;
  inviteToNextOpenDay: boolean;
  reasonForNotAttending?: string;
  candidateTrackerLink?: string;
}

export interface ICandidateForm {
  name?: string;
  photo?: string;
  comments?: string;
  teamId?: number;
  gender?: string;
  aic?: string;
  aicId?: number;
  emailAddress?: string;
  phoneNumber?: string;
  jobTitle?: string;
  status?: number;
  openDayId?: number;
  qualifications?: string;
  location?: string;
  locationId?: number;
  candidateTrackerLink?: string;
  institutions?: IInstitution[];
  recruiterSearch?: string;
  recruiterName?: string;
  recruiterEmail?: string;
  recruiterPhoneNumber?: string;
  willingToRelocate: boolean;
  relocation: string;
  relocationId?: number;
  attendance: number;
  inviteToNextOpenDay?: number;
  reason?: string;
  attendanceId?: number;
}

export const getDefaultCandidateForm = (): ICandidateForm => ({
  institutions: [getDefaultInstitution()],
  recruiterEmail: 'no-email@no-email.hide-email',
  recruiterName: '',
  recruiterPhoneNumber: '000-0000-000',
  aic: '',
  aicId: 0,
  comments: '',
  emailAddress: 'no-email@no-email.hide-email',
  gender: '',
  jobTitle: '',
  location: '',
  locationId: 0,
  name: '',
  status: 0,
  phoneNumber: '000-0000-000',
  photo: '',
  recruiterSearch: '',
  teamId: 0,
  candidateTrackerLink: 'https://talent-link.co.za/client-and-candidate-testimonials/',
  relocation: '',
  relocationId: 0,
  willingToRelocate: false,
  openDayId: 0,
  attendance: 2,
  inviteToNextOpenDay: 2,
  reason: ''
});

export class Candidate {
  public id: number;
  public name?: string;
  public photo?: string;
  public comments?: string;
  public teamId?: number;
  public gender?: string;
  public aic?: string;
  public aicId?: number;
  public emailAddress?: string;
  public phoneNumber?: string;
  public jobTitle?: string;
  public recruiter: IRecruiter | undefined;
  public status: number;
  public openDayDate?: Date;
  public qualifications?: string;
  public location?: string;
  public locationId?: number;
  public candidateTrackerLink?: string;
  public recruiterSearch?: string;
  public institutions: IInstitution[];
  constructor() {
    this.id = 0;
    this.teamId = 0;
    this.status = 0;
    this.institutions = [getDefaultInstitution()];
    this.name = '';
    this.photo = '';
    this.comments = '';
    this.gender = '';
    this.aic = '';
    this.aicId = 0;
    this.emailAddress = '';
    this.phoneNumber = '';
    this.jobTitle = '';
    this.location = '';
    this.locationId = 0;
    this.openDayDate = new Date();
    this.recruiterSearch = '';
    this.candidateTrackerLink = '';
  }
}

export const toCandidateModel = (dto: ICandidate): Candidate => {
  let candidate: Candidate = new Candidate();

  candidate.id = dto.id;
  candidate.name = dto.name;
  candidate.photo = dto.photo;
  candidate.comments = dto.comments;
  candidate.teamId = dto.teamId;
  candidate.gender = dto.gender;
  candidate.aic = dto.aic;
  candidate.emailAddress = dto.emailAddress;
  candidate.phoneNumber = dto.phoneNumber;
  candidate.jobTitle = dto.jobTitle;
  candidate.recruiter = dto.recruiter && toRecruiterModel(dto.recruiter);
  candidate.status = dto.status;
  candidate.location = dto.location;
  candidate.openDayDate = dto.openDayDate;
  candidate.qualifications = dto.qualifications;
  candidate.candidateTrackerLink = dto.candidateTrackerLink;

  return candidate;
};

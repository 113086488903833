import { CandidateCreationStatus, CandidateUpdatingStatus } from '../enums/candidateEnums';

export default interface ICandidatesOperation {
  creationStatus: CandidateCreationStatus;
  updatingStatus: CandidateUpdatingStatus;
}

export const candidatesOperationDefault: ICandidatesOperation = {
  creationStatus: CandidateCreationStatus.Idle,
  updatingStatus: CandidateUpdatingStatus.Idle
};

import { Button } from '@mui/material';
import { SnackbarKey, useSnackbar as useNotistackSnackbar } from 'notistack';

export type SnackbarOptionsType = {
  message: string;
  variant?: 'success' | 'error' | 'warning' | 'info';
  action?: boolean;
  duration?: number;
};

export type SnackbarQueueType = {
  snackbar: (options: SnackbarOptionsType) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey) => void;
};

export const useSnackbar = (): SnackbarQueueType => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const snackbar = (options: SnackbarOptionsType) => {
    const { variant = 'success', action, duration = 6000 } = options || {};
    const key = enqueueSnackbar(options.message, {
      variant,
      action: action
        ? () => (
            <Button color="inherit" onClick={() => closeSnackbar(key)}>
              x
            </Button>
          )
        : undefined,
      autoHideDuration: duration
    });

    return key;
  };

  return {
    snackbar,
    closeSnackbar
  };
};

import { Button, MenuItem, Select, TextareaAutosize } from '@mui/material';
import { CheckPermissions, useCheckPermissions } from 'common/hooks/useCheckPermissions';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation } from 'react-router';
import * as appStorageService from '../../../../common/helpers/appStorageService';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { IFormData } from '../../../../common/models/formDataModels';
import { IGlobalState } from '../../../../startup/store/globalState';
import { ICandidate } from '../../../candidates/operations/models/candidatesModel';
import {
  changeFeedBackCreateStatus,
  getFeedbackPosts,
  getTeamFeedbackPosts,
  submitFeedbackPost,
  submitTeamFeedbackPost
} from '../../operations/actions/feedbackOperationActions';
import { FeedbackCategory, FeedbackCreationStatus } from '../../operations/enums/feedbackEnums';
import { validateFeedbackNote } from '../../operations/helpers/feedbackHelpers';
import {
  ICategory,
  IFeedbackPost,
  IFeedbackTag,
  ITeam,
  ITeamFeedbackPost
} from '../../operations/models/feedbackModel';
import { FeedbackTag } from '../FeedbackTag/FeedbackTag';
import styles from './FeedbackContent.module.scss';

interface IProps {
  candidate?: ICandidate;
  team?: ITeam;
}

export const FeedbackNoteCapture: React.FC<IProps> = ({ candidate, team }: IProps) => {
  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  const location = useLocation();

  const [formData, setFormData] = useState<IFormData>({ tags: [], note: '' });
  const [isValid, setIsValid] = useState<boolean>(false);

  const { tags, creationStatus, categories, authUser, openDay, loading } = useSelector((state: IGlobalState) => ({
    ...state.tagState,
    ...state.feedbackState.feedbackOperation,
    ...state.feedbackCategoryState,
    ...state.appState.app,
    openDay: state.sessionsState.sessionsOperation.openDay,
    authUser: state.authState.authUser
  }));

  const hasAddManageUsersPermission = useCheckPermissions([
    {
      actionType: ClaimActionType.ADD,
      claimType: ClaimTypes.MANAGE_USERS
    }
  ]);

  // Exclude team category, and SuperUser only categories if not Super User
  const filteredCategories = categories?.filter(
    (x) => !(x.name.toLowerCase().trim() === 'team' || (x.onlySuperUsers && !hasAddManageUsersPermission))
  );

  useEffect(() => {
    setIsValid(validateFeedbackNote(formData));
  }, [formData]);

  useEffect(() => {
    const category = filteredCategories?.find((c) => c.default)?.id ?? filteredCategories?.[0]?.id;
    const sessionData = appStorageService.get<IFormData>(location.pathname + location.search) ?? { tags: [] };
    setFormData({ ...sessionData, category });
  }, [location.key, categories]);

  useEffect(() => {
    if ([FeedbackCreationStatus.Success, FeedbackCreationStatus.Failure].includes(creationStatus)) {
      snackbar.snackbar({
        message: creationStatus,
        variant: creationStatus === FeedbackCreationStatus.Success ? 'success' : 'error'
      });
      dispatch(changeFeedBackCreateStatus(FeedbackCreationStatus.Idle));
      candidate && dispatch(getFeedbackPosts(String(candidate.id), openDay?.id as number));
      team && dispatch(getTeamFeedbackPosts(String(team.id), openDay?.id as number));
      creationStatus == FeedbackCreationStatus.Success && resetFormData();
    }
  }, [creationStatus]);

  const handleOnChange = (property: string, value: any) => {
    const tempFormData = { ...formData, [property as keyof IFormData]: value };
    setFormData(tempFormData);
    appStorageService.save(location.pathname + location.search, tempFormData);
  };

  const handleTagChange = (tagId: number, rating: string) => {
    const formTags = [...(formData['tags'] as IFeedbackTag[])];
    const tagIndex = formTags.findIndex((f) => f.tagId == tagId);
    const tag: IFeedbackTag = tagIndex > -1 ? formTags.splice(tagIndex, 1)[0] : ({} as IFeedbackTag);
    handleOnChange('tags', [...formTags, { ...tag, tagId, rating }]);
  };

  const handleSubmit = () => {
    const dto = transformToDto(formData);
    candidate && dispatch(submitFeedbackPost(dto));
    team && dispatch(submitTeamFeedbackPost(dto));
  };

  const transformToDto = (formData: IFormData) => {
    const dto: IFeedbackPost & ITeamFeedbackPost = {
      candidateId: candidate?.id ?? 0,
      teamId: team?.id ?? 0,
      date: new Date().toISOString(),
      note: formData['note'] as string,
      tags: formData['tags'] as IFeedbackTag[],
      userId: authUser?.id,
      sessionId: openDay?.id as number
    };

    const cat: ICategory = {
      id: formData['category'] as number,
      name: categories.find((c) => c.id === formData['category'])?.name
    };

    if (formData['category']) {
      dto.category = cat;
    }

    return dto;
  };

  const resetFormData = () => {
    const category = filteredCategories?.find((c) => c.default)?.id ?? filteredCategories?.[0]?.id;
    const resetFormData = { tags: [], category };
    setFormData(resetFormData);
    setIsValid(false);
    appStorageService.save(location.pathname + location.search, resetFormData);
  };

  const renderTextAreaNoteCapture = () => {
    return (
      <TextareaAutosize
        value={formData['note'] || ''}
        className={styles.feedbackNoteInput}
        placeholder="Type the feedback notes"
        onChange={(e) => handleOnChange('note', e.target.value)}
        minRows={3}
      />
    );
  };

  const renderFeedbackTagsSection = (form: IFormData, isEdit: boolean) => {
    return (
      <div className={styles.feedbackTagChips}>
        {(tags || []).map((tag) => (
          <FeedbackTag
            key={tag.id}
            label={tag.name}
            value={form.tags.find((x: IFeedbackTag) => x.tagId === tag.id)?.rating}
            variant="outlined"
            size="small"
            tag={tag}
            handleChange={(value: string) => handleTagChange(tag.id as any, value)}
          />
        ))}
      </div>
    );
  };

  const hasPermissions = CheckPermissions([
    {
      actionType: ClaimActionType.ADD,
      claimType: ClaimTypes.FEEDBACK
    }
  ]);
  if (!hasPermissions) return <></>;

  if (filteredCategories.length == 0) return <></>;

  return (
    <div className={styles.noteCaptureWrapper}>
      <div className={styles.noteCapture}>
        {!team && (
          <div className={styles.noteCaptureHeader}>
            <Select
              className={styles.feedbackCategorySelection}
              value={formData.category ?? FeedbackCategory.None}
              onChange={(e) => handleOnChange('category', e.target.value)}
            >
              {filteredCategories.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}

        {renderTextAreaNoteCapture()}
      </div>

      <div className={`${styles.feedbackTags}`}>
        <div className={styles.tagHeader}>Feedback Tags</div>
        {renderFeedbackTagsSection(formData, false)}
      </div>
      <div className={styles.buttonGroup}>
        <Button
          variant="outlined"
          disabled={loading}
          size="small"
          className={styles.buttonOutlined}
          onClick={resetFormData}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          size="small"
          className={styles.buttonContained}
          style={{ marginLeft: '1rem' }}
          onClick={handleSubmit}
          disabled={!isValid || loading}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default FeedbackNoteCapture;

export enum Feature {
  App = ' [App]',
  Candidates = ' [Candidates]',
  Feedback = ' [Feedback]',
  Sessions = '[Sessions]',
  Users = '[Users]',
  Roles = '[Roles]',
  Claims = '[Claims]',
  Calibration = '[Calibration]',
  Export = '[Export]'
}

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE'
}

export enum HttpStatus {
  Status500InternalServerError = 500,
  Status200OK = 200,
  Status201Created = 201,
  Status202Accepted = 202,
  Status204NoContent = 204,
  Status302Found = 302,
  Status400BadRequest = 400,
  Status401Unauthorized = 401,
  Status403Forbidden = 403,
  Status404NotFound = 404
}

export enum MessageType {
  None = '',
  Info = 'info',
  Success = 'success',
  Warn = 'warn',
  Error = 'error'
}

export enum DataUpdateKey {
  id = 'id'
}

import getConfiguration from 'modules/shared/helpers/configuration';
import { debounce, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import * as Types from '../types';

function* getAll(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

function* save(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status201Created, HttpStatus.Status200OK]);
}

function* remove(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* getInstitutions() {
  yield debounce(getConfiguration().apiDebounceMs, Types.GET_INSTITUTIONS, getAll);
}

export function* saveInstitution() {
  yield takeLatest(Types.SAVE_INSTITUTION, save);
}

export function* removeInstitution() {
  yield takeLatest(Types.REMOVE_INSTITUTION, remove);
}

import ISessions, { sessionsDefault } from './sessionsModel';
import ISessionsOperation, { sessionsOperationDefault } from './sessionsOperationModel';

export interface ISessionsState {
  sessions: ISessions;
  sessionsOperation: ISessionsOperation;
}

export const sessionsStateDefault: ISessionsState = {
  sessions: sessionsDefault,
  sessionsOperation: sessionsOperationDefault
};

import { HttpMethod } from 'common/enums/appEnums';
import { serialize } from 'common/helpers/serializationHelpers';
import { IPaginationRequest } from 'common/models/IPaginationRequest';
import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import * as Types from '../../../manage-users/operations/types';
import { UserDeletionStatus } from '../enums/userDeletionStatus';
import { IUserFilters } from '../models';

export const getFilteredUsers = (
  payload: IPaginationRequest<IUserFilters> = { pageSize: 10, sortBy: 'id', ascending: false }
): IActionRequest<string> => {
  return {
    type: Types.GET_FILTERED_USERS,
    httpMethod: HttpMethod.Get,
    url: `/user`,
    payload: serialize(payload)
  };
};

export const changeUsersOnFiltered = (payload: any): IActionType<any> => ({
  type: Types.CHANGE_USERS_FILTERED,
  payload
});

export const changeUserDeleteStatus = (status: UserDeletionStatus): IActionType<UserDeletionStatus> => {
  return {
    type: Types.CHANGE_USER_DELETION_STATUS,
    payload: status
  };
};
export const searchUsers = (payload: string): IActionRequest<string> => {
  return {
    type: Types.SEARCH_AD_USERS,
    httpMethod: HttpMethod.Get,
    url: `/user/search_user?txt=` + payload,
    payload: payload,
    onSuccess: searchUsersSucceed
  };
};

export const searchUsersSucceed = (payload: any): IActionType<any> => {
  return {
    type: Types.SEARCH_AD_USERS_SUCCEED,
    payload
  };
};

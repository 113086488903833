export enum TagType {
  Description = 1,
  Rating = 2
}

export enum TagRequired {
  Optional,
  Mandatory
}

export const TagRequiredOptions = [
  { value: TagRequired.Mandatory, label: 'Mandatory (Must be selected when submitting feedback)' },
  { value: TagRequired.Optional, label: 'Optional (Not required to submit feedback)' }
];

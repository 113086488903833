import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import { changeSessionDeleteStatus } from '../actions/sessionsOperationActions';
import { SessionDeletionStatus } from '../enums/sessionEnum';
import * as OpTypes from '../types/sessionOperationTypes';

function* deleteSession(action: any): any {
  yield put(changeSessionDeleteStatus(SessionDeletionStatus.Deleting));
  const { success } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  if (success) {
    yield put(changeSessionDeleteStatus(SessionDeletionStatus.Success));
  } else {
    yield put(changeSessionDeleteStatus(SessionDeletionStatus.Failure));
  }
}

export function* deleteSessionSaga() {
  yield takeLatest(OpTypes.DELETE_SESSION, deleteSession);
}

import { ILocation } from 'common/operations/models/locationModel';
import { ICandidateStatus } from 'modules/manage-app/operations/models/candidateStatusModel';
import * as Yup from 'yup';
import { ICandidateQualification, IInstitution, IQualification } from '../models/candidatesModel';
import { IQualificationDisplay } from '../models/institutionModel';

export const toInstitutionModel = (dto: IInstitution): IInstitution => {
  let model: IInstitution = { qualifications: [] };
  model.id = dto.id;
  model.name = dto.name;
  model.qualifications = dto.qualifications?.map((qualification) => toQualificationModel(qualification)) ?? [];
  return model;
};

export const toQualificationModel = (dto: IQualification): IQualification => {
  let model: IQualification = {};
  model.id = dto.id;
  model.name = dto.name;
  return model;
};

export const flattenQualifications = (candidateQualifications: ICandidateQualification[]): IQualificationDisplay => {
  let qualification: IQualificationDisplay = {
    first: '',
    additionalText: '',
    additionalCount: 0
  };
  if (candidateQualifications && candidateQualifications.length) {
    qualification.first = `${candidateQualifications[0].institutionName} - ${candidateQualifications[0].qualificationName}`;
    let len = candidateQualifications.length;
    const otherQualifications = [];
    qualification.additionalCount = len - 1;
    let i = 1;
    while (i < len) {
      otherQualifications.push(candidateQualifications[i].qualificationName);
      i++;
    }
    qualification.additionalText = otherQualifications.join(', ');
  }
  return qualification;
};

export const getFilteredRelocations = (locations: ILocation[]) =>
  locations.filter((x) => ['JHB', 'CPT', 'PTA', 'DBN'].indexOf(x.name) > -1);

export const locationFullNames: { [key: string]: string } = {
  JHB: 'Johannesburg',
  PTA: 'Pretoria',
  CPT: 'Cape Town',
  DBN: 'Durban',
  NZ: 'New Zealand',
  UK: 'United Kingdom',
  NE: 'Netherlands',
  ZM: 'Zimbabwe'
};

export const getCandidateStatusMetadata = (statuses: ICandidateStatus[], status: number) => {
  const candidateStatus = statuses.find((x) => x.id == status);

  if (candidateStatus) {
    return {
      displayText: candidateStatus.useLabel ? candidateStatus.label : candidateStatus.name,
      displayBackgroundColor: candidateStatus.colour + '30',
      displayBorder: '1px solid ' + candidateStatus.colour
    };
  } else {
    return {
      displayText: 'Unknown',
      displayBackgroundColor: '#E2F6FF',
      displayBorder: '1px solid #53CAFB'
    };
  }
};

export const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().min(3, 'Name is too short').max(50, 'Name is too long').required('Name is required'),
  locationId: Yup.number().required('Location is required').min(1, 'Location is required'),
  willingToRelocate: Yup.boolean(),
  relocation: Yup.number().notRequired(),
  gender: Yup.string().required('Gender is required'),
  aicId: Yup.number().required('Ethnicity is required'),
  jobTitle: Yup.string().required('Job title is required'),
  recruiterName: Yup.string().required('Recruiter Search is required'),
  candidateTrackerLink: Yup.string().required('Candidate Tracker Link is required'),
  institutions: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().min(1, 'University is required').required('University is required'),
      qualifications: Yup.array().of(
        Yup.object().shape({
          id: Yup.number().min(1, 'Qualification is required').required('Qualification is required')
        })
      )
    })
  )
});

export const fileToBlob = async (file: File | null) =>
  file ? new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type }) : new Blob();

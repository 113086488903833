import { Feature } from '../../../../common/enums/appEnums';

export const GET_FEEDBACK_CATEGORIES = `GET_FEEDBACK_CATEGORIES${Feature.App}`;
export const GET_FEEDBACK_CATEGORIES_SUCCEED = `GET_FEEDBACK_CATEGORIES_SUCCEED${Feature.App}`;
export const SAVE_FEEDBACK_CATEGORY = `SAVE_FEEDBACK_CATEGORY${Feature.App}`;
export const SAVE_FEEDBACK_CATEGORY_SUCCEED = `SAVE_FEEDBACK_CATEGORY_SUCCEED${Feature.App}`;
export const REMOVE_FEEDBACK_CATEGORY = `REMOVE_FEEDBACK_CATEGORY${Feature.App}`;
export const REMOVE_FEEDBACK_CATEGORY_SUCCEED = `REMOVE_FEEDBACK_CATEGORY_SUCCEED${Feature.App}`;

export const GET_TAGS = `GET_TAGS${Feature.App}`;
export const GET_TAGS_SUCCEED = `GET_TAGS_SUCCEED${Feature.App}`;
export const SAVE_TAG = `SAVE_TAG${Feature.App}`;
export const SAVE_TAG_SUCCEED = `SAVE_TAG_SUCCEED${Feature.App}`;
export const REMOVE_TAG = `REMOVE_TAG${Feature.App}`;
export const REMOVE_TAG_SUCCEED = `REMOVE_TAG_SUCCEED${Feature.App}`;
export const CHANGED_TAGS = `CHANGED_TAGS${Feature.App}`;

export const GET_CANDIDATE_STATUSES = `GET_CANDIDATE_STATUSES${Feature.App}`;
export const GET_CANDIDATE_STATUSES_SUCCEED = `GET_CANDIDATE_STATUSES_SUCCEED${Feature.App}`;
export const SAVE_CANDIDATE_STATUS = `SAVE_CANDIDATE_STATUS${Feature.App}`;
export const SAVE_CANDIDATE_STATUS_SUCCEED = `SAVE_CANDIDATE_STATUS_SUCCEED${Feature.App}`;
export const REMOVE_CANDIDATE_STATUS = `REMOVE_CANDIDATE_STATUS${Feature.App}`;
export const REMOVE_CANDIDATE_STATUS_SUCCEED = `REMOVE_CANDIDATE_STATUS_SUCCEED${Feature.App}`;

export const GET_INSTITUTIONS = `GET_INSTITUTIONS${Feature.App}`;
export const GET_INSTITUTIONS_SUCCEED = `GET_INSTITUTIONS_SUCCEED${Feature.App}`;
export const SAVE_INSTITUTION = `SAVE_INSTITUTION${Feature.App}`;
export const SAVE_INSTITUTION_SUCCEED = `SAVE_INSTITUTION_SUCCEED${Feature.App}`;
export const REMOVE_INSTITUTION = `REMOVE_INSTITUTION${Feature.App}`;

export const GET_QUALIFICATIONS = `GET_QUALIFICATIONS${Feature.App}`;
export const GET_QUALIFICATIONS_SUCCEED = `GET_QUALIFICATIONS_SUCCEED${Feature.App}`;
export const SAVE_QUALIFICATION = `SAVE_QUALIFICATION${Feature.App}`;
export const SAVE_QUALIFICATION_SUCCEED = `SAVE_QUALIFICATION_SUCCEED${Feature.App}`;
export const REMOVE_QUALIFICATION = `REMOVE_QUALIFICATION${Feature.App}`;

export const GET_ETHNICITIES = `GET_ETHNICITIES${Feature.App}`;
export const GET_ETHNICITIES_SUCCEED = `GET_ETHNICITIES_SUCCEED${Feature.App}`;
export const SAVE_ETHNICITY = `SAVE_ETHNICITY${Feature.App}`;
export const SAVE_ETHNICITY_SUCCEED = `SAVE_ETHNICITY_SUCCEED${Feature.App}`;
export const REMOVE_ETHNICITY = `REMOVE_ETHNICITY${Feature.App}`;

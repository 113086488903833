import { IClaimModelRole, IUserClaimModel } from '../models';
import { ClaimsViewModel } from '../models/claimsViewModel';

export const mapToClaimObject = (claims: IClaimModelRole[]): ClaimsViewModel => {
  return claims.reduce((finalObj, claimMap) => {
    const { id, claim } = claimMap;
    if (!finalObj[claim.claimType]) {
      finalObj[claim.claimType] = {};
    }
    finalObj[claim.claimType][claim.actionType] = {
      value: true,
      isRoleClaim: claimMap.isRole,
      claimId: claim.id,
      claimMapId: id
    };
    return finalObj;
  }, {} as ClaimsViewModel);
};

export const mapToClaimModels = (claimViewModel: ClaimsViewModel): IUserClaimModel[] => {
  return Object.keys(claimViewModel)
    .map((claimType) => {
      return Object.keys(claimViewModel[claimType])
        .map((actionType) => {
          const { isRoleClaim: isProfileClaim, claimId, value, claimMapId } = claimViewModel[claimType][actionType];
          if (value && !isProfileClaim)
            return {
              claim: { actionType, claimType, isProfile: isProfileClaim, id: claimId },
              id: claimMapId
            } as IUserClaimModel;
          return undefined;
        })
        .filter((model) => model) as IUserClaimModel[];
    })
    .flat(1);
};

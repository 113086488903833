import produce from 'immer';
import ICandidatesOperation, { candidatesOperationDefault } from '../models/candidatesOperationModel';
import * as Types from '../types/candidatesOperationTypes';

export default function candidatesOperationReducer(
  state: ICandidatesOperation = candidatesOperationDefault,
  action: any
): ICandidatesOperation {
  switch (action.type) {
    case Types.CHANGE_CANDIDATE_CREATION_STATUS:
      return produce(state, (draft) => {
        draft.creationStatus = action.payload;
      });
    case Types.CHANGE_CANDIDATE_UPDATING_STATUS:
      return produce(state, (draft) => {
        draft.updatingStatus = action.payload;
      });
    default:
      return state;
  }
}

import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../enums/appEnums';
import { ApiSagaRequest } from '../../saga/apiSaga';
import { commonActions } from '../actions';
import { IQualification } from '../models/qualificationModel';
import * as OpTypes from '../types/commonOperationTypes';

function* getQualifications(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    const data: IQualification[] = response.data as IQualification[];
    yield put(commonActions.changeQualifications(data));
  }
}

export function* getQualificationsSaga() {
  yield takeLatest(OpTypes.GET_QUALIFICATIONS, getQualifications);
}

import { ICalibrationCandidate, ICandidate } from 'modules/candidates/operations/models/candidatesModel';
import { IOpenDay, ITeam } from 'modules/feedback/operations/models/feedbackModel';
import { ISessionTeamCaptain } from 'modules/sessions/operations/models/sessionsModel';
import { CalibrationFields } from '../enums/calibrationEnums';
import { ICalibrationFilterRequest, ICandidateCalibration } from '../models';

export const flattenCandidatesFromOpenDay = (openDay: IOpenDay) => {
  let candidates: ICandidate[] = [];
  candidates = openDay.teams.flatMap((team) =>
    team.candidates.map((c) => ({
      ...c,
      teamId: team.id
    }))
  );

  if (openDay?.unassignedCandidates?.length > 0) {
    candidates = candidates.concat(openDay.unassignedCandidates);
  }

  return candidates;
};

export const flattenTeamCaptainsFromOpenDay = (openDay: IOpenDay) => {
  let teamCaptains: ISessionTeamCaptain[] = [];
  teamCaptains = openDay.teams.map((team) => team.teamCaptain);

  return teamCaptains;
};

export const filterCalibrations = (calibrations: ICandidateCalibration[], request: ICalibrationFilterRequest) => {
  const { statuses, candidateName, captainIds } = request;
  const statusesCaps = statuses?.map((status) => status.toUpperCase());
  return calibrations.filter((calibration) => {
    if (candidateName && !calibration.candidate.name.toLowerCase().includes(candidateName.toLowerCase())) {
      return false;
    }
    if (statusesCaps.length && !statusesCaps.includes(calibration.offerTypeCode ?? '')) {
      return false;
    }
    if (captainIds.length && !captainIds.includes(calibration?.candidate?.team?.teamCaptain?.id ?? 0)) {
      return false;
    }
    return true;
  });
};

export const mergeCalibrationsAndFeedback = (
  calibrations: ICandidateCalibration[],
  candidates: ICalibrationCandidate[],
  feedbackFromTeams: ITeam[]
) => {
  return calibrations.map((calibration) => {
    const candidate = candidates.find((c) => c.id === calibration?.candidate?.id);
    const candidateFeedback = candidate?.feedback;
    const candidateTeamId = feedbackFromTeams.find((t) => t.candidates.find((c) => c.id === candidate?.id))?.id;
    return {
      ...calibration,
      teamId: candidateTeamId,
      feedback: candidateFeedback,
      qualification: candidate?.qualification,
      university: candidate?.university
    };
  });
};

export const SORT_CONFIG: { [field: string]: { sorter: any } } = {
  [CalibrationFields.CandidateName]: {
    sorter: (calibrations: ICandidateCalibration[], isAscending: boolean) => {
      return calibrations.sort((a, b) => a.candidate.name.localeCompare(b.candidate.name) * (isAscending ? 1 : -1));
    }
  },
  [CalibrationFields.TeamCaptain]: {
    sorter: (calibrations: ICandidateCalibration[], isAscending: boolean) => {
      return calibrations.sort(
        (a, b) =>
          (a.candidate?.team?.teamCaptain?.name ?? '').localeCompare(b.candidate?.team?.teamCaptain?.name ?? '') *
          (isAscending ? 1 : -1)
      );
    }
  },
  [CalibrationFields.Qualifications]: {
    sorter: (calibrations: ICandidateCalibration[], isAscending: boolean) => {
      return calibrations.sort((a, b) => {
        const qualArrayA = a.candidate?.candidateQualifications ?? [];
        const qualArrayB = b.candidate?.candidateQualifications ?? [];
        return (
          (qualArrayA[qualArrayA.length - 1]?.qualificationName ?? '').localeCompare(
            qualArrayB[qualArrayB.length - 1]?.qualificationName ?? ''
          ) * (isAscending ? 1 : -1)
        );
      });
    }
  },
  [CalibrationFields.University]: {
    sorter: (calibrations: ICandidateCalibration[], isAscending: boolean) => {
      return calibrations.sort((a, b) => {
        const qualArrayA = a.candidate?.candidateQualifications ?? [];
        const qualArrayB = b.candidate?.candidateQualifications ?? [];
        return (
          (qualArrayA[qualArrayA.length - 1]?.institutionName ?? '').localeCompare(
            qualArrayB[qualArrayB.length - 1]?.institutionName ?? ''
          ) * (isAscending ? 1 : -1)
        );
      });
    }
  },
  [CalibrationFields.Interviewer]: {
    sorter: (calibrations: ICandidateCalibration[], isAscending: boolean) => {
      return calibrations.sort(
        (a, b) => a.interviewer?.displayName?.localeCompare(b.interviewer?.displayName) * (isAscending ? 1 : -1)
      );
    }
  },
  [CalibrationFields.Offer]: {
    sorter: (calibrations: ICandidateCalibration[], isAscending: boolean) => {
      return calibrations.sort((a, b) => (a.offerTypeCode ?? '').localeCompare(b.offerTypeCode ?? '') * (isAscending ? 1 : -1));
    }
  },
  [CalibrationFields.Team]: {
    sorter: (calibrations: ICandidateCalibration[], isAscending: boolean) => {
      return calibrations.sort(
        (a, b) => (a.candidate.team?.name ?? '').localeCompare(b.candidate.team?.name ?? '') * (isAscending ? 1 : -1)
      );
    }
  }
};

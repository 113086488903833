import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { changeAddTeamToSessionStatus } from '../actions/sessionsOperationActions';
import { TeamToSessionStatus } from '../enums/sessionEnum';
import * as OpTypes from '../types/sessionOperationTypes';

function* addTeamToSession(action: any): any {
  yield put(changeAddTeamToSessionStatus(TeamToSessionStatus.Adding));
  const { success } = yield ApiSagaRequest(action, [HttpStatus.Status201Created]);
  if (success) {
    yield put(changeAddTeamToSessionStatus(TeamToSessionStatus.Success));
  } else {
    yield put(changeAddTeamToSessionStatus(TeamToSessionStatus.Failure));
  }
}

export function* addTeamToSessionSaga() {
  yield takeLatest(OpTypes.ADD_TEAM_TO_SESSION, addTeamToSession);
}

import produce from 'immer';
import IFeedback, { feedbackDefault } from '../models/feedbackModel';
import * as Types from '../types/feedbackTypes';

export default function feedbackReducer(state: IFeedback = feedbackDefault, action: any): IFeedback {
  switch (action.type) {
    case Types.FEEDBACK_CHANGED: {
      return produce(state, (draft) => {
        draft.feedback = action.payload;
      });
    }
    case Types.OPEN_DAYS_CHANGED: {
      return produce(state, (draft) => {
        draft.openDays = action.payload;
      });
    }
    case Types.COMPLETED_OPEN_DAYS_CHANGED: {
      return produce(state, (draft) => {
        draft.completedOpenDays = action.payload;
      });
    }
    case Types.FEEDBACK_POSTS_CHANGED: {
      return produce(state, (draft) => {
        draft.feedbackPosts = action.payload;
      });
    }
    case Types.TEAM_FEEDBACK_POSTS_CHANGED: {
      return produce(state, (draft) => {
        draft.teamFeedbackPosts = action.payload;
      });
    }
    case Types.CANDIDATES_ADDED_TO_TEAM: {
      return produce(state, (draft) => {});
    }
    case Types.CANDIDATE_REMOVED_FROM_TEAM: {
      return produce(state, (draft) => {});
    }
    case Types.CHANGE_FEEDBACK_TAB: {
      return produce(state, (draft) => {
        draft.selectedTab = action.payload;
      });
    }
    default:
      return state;
  }
}

interface Mapper {
  [key: string]: string;
}

export interface TransformedClaimClaim {
  id: number;
  claimLabel: string;
}

export const ClaimActionMapper: Mapper = {
  Add: 'Add',
  Edit: 'Edit',
  View_Only: 'View'
};

export const ClaimTypeMapper: Mapper = {
  Candidates: 'Candidates',
  Feedback: 'Feedback',
  'Feedback - Calibration': 'Feedback Calibration',
  'Feedback - Captain': 'Feedback Captain',
  'Manage Open Days': 'Open Days',
  'Manage Applications': 'Application',
  'Manage Users': 'Users'
};

export const claimsBuilder = (claims: any[]) => {
  const transformedClaim: any = {};
  claims.forEach((claim) => {
    transformedClaim[`${ClaimActionMapper[claim.actionType]} ${ClaimTypeMapper[claim.claimType]}`] = claim.id;
  });

  return transformedClaim;
};

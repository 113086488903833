import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { HttpClient } from '../../common/api/httpClient';
import rootSaga from '../saga/rootSaga';
import configureMiddlewares from './configureMiddlewares';
import rootReducer from './configureReducers';

const createStore = (httpClient: HttpClient) => {
  const history = createBrowserHistory();
  const middlewares = configureMiddlewares(history);
  const sagaMiddleware = createSagaMiddleware({ context: { httpClient } });

  middlewares.push(sagaMiddleware);

  const serializedAppState = localStorage.getItem('appState');
  const preloadedAppState = serializedAppState ? JSON.parse(serializedAppState) : undefined;

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: {
      ...rootReducer(undefined, { type: '' }),
      appState: preloadedAppState
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middlewares)
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export default createStore;

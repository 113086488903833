import { Divider } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import useScreenSize from '../../../../common/hooks/useScreenSize';
import { ICandidate } from '../../../candidates/operations/models/candidatesModel';
import { IFeedbackPost, ITeam, ITeamFeedbackPost } from '../../operations/models/feedbackModel';
import styles from './FeedbackContent.module.scss';
import FeedbackHeader from './FeedbackHeader';
import FeedbackNoteCapture from './FeedbackNoteCapture';
import FeedbackPost from './FeedbackPost';
import FeedbackRecruitmentNote from './FeedbackRecruitmentNote';

interface IProps {
  candidate?: ICandidate;
  team?: ITeam;
  candidates: ICandidate[];
  posts: (IFeedbackPost | ITeamFeedbackPost)[];
  goBack: () => void;
  handleCandidateChange: (candidate: ICandidate) => void;
  handleTeamChange: (team: ITeam) => void;
}

export const FeedbackContent: React.FC<IProps> = (props: IProps) => {
  const { candidate, candidates, posts, team, goBack, handleCandidateChange, handleTeamChange } = props;
  const headerRoot = document.getElementById('header-root') as HTMLElement;

  const queryParams = new URLSearchParams(window.location.search);
  const urlTeamId = queryParams.get('teamId');
  const screenSize = useScreenSize();
  const isMobile = screenSize.isMobile || screenSize.isTablet;
  const [dateGrouping, setDateGrouping] = useState<string[]>([]);

  useEffect(() => {
    if (posts) {
      const dates = posts.sort((a, b) => moment(b.date).diff(a.date)).map((feedBack) => feedBack.date);
      let loop = [...new Set(dates.map((x) => moment(x).format('YYYY-MM-DD')))];
      setDateGrouping([...loop]);
    }
  }, [posts]);

  const headerComponent = (
    <FeedbackHeader
      candidate={candidate}
      candidates={candidates}
      goBack={goBack}
      handleCandidateChange={handleCandidateChange}
      handleTeamChange={handleTeamChange}
      team={team}
    />
  );

  return (
    <div key={candidate?.id} className={styles.wrapper}>
      {isMobile ? createPortal(headerComponent, headerRoot) : headerComponent}
      <div className={styles.content}>
        {!urlTeamId && candidate && <FeedbackRecruitmentNote candidate={candidate} />}
        {urlTeamId && <br />}
        <FeedbackNoteCapture candidate={!urlTeamId ? candidate : undefined} team={urlTeamId ? team : undefined} />
        {dateGrouping?.map((date) => (
          <>
            <Divider className={styles.divider} sx={{ m: 2 }}>
              {moment(date).format('dddd, MMMM DD')}
            </Divider>
            {posts
              .filter((x) => moment(x.date).format('YYYY-MM-DD') === date)
              .map((m) => (
                <FeedbackPost
                  key={m.id ?? 'key'}
                  feedback={m}
                  candidate={!urlTeamId ? candidate : undefined}
                  team={urlTeamId ? team : undefined}
                />
              ))}
          </>
        ))}
      </div>
    </div>
  );
};

export default FeedbackContent;

import { Feature } from '../../../../common/enums/appEnums';

export const FETCH_ALL_CLAIMS = `FETCH_ALL_CLAIMS${Feature.Claims}`;
export const FETCHED_ALL_CLAIMS = `FETCHED_ALL_CLAIMS${Feature.Claims}`;

export const FETCH_ROLES = `FETCH_ROLES${Feature.Roles}`;
export const FETCHED_ROLES = `FETCHED_ROLES${Feature.Roles}`;

export const GET_FILTERED_USERS = `GET_FILTERED_USERS${Feature.Users}`;
export const CHANGE_USERS_FILTERED = `CHANGE_USERS_FILTERED${Feature.Users}`;
export const CHANGE_USER_DELETION_STATUS = `CHANGE_USER_DELETION_STATUS${Feature.Users}`;
export const SEARCH_AD_USERS = `SEARCH_AD_USERS${Feature.Users}`;
export const SEARCH_AD_USERS_SUCCEED = `SEARCH_AD_USERS_SUCCEED${Feature.Users}`;

export const FETCH_USERS = `FETCH_USERS${Feature.Users}`;
export const FETCHED_USERS = `FETCHED_USERS${Feature.Users}`;
export const SAVE_USER = `SAVE_USER${Feature.Users}`;
export const SAVE_USER_SUCCESS = `SAVE_USER_SUCCESS${Feature.Users}`;
export const SAVE_USER_FAILED = `SAVE_USER_FAILED${Feature.Users}`;
export const DELETE_USER = `DELETE_USER${Feature.Users}`;

import { combineReducers } from '@reduxjs/toolkit';
import commonStateReducer from 'common/operations/reducers';
import calibrationReducer from 'modules/calibration/operations/reducers/calibrationReducer';
import appStateReducer from '../../modules/app/operations/reducers';
import authStateReducer from '../../modules/auth/operations/reducers';
import candidatesStateReducer from '../../modules/candidates/operations/reducers';
import feedbackStateReducer from '../../modules/feedback/operations/reducers';
import {
  candidateStatusStateReducer,
  ethnicitiesStateReducer,
  feedbackCategoriesStateReducer,
  institutionsStateReducer,
  qualificationsStateReducer,
  tagsStateReducer
} from '../../modules/manage-app/operations/reducers';
import userManagementReducer from '../../modules/manage-users/operations/reducers';
import reportReducer from '../../modules/report/operations/reducers/reportReducer';
import sessionsStateReducer from '../../modules/sessions/operations/reducers';

const rootReducer = combineReducers({
  appState: appStateReducer,
  candidatesState: candidatesStateReducer,
  feedbackState: feedbackStateReducer,
  sessionsState: sessionsStateReducer,
  commonState: commonStateReducer,
  authState: authStateReducer,
  tagState: tagsStateReducer,
  feedbackCategoryState: feedbackCategoriesStateReducer,
  candidateStatusState: candidateStatusStateReducer,
  userManagementState: userManagementReducer,
  calibrationState: calibrationReducer,
  reportState: reportReducer,
  institutionsState: institutionsStateReducer,
  qualificationsState: qualificationsStateReducer,
  ethnicitiesState: ethnicitiesStateReducer
});

export default rootReducer;

import { ArrowUpward } from '@mui/icons-material';
import { Grid, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import DeleteTag from 'modules/manage-app/components/Tags/DeleteTag';
import SaveTag from 'modules/manage-app/components/Tags/SaveTag';
import { getTagData } from 'modules/manage-app/helpers/tagHelper';
import { ITag } from 'modules/manage-app/operations/models/tagModel';
import { useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import styles from './SessionDetails.module.scss';

interface IProps {
  tags: ITag[];
  setTags: (tags: ITag[]) => void;
}

export const TagDetails: React.FC<IProps> = ({ tags, setTags }: IProps) => {
  const globalTags = useSelector((state: IGlobalState) => state.tagState.tags);

  const loadDefaultTags = () => {
    const copyTags = globalTags.map((globalTag, priority) => {
      const tag = { ...globalTag, id: -1, feedbackCount: 0, priority };
      tag.options = tag.options?.map((option) => ({ ...option, default: option.id == tag.defaultSelected, id: -1 }));
      return tag;
    });
    setTags(copyTags);
  };

  const handleSave = (tag: ITag, index: number) => {
    const updatedTags = [...tags];
    updatedTags[index] = { ...tag, id: tag.id ?? -1, priority: index };
    setTags(updatedTags);
  };

  const handleRemoveTag = (index: number) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags.map((tag, priority) => ({ ...tag, priority })));
  };

  const onMoveUp = (index: number) => {
    const updatedTags = [...tags];
    updatedTags[index] = { ...updatedTags[index], priority: index - 1 };
    updatedTags[index - 1] = { ...updatedTags[index - 1], priority: index };
    setTags(updatedTags.sort((a, b) => a.priority - b.priority));
  };

  return (
    <>
      <div className={styles.formHeading}>Tags</div>
      <div className={styles.formSubHeading}>
        Select which tags you like to use on this {process.env.REACT_APP_SESSION_NAME}
      </div>

      <br />

      <Grid container>
        <Grid item xs={5}>
          <SaveTag
            tags={globalTags}
            buttonDef={{ color: 'primary', varient: 'text' }}
            handleOnSave={(t) => handleSave(t, tags.length)}
          />
        </Grid>
        <Grid item xs={7}>
          {!tags?.length && (
            <EntelectButton
              handleClick={loadDefaultTags}
              label="Load Default Tags"
              variant={ButtonType.LinkButton}
              className="float-right"
            />
          )}
        </Grid>
      </Grid>

      <List dense={true}>
        {[...tags].map((tag, index) => (
          <ListItem key={`tag_${tag.name}`}>
            <ListItemIcon>
              {index > 0 && (
                <IconButton onClick={() => onMoveUp(index)}>
                  <ArrowUpward />
                </IconButton>
              )}
            </ListItemIcon>
            <ListItemText className={styles.listItemText} primary={tag.name} secondary={getTagData(tag).selection} />
            <ListItemIcon>
              <SaveTag tags={tags} tag={tag} handleOnSave={(t) => handleSave(t, index)} />
              <DeleteTag tag={tag} handleRemoveTag={() => handleRemoveTag(index)} />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </>
  );
};

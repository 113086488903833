import { DataUpdateKey } from 'common/enums/appEnums';
import { updateItemByKeyInArray } from 'common/helpers/dataHelpers';
import produce from 'immer';
import { ICandidate, ICandidates, candidatesDefault } from '../models/candidatesModel';
import { FILTER_CANDIDATES } from '../types/candidatesOperationTypes';
import * as Types from '../types/candidatesTypes';

export default function candidatesReducer(state: ICandidates = candidatesDefault, action: any): ICandidates {
  switch (action.type) {
    case Types.CHANGED_CANDIDATES: {
      return produce(state, (draft) => {
        draft.candidates = (action.payload.data as ICandidate[]).sort(
          (a, b) => a.name?.localeCompare(b.name ?? '') ?? 0
        );
        draft.totalRecords = action.payload.totalRecords;
        draft.operationSuccess = true;
      });
    }
    case Types.ADDED_CANDIDATE: {
      return produce(state, (draft) => {
        draft.candidates = [...state.candidates, action.payload];
      });
    }
    case Types.CHANGED_CANDIDATE: {
      return produce(state, (draft) => {
        draft.candidate = action.payload;
      });
    }
    case Types.CHANGED_CANDIDATE_VIEW: {
      return produce(state, (draft) => {
        draft.viewType = action.payload;
      });
    }
    case Types.CHANGED_CANDIDATE_SEARCH: {
      return produce(state, (draft) => {
        draft.searchType = action.payload;
      });
    }
    case FILTER_CANDIDATES:
      return produce(state, (draft) => {
        draft.searchedCandidates = [];
        draft.totalRecords = 0;
      });
    case Types.CHANGED_CANDIDATES_SEARCH_FILTER: {
      return produce(state, (draft) => {
        draft.searchedCandidates = action.payload.data;
        draft.totalRecords = action.payload.totalRecords;
      });
    }
    case Types.CHANGED_GENDERS: {
      return produce(state, (draft) => {
        draft.genders = action.payload;
      });
    }
    case Types.CHANGED_ETHNICITIES: {
      return produce(state, (draft) => {
        draft.ethnicities = action.payload;
      });
    }
    case Types.CHANGED_CANDIDATE_RECRUITERS: {
      return produce(state, (draft) => {
        draft.recuiters = action.payload;
      });
    }
    case Types.UPDATED_CANDIDATE: {
      return {
        ...state,
        candidates: updateItemByKeyInArray(state.candidates, action.payload, DataUpdateKey.id)
      };
    }
    default:
      return state;
  }
}

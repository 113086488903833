import { ICandidateImport } from './candidateImportModel';
import { ICandidates, candidatesDefault } from './candidatesModel';
import ICandidatesOperation, { candidatesOperationDefault } from './candidatesOperationModel';

export interface ICandidateBulkImportState {
  temporaryCandidates: ICandidateImport[];
  errorMessage: string | null;
  saveImportStatus: 'success' | 'failed' | 'none';
}

export const candidatesBulkImportStateDefault: ICandidateBulkImportState = {
  temporaryCandidates: [],
  errorMessage: null,
  saveImportStatus: 'none'
};

export interface ICandidatesState {
  candidates: ICandidates;
  candidatesOperation: ICandidatesOperation;
  candidatesBulkImport: ICandidateBulkImportState;
}

export const candidatesStateDefault: ICandidatesState = {
  candidates: candidatesDefault,
  candidatesOperation: candidatesOperationDefault,
  candidatesBulkImport: candidatesBulkImportStateDefault
};

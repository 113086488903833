import produce from 'immer';
import { ICandidateStatusState } from '../models/candidateStatusState';
import * as ActionTypes from '../types';

export default function candidateStatusReducer(
  state: ICandidateStatusState = { statuses: [], operationSuccess: null },
  action: any
): ICandidateStatusState {
  switch (action.type) {
    case ActionTypes.GET_CANDIDATE_STATUSES_SUCCEED: {
      return produce(state, (draft) => {
        draft.statuses = action.payload;
      });
    }
    case ActionTypes.GET_CANDIDATE_STATUSES: {
      return produce(state, (draft) => {
        draft.operationSuccess = null;
      });
    }
    case ActionTypes.SAVE_CANDIDATE_STATUS_SUCCEED: {
      return produce(state, (draft) => {
        draft.operationSuccess = true;
      });
    }
    case ActionTypes.REMOVE_CANDIDATE_STATUS_SUCCEED: {
      return produce(state, (draft) => {
        draft.operationSuccess = true;
      });
    }
    default:
      return state;
  }
}

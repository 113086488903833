import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { ITeam } from 'modules/feedback/operations/models/feedbackModel';
import getConfiguration from 'modules/shared/helpers/configuration';
import { debounce, put } from 'redux-saga/effects';
import { changeSessionTeams } from '../actions/sessionsActions';
import * as OpTypes from '../types/sessionOperationTypes';

function* getOpenDaySessionTeams(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    const data: ITeam[] = response.data as ITeam[];
    yield put(changeSessionTeams(data));
  }
}

export function* getOpenDaySessionTeamsSaga() {
  yield debounce(getConfiguration().apiDebounceMs, OpTypes.GET_OPEN_DAY_SESSION_TEAMS, getOpenDaySessionTeams);
}

import { Grid } from '@mui/material';
import { Institutions } from '../Institutions';
import { Qualifications } from '../Qualification';

const InstitutionsQualifications = () => {
  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={6} padding={2}>
        <Institutions />
      </Grid>
      <Grid item xs={6} padding={2}>
        <Qualifications />
      </Grid>
    </Grid>
  );
};

export default InstitutionsQualifications;

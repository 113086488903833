import produce from 'immer';
import ISessions, { sessionsDefault } from '../models/sessionsModel';
import * as Types from '../types/sessionsTypes';

export default function sessionsReducer(state: ISessions = sessionsDefault, action: any): ISessions {
  switch (action.type) {
    case Types.SESSIONS_CHANGED: {
      return produce(state, (draft) => {
        draft.sessions = action.payload;
      });
    }
    case Types.SESSION_SUMMARIES_CHANGED: {
      return produce(state, (draft) => {
        draft.summaries = action.payload;
      });
    }
    case Types.CHANGED_TEAM_CAPTAINS: {
      return produce(state, (draft) => {
        draft.teamCaptains = action.payload;
      });
    }
    case Types.SESSIONS_CHANGED_BY_ID: {
      return produce(state, (draft) => {
        draft.sessionById = action.payload;
      });
    }
    case Types.SESSION_TEAMS_CHANGED: {
      return produce(state, (draft) => {
        draft.sessionTeams = action.payload;
      });
    }
    default:
      return state;
  }
}

import { SvgIconComponent } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import { ButtonType } from '../../enums/buttonTypeEnum';
import styles from './EntelectButton.module.scss';

interface IProps {
  label: string;
  handleClick?: (event?: any) => void;
  variant: ButtonType;
  disabled?: boolean;
  startIcon?: SvgIconComponent;
  endIcon?: SvgIconComponent;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
}

const EntelectButton = (props: IProps) => {
  const { loading } = useSelector((state: IGlobalState) => state.appState.app);
  const isTextButton = [ButtonType.Link].includes(props.variant);
  const isLinkButton = [ButtonType.LinkButton, ButtonType.DangerLink].includes(props.variant);
  return (
    <Button
      onClick={props.handleClick}
      startIcon={props.startIcon ? <props.startIcon /> : null}
      endIcon={props.endIcon ? <props.endIcon /> : null}
      className={`${styles[props.variant]} ${props.className}`}
      variant={isTextButton ? 'text' : isLinkButton ? 'outlined' : 'contained'}
      disabled={props.disabled || loading}
      classes={{ disabled: styles[`disabled${props.variant}`] }}
      type={props.type}
    >
      {props.label}
    </Button>
  );
};

export default EntelectButton;

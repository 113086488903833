import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { feedbackActions } from '../actions';
import { changeAddCandidatesToTeamStatus } from '../actions/feedbackOperationActions';
import { AddCandidatesToTeamStatus } from '../enums/feedbackEnums';
import * as OpTypes from '../types/feedbackOperationTypes';

function* addCandidatesToTeam(action: any): any {
  yield put(changeAddCandidatesToTeamStatus(AddCandidatesToTeamStatus.Adding));

  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status201Created]);

  if (success) {
    yield put(feedbackActions.candidatesAddedToTeams(response.data));
    yield put(changeAddCandidatesToTeamStatus(AddCandidatesToTeamStatus.Success));
  } else {
    yield put(changeAddCandidatesToTeamStatus(AddCandidatesToTeamStatus.Failure));
  }
}

export function* addCandidatesToTeamSaga() {
  yield takeLatest(OpTypes.ADD_CANDIDATES_TO_TEAM, addCandidatesToTeam);
}

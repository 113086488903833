import { Search } from '@mui/icons-material';
import { Container, Grid, InputAdornment, OutlinedInput } from '@mui/material';
import FilterButton, { IGenericFilter } from 'common/components/FilterComponent/FilterButton';
import { FilterTypeEnum } from 'common/enums/filterTypeEnum';
import { serialize } from 'common/helpers/serializationHelpers';
import { IPaginationRequest } from 'common/models/IPaginationRequest';
import { getLocations } from 'common/operations/actions/commonOperationActions';
import { getOpenDaySessionSummaries } from 'modules/sessions/operations/actions/sessionsOperationActions';
import { IOpenDaySummary, ISessionFilters } from 'modules/sessions/operations/models/sessionsModel';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import OpenDayCard from '../OpenDayCard/OpenDayCard';
import styles from './OpenDayGrid.module.scss';

const defaultGenericFilters: IGenericFilter[] = [
  {
    label: 'Location',
    reduxData: [],
    selectedOptions: [],
    filterType: FilterTypeEnum.SELECT
  },
  {
    label: 'Date',
    reduxData: [],
    selectedOptions: [],
    filterType: FilterTypeEnum.DATE_PICKER
  }
];

export const OpenDayGrid: React.FC = () => {
  const dispatch = useDispatch();
  const [genericFilters, setGenericFilters] = useState<IGenericFilter[]>(defaultGenericFilters);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filters, setFilters] = useState<ISessionFilters>({
    statuses: [],
    locations: [],
    dates: [],
    attendees: []
  });

  const { summaries, common, loading } = useSelector((state: IGlobalState) => ({
    ...state.sessionsState.sessions,
    ...state.commonState,
    ...state.appState.app
  }));

  useEffect(() => {
    dispatch(getLocations());
  }, []);

  useEffect(() => {
    fetchOpenDays();
  }, [searchTerm, filters]);

  useEffect(() => {
    const newGenericFilters = [...genericFilters];
    if (common.locations.length > 0) {
      let index = newGenericFilters.findIndex((x) => x.label === 'Location');
      newGenericFilters[index] = {
        ...newGenericFilters[index],
        reduxData: common.locations?.map((l) => l.name)
      };
    }

    setGenericFilters(newGenericFilters);
  }, [common]);

  const fetchOpenDays = () => {
    const request = applyStatusFilter({
      filters: filters,
      searchTerm: searchTerm,
      sortBy: 'dateTime',
      ascending: false
    });
    dispatch(getOpenDaySessionSummaries(request));
  };

  const fetchDataFromApi = (genericFilters: IGenericFilter[]) => {
    const locationFilters = genericFilters.find((f) => (f.label = 'Location'));
    const dateFilters = genericFilters.find((f) => f.label === 'Date');
    setFilters({
      ...filters,
      locations: locationFilters?.selectedOptions ?? [],
      dates: dateFilters?.selectedOptions ?? []
    });
  };

  const applyStatusFilter = (pageRequest: IPaginationRequest<ISessionFilters>) => {
    const filterClone: IPaginationRequest<ISessionFilters> = JSON.parse(JSON.stringify(pageRequest));
    return serialize(filterClone);
  };

  const renderOpenDayGrid = () => {
    if (summaries?.data.length === 0) return <></>;

    return (
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          height: '100%',
          display: 'flex',
          flex: '1 0 0'
        }}
      >
        <Grid
          container
          sx={{
            height: 'fit-content',
            gridAutoFlow: 'column'
          }}
          spacing={4}
        >
          {summaries.data.map((openDay: IOpenDaySummary) => (
            <Grid key={openDay.id} item xs={12} sm={6} md={6} lg={4} xl={3}>
              <OpenDayCard openDay={openDay} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Container className={styles.searchContainer}>
        <div className={styles.searchBar}>
          <OutlinedInput
            sx={{ backgroundColor: 'white' }}
            fullWidth={true}
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            endAdornment={
              <InputAdornment position="end" sx={{ paddingRight: '1rem' }}>
                <Search />
              </InputAdornment>
            }
            placeholder={`Search For ${process.env.REACT_APP_SESSION_NAME}`}
          />
        </div>
        {genericFilters && (
          <div className={styles.filterButton}>
            <FilterButton filterTypes={genericFilters} onFilterClicked={fetchDataFromApi}></FilterButton>
          </div>
        )}
      </Container>
      {renderOpenDayGrid()}
    </div>
  );
};
export default OpenDayGrid;

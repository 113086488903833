import { Card } from '@mui/material';
import { useSnackbar } from 'common/hooks/useSnackbar';
import {
  changeSessionCreateStatus,
  changeSessionDeleteStatus,
  changeSessionUpdateStatus
} from 'modules/sessions/operations/actions/sessionsOperationActions';
import {
  SessionCreationStatus,
  SessionDeletionStatus,
  SessionUpdateStatus
} from 'modules/sessions/operations/enums/sessionEnum';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import SessionDetails from '../SessionDetails/SessionDetails';
import SessionsList from '../SessionsList/SessionsList';
import styles from './Sessions.module.scss';

export const Sessions: React.FC = () => {
  const dispatch = useDispatch();

  const [tab, setTab] = useState<number>(0);
  const [showAddSession, setShowAddSession] = useState<Boolean>(false);
  const snackbar = useSnackbar();
  const creationStatus = useSelector((state: IGlobalState) => state.sessionsState.sessionsOperation.creationStatus);
  const updateStatus = useSelector((state: IGlobalState) => state.sessionsState.sessionsOperation.updateStatus);
  const deleteStatus = useSelector((state: IGlobalState) => state.sessionsState.sessionsOperation.deletionStatus);

  useEffect(() => {
    if ([SessionCreationStatus.Success, SessionCreationStatus.Failure].includes(creationStatus)) {
      if (creationStatus === SessionCreationStatus.Success) {
        snackbar.snackbar({
          message: 'Session created successfully',
          duration: 3000,
          variant: 'success'
        });
      }
      dispatch(changeSessionCreateStatus(SessionCreationStatus.Idle));
    }
    if ([SessionUpdateStatus.Success, SessionUpdateStatus.Failure].includes(updateStatus)) {
      dispatch(changeSessionUpdateStatus(SessionUpdateStatus.Idle));
      if (updateStatus === SessionUpdateStatus.Success) {
        snackbar.snackbar({
          message: updateStatus,
          duration: 3000,
          variant: 'success'
        });
      }
    }
    if ([SessionDeletionStatus.Success, SessionDeletionStatus.Failure].includes(deleteStatus)) {
      snackbar.snackbar({
        message: deleteStatus,
        duration: 3000,
        variant: deleteStatus === SessionDeletionStatus.Success ? 'success' : 'error'
      });
      dispatch(changeSessionDeleteStatus(SessionDeletionStatus.Idle));
    }
  }, [creationStatus, updateStatus, deleteStatus]);

  return (
    <>
      {showAddSession ? (
        <SessionDetails closeHandler={() => setShowAddSession(false)} />
      ) : (
        <Card sx={{ padding: '16px' }}>
          <>
            <span className={styles.title}>Manage {process.env.REACT_APP_SESSION_NAME}s</span>
            <SessionsList tab={tab} setTab={setTab} openAddSessionHandler={() => setShowAddSession(true)} />
          </>
        </Card>
      )}
    </>
  );
};

export default Sessions;

import { Popover, Typography } from '@mui/material';
import { ITooltipParams } from 'ag-grid-enterprise';
import React, { useMemo } from 'react';

export const MatrixToolTip: React.FC<ITooltipParams> = (props: ITooltipParams) => {
  const data = useMemo(() => props.api.getDisplayedRowAtIndex(props.rowIndex!)!.data, []);
  return (
    <Popover
      open={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
    </Popover>
  );
};

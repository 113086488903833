import { Feature } from '../../../../common/enums/appEnums';

export const GET_FEEDBACK_POSTS = `GET_FEEDBACK_POSTS${Feature.Feedback}`;
export const GET_TEAM_FEEDBACK_POSTS = `GET_TEAM_FEEDBACK_POSTS${Feature.Feedback}`;
export const SUBMIT_FEEDBACK_POST = `SUBMIT_FEEDBACK_POST${Feature.Feedback}`;
export const DELETE_FEEDBACK_POST = `DELETE_FEEDBACK_POST${Feature.Feedback}`;
export const DELETE_TEAM_FEEDBACK_POST = `DELETE_TEAM_FEEDBACK_POST${Feature.Feedback}`;
export const ADD_CANDIDATES_TO_TEAM = `ADD_CANDIDATES_TO_TEAM${Feature.Candidates}`;
export const CHANGE_ADD_CANDIDATES_TO_TEAM_STATUS = `CHANGE_ADD_CANDIDATES_TO_TEAM_STATUS${Feature.Candidates}`;
export const REMOVE_CANDIDATE_FROM_TEAM = `REMOVE_CANDIDATE_FROM_TEAM${Feature.Candidates}`;
export const CHANGE_REMOVE_CANDIDATE_FROM_TEAM_STATUS = `CHANGE_REMOVE_CANDIDATE_FROM_TEAM_STATUS${Feature.Candidates}`;
export const GET_OPEN_DAYS = `GET_OPEN_DAYS${Feature.Feedback}`;
export const GET_COMPLETED_OPEN_DAYS = `GET_COMPLETED_OPEN_DAYS${Feature.Feedback}`;
export const CHANGE_FEEDBACK_POST_CREATION_STATUS = `CHANGE_FEEDBACK_POST_CREATION_STATUS${Feature.Feedback}`;
export const CHANGE_FEEDBACK_POST_DELETION_STATUS = `CHANGE_FEEDBACK_POST_DELETION_STATUS${Feature.Feedback}`;
export const CHANGE_TEAM_FEEDBACK_POST_DELETION_STATUS = `CHANGE_TEAM_FEEDBACK_POST_DELETION_STATUS${Feature.Feedback}`;
export const CHANGE_FEEDBACK_POST_UPDATE_STATUS = `CHANGE_FEEDBACK_POST_UPDATE_STATUS${Feature.Feedback}`;
export const UPDATE_FEEDBACK_POST = `UPDATE_FEEDBACK_POST${Feature.Feedback}`;

import { AddBoxOutlined } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, FormControl, Modal, TextField } from '@mui/material';
import { IBasicModel } from 'common';
import GuardedEdit from 'common/components/GuardedEdit/GuardedEdit';
import { manageAppClaims } from 'modules/manage-app/helpers/permissionsHelper';
import { getAll, save } from 'modules/manage-app/operations/actions/ethnicityActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GuardedButton from '../../../../common/components/GuardedButton/GuardedButton';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { IGlobalState } from '../../../../startup/store/globalState';
import styles from '../../styles/ManageApp.module.scss';

const initialModel = { name: '' } as IBasicModel;

export const SaveModal = (props: { item?: IBasicModel }) => {
  const [open, setOpen] = useState(false);
  const { snackbar } = useSnackbar();
  const [item, setItem] = useState(props.item || initialModel);
  const dispatch = useDispatch();

  let { loading, success, data } = useSelector((state: IGlobalState) => {
    return { ...state.ethnicitiesState, ...state.appState.app };
  });

  useEffect(() => {
    if (!open || success == undefined) {
      return;
    }

    if (success) {
      const message = `Ethnicity '${item.name}' was successfully ${item.id ? 'updated.' : 'added.'}`;
      snackbar({ message, variant: 'success', action: true });
      !item.id && setItem(initialModel);
      dispatch(getAll());
      setOpen(false);
    }
  }, [success]);

  useEffect(() => {
    props.item && setItem(props.item);
  }, [props.item]);

  const updateForm = (name: string, value: any) => {
    setItem({ ...item, [name]: value });
  };

  const isValid = (): boolean => {
    return !!item.name?.length && data?.filter((f) => f.id != item.id).find((x) => x.name === item.name) === undefined;
  };

  const submit = async () => {
    dispatch(save(item));
  };

  return (
    <>
      {item?.id ? (
        <GuardedEdit
          editStyle="outlined"
          sx={{ marginTop: '2px' }}
          scopes={manageAppClaims}
          renderErrorItem={() => <></>}
          onClick={() => setOpen(true)}
        ></GuardedEdit>
      ) : (
        <GuardedButton
          label="ADD NEW ETHNICITY"
          onClick={() => setOpen(true)}
          className={styles.addButton}
          color="success"
          variant="contained"
          startIcon={<AddBoxOutlined />}
          errorProps={{ disabled: true }}
          scopes={manageAppClaims}
        />
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box className={styles.modalBox}>
          <Card className={styles.modal}>
            <CardHeader title={`${item.id ? 'Edit' : 'Add'} Ethnicity`} />
            <CardContent>
              <FormControl fullWidth>
                <TextField
                  value={item.name}
                  onChange={(e) => updateForm('name', e.target.value)}
                  label="Name*"
                  variant="outlined"
                />
              </FormControl>
            </CardContent>
            <CardActions sx={{ float: 'right' }}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                CANCEL
              </Button>
              <Button variant="contained" onClick={submit} disabled={!isValid() || loading}>
                SAVE
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

import { HttpMethod } from 'common/enums/appEnums';
import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { IPaginatedResponse } from '../../../../common/models/paginatedResponse';
import { IReport, IReportGenerateRequest, IReportType } from '../models';
import * as Types from '../types/index';

export const getReports = (parameters: string): IActionRequest<string> => {
  return {
    type: Types.GET_EXPORTS,
    httpMethod: HttpMethod.Get,
    url: `/reports`,
    payload: parameters
  };
};

export const changeReports = (response: IPaginatedResponse<IReport>): IActionType<IPaginatedResponse<IReport>> => {
  return {
    type: Types.EXPORTS_CHANGED,
    payload: response
  };
};

export const generateReport = (payload: IReportGenerateRequest): IActionRequest<IReportGenerateRequest> => {
  return {
    type: Types.GENERATE_EXPORT,
    httpMethod: HttpMethod.Put,
    url: `/reports/generate`,
    onSuccess: generateReportSuccess,
    onFailure: generateReportFailure,
    payload
  };
};

export const generateReportSuccess = (payload: any): IActionType<any> => ({
  type: Types.GENERATE_EXPORT_SUCCEEDED,
  payload
});

export const resetGenerateReportSuccess = (): IActionType<any> => ({
  type: Types.RESET_GENERATE_EXPORT
});

export const generateReportFailure = (payload: any): IActionType<any> => ({
  type: Types.GENERATE_EXPORT_FAILED,
  payload
});

//Report Types
export const getReportTypes = (): IActionRequest<undefined> => {
  return {
    type: Types.GET_EXPORT_TYPES,
    httpMethod: HttpMethod.Get,
    url: `/lookups/report_types`
  };
};

export const changeReportTypes = (payload: IReportType[]): IActionType<IReportType[]> => {
  return {
    type: Types.CHANGED_EXPORT_TYPES,
    payload: payload
  };
};

export const deleteReport = (report: IReport): IActionRequest<IReport> => {
  return {
    type: Types.DELETE_EXPORT,
    httpMethod: HttpMethod.Delete,
    url: `/reports/${report.id}`,
    onSuccess: () => deleteReportSuccess(report),
    onFailure: () => deleteReportFailure(report)
  };
};

export const deleteReportSuccess = (report: IReport): IActionType<IReport> => ({
  type: Types.DELETE_EXPORT_SUCCEEDED,
  payload: report
});

export const resetDeleteReportSuccess = (): IActionType<any> => ({
  type: Types.RESET_DELETE_EXPORT
});

export const deleteReportFailure = (report: IReport): IActionType<IReport> => ({
  type: Types.DELETE_EXPORT_FAILED,
  payload: report
});

import { TagRequired, TagType } from '../enums/tags';
import { ITag } from '../operations/models/tagModel';

export const getTagData = (tag: ITag): { selection: string; default: string } => {
  if (tag.type == TagType.Description) {
    const selection = [...(tag.options || [])]
      .sort((a, b) => a.priority - b.priority)
      .map((option) => option.description)
      .join(', ');
    const selected = (tag.options || []).find((f) => f.id == tag.defaultSelected) || ({} as any);
    return { selection, default: selected.description };
  }

  if (tag.type == TagType.Rating && tag.rating) {
    return {
      selection: `${tag.rating.start} - ${tag.rating.end}`,
      default: (tag.defaultSelected || 0).toString()
    };
  }

  return { selection: '-', default: '-' };
};

export const mapTag = (tempTag: ITag): ITag => {
  const tag = { ...tempTag };
  tag.required = tag.required ? TagRequired.Mandatory : TagRequired.Optional;
  if (tag.type == TagType.Description && tag.options) {
    const index = tag.options.findIndex((f) => f.id == tag.defaultSelected);
    index >= 0 && (tag.options[index].default = true);
  }
  return tag;
};

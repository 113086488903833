export const save = <T>(key: string, data: T) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const get = <T>(key: string): T | undefined => {
  const data = sessionStorage.getItem(key);
  if (data) {
    try {
      return JSON.parse(data) as T;
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

export const remove = (key: string) => {
  sessionStorage.removeItem(key);
};

import { Autocomplete, Box, Chip, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ICalibrationFilter, ICalibrationFilterRequest, IOfferCount } from 'modules/calibration/operations/models';
import React from 'react';

interface IProps {
  filterData: ICalibrationFilter;
  onFilterChanged: (type: string, value: string | string[] | number[]) => void;
  offerCount: IOfferCount;
  filterRequest: ICalibrationFilterRequest;
}

export const CalibrationFilterView: React.FC<IProps> = (props) => {
  const { filterRequest } = props;

  const onOptionRemoved = (type: string, value: any) => {
    if (type === 'statuses') {
      const statuses = filterRequest[type].filter((x) => x !== value);
      props.onFilterChanged(type, statuses);
    }

    if (type === 'captainIds') {
      const captainIds = filterRequest.captainIds.filter((x) => x !== value);
      props.onFilterChanged(type, captainIds);
    }
  };

  const captainOptions = (props?.filterData?.captains || []).map((option) => ({
    label: option.name,
    ...option
  }));
  const onCaptainChange = (captainIds: number[]) => props.onFilterChanged('captainIds', captainIds);

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <TextField
            label="Filter by Candidate"
            variant="outlined"
            onChange={(event) => props.onFilterChanged('candidateName', event.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <Autocomplete
            multiple
            options={captainOptions}
            onChange={(_, v) => onCaptainChange(v.map((v) => v.id))}
            renderInput={(params) => <TextField {...params} label="Filter By Team Captain" />}
          />
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="status-select-label">Filter by Offer</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            multiple
            value={filterRequest?.statuses}
            label="Filter by Offer"
            onChange={(event) => {
              props.onFilterChanged('statuses', event.target.value as any);
            }}
            renderValue={(selected: string[]) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value, i) => {
                  const status = props?.filterData?.statuses?.find((f) => f.value == value);
                  return (
                    <Chip
                      key={value + 'status'}
                      size="small"
                      label={status?.value}
                      onMouseDown={(e) => e.stopPropagation()}
                      onDelete={() => onOptionRemoved('statuses', value)}
                    />
                  );
                })}
              </Box>
            )}
          >
            {props?.filterData?.statuses?.map((status) => (
              <MenuItem key={`${status?.id}`} value={status?.value}>
                {status?.value} ({props?.offerCount[`${status?.value?.toLowerCase()}` as keyof IOfferCount]})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

import { Box, Container, Pagination } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './GenericPaginationWrapper.module.scss';

export interface IGenericPagination {
  pageNo: number;
  totalPages: number;
}

interface IProps {
  paginationData: IGenericPagination;
  onPageChange: (pageNo: number) => void;
  listComp: ReactNode;
}
const GenericPaginationWrapper: React.FC<IProps> = (props) => {
  const { paginationData, onPageChange, listComp } = props;

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value);
  };

  return (
    <>
      <Box>{listComp}</Box>
      <Container className={styles.navBar}>
        {paginationData.totalPages > 1 && (
          <Container sx={{ marginBlock: 2 }}>
            <Pagination
              className={styles.navBar}
              count={paginationData.totalPages}
              page={paginationData.pageNo}
              onChange={handlePageChange}
              shape="rounded"
            />
          </Container>
        )}
      </Container>
    </>
  );
};
export default GenericPaginationWrapper;

import { HttpMethod } from 'common/enums/appEnums';
import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import * as Types from '../types/authTypes';

export const changeUserName = (username?: string): IActionType<string> => {
  return {
    type: Types.USER_NAME_CHANGED,
    payload: username
  };
};

export const changeToken = (token?: string): IActionType<string> => {
  return {
    type: Types.TOKEN_CHANGED,
    payload: token
  };
};

export const changeIsAuthenticated = (value: boolean): IActionType<boolean> => {
  return {
    type: Types.AUTH_CHANGED,
    payload: value
  };
};

export const signIn = (): IActionType<undefined> => {
  return {
    type: Types.SIGN_IN
  };
};

export const signOut = (): IActionType<undefined> => {
  return {
    type: Types.SIGN_OUT
  };
};

export const getToken = (): IActionType<undefined> => {
  return {
    type: Types.GET_TOKEN
  };
};

export const getLoggedInUser = (payload: string): IActionRequest<string> => {
  return {
    type: Types.GET_LOGGEDIN_USER,
    httpMethod: HttpMethod.Get,
    url: `/authenticatedUser?email=${payload}`,
    payload: payload,
    onSuccess: getLoggedInUserSuccess,
    onFailure: getLoggedInUserFailed
  };
};

export const getLoggedInUserSuccess = (payload: any): IActionType<any> => ({
  type: Types.GET_LOGGED_IN_USER_SUCCESS,
  payload
});

export const getLoggedInUserFailed = (payload: any): IActionType<any> => ({
  type: Types.GET_LOGGED_IN_USER_FAILED,
  payload
});

import { CloseOutlined, InsertDriveFileOutlined } from '@mui/icons-material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {
  Avatar,
  Box,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { ROUTES } from 'common/constants/routesConstants';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { IGlobalState } from 'startup/store/globalState';
import {
  flattenQualifications,
  getCandidateStatusMetadata
} from '../../../modules/candidates/operations/helpers/candidateHelpers';
import { ICandidate } from '../../../modules/candidates/operations/models/candidatesModel';
import { formatShortDate } from '../../helpers/dateHelpers';
import CandidateTrackerLink from '../CandidateTrackerLink/CandidateTrackerLink';
import GuardedButton from '../GuardedButton/GuardedButton';
import { BootstrapTooltip, OverflowTooltip } from '../OverflowTooltip/OverflowTooltip';

interface IProps {
  candidate: ICandidate;
  handleClose: () => void;
}

const CandidateProfileDialog = ({ candidate, handleClose }: IProps) => {
  const navigate = useNavigate();
  const { openDay, locations, statuses } = useSelector((state: IGlobalState) => {
    return {
      openDay: state.sessionsState.sessions.sessionById,
      locations: state.commonState.common.locations,
      ...state.candidateStatusState
    };
  });

  const candidateStatusMetadata = getCandidateStatusMetadata(statuses, candidate.status || 0);

  const CandidateLocationAndStatus = () => {
    return (
      <Container disableGutters sx={{ display: 'flex', gap: '4px' }}>
        <Chip
          size={'small'}
          sx={{
            margin: 0,
            border: '1px solid #009499',
            backgroundColor: '#DFF7F7'
          }}
          component={'span'}
          label={
            candidate.willingToRelocate ? (
              <BootstrapTooltip
                title={`Willing to relocate from ${candidate.location} to ${
                  locations.find((x) => x.id === candidate.relocationId)?.name
                }.`}
                arrow
                placement={'top-end'}
                enterTouchDelay={0}
              >
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center'
                  }}
                >
                  {candidate.location}
                  <ArrowRightAltIcon fontSize={'small'} />
                  {locations.find((x) => x.id === candidate.relocationId)?.name}
                </span>
              </BootstrapTooltip>
            ) : (
              <span>{candidate.location}</span>
            )
          }
        />
        <Chip
          size={'small'}
          sx={{
            margin: 0,
            border: candidateStatusMetadata.displayBorder,
            backgroundColor: candidateStatusMetadata.displayBackgroundColor
          }}
          component={'span'}
          label={candidateStatusMetadata.displayText}
        />
      </Container>
    );
  };

  const Qualifications = () => {
    const qualificationDisplay = flattenQualifications(candidate.candidateQualifications || []);

    return (
      <Typography sx={{ fontSize: 14, display: 'flex', alignItems: 'center' }} component={'span'} variant={'body2'}>
        {qualificationDisplay.first || '-'}
        {qualificationDisplay.additionalCount ? (
          <BootstrapTooltip title={qualificationDisplay.additionalText} arrow placement={'top-end'} enterTouchDelay={0}>
            <Typography
              sx={{
                color: '#133970'
              }}
              component={'span'}
            >
              ,+{qualificationDisplay.additionalCount}{' '}
            </Typography>
          </BootstrapTooltip>
        ) : (
          ''
        )}
        {candidate.jobTitle && ' - ' + candidate.jobTitle}
      </Typography>
    );
  };

  const CandidateDetail = () => {
    return (
      <Container disableGutters sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, marginTop: 1 }}>
        <Container disableGutters sx={{ display: 'flex', gap: '8px' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 14 }} component={'span'}>
            {process.env.REACT_APP_SESSION_NAME}:
          </Typography>
          <Typography sx={{ fontSize: 14 }} component={'span'}>
            {formatShortDate(openDay?.date) ?? '-'}
          </Typography>
        </Container>
        <Container disableGutters sx={{ display: 'flex', gap: '8px' }}>
          <Typography sx={{ fontSize: 14 }} component={'span'}>
            {candidate.aic ?? '-'}
          </Typography>
          |
          <Typography sx={{ fontSize: 14 }} component={'span'}>
            {candidate.gender ?? '-'}
          </Typography>
        </Container>
        <Typography sx={{ fontSize: 14, display: 'flex', alignItems: 'center' }} component={'p'}>
          <Qualifications />
        </Typography>
      </Container>
    );
  };

  return (
    <Dialog open={true} fullWidth onClose={handleClose}>
      <DialogTitle
        sx={{
          backgroundColor: '#F6F6F6',
          color: '#5D5D5D',
          fontWeight: 500,
          fontSize: 18,
          height: '46px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingLeft: '16px',
          paddingRight: '8px'
        }}
        component={'h4'}
      >
        Candidate Profile <CloseOutlined sx={{ height: 18, width: 18, cursor: 'pointer' }} onClick={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Container disableGutters sx={{ display: 'flex', marginTop: 1 }}>
          {candidate.photo && candidate.photo !== '' ? (
            <Box
              component="img"
              sx={{
                height: '192px',
                width: '108px',
                backgroundColor: 'gray',
                objectFit: 'cover'
              }}
              src={candidate.photo}
            />
          ) : (
            <Container
              disableGutters
              sx={{
                height: '192px',
                width: '108px',
                backgroundColor: 'lightgray',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Avatar />
            </Container>
          )}
          <Container
            disableGutters
            sx={{
              padding: '16px',
              backgroundColor: 'white',
              color: 'black',
              margin: 0
            }}
          >
            <span className="float-right">
              <CandidateTrackerLink candidateTrackerLink={candidate.candidateTrackerLink} />
            </span>
            <Container disableGutters>
              <OverflowTooltip
                sx={{
                  color: '#5D5D5D',
                  fontWeight: 500,
                  fontSize: 18
                }}
                variant={'h5'}
                name={candidate.name}
              />
            </Container>
            <CandidateLocationAndStatus />
            <CandidateDetail />
          </Container>
        </Container>
        <Container disableGutters sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {candidate.comments && candidate.comments !== '' ? (
            <Box
              sx={{
                backgroundColor: '#F0F0F0',
                padding: '8px 16px',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                borderRadius: '5px'
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontSize: 14,
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                Candidate Notes <InsertDriveFileOutlined sx={{ height: 18, width: 18, opacity: 0.54 }} />
              </Typography>
              <Typography component={'p'} variant={'body2'}>
                {candidate.comments}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Container>
      </DialogContent>
      <DialogActions>
        <GuardedButton
          sx={{
            width: '50%',
            border: '1px solid #133970',
            color: '#133970'
          }}
          scopes={[
            {
              claimType: 'Candidates',
              actionType: ClaimActionType.EDIT
            }
          ]}
          errorProps={{ disabled: true }}
          onClick={() => navigate(`${ROUTES.Candidate}/${candidate.id}`)}
          label={'Edit'}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CandidateProfileDialog;

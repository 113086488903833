import { Close } from '@mui/icons-material';
import { Box, Grid, Modal } from '@mui/material';
import { getCandidateCalibrations } from 'modules/calibration/operations/actions';
import { CALIBRATION_NAME, CalibrationType } from 'modules/calibration/operations/enums';
import { ICandidateCalibration } from 'modules/calibration/operations/models';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import CalibrationContent from './CalibrationContent';
import styles from './FeedbackModal.module.scss';

interface IProps {
  candidateCalibration: ICandidateCalibration;
  calibrationType: CalibrationType;
  handleClose: () => void;
}

const FeedbackModal: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { candidateCalibration, calibrationType, handleClose } = props;
  const updateOperationSuccess = useSelector((state: IGlobalState) => state.calibrationState.updateOperationSuccess);

  const modalTitle = `${CALIBRATION_NAME[calibrationType]} Notes`;

  useEffect(() => {
    if (updateOperationSuccess && candidateCalibration.candidate?.id) {
      dispatch(getCandidateCalibrations(candidateCalibration.sessionId, candidateCalibration.candidate.id));
    }
  }, [updateOperationSuccess]);

  return (
    <Modal open={true} onClose={handleClose}>
      <Box className={styles.dialog}>
        <div className={styles.header}>
          <Grid container>
            <Grid item xs={4}>
              <b className={styles.subTitle}>{modalTitle}</b>
            </Grid>
            <Grid item xs={4} className="textCenter">
              <span className={styles.title}>{candidateCalibration?.candidate.name}</span>
            </Grid>
            <Grid item xs={4}>
              <Close onClick={handleClose} className={styles.iconClose} />
            </Grid>
          </Grid>
        </div>
        <CalibrationContent
          calibrationType={calibrationType}
          candidateCalibration={candidateCalibration}
          handleClose={handleClose}
          compact={false}
        />
      </Box>
    </Modal>
  );
};

export default FeedbackModal;

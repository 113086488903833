import IFeedback, { feedbackDefault } from './feedbackModel';
import IFeedbackOperation, { feedbackOperationDefault } from './feedbackOperationModel';

export interface IFeedbackState {
  feedback: IFeedback;
  feedbackOperation: IFeedbackOperation;
}

export const feedbackStateDefault: IFeedbackState = {
  feedback: feedbackDefault,
  feedbackOperation: feedbackOperationDefault
};

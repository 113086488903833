import { IUserManagementState, userManagementStateDefault } from '../models/userManagementState';
import claimReducer from './claimReducer';
import roleReducer from './roleReducer';
import userReducer from './userReducer';

export default function userManagementReducer(state = userManagementStateDefault, action: any): IUserManagementState {
  return {
    claimState: claimReducer(state.claimState, action),
    userState: userReducer(state.userState, action),
    roleState: roleReducer(state.roleState, action)
  };
}

import { ResponseType } from 'axios';
import { HttpMethod } from '../enums/appEnums';
import { FullRequestParams, HttpClient } from './httpClient';

export const request = (httpClient: HttpClient, token: string) => (action: IRequest) => {
  const headers: { [key: string]: string } = {};
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const request: FullRequestParams = {
    path: action.url,
    method: action.httpMethod,
    headers
  };

  if ([HttpMethod.Post, HttpMethod.Put].includes(action.httpMethod)) {
    request.body = action.payload;
    httpClient.format = action.format ?? 'json';
  }

  return httpClient.request(request);
};

export interface IRequest {
  type: string;
  httpMethod: HttpMethod;
  format?: ResponseType;
  url: string;
  onSuccess?: any;
  onFailure?: any;
  payload?: any;
  meta?: any;
}

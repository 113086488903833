import { ColDef } from 'ag-grid-community';
import { teamFeedbackCategory } from 'common/constants/appContants';
import { MatrixToolTip } from 'modules/calibration/components/CalibrationMatrix/MatrixToolTip';
import { ICandidate } from 'modules/candidates/operations/models/candidatesModel';
import { ITag } from 'modules/manage-app/operations/models/tagModel';
import { IUserModel } from 'modules/manage-users/operations/models';
import { CALIBRATION_NAME, CalibrationType } from '../enums';
import { ColumnDef, ICandidateCalibration } from '../models';

export type IMatrixData = ICandidateCalibration &
  ICandidate & {
    [CalibrationType.Interview]: ICandidateCalibration;
    [CalibrationType.PostCalibration]: ICandidateCalibration;
    [CalibrationType.PreCalibration]: ICandidateCalibration;
  };

export type ValueGetterType = {
  data?: IMatrixData;
};

export const defaultColumnDef: ColDef = {
  flex: 1,
  minWidth: 100,
  enablePivot: true,
  enableValue: true,
  sortable: true,
  resizable: true,
  enableRowGroup: true,
  tooltipComponent: MatrixToolTip
};

const LINE_DELIMETER = '\n|\n';

const getTagValue = (data: IMatrixData, tag: ITag, userId: number | undefined): string => {
  const tagRating = data.feedbackResponseDTOS
    ?.filter((f) => f.userId == userId)
    .flatMap((f) => f.tags)
    .find((f) => f?.tag?.id == tag.id);
  return tagRating?.rating ?? '-';
};

const getCalibrationTags = (candidateCalibration: ICandidateCalibration | undefined, tag: ITag): string => {
  const selectedTag = candidateCalibration?.tags?.find((f) => f.tag?.id == tag.id);
  return selectedTag?.rating ?? '-';
};

const getFeedbackPost = (data: IMatrixData, userId: number | undefined): string => {
  return (
    data?.feedbackResponseDTOS
      ?.filter((f) => f.userId == userId)
      .map((m) => {
        const teamFeedback = m.category?.name == teamFeedbackCategory ? '(Team Feedback)\n' : '';
        return `${teamFeedback}${m.note ?? ''}`;
      })
      .join(LINE_DELIMETER) || '-'
  );
};

export const getCalibrationColumns = (selectedTags: ITag[]): ColumnDef[] => {
  return Object.values(CalibrationType)
    .reverse()
    .map((key, i) => {
      const hide = key !== CalibrationType.PostCalibration;
      return {
        headerName: CALIBRATION_NAME[key],
        groupId: key,
        hide,
        type: key,
        children: [
          {
            hide,
            field: 'offer',
            minWidth: 25,
            filter: true,
            sortable: true,
            colId: `offer_${i}`,
            type: key,
            valueGetter: (a: ValueGetterType) => (a.data ? a.data[key]?.offerTypeCode : '')
          },
          {
            hide,
            field: 'Notes',
            tooltipValueGetter: (a: ValueGetterType) =>
              a.data ? a.data[key]?.calibrationNotes?.map((note) => note.note).join(LINE_DELIMETER) : '',
            minWidth: 100,
            filter: true,
            sortable: true,
            type: key,
            colId: `offer_${i}`,
            valueGetter: (a: ValueGetterType) =>
              a.data ? a.data[key]?.calibrationNotes?.map((note) => note.note).join(LINE_DELIMETER) : ''
          },
          {
            field: 'tags',
            filter: true,
            hide,
            type: key,
            sortable: true,
            colId: `tags_${i}`,
            children: [
              ...selectedTags.map((tag) => {
                return {
                  hide: hide,
                  type: key,
                  headerName: tag.name,
                  colId: `offer_${i}_${tag.id}`,
                  valueGetter: (a: ValueGetterType) => (a.data ? getCalibrationTags(a.data[key], tag) : '')
                };
              })
            ]
          }
        ]
      };
    });
};

export const getCandidateColumns = (cellRenderer?: any): ColumnDef => {
  const type = 'Candidate';
  return {
    headerName: 'Candidate',
    children: [
      {
        field: 'photo',
        headerName: 'Photo',
        // rowDrag: true,
        width: 1,
        maxWidth: 75,
        cellRenderer,
        useValueFormatterForExport: true,
        rowGroup: false,
        showRowGroup: false,
        type,
        valueGetter: (params: ValueGetterType) => params.data?.candidateTrackerLink
      },
      { field: 'name', filter: true, sortable: true, type },
      { field: 'gender', maxWidth: 120, filter: true, sortable: true, hide: true, type },
      { field: 'university', filter: true, sortable: true, type },
      { field: 'qualification', filter: true, sortable: true, hide: true, type },
      { field: 'team.name', filter: true, sortable: true, hide: true, type },
      {
        field: 'comments',
        headerName: 'Candidate Notes',
        tooltipField: 'Candidate Notes',
        filter: true,
        sortable: true,
        resizable: true,
        hide: true,
        type
      }
    ]
  };
};

export const getFeedbackTagsColumns = (tags: ITag[], users: IUserModel[]): ColumnDef => {
  return {
    headerName: 'Feedback Tags',
    hide: true,
    rowGroup: true,
    children: [
      ...tags.map((tag) => {
        let tagOptions = (tag.rating?.end && `(${tag.rating.start} - ${tag.rating.end})`) || '';
        return {
          headerName: `${tag.name} ${tagOptions}`,
          hide: true,
          children: [
            ...users.map((user) => {
              return {
                field: `${user.name} ${user.surname}`,
                sortable: true,
                hide: true,
                colId: `tag_${tag.id}_user_${user.id}`,
                valueGetter: (e: ValueGetterType) => (e.data ? getTagValue(e.data, tag, user.id) : '')
              };
            })
          ]
        };
      })
    ]
  };
};

export const getFeedbackPostsColumn = (users: IUserModel[]): ColumnDef => {
  return {
    headerName: 'Feedback Posts',
    hide: true,
    rowGroup: true,
    children: [
      ...users.map((user) => {
        return {
          field: `${user.name} ${user.surname}`,
          sortable: true,
          hide: true,
          colId: `user_${user.id}_feedback`,
          tooltipValueGetter: (e: ValueGetterType) => (e.data ? getFeedbackPost(e.data, user.id) : ''),
          valueGetter: (e: ValueGetterType) => (e.data ? getFeedbackPost(e.data, user.id) : '')
        };
      })
    ]
  };
};

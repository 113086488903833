import { ChevronRight } from '@mui/icons-material';
import { Card, CardContent, Chip } from '@mui/material';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import { ROUTES } from 'common/constants/routesConstants';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import { useSnackbar } from 'common/hooks/useSnackbar';
import dayjs from 'dayjs';
import {
  changeHttpActionEnded,
  changeHttpActionStarted,
  changeLoading
} from 'modules/app/operations/actions/appActions';
import { DOWNLOAD_EXPORT } from 'modules/report/operations/types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { IGlobalState } from 'startup/store/globalState';
import { formatDate, getWeekDay } from '../../../../common/helpers/dateHelpers';
import useScreenSize from '../../../../common/hooks/useScreenSize';
import { IReport } from '../../operations/models';
import DeleteExport from '../DeleteExport';
import styles from './ExportCard.module.scss';

interface IProps {
  report: IReport;
}

export const ExportCard: React.FC<IProps> = ({ report }) => {
  const API_BASE = process.env.REACT_APP_API_BASE;
  const { snackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  const { token, loading } = useSelector((state: IGlobalState) => ({ ...state.authState, ...state.appState.app }));
  const dateDiff = dayjs().startOf('D').diff(dayjs(report.createdAt).startOf('D'), 'days');

  const handleDownload = async () => {
    try {
      dispatch(changeHttpActionStarted(DOWNLOAD_EXPORT));
      const response = await fetch(`${API_BASE}/reports/zip/download/session-reports/${report.id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      if (!response.ok) {
        const message = 'Error downloading zip file';
        snackbar({ message, variant: 'error', action: true });
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = report.name.replace('.xlsx', '.zip');
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      const message = 'Error downloading zip file';
      snackbar({ message, variant: 'error', action: true });
    } finally {
      dispatch(changeHttpActionEnded(DOWNLOAD_EXPORT));
      setTimeout(() => dispatch(changeLoading(false)), 500);
    }
  };

  const renderDesktop = () => {
    return (
      <Card
        className={`${styles.reportCard} ${
          dateDiff > 0 ? styles.statusComplete : dateDiff == 0 ? styles.statusToday : styles.statusFuture
        }`}
      >
        <CardContent className={styles.cardContent}>
          <span className="flexBasis20">{report.name}</span>
          <span className="flexBasis10">{report.reportTypeCode}</span>
          <span className="flexBasis10">{report.createdBy.displayName}</span>
          <span className="flexBasis15">{formatDate(report.createdAt)}</span>
          <span className="flexBasis15">
            <EntelectButton
              label="Download"
              handleClick={handleDownload}
              variant={ButtonType.Primary}
              className={styles.actionLink}
              disabled={loading}
            />
            <DeleteExport report={report} />
          </span>
        </CardContent>
      </Card>
    );
  };

  const renderMobile = () => {
    return (
      <div className={`${styles.cardMobile}`} onClick={() => navigate(`${ROUTES.Exports}/${report.id}`)}>
        <div className={`${styles.leftSection} ${dateDiff == 0 ? styles.colorBlue : styles.colorGreen}`}>
          <span>{getWeekDay(report.createdAt, true)}</span>
          <br />
          <span>
            <b>{formatDate(report.createdAt, true)}</b>
          </span>
          <br />
        </div>
        <div className={styles.rightSection}>
          {report.name}
          <br />
          <Chip label="Download" onClick={handleDownload} variant="outlined" size="small" className={styles.chip} />
          <br />
          Type: {report.reportTypeCode}
        </div>
        <div style={{ paddingRight: '0.5rem', fontWeight: 700 }}>
          <ChevronRight sx={{ height: 18, width: 18, cursor: 'pointer' }} />
        </div>
      </div>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default ExportCard;

import { Box } from '@mui/material';
import React from 'react';
import candidateTrackerIcon from '../../../assets/candidate-tracker-icon.ico';
import { BootstrapTooltip } from '../OverflowTooltip/OverflowTooltip';
import styles from './CandidateTrackerLink.module.scss';

interface IProps {
  candidateTrackerLink?: string;
}

const CandidateTrackerLink: React.FC<IProps> = ({ candidateTrackerLink }) => {
  if (!candidateTrackerLink) {
    return <></>;
  }

  return (
    <Box>
      <BootstrapTooltip title={`View on Candidate Tracker`} arrow placement={'top-end'} enterTouchDelay={0}>
        <a
          style={{
            display: 'inline-flex',
            alignItems: 'center'
          }}
          rel="noopener noreferrer"
          href={candidateTrackerLink}
          target="_blank"
        >
          <img src={candidateTrackerIcon} className={styles.candidateTrackerLink} alt="CT" />
        </a>
      </BootstrapTooltip>
    </Box>
  );
};

export default CandidateTrackerLink;

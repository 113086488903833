import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../enums/appEnums';
import { ApiSagaRequest } from '../../saga/apiSaga';
import { commonActions } from '../actions';
import { IInstitution } from '../models/institutionModel';
import * as OpTypes from '../types/commonOperationTypes';

function* getInstitutions(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    const data: IInstitution[] = response.data as IInstitution[];
    yield put(commonActions.changeInstitutions(data));
  }
}

export function* getInstitutionsSaga() {
  yield takeLatest(OpTypes.GET_INSTITUTIONS, getInstitutions);
}

import { AddBoxOutlined, Clear } from '@mui/icons-material';
import {
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
  List,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { useCheckPermissions } from 'common/hooks/useCheckPermissions';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import { ISessionTeamRequestDto } from 'modules/sessions/operations/models/sessionsModel';
import { IKeyMap, IValidationError } from 'modules/shared/models/shared-models';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import styles from './SessionDetails.module.scss';
interface IProps {
  teams: ISessionTeamRequestDto[];
  errors: IKeyMap<IValidationError>;
  setTeams: (teams: ISessionTeamRequestDto[]) => void;
  setValidationTouched: (key: string) => void;
}

interface ITextfieldState {
  isDirty: boolean;
  textfieldId: number;
}

export const TeamDetails: React.FC<IProps> = ({ setTeams, teams, errors, setValidationTouched }: IProps) => {
  const { teamCaptains, openDay } = useSelector((state: IGlobalState) => {
    return { ...state.sessionsState.sessionsOperation, ...state.sessionsState.sessions };
  });
  const canManageOpenDay = useCheckPermissions([
    { actionType: ClaimActionType.ADD, claimType: ClaimTypes.MANAGE_OPEN_DAYS }
  ]);
  const hasError = teams.some(
    (_, key) => errors[`team_${key}_name`]?.message ?? errors[`team_${key}_captain`]?.message
  );

  const [textfielsdState, setTextfielsdState] = useState<ITextfieldState[]>([]);

  const hasClicked = (index: number) => {
    markTeamfieldDirty(index);
  };

  const onUpdateTeamValues = (index: number, key: string, value: string | number) => {
    let tempTeams = [...teams];
    (tempTeams[index] as any)[key] = value;

    let fieldDirty = textfielsdState.find((field) => field.textfieldId == index);

    if (key === 'teamCaptainId' && !tempTeams[index].id && !fieldDirty) {
      tempTeams[index].name = teamCaptains.find((captain) => captain.id === value)?.name + "'s Team";
    }
    setValidationTouched(`team_${index}`);
    setTeams(tempTeams);
  };

  const onRemoveTeam = (index: number) => {
    let tempTeams = [...teams];
    tempTeams[index].isDeleted = true;
    setTeams(tempTeams);
  };

  const addTeam = () => {
    const tempTeams = [...teams, {}];
    setTeams(tempTeams);
  };

  const markTeamfieldDirty = (index: number) => {
    const newField = { isDirty: true, textfieldId: index };
    let fielsdState = [...textfielsdState];
    if (!fielsdState.find((field) => field.textfieldId == newField.textfieldId)) {
      fielsdState.push(newField);
      setTextfielsdState(fielsdState);
    }
  };

  useEffect(() => {
    let tempTeams = [...teams];
    let tempTeamsFromDB = tempTeams.filter((team) => team.id != null) || [];
    tempTeamsFromDB.map((team, key) => markTeamfieldDirty(key));
  }, [teams]);

  return (
    <>
      <div className={styles.formHeading}>Teams</div>
      <div className={styles.formSubHeading}>
        Create Teams and Assign Captains for {process.env.REACT_APP_SESSION_NAME}
      </div>

      <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {[...teams].map((team, key) => {
          const teamNameError = !!errors[`team_${key}`]?.touched && errors[`team_${key}_name`]?.message;
          let teamCaptainError = !!errors[`team_${key}`]?.touched && errors[`team_${key}_captain`]?.message;
          const isCaptainAvailable =
            !team.teamCaptainId || teamCaptains.filter((captain) => captain.id === team.teamCaptainId).length > 0;
          const teamDetails = openDay?.teams.find((t) => t.id === team.id);
          const hasCandidates = (teamDetails?.candidates ?? []).length > 0;
          if (!teamCaptainError && !isCaptainAvailable) {
            teamCaptainError = `${teamDetails?.teamCaptain.name ?? 'User'} cannot be a Team Captain`;
          }

          return (
            <FormGroup key={`${key}team_key${team?.id}`} className={team.isDeleted ? 'd-none' : ''} row>
              <FormControl sx={{ width: 225, mt: 1 }}>
                <InputLabel id="demo-select-small-label" error={!!teamCaptainError}>
                  Team Captain*
                </InputLabel>
                <Select
                  value={team.teamCaptainId}
                  label="Team Captain"
                  disabled={!canManageOpenDay}
                  onChange={(e) => onUpdateTeamValues(key, 'teamCaptainId', e.target.value)}
                >
                  {teamCaptains.map((captain) => (
                    <MenuItem key={`captain_${captain.id}`} value={captain.id}>
                      {captain.name}
                    </MenuItem>
                  ))}

                  {!isCaptainAvailable && (
                    <MenuItem disabled key={`captain_${team.teamCaptainId}`} value={team.teamCaptainId}>
                      {teamDetails?.teamCaptain.name}
                    </MenuItem>
                  )}
                </Select>
                <FormHelperText className="error">{teamCaptainError}</FormHelperText>
              </FormControl>
              <FormControl sx={{ width: 225, marginLeft: 4, mt: 1 }}>
                <TextField
                  value={team.name}
                  onChange={(e) => onUpdateTeamValues(key, 'name', e.target.value)}
                  label="Team Name*"
                  onClick={() => hasClicked(key)}
                  variant="outlined"
                  error={!!teamNameError}
                  helperText={teamNameError}
                  InputLabelProps={{ shrink: !!team.name?.length }}
                  disabled={!canManageOpenDay}
                />
              </FormControl>
              <FormControl title={hasCandidates ? 'Team has candidates, cannot be deleted' : 'Delete Team'}>
                <Button
                  sx={{ mt: 2 }}
                  onClick={() => onRemoveTeam(key)}
                  color="error"
                  variant="text"
                  size="large"
                  disabled={!canManageOpenDay || hasCandidates}
                  startIcon={<Clear fontSize="large" />}
                />
              </FormControl>
            </FormGroup>
          );
        })}
      </List>

      <Button
        sx={{ mt: 1 }}
        onClick={addTeam}
        color="primary"
        variant="text"
        startIcon={<AddBoxOutlined />}
        disabled={!canManageOpenDay || hasError}
      >
        ADD TEAM
      </Button>
    </>
  );
};

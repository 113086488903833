import { Box, Button, Grid, TextareaAutosize } from '@mui/material';
import { useCheckPermissions } from 'common/hooks/useCheckPermissions';
import { saveCalibrationOffer } from 'modules/calibration/operations/actions';
import { CALIBRATION_NAME, CalibrationType } from 'modules/calibration/operations/enums';
import { ICandidateCalibration, ICandidateCalibrationRequest } from 'modules/calibration/operations/models';
import { FeedbackTag } from 'modules/feedback/components/FeedbackTag/FeedbackTag';
import { hasEditFeedbackCalibration } from 'modules/manage-app/helpers/permissionsHelper';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as appStorageService from '../../../../common/helpers/appStorageService';
import { formatDate, getTime } from '../../../../common/helpers/dateHelpers';
import useScreenSize from '../../../../common/hooks/useScreenSize';
import { IGlobalState } from '../../../../startup/store/globalState';
import { CalibrationOfferView } from '../CalibrationOfferView/CalibrationOfferView';
import CalibrationNotes from './CalibrationNotes';
import CalibrationNotesToggleView from './CalibrationNotesToggleView';
import styles from './FeedbackModal.module.scss';
import FeedbackPostsToggleView from './FeedbackPostsToggleView';

interface IProps {
  candidateCalibration: ICandidateCalibration;
  calibrationType: CalibrationType;
  handleClose: () => void;
  compact?: boolean;
}

const CalibrationContent: React.FC<IProps> = (props: IProps) => {
  const { candidateCalibration, calibrationType, handleClose, compact } = props;
  const draftPath = `${location.pathname}/${candidateCalibration?.id}`;
  const draft = appStorageService.get<any>(draftPath) ?? {};
  const modalTitle = `${CALIBRATION_NAME[calibrationType]} Notes`;
  const { isMobile } = useScreenSize();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const dispatch = useDispatch();

  const canCreateNotes = useCheckPermissions(hasEditFeedbackCalibration);
  const { calibrations, tags } = useSelector((state: IGlobalState) => ({
    calibrations: state.calibrationState.openDayCalibration.candidateCalibrations,
    ...state.tagState
  }));
  const [candidateCalibrations, setCandidateCalibrations] = useState<ICandidateCalibration[]>([]);
  const [selectedTags, setSelectedTags] = useState<{ [id: number]: string }>(draft.selectedTags ?? {});
  const [isValid, setIsValid] = useState<boolean>(false);
  const [feedbackComment, setFeedbackComment] = useState<string>(draft.feedbackComment ?? '');
  const [offerTypeCode, setOfferTypeCode] = useState<string>(candidateCalibration?.offerTypeCode ?? '');
  const currentStageCandidateCalibration = candidateCalibrations.find((f) => f.interviewTypeCode == calibrationType);

  useEffect(() => {
    setIsValid(feedbackComment.length > 0 || offerTypeCode.length > 0);
  }, [feedbackComment, offerTypeCode]);

  useEffect(() => {
    appStorageService.save(draftPath, { feedbackComment, selectedTags });
  }, [feedbackComment, selectedTags]);

  useEffect(() => {
    const filteredCalibrations = calibrations.filter((f) => f.candidate.id == candidateCalibration?.candidate.id);
    const currentCalibration = filteredCalibrations.find((f) => f.interviewTypeCode == calibrationType);
    let previousCalibration: ICandidateCalibration | undefined;
    if (filteredCalibrations?.length && !currentCalibration?.calibrationNotes?.length) {
      const indexOf = Object.values(CalibrationType).indexOf(calibrationType);
      if (indexOf > 0) {
        const prevType = Object.values(CalibrationType)[indexOf - 1];
        previousCalibration = filteredCalibrations.find((f) => f.interviewTypeCode == prevType);
      }
    }

    setCandidateCalibrations(filteredCalibrations);
    setDefaults(currentCalibration, previousCalibration);
  }, [calibrations]);

  useEffect(() => {
    if (currentStageCandidateCalibration?.calibrationNotes?.length) {
      setTimeout(() => messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' }), 200);
    }
  }, [currentStageCandidateCalibration]);

  const setDefaults = (curCalibration?: ICandidateCalibration, prevCalibration?: ICandidateCalibration) => {
    const tags = curCalibration?.tags?.length ? curCalibration.tags : prevCalibration?.tags ?? [];
    const tagsMap = tags?.reduce(
      (combined, tag) => {
        combined[tag.tag?.id ?? 0] = tag.rating;
        return combined;
      },
      {} as { [id: number]: string }
    );
    setSelectedTags(tagsMap);

    if (!curCalibration?.calibrationNotes?.length) {
      const notes = prevCalibration?.calibrationNotes ?? [];
      notes.length && setFeedbackComment(notes[notes.length - 1].note);
    }
  };

  const handleSubmit = () => {
    if (candidateCalibration) {
      const { candidate, sessionId, interviewTypeCode } = candidateCalibration;
      if (candidate?.id) {
        const saveCalibrationRequest: ICandidateCalibrationRequest = {
          sessionId: sessionId,
          candidateId: candidate.id,
          offerTypeCode: offerTypeCode || 'NONE',
          interviewTypeCode: interviewTypeCode,
          note: feedbackComment,
          tags: Object.keys(selectedTags).map((tagId) => ({ id: 0, tagId: +tagId, rating: selectedTags[+tagId] }))
        };

        setFeedbackComment('');
        setSelectedTags({});
        dispatch(saveCalibrationOffer(saveCalibrationRequest));
      }
    }
  };

  const renderNoteCapture = () => {
    return (
      <>
        <div className={compact ? '' : styles.noteCaptureBox}>
          <div className={styles.interviewNotesCardList}>
            {currentStageCandidateCalibration?.calibrationNotes.map((feedbackComment) => (
              <div key={'_comment _' + feedbackComment.id} className={styles.feedbackTextPreCalibration}>
                <span className={styles.feedbackUser}>{feedbackComment.createdBy?.displayName} </span>
                <span className={styles.feedbackDate}>{formatDate(feedbackComment.createdAt)} </span>
                <span className={styles.feedbackDate}>{getTime(feedbackComment.createdAt)}</span>
                <p className={styles.feedbackNote}>{feedbackComment.note}</p>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          {canCreateNotes && (
            <div className={styles.commentSection}>
              <TextareaAutosize
                className={styles.feedbackNoteInput}
                placeholder={'Type the ' + modalTitle.toLowerCase()}
                onChange={(e) => setFeedbackComment(e.target.value)}
                value={feedbackComment}
                minRows={4}
              />
              {tags.map((tag) => {
                return (
                  <FeedbackTag
                    key={`note_tag_${tag.id}`}
                    tag={tag}
                    handleChange={(value) => setSelectedTags({ ...selectedTags, [tag.id]: value })}
                    label={tag.name}
                    value={selectedTags[tag.id]}
                  />
                );
              })}
            </div>
          )}

          {compact && (
            <>
              <div className="ml-05">
                <CalibrationOfferView onOfferChanged={(offer) => setOfferTypeCode(offer)} offer={offerTypeCode} />
              </div>
              {canCreateNotes && (
                <div className={styles.footer}>
                  <Button variant="outlined" className={styles.buttonCancel} onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.buttonSubmit}
                    onClick={handleSubmit}
                    disabled={!isValid}
                  >
                    Save
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        {!compact && (
          <div className={styles.offerSection}>
            <CalibrationOfferView onOfferChanged={(offer) => setOfferTypeCode(offer)} offer={offerTypeCode} />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className={compact ? '' : styles.content}>
        <Box
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            height: '100%'
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <div className={compact ? '' : styles.maxContainerHeight}>
                <CalibrationNotes candidate={candidateCalibration.candidate} />

                <FeedbackPostsToggleView
                  candidateCalibration={
                    calibrations.find((f) => f.id == candidateCalibration.id) ?? candidateCalibration
                  }
                  showContent={calibrationType == CalibrationType.PreCalibration}
                />

                {[CalibrationType.Interview, CalibrationType.PostCalibration].includes(calibrationType) && (
                  <CalibrationNotesToggleView
                    showContent={calibrationType == CalibrationType.Interview}
                    calibrationType={CalibrationType.PreCalibration}
                    candidateCalibrations={candidateCalibrations}
                  />
                )}
                {calibrationType == CalibrationType.PostCalibration && (
                  <CalibrationNotesToggleView
                    showContent={true}
                    calibrationType={CalibrationType.Interview}
                    candidateCalibrations={candidateCalibrations}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={3} className={styles.contentCapture}>
              {renderNoteCapture()}
            </Grid>
          </Grid>
        </Box>
      </div>
      {!compact && canCreateNotes && (
        <div className={styles.footer}>
          <Grid container spacing={1}>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Button variant="outlined" className={styles.buttonCancel} onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" className={styles.buttonSubmit} onClick={handleSubmit} disabled={!isValid}>
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default CalibrationContent;

import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography
} from '@mui/material';

import { ROUTES } from 'common/constants/routesConstants';
import { CheckPermissions } from 'common/hooks/useCheckPermissions';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType, IClaimSearch } from 'modules/manage-users/operations/models';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import entelectLogo from '../../../../assets/entelect-logo.png';
import entelectText from '../../../../assets/entelect-text.png';
import { signOut } from '../../../auth/operations/actions/authActions';
import styles from './MenuBar.module.scss';

// TO-DO: DISABLED DASHBOARD AND REPORTS UNTIL THEY ARE READY
export interface Page {
  name: string;
  path: string;
  action?: Function;
  scope?: IClaimSearch[];
}

interface IProps {
  userName: string | null;
}

export const MenuBar: React.FC<IProps> = (props: IProps) => {
  const { userName } = props;
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('/candidates');
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pages: Page[] = [
    // {
    //   name: 'Dashboard',
    //   path: ROUTES.Dashboard,
    //   scope: [
    //     {
    //       claimType: ClaimTypes.FEEDBACK,
    //       actionType: ClaimActionType.VIEW_ONLY
    //     }
    //   ]
    // },
    {
      name: 'Candidates',
      path: ROUTES.OpenDayCandidates,
      scope: [
        {
          claimType: ClaimTypes.CANDIDATES,
          actionType: ClaimActionType.VIEW_ONLY
        }
      ]
    },
    {
      name: `${process.env.REACT_APP_SESSION_NAME}`,
      path: ROUTES.OpenDays,
      scope: [
        {
          claimType: ClaimTypes.FEEDBACK,
          actionType: ClaimActionType.VIEW_ONLY
        }
      ]
    },
    {
      name: 'Exports',
      path: ROUTES.Exports,
      scope: [
        {
          claimType: ClaimTypes.EXPORTING,
          actionType: ClaimActionType.VIEW_ONLY
        }
      ]
    }
  ];

  const handleLogOutAction = () => {
    dispatch(signOut());
  };

  const navigateTo = (page: Page) => {
    navigate(page.path);
    handleCloseNavMenu(page);
  };

  const settings: Page[] = [
    {
      name: 'Manage the Application',
      path: '/manage-app',
      scope: [
        {
          claimType: ClaimTypes.MANAGE_APPLICATIONS,
          actionType: ClaimActionType.VIEW_ONLY
        }
      ]
    },
    {
      name: 'Manage Users',
      path: '/manage-users',
      action: (): void => navigateTo({ name: 'Users', path: '/manage-users' }),
      scope: [
        {
          claimType: ClaimTypes.MANAGE_USERS,
          actionType: ClaimActionType.VIEW_ONLY
        }
      ]
    },
    {
      name: 'Logout',
      path: undefined as any,
      action: (): void => handleLogOutAction()
    }
  ];

  useEffect(() => {
    const pathname: string = (location.pathname || '').toLowerCase();
    const index: number = pages.map((page) => page.path.toLowerCase()).findIndex((path) => pathname.startsWith(path));
    if (index != -1) {
      setActiveTab(pages[index].name);
    }
  }, [location]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page: Page) => {
    if (page.action) {
      page.action.call(null);
    }
    setActiveTab(page.name);
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleUserMenuAction = (page: Page) => {
    if (page.action) {
      page.action.call(null);
    }
    handleCloseUserMenu();
  };

  const checkPagePermissions = (page: Page) => (page.scope ? CheckPermissions(page.scope) : true);

  return (
    <AppBar className={styles.menuBar} position={'static'}>
      <Container disableGutters sx={{ maxWidth: '100% !important' }}>
        <Toolbar>
          <Box>
            <Button>
              <img className={styles.brandName} src={entelectText} />
              <Avatar src={entelectLogo} />
            </Button>
          </Box>

          <Box
            className={styles.burgerMenuContainer}
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', sm: 'flex', md: 'none' }
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {pages
                .concat(settings)
                .filter(checkPagePermissions)
                .map((page) => (
                  <MenuItem key={page.name} onClick={() => handleCloseNavMenu(page)}>
                    <Typography component={Link} className={styles.link} textAlign="center" to={page.path}>
                      {page.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
          <Container
            disableGutters
            sx={{
              width: 'auto',
              height: '100%',
              display: { xs: 'none', sm: 'none', md: 'flex' },
              flexGrow: 1,
              marginLeft: '5px'
            }}
          >
            <List component={'nav'} className={styles.navList}>
              {pages
                .filter((page) => (page.scope ? CheckPermissions(page.scope) : true))
                .map((page) => (
                  <ListItemButton
                    component={Link}
                    key={page.name}
                    className={activeTab === page.name ? styles.active : ''}
                    onClick={() => handleCloseNavMenu(page)}
                    to={page.path}
                  >
                    {page.name}
                  </ListItemButton>
                ))}
            </List>
          </Container>

          <Container
            disableGutters
            className={styles.authUser}
            sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
          >
            <Typography paragraph={true} component="span" className={styles.mb0}>
              {userName || ''}
            </Typography>
          </Container>
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: 'none', sm: 'none', md: 'flex' }
            }}
          >
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings
                .filter((page) => (page.scope ? CheckPermissions(page.scope) : true))
                .map((setting) => (
                  <MenuItem
                    key={setting.name}
                    onClick={() => {
                      handleUserMenuAction(setting);
                    }}
                  >
                    <Typography
                      className={styles.link}
                      sx={{ marginBottom: 'auto' }}
                      component={Link}
                      textAlign="center"
                      to={setting.path}
                    >
                      {setting.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default MenuBar;

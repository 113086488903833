import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { takeLatest } from 'redux-saga/effects';
import * as UserOperationTypes from '../../../manage-users/operations/types';

function* searchADUsers(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* searchADUsersSaga() {
  yield takeLatest(UserOperationTypes.SEARCH_AD_USERS, searchADUsers);
}

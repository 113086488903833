import GuardedButton from 'common/components/GuardedButton/GuardedButton';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import styles from './ImportCandidateButton.module.scss';

interface IProps {
  showBulkImport: (show: boolean) => void;
}
const ImportCandidateButton: React.FC<IProps> = (props) => {
  const { showBulkImport } = props;
  return (
    <GuardedButton
      variant={'contained'}
      label={'Import Candidates'}
      className={styles.actionButton}
      sx={{
        width: { xs: '100%', sm: 'auto', marginTop: 10 }
      }}
      onClick={() => showBulkImport(true)}
      errorProps={{ disabled: true }}
      scopes={[
        {
          actionType: ClaimActionType.ADD,
          claimType: ClaimTypes.CANDIDATES
        }
      ]}
    />
  );
};

export default ImportCandidateButton;

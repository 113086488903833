import { Tab, Tabs } from '@mui/material';
import { changeShowBackdrop } from 'modules/app/operations/actions/appActions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Ethnicities } from '../components/Ethnicities';
import FeedbackCategories from '../components/FeedbackCategories/FeedbackCategories';
import InstitutionsQualifications from '../components/InstitutionsQualifications';
import Tags from '../components/Tags/Tags';
import styles from '../styles/ManageApp.module.scss';

interface TabContainer {
  name: string;
  component: JSX.Element;
}

export const ManageAppContainer: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const dispatch = useDispatch();

  const tabs: TabContainer[] = [
    { name: 'Feedback Categories', component: <FeedbackCategories /> },
    { name: 'Feedback Tags', component: <Tags /> },
    { name: 'Universities/Qualifications', component: <InstitutionsQualifications /> },
    { name: 'Ethnicities', component: <Ethnicities /> }
  ];

  const selectTab = (e: React.SyntheticEvent, value: number) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    dispatch(changeShowBackdrop(false));
  }, []);

  return (
    <>
      <Tabs value={selectedTab} onChange={selectTab}>
        {tabs.map((tab, key) => (
          <Tab className={styles.tabCustom} key={`tab_${key}`} label={tab.name} />
        ))}
      </Tabs>
      {tabs[selectedTab].component}
    </>
  );
};

export default ManageAppContainer;

import { Box, Button, Card, CardActions, CardContent, CardHeader, Modal, Typography } from '@mui/material';
import GuardedDelete from 'common/components/GuardedDelete/GuardedDelete';
import { ITag } from 'modules/manage-app/operations/models/tagModel';
import { ClaimActionType } from 'modules/manage-users/operations/enums/claimActionType';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { getTags, removeTag } from '../../operations/actions/tagOperationActions';
import styles from '../../styles/ManageApp.module.scss';

const DeleteTag = (props: { tag: ITag; handleRemoveTag?: (tag: ITag) => void }) => {
  const [open, setOpen] = useState(false);
  const { snackbar } = useSnackbar();
  const { tag } = props;
  const dispatch = useDispatch();
  const { loading, operationSuccess } = useSelector((state: IGlobalState) => {
    return { ...state.tagState, ...state.appState.app };
  });

  useEffect(() => {
    if (!open || operationSuccess == null) {
      return;
    }

    if (operationSuccess) {
      snackbar({
        message: 'Feedback tag was successfully removed',
        variant: 'success',
        action: true
      });
      dispatch(getTags());
      setOpen(false);
      return;
    }

    if (!operationSuccess) {
      const message = 'Feedback tag could not be removed';
      snackbar({ message, variant: 'error', action: true });
    }
  }, [operationSuccess]);

  const deleteTag = async () => {
    props.handleRemoveTag ? props.handleRemoveTag(tag) : dispatch(removeTag(tag.id));
  };

  return (
    <>
      <GuardedDelete
        onClick={() => setOpen(true)}
        disabled={tag.feedbackCount > 0}
        scopes={[
          {
            actionType: ClaimActionType.EDIT,
            claimType: ClaimTypes.MANAGE_APPLICATIONS
          }
        ]}
        renderErrorItem={() => <></>}
        editStyle="outlined"
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box className={styles.modalBox}>
          <Card className={styles.modal}>
            <CardHeader title="Remove Feedback Tag" />
            <CardContent>
              <Typography>Are you sure you want to remove this Tag? This cannot be undone.</Typography>
            </CardContent>
            <CardActions sx={{ float: 'right' }}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                CANCEL
              </Button>
              <Button disabled={loading} color="error" variant="contained" onClick={deleteTag}>
                REMOVE TAG
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteTag;

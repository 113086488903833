import produce from 'immer';
import { ICandidateBulkImportState, candidatesBulkImportStateDefault } from '../models/candidatesState';
import * as Types from '../types/candidatesOperationTypes';

export default function candidatesBulkImportReducer(
  state: ICandidateBulkImportState = candidatesBulkImportStateDefault,
  action: any
): ICandidateBulkImportState {
  switch (action.type) {
    case Types.BULK_IMPORT_CANDIDATES_SUCCEED:
      return produce(state, (draft) => {
        draft.temporaryCandidates = action.payload;
      });

    case Types.BULK_IMPORT_CANDIDATES_FAILED:
      return produce(state, (draft) => {
        draft.errorMessage = action.payload;
      });

    case Types.BULK_IMPORT_CANDIDATES_CLEAR_ERRORS:
      return produce(state, (draft) => {
        draft.errorMessage = '';
      });

    case Types.SAVE_BULK_IMPORT_CANDIDATES_SUCCEED:
      return produce(state, (draft) => {
        draft.saveImportStatus = 'success';
      });

    case Types.SAVE_BULK_IMPORT_CANDIDATES_FAILED:
      return produce(state, (draft) => {
        draft.saveImportStatus = 'failed';
      });

    case Types.SAVE_BULK_IMPORT_CANDIDATES_CLEAR_STATUS:
      return produce(state, (draft) => {
        draft.saveImportStatus = 'none';
      });

    case Types.CLEAR_BULK_IMPORT_CANDIDATES:
      return produce(state, (draft) => {
        draft.temporaryCandidates = [];
      });
    default:
      return state;
  }
}

import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { takeLatest } from 'redux-saga/effects';
import * as OpTypes from '../types/sessionOperationTypes';

function* getUnavailableSessions(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* getUnavailableSessionsSaga() {
  yield takeLatest(OpTypes.GET_UNAVAILABLE_SESSION_DATES, getUnavailableSessions);
}

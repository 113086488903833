import { takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import * as OpTypes from '../types';

function* getFeedbackCategories(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

function* saveFeedbackCategory(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status201Created, HttpStatus.Status200OK]);
}

function* removeFeedbackCategory(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* getFeedbackCategoriesSaga() {
  yield takeLatest(OpTypes.GET_FEEDBACK_CATEGORIES, getFeedbackCategories);
}

export function* saveFeedbackCategorySaga() {
  yield takeLatest(OpTypes.SAVE_FEEDBACK_CATEGORY, saveFeedbackCategory);
}

export function* removeFeedbackCategorySaga() {
  yield takeLatest(OpTypes.REMOVE_FEEDBACK_CATEGORY, removeFeedbackCategory);
}

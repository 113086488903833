import { IPaginatedResponse } from 'common/models/paginatedResponse';
import { ICandidateCalibration } from 'modules/calibration/operations/models';
import { IFeedbackPost, IOpenDay, ITeam } from 'modules/feedback/operations/models/feedbackModel';

// Some of these models are wrong. Need to look into normalizing and reusing some of these.
export interface IQualification {
  id?: number;
  name?: string;
}

export interface IRecruiter {
  recruiterName: string;
  recruiterEmail: string;
  recruiterPhoneNumber: string;
}

export interface IThing {
  id?: number;
  name: string;
}

export interface ICandidateFilters {
  statuses: string[];
  locations: string[];
  genders: string[];
  aics: string[];
  sessionIds: number[];
}

export interface IInstitution {
  id?: number;
  name?: string;
  qualifications: IQualification[];
}

export interface ICandidate {
  id: number;
  name?: string;
  photo?: string;
  comments?: string;
  teamId?: number;
  gender?: string;
  aic?: string;
  aicId?: number;
  emailAddress?: string;
  phoneNumber?: string;
  jobTitle?: string;
  recruiter: IRecruiter | undefined;
  status?: number;
  openDayDate?: string;
  openDayId?: number;
  institutions: IInstitution[];
  location?: string;
  feedback?: IFeedbackPost[];
  locationId?: number;
  willingToRelocate?: boolean;
  relocationId?: number;
  candidateTrackerLink?: string;
  candidateQualifications?: ICandidateQualification[];
  candidateCalibration?: ICandidateCalibration;
  sessions?: IOpenDay[];
}

export interface ICandidateMini {
  id: number;
  name: string | '';
  photo?: string;
  team?: ITeam;
  gender?: string;
  comments?: string;
  candidateTrackerLink?: string;
  emailAddress?: string;
  qualification?: string;
  institutions?: string;
  candidateQualifications?: ICandidateQualification[];
}

export interface ICalibrationCandidate {
  id: number;
  name: string;
  feedback: IFeedbackPost[];
  qualification: string;
  university: string;
}

export type SearchType = 'filter' | 'search' | undefined;
export interface ICandidates {
  totalRecords: number;
  viewType?: 'list' | 'pipeline';
  searchType?: SearchType;
  searchedCandidates: ICandidate[];
  candidates: ICandidate[];
  locations: IThing[];
  genders: IThing[];
  candidateStatuses: IThing[];
  ethnicities: IThing[];
  recuiters: IRecruiter[];
  candidate?: ICandidate;
  operationSuccess?: boolean | null;
}

export const candidatesDefault: ICandidates = {
  totalRecords: 0,
  viewType: 'list',
  searchType: undefined,
  searchedCandidates: [],
  candidates: [],
  locations: [],
  genders: [],
  candidateStatuses: [],
  ethnicities: [],
  recuiters: [],
  operationSuccess: null
};

export interface ICandidatePaginatedResponse extends IPaginatedResponse<ICandidate> {
  loaded?: boolean;
}

export interface ICandidateQualification {
  id: number;
  candidateId: number;
  institutionId: number;
  institutionName: string;
  qualificationId: number;
  qualificationName: string;
}

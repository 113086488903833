import { CloseOutlined } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import styles from './ConfirmDialog.module.scss';

interface IProps {
  title: string;
  message: string;
  isOpen: boolean;
  handleClose: any;
  children?: any;
  negativeButtonClass?: string;
  positiveButtonClass?: string;
  positiveLabel: string;
  negativeLabel: string;
  dialogContentClass?: string;
  dialogActionAreaClass?: string;
  dialogActionButtonClass?: string;
}

export const ConfirmDialog: React.FC<IProps> = (props: IProps) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => props.handleClose(false)}
      sx={{
        '& .MuiDialog-paper': {
          margin: '4px'
        }
      }}
    >
      {props.title ? (
        <DialogTitle className={styles.dialogTitle} component={'h4'}>
          {props.title.toUpperCase()}
          <CloseOutlined className={styles.closeAction} onClick={() => props.handleClose(false)} />
        </DialogTitle>
      ) : (
        <></>
      )}
      <DialogContent className={`${styles.dialogContent} ${props.dialogContentClass || ''}`}>
        {props.message}
      </DialogContent>
      <DialogActions className={`${styles.dialogActions} ${props.dialogActionAreaClass || ''}`}>
        <Button
          className={`${props.negativeButtonClass ? props.negativeButtonClass : styles.defaultNegativeButton} ${
            props.dialogActionButtonClass || ''
          }`}
          onClick={() => props.handleClose(false)}
        >
          {props.negativeLabel}
        </Button>
        <Button
          className={`${props.positiveButtonClass ? props.positiveButtonClass : styles.defaultPositiveButton} ${
            props.dialogActionButtonClass || ''
          }`}
          autoFocus
          onClick={() => props.handleClose(true)}
        >
          {props.positiveLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

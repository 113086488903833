import { ArrowBack, Clear, Groups, KeyboardArrowDown, KeyboardArrowUp, Person, Search } from '@mui/icons-material';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import { Chip, Container, Grid, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import ConfirmDialog from 'common/components/ConfirmDialog/ConfirmDialog';
import { ROUTES } from 'common/constants/routesConstants';
import { useCheckPermissions } from 'common/hooks/useCheckPermissions';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { getCandidates } from 'modules/candidates/operations/actions/candidatesOperationActions';
import {
  addCandidatesToTeam,
  changeAddCandidatesToTeamStatus,
  changeRemoveCandidateFromTeamStatus,
  getFeedbackPosts,
  getTeamFeedbackPosts,
  removeCandidateFromTeam
} from 'modules/feedback/operations/actions/feedbackOperationActions';
import {
  AddCandidatesToTeamStatus,
  RemoveCandidateFromTeamStatus
} from 'modules/feedback/operations/enums/feedbackEnums';
import { getFeedbackCategories } from 'modules/manage-app/operations/actions/feedbackCategoryActions';
import { getTags } from 'modules/manage-app/operations/actions/tagOperationActions';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { isInterviewer } from 'modules/manage-users/operations/functions/util';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import {
  addTeamToSession,
  changeAddTeamToSessionStatus,
  getOpenDaySessionTeams,
  getTeamCaptains
} from 'modules/sessions/operations/actions/sessionsOperationActions';
import { TeamToSessionStatus } from 'modules/sessions/operations/enums/sessionEnum';
import { ISessionTeamRequestDto } from 'modules/sessions/operations/models/sessionsModel';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import useScreenSize from '../../../../common/hooks/useScreenSize';
import { IGlobalState } from '../../../../startup/store/globalState';
import { ICandidate } from '../../../candidates/operations/models/candidatesModel';
import { AddCandidatesToTeamRequest, IOpenDay, IPost, ITeam } from '../../operations/models/feedbackModel';
import AddDialog from '../AddDialog/AddDialog';
import AddTeamDialog from '../AddTeamDialog';
import { FeedbackContent } from '../FeedbackContent/FeedbackContent';
import styles from './Feedback.module.scss';

interface IProps {
  openDay: IOpenDay;
  handleCloseFeedback: () => void;
}

export const Feedback: React.FC<IProps> = (props: IProps) => {
  const {
    candidates,
    feedbackPosts,
    teamFeedbackPosts,
    authUser,
    feedbackOperation,
    addTeamToSessionStatus,
    users,
    sessionTeams
  } = useSelector((state: IGlobalState) => {
    return {
      candidates: state.candidatesState.candidates.candidates,
      feedbackPosts: state.feedbackState.feedback.feedbackPosts,
      teamFeedbackPosts: state.feedbackState.feedback.teamFeedbackPosts,
      authUser: state.authState.authUser,
      feedbackOperation: state.feedbackState.feedbackOperation,
      addTeamToSessionStatus: state.sessionsState.sessionsOperation.addTeamToSessionStatus,
      users: state.userManagementState.userState.users,
      sessionTeams: state.sessionsState.sessions.sessionTeams
    };
  });
  const { addCandidatesToTeamStatus, removeCandidateFromTeamStatus } = feedbackOperation;

  const navigate = useNavigate();

  const queryParams = new URLSearchParams(window.location.search);
  const urlMemberId = queryParams.get('memberId');
  const urlTeamId = queryParams.get('teamId');

  const { openDay, handleCloseFeedback } = props;
  const sessionId: number = openDay?.id;
  const screenSize = useScreenSize();
  const isMobile = screenSize.isMobile || screenSize.isTablet;

  const hasAddManageOpenDayPermission = useCheckPermissions([
    { actionType: ClaimActionType.ADD, claimType: ClaimTypes.MANAGE_OPEN_DAYS }
  ]);

  const hasAddFeedbackCaptainPermission = useCheckPermissions([
    { actionType: ClaimActionType.ADD, claimType: ClaimTypes.FEEDBACK_CAPTAIN }
  ]);

  const hasViewFeedbackPermission = useCheckPermissions([
    { actionType: ClaimActionType.VIEW_ONLY, claimType: ClaimTypes.FEEDBACK }
  ]);

  const hasEditFeedbackPermission = useCheckPermissions([
    { actionType: ClaimActionType.EDIT, claimType: ClaimTypes.FEEDBACK }
  ]);

  const canCreateTeams = hasAddFeedbackCaptainPermission && authUser?.isTeamCaptain;

  const canAddExistingCandidate = (): boolean | undefined => {
    return hasAddManageOpenDayPermission || canCreateTeams;
  };

  const canEditExistingCandidate = (team: ITeam): boolean => {
    return hasAddManageOpenDayPermission || (hasEditFeedbackPermission && authUser?.id === team.teamCaptain.id);
  };

  const [currentTeamId, setCurrentTeamId] = useState<number | null>(null);
  const [selectedTeamIds, setSelectedTeamIds] = useState<number[]>([]);
  const [currentTeam, setCurrentTeam] = useState<ITeam | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentMember, setCurrentMember] = useState<ICandidate | undefined>(undefined);
  const [unallocatedCandidates, setUnallocatedCandidates] = useState<ICandidate[]>([]);
  const [unassignedHeaderSelected, setUnassignedHeaderSelected] = useState<boolean>(false);
  const [teams, setTeams] = useState<ITeam[]>([]);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [removeMember, setRemoveMember] = useState<ICandidate | undefined>(undefined);
  const [showAddTeamCaptainDialog, setShowAddTeamCaptainDialog] = useState<boolean>(false);

  const snackbar = useSnackbar();
  const dispatch = useDispatch();
  useEffect(() => {
    if (sessionTeams?.length) {
      let teams: ITeam[] = JSON.parse(JSON.stringify(sessionTeams));
      teams = teams.sort((a, b) => a.name.localeCompare(b.name));
      teams.forEach((team) => {
        team.candidates.sort((a, b) => a?.name?.localeCompare(b?.name ?? '') ?? 0);
      });
      setTeams(teams);
      dispatch(getTags(sessionId));
    }
  }, [sessionTeams]);

  useEffect(() => {
    if (urlMemberId && candidates?.length) {
      const urlCandidate = candidates.find((f) => f.id == +urlMemberId);

      if (urlCandidate) {
        const teamId = urlCandidate.teamId;

        if (teamId) {
          const urlTeam = sessionTeams.find((f) => f.id == +teamId);
          setCurrentTeam(urlTeam);
        }

        setCurrentMember(urlCandidate);
        setUnassignedHeaderSelected(!urlCandidate.teamId);
        urlCandidate.teamId && setCurrentTeamId(urlCandidate.teamId);
      }
    }
  }, [urlMemberId, candidates]);

  useEffect(() => {
    if (urlTeamId) {
      const urlTeam = sessionTeams.find((f) => f.id == +urlTeamId);
      if (urlTeam) {
        setCurrentTeam(urlTeam);
        setCurrentTeamId(urlTeam.id);
        setUnassignedHeaderSelected(false);
        setCurrentMember(undefined);
      }
    }
  }, [urlTeamId, sessionTeams]);

  useEffect(() => {
    if ([AddCandidatesToTeamStatus.Success, AddCandidatesToTeamStatus.Failure].includes(addCandidatesToTeamStatus)) {
      snackbar.snackbar({
        message: addCandidatesToTeamStatus,
        variant: addCandidatesToTeamStatus === AddCandidatesToTeamStatus.Success ? 'success' : 'error'
      });
      dispatch(changeAddCandidatesToTeamStatus(AddCandidatesToTeamStatus.Idle));
      dispatch(getOpenDaySessionTeams(sessionId));
      dispatch(getCandidates(sessionId, ''));
    }
  }, [addCandidatesToTeamStatus]);

  useEffect(() => {
    if (
      [RemoveCandidateFromTeamStatus.Success, RemoveCandidateFromTeamStatus.Failure].includes(
        removeCandidateFromTeamStatus
      )
    ) {
      snackbar.snackbar({
        message: removeCandidateFromTeamStatus,
        variant: removeCandidateFromTeamStatus === RemoveCandidateFromTeamStatus.Success ? 'success' : 'error'
      });
      dispatch(changeRemoveCandidateFromTeamStatus(RemoveCandidateFromTeamStatus.Idle));
      dispatch(getOpenDaySessionTeams(sessionId));
      setCurrentMember(undefined);
      dispatch(getCandidates(sessionId, ''));
    }
  }, [removeCandidateFromTeamStatus]);

  useEffect(() => {
    dispatch(getFeedbackCategories());
    if (!candidates || candidates.length === 0) {
      dispatch(getCandidates(+openDay?.id, ''));
      dispatch(getTeamCaptains());
    }
    dispatch(getOpenDaySessionTeams(sessionId));
  }, []);

  useEffect(() => {}, [feedbackOperation.creationStatus, feedbackOperation.deletionStatus]);

  useEffect(() => {
    if (candidates && candidates.length > 0) {
      let unallocated = candidates?.filter((c) => c.teamId === 0 && c.openDayId == openDay?.id);
      const sortedUnallocated = [...unallocated].sort((a, b) => a?.name?.localeCompare(b?.name ?? '') ?? 0);
      setUnallocatedCandidates(sortedUnallocated);
    }
  }, [candidates]);

  const handleAddCandidateToTeam = (candidateIds: number[]) => {
    setShowAddDialog(false);
    const req: AddCandidatesToTeamRequest = {
      teamId: currentTeamId,
      candidateIds,
      sessionId: openDay.id
    };
    dispatch(addCandidatesToTeam(req));
  };

  const handleRemoveCandidate = (e: React.MouseEvent<HTMLDivElement>, member: ICandidate) => {
    e.stopPropagation();
    e.preventDefault();
    setRemoveMember(member);
  };

  const handleDialogConfirmationClose = (result: boolean, id: number) => {
    setRemoveMember(undefined);
    if (result) {
      dispatch(removeCandidateFromTeam(`${id}`, openDay.id));
      dispatch(getTeamCaptains());
      dispatch(getCandidates(+openDay?.id, ''));
      if (currentTeam) {
        const team: ITeam = { ...currentTeam };
        team.candidates = team.candidates?.filter((c) => c.id !== id) ?? [];
        selectTeam(team, !isMobile);
      }
    }
  };

  useEffect(() => {
    if ([TeamToSessionStatus.Success, TeamToSessionStatus.Failure].includes(addTeamToSessionStatus)) {
      snackbar.snackbar({
        message: addTeamToSessionStatus,
        variant: addTeamToSessionStatus === TeamToSessionStatus.Success ? 'success' : 'error'
      });
      dispatch(changeAddTeamToSessionStatus(TeamToSessionStatus.Idle));
      dispatch(getOpenDaySessionTeams(sessionId));
    }
  }, [addTeamToSessionStatus]);

  useEffect(() => {
    if (currentMember) {
      dispatch(getFeedbackPosts(String(currentMember.id), openDay?.id as number));
    }
  }, [currentMember]);

  useEffect(() => {
    if (currentTeam) {
      dispatch(getTeamFeedbackPosts(String(currentTeam.id), openDay?.id as number));
    }
  }, [currentTeam]);

  const handleAddNewTeamToSession = (request: ISessionTeamRequestDto) => {
    setShowAddTeamCaptainDialog(false);
    dispatch(addTeamToSession(String(sessionId), request));
    dispatch(getOpenDaySessionTeams(sessionId));
  };

  const isOpenDayCompleted = () => {
    return false;
  };

  const handleCandidateChange = (candidate?: ICandidate) => {
    const memberIdUrl = candidate?.id ? `?memberId=${candidate.id}` : '';
    navigate(`${ROUTES.OpenDays}/${sessionId}${memberIdUrl}`);
    setCurrentMember(candidate);
  };

  const handleTeamChange = (team: ITeam) => {
    navigate(`${ROUTES.OpenDays}/${sessionId}?teamId=${team.id}`);
  };

  const handleCurrentMember = (member: ICandidate) => {
    let fullCandidate = unallocatedCandidates.find((x) => x.id === member.id);
    if (hasViewFeedbackPermission) {
      navigate(`${ROUTES.OpenDays}/${sessionId}?memberId=${fullCandidate?.id}`);
      setCurrentMember(fullCandidate);
      setCurrentTeam(undefined);
    }
  };

  const { state } = useLocation();
  useEffect(() => {
    if (state?.feedbackNavState) {
      setCurrentTeam(state.feedbackNavState.team);
      setCurrentTeamId(state.feedbackNavState.team.id);
    }
  }, [state]);

  useEffect(() => {
    if (state?.feedbackNavState) {
      const candidate = candidates.find((x) => x.id === state.feedbackNavState.candidate.id);
      setCurrentMember(candidate);
    }
    window.history.replaceState({}, 'feedbackNavState');
  }, [currentTeamId]);

  const toggleTeamAccordion = (team: ITeam) => {
    setUnassignedHeaderSelected(false);
    if (selectedTeamIds.includes(team.id) && team.id == currentTeamId) {
      const tempSelectedIds = [...selectedTeamIds];
      tempSelectedIds.splice(tempSelectedIds.indexOf(team.id), 1);
      setSelectedTeamIds(tempSelectedIds);
    }

    if (!selectedTeamIds.includes(team.id)) {
      setSelectedTeamIds([...selectedTeamIds, team.id]);
    }

    selectTeam(team, !isMobile);
  };

  const onCandidateClick = (team: ITeam, fullCandidate: ICandidate) => {
    if (hasViewFeedbackPermission) {
      selectTeam(team);
      handleCandidateChange(fullCandidate);
    }
  };

  const onTeamFeedbackClick = (team: ITeam) => {
    if (hasViewFeedbackPermission) {
      selectTeam(team);
      setCurrentMember(undefined);
      navigate(`${ROUTES.OpenDays}/${sessionId}?teamId=${team.id}`);
    }
  };

  const sortPosts = (a: IPost, b: IPost) => {
    return new Date(a.date) > new Date(b.date) ? -1 : 1;
  };

  const selectTeam = (team: ITeam, isToggleAction = false) => {
    setCurrentTeamId(team.id);
    setCurrentTeam(team);
    isToggleAction && handleCandidateChange(candidates?.find((x) => x.id === team.candidates[0]?.id));
  };

  const toggleUnassignedCandidates = () => {
    navigate(`${ROUTES.OpenDays}/${sessionId}?memberId=${unallocatedCandidates[0]?.id}`);
    setCurrentTeamId(-1);
    setCurrentTeam(undefined);
    setCurrentMember(unallocatedCandidates[0]);
    setSelectedTeamIds([]);
    if (unassignedHeaderSelected) {
      setUnassignedHeaderSelected(false);
    } else {
      setUnassignedHeaderSelected(true);
    }
  };

  const renderUnassignedCandidates = () => {
    const searchMatch = unallocatedCandidates.some((uc) => uc.name?.toLowerCase().includes(searchTerm?.toLowerCase()));
    if (searchTerm.length > 0 && !searchMatch) return <></>;

    const open = unassignedHeaderSelected || searchTerm.length > 0;
    return (
      <>
        <div
          className={`${styles.teamRow} ${unassignedHeaderSelected ? styles.teamRowSelected : styles.teamRow}`}
          onClick={() => toggleUnassignedCandidates()}
        >
          Unassigned ({unallocatedCandidates.length})
          {open ? <KeyboardArrowUp sx={{ float: 'right' }} /> : <KeyboardArrowDown sx={{ float: 'right' }} />}
        </div>
        {open &&
          unallocatedCandidates
            .filter((uc) => uc.name?.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((member: ICandidate) => {
              return (
                <div
                  key={member.id}
                  className={`${styles.teamRowMember} ${member.id === currentMember?.id ? styles.teamRowSelected : ''}`}
                >
                  <span onClick={() => handleCurrentMember(member)}>
                    {member.id === currentMember?.id ? (
                      <span className={styles.teamRowMemberSelectedColor}></span>
                    ) : (
                      <></>
                    )}
                    {member.name}
                  </span>
                </div>
              );
            })}
      </>
    );
  };

  const renderTeam = (team: ITeam) => {
    const teamCaptainIds = [...new Set(teams.map((team) => team.teamCaptain.id) ?? [])];
    const openDayCandidates = openDay.teams.find((f) => f.id === team.id)?.candidates ?? [];
    return (
      <>
        {team.candidates
          .filter((uc) => !searchTerm.length || uc.name?.toLowerCase().includes(searchTerm.toLowerCase()))
          .map((member: ICandidate) => {
            const candidate = candidates.find((x) => x.id === member.id) as ICandidate;
            const feedbackUserIds = openDayCandidates.find((f) => f.id === member.id)?.feedback?.map((f) => f.userId);
            const uniqueUserIds = [...new Set(feedbackUserIds ?? [])].filter((f) => f) as number[];
            const interviewUserIds = uniqueUserIds
              .map((id) => users.find((u) => u.id === id))
              .filter((u) => u && isInterviewer(u));
            const feedbackProgress = `${interviewUserIds.length} of ${teamCaptainIds.length}`;
            const borderColor = interviewUserIds.length ? green[600] : undefined;
            return (
              <div
                key={member.id}
                className={`${styles.teamRowMember} ${member.id === currentMember?.id ? styles.teamRowSelected : ''}`}
              >
                <span onClick={() => onCandidateClick(team, candidate)}>
                  {
                    <Chip
                      color={interviewUserIds.length ? 'success' : undefined}
                      className="mr-05"
                      size="small"
                      variant={interviewUserIds.length > 1 ? 'filled' : 'outlined'}
                      sx={{ borderWidth: '2px', borderColor, color: interviewUserIds.length < 2 ? 'black' : undefined }}
                      label={feedbackProgress}
                    />
                  }
                  <span>{member.name}</span>
                </span>
                <div
                  className={`${styles.deleteButtonContainer}`}
                  onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                    if (canEditExistingCandidate(team)) {
                      handleRemoveCandidate(event, member);
                    }
                  }}
                  hidden={!canEditExistingCandidate(team)}
                >
                  {isOpenDayCompleted() ? <></> : <RemoveCircleOutline className={styles.icons} />}
                </div>
                <ConfirmDialog
                  message={`Are you sure you want to unassign "${removeMember?.name}" from Team "${team.name}" ?`}
                  title="Unassign Candidate"
                  positiveLabel="Yes,Unassign"
                  negativeLabel="No,Cancel"
                  handleClose={(val: boolean) => handleDialogConfirmationClose(val, removeMember?.id ?? 0)}
                  isOpen={removeMember?.id == member.id}
                ></ConfirmDialog>
              </div>
            );
          })}
        {team.candidates.length > 0 && (
          <Stack
            onClick={() => onTeamFeedbackClick(team)}
            direction="row"
            alignItems="center"
            gap={1}
            className={`${styles.teamRowMemberNew} ${styles.teamRowMember}`}
          >
            <Groups />
            <Typography className={styles.successText} variant="body1">
              Give Team Feedback
            </Typography>
          </Stack>
        )}
        {!isOpenDayCompleted() && (
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            className={canAddExistingCandidate() ? `${styles.teamRowMemberNew}` : `${styles.teamRowMemberDisabled}`}
            onClick={() => {
              if (canAddExistingCandidate()) {
                selectTeam(team, !isMobile);
                setShowAddDialog(true);
              }
            }}
          >
            <Person />
            <Typography className={styles.successText} variant="body1">
              Add Candidate
            </Typography>
          </Stack>
        )}
      </>
    );
  };

  const renderContent = () => {
    const currentPosts = urlTeamId ? teamFeedbackPosts : urlMemberId ? feedbackPosts : [];
    const teamCandidates = unassignedHeaderSelected
      ? unallocatedCandidates
      : candidates.filter((c) => c.teamId === currentTeamId);

    return (
      <FeedbackContent
        candidate={currentMember}
        candidates={teamCandidates}
        handleCandidateChange={handleCandidateChange}
        handleTeamChange={handleTeamChange}
        team={currentTeam}
        posts={[...(currentPosts ?? [])].sort((a, b) => sortPosts(a, b))}
        goBack={() => navigate(`${ROUTES.OpenDays}/${sessionId}`)}
      />
    );
  };

  const renderSidebar = () => {
    return (
      <div className={isMobile ? styles.sidebarMobile : styles.sidebar}>
        <div className={styles.sidebarHeader} onClick={handleCloseFeedback}>
          <div className={styles.sidebarGoBackLink}>
            <ArrowBack />
          </div>
          <div className={styles.sidebarHeaderContent}>
            <b>{openDay.name}</b>
            <br />
            {moment(openDay.date).format('dddd D MMMM yyyy')}
          </div>
        </div>

        <div className={styles.sidebarContent}>
          <Container className={styles.searchContainer}>
            <OutlinedInput
              sx={{ backgroundColor: 'white', padding: '0px' }}
              fullWidth={true}
              type="text"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              endAdornment={
                <InputAdornment position="end" sx={{ paddingRight: '1rem' }}>
                  {searchTerm == '' ? <Search /> : <Clear onClick={() => setSearchTerm('')}></Clear>}
                </InputAdornment>
              }
              placeholder="Search for Candidate name"
            />
          </Container>

          <div className={styles.memberList}>
            {teams
              ?.filter(
                (x) =>
                  x.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  x.candidates.some((y) => y.name?.toLowerCase().includes(searchTerm.toLowerCase()))
              )
              .map((team) => {
                const className = `${styles.teamRow} ${
                  team.id === currentTeamId ? styles.teamRowSelected : styles.teamRow
                }`;
                return (
                  <div key={`key_` + team.id} className={(team.id === currentTeamId && styles.teamSelected) || ''}>
                    <div className={className} onClick={() => toggleTeamAccordion(team)}>
                      {team.id === currentTeamId ? <span className={styles.teamRowSelectedColor}></span> : <></>}
                      {team.name} {(team.candidates?.length && `(${team.candidates.length})`) || ''}
                      {selectedTeamIds.includes(team.id) ? (
                        <KeyboardArrowUp sx={{ float: 'right' }} />
                      ) : (
                        <KeyboardArrowDown sx={{ float: 'right' }} />
                      )}
                    </div>
                    {searchTerm?.length || selectedTeamIds.includes(team.id) ? renderTeam(team) : null}
                  </div>
                );
              })}
            <br />
            {renderUnassignedCandidates()}
            {!isOpenDayCompleted() && (
              <Stack
                direction="row"
                alignItems="center"
                gap={1}
                className={canCreateTeams ? `${styles.teamRowNew}` : `${styles.teamRowDisabled}`}
                onClick={() => {
                  if (canCreateTeams) setShowAddTeamCaptainDialog(true);
                }}
              >
                <Groups />
                <span className={styles.successText}>Add Team</span>
              </Stack>
            )}
          </div>
        </div>
        {showAddDialog && teams.length > 0 && (
          <AddDialog
            handleClose={() => {
              setShowAddDialog(false);
            }}
            handleSubmit={handleAddCandidateToTeam}
            candidates={candidates.filter((c) => unallocatedCandidates.map((uc) => uc.id).includes(c.id))}
          />
        )}
        {showAddTeamCaptainDialog && (
          <AddTeamDialog
            handleClose={() => {
              setShowAddTeamCaptainDialog(false);
            }}
            handleSubmit={handleAddNewTeamToSession}
          />
        )}
      </div>
    );
  };

  const showSideBar = (isMobile && !urlMemberId && !urlTeamId) || !isMobile;
  const showContent = urlMemberId ?? urlTeamId;

  return (
    <Grid container spacing={0}>
      {showSideBar && (
        <Grid item xs={12} md={4}>
          {renderSidebar()}
        </Grid>
      )}

      {showContent && (
        <Grid item xs={12} md={isMobile ? 12 : 8}>
          <div className={`${styles.content} ${!isMobile ? styles.boxShadow : ''}`}>{renderContent()}</div>
        </Grid>
      )}
    </Grid>
  );
};

export default Feedback;

export enum CandidateCreationStatus {
  Idle = 'Idle',
  Adding = 'Adding candidate',
  Success = 'Successfully added candidate',
  Failure = 'Error adding candidate'
}

export enum CandidateUpdatingStatus {
  Idle = 'Idle',
  Updating = 'Updating candidate',
  Success = 'Successfully updated candidate',
  Failure = 'Error updating candidate'
}

export enum CandidateStatus {
  Screening = 'SCREENING',
  OfferSent = 'YES',
  FutureConsideration = 'MAYBE',
  Declined = 'NO'
}

export interface ICandidateStatusMetadata {
  displayText: string;
  displayBackgroundColor: string;
  displayBorder: string;
}

export enum Gender {
  Female = 'Female',
  Male = 'Male',
  Other = 'Other'
}

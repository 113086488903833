import { Box } from '@mui/material';
import React from 'react';
import ExportList from '../components/ExportList/ExportList';

export const ExportContainer: React.FC = () => {
  return (
    <Box>
      <ExportList />
    </Box>
  );
};

import { Box, Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { SelectOptionItem } from 'common/models/filterModels';
import { useEffect, useState } from 'react';
import styles from './FilterButton.module.scss';

interface IProps {
  label: string;
  values: string[];
  items: SelectOptionItem[];
  onChanges: (event: SelectChangeEvent<string[]>) => void;
  onDelete?: (event: string) => void;
}

export const MultiChipSelect = (props: IProps) => {
  const [values, setValues] = useState<string[]>(props.values);

  useEffect(() => {
    setValues(props.values);
  }, [props.values]);

  const getSelectMenuItems = (items: SelectOptionItem[] = []) => {
    return items.map((item, i) => {
      const name = typeof item === 'string' ? item : item.label;
      const value = typeof item === 'string' ? item : item.value;
      return (
        <MenuItem key={'_option_' + value} value={value}>
          {name}
        </MenuItem>
      );
    });
  };

  const SelectMenuItems = getSelectMenuItems(props.items);

  const menuItemsObj = props.items.reduce(
    (acc, item) => {
      if (typeof item === 'string') {
        acc[item] = item;
      } else {
        acc[item.value] = item.label;
      }
      return acc;
    },
    {} as { [key: string]: string }
  );

  const handleChange = ({ target }: SelectChangeEvent<typeof values>) => {
    const { value } = target;
    setValues(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl>
      <InputLabel>{props.label}</InputLabel>
      <Select
        multiple
        value={values}
        onClose={() => props.onChanges({ target: { value: values } } as any)}
        onChange={handleChange}
        label={props.label}
        renderValue={(selected: string[]) => (
          <Box className={styles.filterMultiSelect}>
            {selected.map((value, i) => (
              <Chip
                onMouseDown={(e) => e.stopPropagation()}
                onDelete={() => props.onDelete && props.onDelete(value)}
                key={i}
                label={menuItemsObj[value]}
                className={styles.filterChip}
              />
            ))}
          </Box>
        )}
      >
        {SelectMenuItems}
      </Select>
    </FormControl>
  );
};

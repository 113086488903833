export enum SessionCreationStatus {
  Idle = 'Idle',
  Adding = 'Adding session',
  Success = 'Successfully added session',
  Failure = 'Error adding session'
}

export enum SessionDeletionStatus {
  Idle = 'Idle',
  Deleting = 'Deleting session',
  Success = 'Successfully deleted session',
  Failure = 'Error deleting session'
}

export enum SessionUpdateStatus {
  Idle = 'Idle',
  Updating = 'Updating session',
  Success = 'Successfully updated session',
  Failure = 'Error updating session'
}

export enum TeamToSessionStatus {
  Idle = 'Idle',
  Adding = 'Adding team to session',
  Success = 'Successfully added team to session',
  Failure = 'Error adding team to session'
}

import { Box } from '@mui/material';
import { useSnackbar } from 'common/hooks/useSnackbar';
import {
  changeSessionDeleteStatus,
  getSessionByIdSucceed
} from 'modules/sessions/operations/actions/sessionsOperationActions';
import { SessionDeletionStatus } from 'modules/sessions/operations/enums/sessionEnum';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import { getTags } from '../../manage-app/operations/actions/tagOperationActions';
import FilterOpenDayList from '../components/OpenDayList/FilterOpenDayList';
import OpenDayList from '../components/OpenDayList/OpenDayList';
import styles from '../components/OpenDayList/OpenDayList.module.scss';

export const OpenDaysContainer: React.FC = () => {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const { deletionStatus } = useSelector((state: IGlobalState) => state.sessionsState.sessionsOperation);
  const [pageNo, setPageNo] = useState(0);

  useEffect(() => {
    if ([SessionDeletionStatus.Success, SessionDeletionStatus.Failure].includes(deletionStatus)) {
      if (deletionStatus === SessionDeletionStatus.Success) {
        snackbar.snackbar({
          message: deletionStatus,
          duration: 3000,
          variant: 'success'
        });
      }
      dispatch(changeSessionDeleteStatus(SessionDeletionStatus.Idle));
    }
  }, [deletionStatus]);
  useEffect(() => {
    dispatch(getSessionByIdSucceed());
    dispatch(getTags());
  }, []);

  return (
    <Box>
      <div className={styles.wrapper}>
        <FilterOpenDayList pageNo={pageNo} />
        <OpenDayList setPageNo={setPageNo} />
      </div>
    </Box>
  );
};

export default OpenDaysContainer;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { ROUTES } from 'common/constants/routesConstants';
import { changeShowBackdrop } from 'modules/app/operations/actions/appActions';
import { getSessionById } from 'modules/sessions/operations/actions/sessionsOperationActions';
import { useNavigate, useParams } from 'react-router-dom';
import { IGlobalState } from '../../../startup/store/globalState';
import CandidateGridView from '../components/CandidateGridView/CandidateGrid';
import CandidateSearchBar from '../components/CandidateSearchBar/CandidateSearchBar';
import CandidateTableView from '../components/CandidateTableView';
import { ICandidateFilters } from '../operations/models/candidatesModel';
import styles from './OpenDayCandidatesContainer.module.scss';

const DEFAULT_FILTER: ICandidateFilters = {
  aics: [],
  genders: [],
  locations: [],
  sessionIds: [],
  statuses: []
};

const OpenDayCandidatesContainer: React.FC = () => {
  const navigate = useNavigate();
  const id = Number(useParams().sessionId);
  const dispatch = useDispatch();
  const [tableView, setTableView] = useState<boolean>(false);

  const { openDay, searchedCandidates: candidates } = useSelector((state: IGlobalState) => {
    return {
      ...state.sessionsState.sessionsOperation,
      ...state.candidatesState.candidates
    };
  });

  useEffect(() => {
    dispatch(changeShowBackdrop(false));
  }, []);

  useEffect(() => {
    id && dispatch(getSessionById(`${id}`));
  }, [id]);

  if (!openDay || openDay?.id !== id) return <></>;

  return (
    <div>
      <h2 className="textCenter mt-0 mb-05">
        <IconButton onClick={() => navigate(ROUTES.OpenDayCandidates)} className={styles.backButton}>
          <ArrowBackIcon className={styles.backArrow} />
        </IconButton>
        <b>{openDay.name}</b>
      </h2>
      <CandidateSearchBar
        showBulkImport={() => navigate(`${ROUTES.OpenDayCandidates}/${id}/import`)}
        clearSearch={false}
        clearFilters={false}
        filters={{ ...DEFAULT_FILTER, sessionIds: [id] }}
        setTableView={(val) => setTableView(val)}
      />
      {tableView ? (
        <CandidateTableView candidates={candidates ?? []} />
      ) : (
        <CandidateGridView candidates={candidates ?? []} />
      )}
    </div>
  );
};

export default OpenDayCandidatesContainer;

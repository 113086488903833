import { Card, CardContent, Chip } from '@mui/material';
import ConfirmDialog from 'common/components/ConfirmDialog/ConfirmDialog';
import { BootstrapTooltip } from 'common/components/OverflowTooltip/OverflowTooltip';
import useScreenSize from 'common/hooks/useScreenSize';
import { deleteUser } from 'modules/manage-users/operations/actions/userActions';
import { ClaimActionType, IClaimModel, IUserModel } from 'modules/manage-users/operations/models';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import GuardedDelete from '../../../../common/components/GuardedDelete/GuardedDelete';
import { ClaimTypes } from '../../operations/enums/claimTypes';
import UserDialog from '../UserDialog/UserDialog';
import styles from './UserCard.module.scss';

interface IProps {
  user: IUserModel;
}

const UserCard: React.FC<IProps> = ({ user }) => {
  const { isMobile } = useScreenSize();
  const [isDeleteComfirmOpen, setDeleteComfirmOpen] = useState(false);
  const dispatch = useDispatch();

  const permissionTextBuilder = (action: string, type: string) => {
    const capitalized = action[0] + action.substring(1).toLowerCase();
    return action.toLocaleUpperCase() === 'VIEW_ONLY' ? `View ${type}` : `${capitalized} ${type}`;
  };

  const getClaimsChips = (userClaims: IClaimModel[]) => {
    const additionalClaims = [...(userClaims.map((x) => permissionTextBuilder(x.actionType, x.claimType)) || [])];
    const displayChips = additionalClaims.length ? additionalClaims.splice(0, 2) : [];

    const claims = (
      <>
        {additionalClaims.length ? (
          <label>
            <BootstrapTooltip
              title={`${additionalClaims.map((claim) => ` ${claim}`)}.`}
              arrow
              placement={'top-end'}
              enterTouchDelay={0}
            >
              <span className={styles.InlineflexCenter}>{`+${additionalClaims.length}`}</span>
            </BootstrapTooltip>
          </label>
        ) : (
          ''
        )}
      </>
    );
    return (
      <>
        {displayChips.map((claim) => (
          <Chip label={`${claim}`} variant="outlined" size="small" key={claim} className={styles.chip} />
        ))}
        <b>{claims}</b>
      </>
    );
  };

  const handleConfirmDeleteClose = (canDeleteUser: boolean) => {
    if (canDeleteUser && user.id) {
      dispatch(deleteUser(user.id));
    }
    setDeleteComfirmOpen(false);
  };

  const renderMobile = () => {
    return (
      <div className={`${styles.cardMobile}`}>
        <div className={styles.flexRow}>
          <div className={`${styles.flexTitleRow}`}>{user.displayName}</div>
          <div>
            <UserDialog user={user} />
          </div>
          <div>
            <GuardedDelete
              onClick={() => setDeleteComfirmOpen(true)}
              disabled={!user.active}
              scopes={[
                {
                  actionType: ClaimActionType.EDIT,
                  claimType: ClaimTypes.MANAGE_USERS
                }
              ]}
              renderErrorItem={() => <></>}
              editStyle="outlined"
            />

            <ConfirmDialog
              message={`Are you sure you want to delete this user from Jedi-Finders? This cannot be undone.`}
              title="Delete User"
              isOpen={isDeleteComfirmOpen}
              positiveLabel="Yes, Delete"
              negativeLabel="No, Cancel"
              handleClose={handleConfirmDeleteClose}
            ></ConfirmDialog>
          </div>
        </div>
        <div className={styles.flexRow}>
          <span>{user.email}</span>
        </div>
        <div className={styles.flexRow}>
          <span>
            {`Role: `} <b>{user.userRole?.name}</b>
          </span>
        </div>
        <div className={styles.flexRow}>
          {user?.userClaims.length ? (
            <div className={styles.mobileChips}>
              <span>Additional role permissions: </span>
              <span>{getClaimsChips(user.userClaims.map((claim) => claim.claim))}</span>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={`${styles.flexRow} ${styles.statusRow}`}>
          <span> Status: </span>
          <span className={`${styles.status} ${user.active ? styles.statusActive : styles.statusInActive}`}>
            {user.active ? ' Active' : ' Inactive'}
          </span>
        </div>
      </div>
    );
  };

  const renderDesktop = () => {
    return (
      <>
        <Card className={`${styles.userCard}`}>
          <CardContent className={styles.cardContent}>
            <span className="flexBasis15">
              <b>{user.displayName}</b>
            </span>
            <span className={styles.email}>{user.email}</span>
            <span className="flexBasis15">{user.userRole?.name}</span>
            <span className="flexBasis25">{getClaimsChips(user.userClaims.map((claim) => claim.claim))}</span>
            <span
              className={`flexBasis15 ${styles.status} ${user.active ? styles.statusActive : styles.statusInActive}`}
            >
              {user.active ? 'Active' : 'Inactive'}
            </span>
            <span className={`${styles.userLink} flexBasis10`}>
              <UserDialog user={user} />
              <GuardedDelete
                onClick={() => setDeleteComfirmOpen(true)}
                disabled={!user.active}
                scopes={[
                  {
                    actionType: ClaimActionType.EDIT,
                    claimType: ClaimTypes.MANAGE_USERS
                  }
                ]}
                renderErrorItem={() => <></>}
                editStyle="outlined"
              />

              <ConfirmDialog
                message={`Are you sure you want to delete the user "${user.displayName}" from the list? This cannot be undone.`}
                title="Delete User"
                isOpen={isDeleteComfirmOpen}
                positiveLabel="Yes, Delete"
                negativeLabel="No, Cancel"
                handleClose={handleConfirmDeleteClose}
              ></ConfirmDialog>
            </span>
          </CardContent>
        </Card>
      </>
    );
  };

  return isMobile ? renderMobile() : renderDesktop();
};

export default UserCard;

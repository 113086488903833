import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { changeSessionCreateStatus, getSessionByIdSucceed } from '../actions/sessionsOperationActions';
import { SessionCreationStatus } from '../enums/sessionEnum';
import * as OpTypes from '../types/sessionOperationTypes';

function* createSession(action: any): any {
  yield put(changeSessionCreateStatus(SessionCreationStatus.Adding));
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status201Created]);
  const status = success ? SessionCreationStatus.Success : SessionCreationStatus.Failure;
  if (status === SessionCreationStatus.Success && response?.data) {
    yield put(getSessionByIdSucceed(response.data));
  }
  yield put(changeSessionCreateStatus(status));
}

export function* createSessionSaga() {
  yield takeLatest(OpTypes.CREATE_SESSION, createSession);
}

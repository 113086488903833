import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { ICalibrationCandidate } from 'modules/candidates/operations/models/candidatesModel';
import { ITeam } from 'modules/feedback/operations/models/feedbackModel';
import { put, select, takeLatest } from 'redux-saga/effects';
import { retrievedOpenDayCalibrations } from '../actions';
import { mergeCalibrationsAndFeedback } from '../helpers';
import { ICandidateCalibration } from '../models';
import { getTodaysOpenDayCandidates, getTodaysOpenDayTeams } from '../selectors';
import * as types from '../types';

function* getOpenDayCalibration(action: any): any {
  let allSessionTeams: ITeam[] = yield select(getTodaysOpenDayTeams);
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  if (success && response && allSessionTeams) {
    const resp = <ICandidateCalibration[]>response.data;
    const candidates: ICalibrationCandidate[] = yield select(getTodaysOpenDayCandidates);
    if (resp?.length > 0) {
      const updatedCalibrationsWithFeedback: ICandidateCalibration[] = mergeCalibrationsAndFeedback(
        resp,
        candidates,
        allSessionTeams
      );
      yield put(retrievedOpenDayCalibrations(updatedCalibrationsWithFeedback));
    }
  }
}

export function* getOpenDayCalibrationSaga() {
  yield takeLatest(types.GET_OPEN_DAY_CALIBRATIONS, getOpenDayCalibration);
}

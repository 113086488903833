import { IPaginatedResponse } from 'common/models/paginatedResponse';
import { ISessionTeamCaptain } from 'modules/sessions/operations/models/sessionsModel';
import { ITag } from '../../../../modules/manage-app/operations/models/tagModel';
import { ICandidate } from '../../../candidates/operations/models/candidatesModel';

export interface ITeam {
  id: number;
  name: string;
  candidates: ICandidate[];
  feedback?: ITeamFeedbackPost[];
  teamCaptain: ISessionTeamCaptain;
}

export interface ILocation {
  id: number;
  name: string;
  description: string;
}

export interface IOpenDay {
  id: number;
  name: string;
  date: string;
  startTime: string;
  endTime: string;
  location: ILocation;
  status: string | null;
  teams: ITeam[];
  tags: ITag[];
  unassignedCandidates: ICandidate[];
  assignedAttendees?: string[];
  time?: string;
  noOfCandidates?: number;
}

export interface IFeedbackTag {
  tagId: number;
  rating: string;
  tag?: ITag;
  id: number;
}

export interface ICategory {
  id: number;
  name?: string;
}

export interface IPost {
  id?: string;
  userId?: number;
  userImage?: string;
  note?: string;
  date: string;
  tags?: IFeedbackTag[];
  category?: ICategory;
  addedBy?: string;
  imgUrl?: string;
  canEdit?: boolean;
  canRemove?: boolean;
  sessionId: number;
}

export interface IFeedbackPost extends IPost {
  candidateId: number;
}

export interface ITeamFeedbackPost extends IPost {
  teamId: number;
}

export default interface IFeedback {
  feedback?: string | null;
  openDays: IPaginatedResponse<IOpenDay>;
  completedOpenDays: IPaginatedResponse<IOpenDay>;
  feedbackPosts: IFeedbackPost[];
  teamFeedbackPosts: ITeamFeedbackPost[];
  selectedTab: number;
}

export const feedbackDefault: IFeedback = {
  feedback: null,
  openDays: {
    data: [],
    pageNo: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    totalRecordsOnPage: 0
  },
  completedOpenDays: {
    data: [],
    pageNo: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    totalRecordsOnPage: 0
  },
  feedbackPosts: [],
  teamFeedbackPosts: [],
  selectedTab: 0
};

export interface OpenDayFilters {
  locations: string[];
  genders: Date[];
  attendees: string[];
  statuses: string[];
}

export interface AddCandidatesToTeamRequest {
  teamId: number | null;
  candidateIds: number[];
  sessionId: number;
}

import { AxiosError } from 'axios';

export interface IHttpAction {
  [name: string]: boolean;
}

export const httpActionDefault: IHttpAction = {};

export default interface IApp {
  httpAction: IHttpAction;
  error: AxiosError | string | null;
  loading: boolean;
  showBackdrop: boolean;
}

export const appDefault: IApp = {
  httpAction: httpActionDefault,
  error: null,
  loading: false,
  showBackdrop: true
};

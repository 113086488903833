import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { HttpMethod } from '../../../../common/enums/appEnums';
import * as Types from '../types';

export const getRoles = (): IActionRequest<undefined> => {
  return {
    type: Types.FETCH_ROLES,
    httpMethod: HttpMethod.Get,
    url: `/user_role`,
    onSuccess: fetchedRoles
  };
};

export const fetchedRoles = (payload: any): IActionType<any> => ({
  type: Types.FETCHED_ROLES,
  payload
});

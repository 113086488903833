import EditIcon from '@mui/icons-material/Edit';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, IconButtonProps } from '@mui/material';
import { IClaimSearch } from 'modules/manage-users/operations/models';
import React, { ReactElement } from 'react';
import { GuardedView } from '../GuardedView/GuardedView';

interface IProps extends IconButtonProps {
  iconProps?: {};
  editStyle: string;
  scopes: IClaimSearch[];
  renderErrorItem: (props?: any) => ReactElement;
}

const GuardedEdit: React.FC<IProps> = (props) => {
  const { iconProps, editStyle, scopes, renderErrorItem, ...iconButtonProps } = props;
  return (
    <GuardedView scopes={scopes} renderErrorItem={renderErrorItem}>
      <IconButton {...iconButtonProps}>
        {editStyle === 'filled' ? <EditIcon {...iconProps} /> : <EditOutlinedIcon {...iconProps} />}
      </IconButton>
    </GuardedView>
  );
};

export default GuardedEdit;

import { candidatesStateDefault, ICandidatesState } from '../models/candidatesState';
import candidatesBulkImportReducer from './candidatesBulkImportReducer';
import candidatesOperationReducer from './candidatesOperationReducer';
import candidatesReducer from './candidatesReducer';

export default function candidatesStateReducer(state = candidatesStateDefault, action: any): ICandidatesState {
  return {
    candidates: candidatesReducer(state.candidates, action),
    candidatesOperation: candidatesOperationReducer(state.candidatesOperation, action),
    candidatesBulkImport: candidatesBulkImportReducer(state.candidatesBulkImport, action)
  };
}

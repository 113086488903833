import { History } from 'history';
import { stateMiddleware } from './stateMiddleware';

const configureMiddlewares = (_history: History) => {
  const middlewares = [];

  if (process.env.NODE_ENV !== `production`) {
    const { createLogger } = require(`redux-logger`);

    middlewares.push(createLogger({ collapsed: true }));
  }

  middlewares.push(stateMiddleware);

  return middlewares;
};

export default configureMiddlewares;

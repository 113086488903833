import { DataUpdateKey } from 'common/enums/appEnums';
import { updateItemByKeyInArray } from 'common/helpers/dataHelpers';
import produce from 'immer';
import { ICalibrationState, ICandidateCalibration } from '../models';
import { defaultCalibrationState } from '../models/defaultStates';
import * as Types from '../types';

export default function calibrationReducer(
  state: ICalibrationState = defaultCalibrationState,
  action: any
): ICalibrationState {
  switch (action.type) {
    case Types.CREATE_OPEN_DAY_CALIBRATIONS_SUCCEEDED: {
      return produce(state, (draft) => {
        draft.createCalibrationsSuccess = true;
      });
    }
    case Types.CREATE_OPEN_DAY_CALIBRATIONS_FAILED: {
      return produce(state, (draft) => {
        draft.createCalibrationsSuccess = false;
      });
    }
    case Types.GET_OPEN_DAY_CALIBRATIONS_SUCCEEDED:
    case Types.OPEN_DAY_CALIBRATIONS_RETRIEVED: {
      return produce(state, (draft) => {
        draft.openDayCalibration.calibrations = action.payload;
      });
    }
    case Types.GET_OPEN_DAY_CALIBRATIONS: {
      return produce(state, (draft) => {
        draft.openDayCalibration.calibrations = [];
      });
    }
    case Types.GET_CANDIDATE_CALIBRATIONS_SUCCEEDED: {
      return produce(state, (draft) => {
        (action.payload ?? []).forEach(
          (calibration: ICandidateCalibration) =>
            (draft.openDayCalibration.calibrationObj[calibration.id] = calibration)
        );
        draft.openDayCalibration.candidateCalibrations = Object.values(draft.openDayCalibration.calibrationObj);
      });
    }
    case Types.SAVE_CALIBRATION_OFFER: {
      return produce(state, (draft) => {
        draft.openDayCalibration.candidateId = action.payload.candidateId;
      });
    }
    case Types.UPDATE_CALIBRATION_OFFER: {
      return {
        ...state,
        openDayCalibration: {
          ...state.openDayCalibration,
          calibrations: updateItemByKeyInArray(state.openDayCalibration.calibrations, action.payload, DataUpdateKey.id)
        }
      };
    }
    case Types.UPDATE_OPERATION_CHANGED: {
      return produce(state, (draft) => {
        draft.updateOperationSuccess = action.payload;
      });
    }
    default:
      return state;
  }
}

import { Close } from '@mui/icons-material';
import { Box, Button, FormControl, Grid, MenuItem, Modal, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useScreenSize from '../../../../common/hooks/useScreenSize';
import { IGlobalState } from '../../../../startup/store/globalState';
import { generateReport } from '../../operations/actions';
import { IReportGenerateRequest, getDefaultReportGenerateRequest } from '../../operations/models';
import styles from './GenerateExportModal.module.scss';

interface IProps {
  handleClose: () => void;
}

const GenerateExportModal: React.FC<IProps> = (props: IProps) => {
  const { reportTypes, summaries } = useSelector((state: IGlobalState) => {
    return {
      reportTypes: state.reportState.reportTypes,
      ...state.sessionsState.sessions
    };
  });

  const { handleClose } = props;
  const { isMobile } = useScreenSize();
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [reportGenerateRequest, setReportGenerateRequest] = useState<IReportGenerateRequest>({
    ...getDefaultReportGenerateRequest()
  });

  // TODO: replace hard-coded report control logic with
  // report parameters from report_type configuration,
  // after several report are implemnted
  const isOpenDayReport = reportGenerateRequest.reportTypeCode === 'OPEN_DAY';

  const handleFormChange = (prop: string, val: any) => {
    const _state = { ...reportGenerateRequest, [prop as keyof IReportGenerateRequest]: val };
    setReportGenerateRequest(_state);
  };

  useEffect(() => {
    let currentIsValid = reportGenerateRequest?.reportTypeCode.length > 0;
    if (isOpenDayReport) {
      currentIsValid = currentIsValid && reportGenerateRequest.sessionId > 0;
    }
    setIsValid(currentIsValid);
  }, [reportGenerateRequest?.reportTypeCode, reportGenerateRequest?.sessionId]);

  const handleSubmit = () => {
    if (!isValid) {
      return;
    }

    dispatch(generateReport(reportGenerateRequest));
    handleClose();
  };

  const renderInputForm = () => {
    return (
      <Grid item container xs={12} sm={12} md={12} spacing={1}>
        <Grid item container xs={12} rowSpacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              id="report-type-select-helper"
              name="reportTypeId"
              label="Export Type"
              sx={{ width: '99%' }}
              placeholder="Select Export Type"
              value={reportGenerateRequest?.reportTypeCode}
              onChange={(e) => handleFormChange('reportTypeCode', e.target.value)}
            >
              {reportTypes.map((type: any) => (
                <MenuItem value={type.code} key={type.code}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {isOpenDayReport && renderOpenDayControls()}
          {!isOpenDayReport && renderDefaultControls()}
        </Grid>
      </Grid>
    );
  };

  const renderDefaultControls = () => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date From"
                value={reportGenerateRequest?.startDate}
                maxDate={dayjs().startOf('d')}
                onChange={(e: any) => handleFormChange('startDate', e)}
              />
            </LocalizationProvider>
            {!reportGenerateRequest.startDate && <p className={styles.error}>*Session date is required</p>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date To"
                value={reportGenerateRequest?.endDate}
                maxDate={dayjs().startOf('d')}
                onChange={(e: any) => handleFormChange('endDate', e)}
              />
            </LocalizationProvider>
            {!reportGenerateRequest.endDate && <p className={styles.error}>*Session date is required</p>}
          </FormControl>
        </Grid>
      </>
    );
  };

  const renderOpenDayControls = () => {
    return (
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth>
          <TextField
            select
            id="sessionId"
            name="sessionId"
            label={process.env.REACT_APP_SESSION_NAME}
            sx={{ width: '99%' }}
            placeholder={`Select a ${process.env.REACT_APP_SESSION_NAME}`}
            value={reportGenerateRequest?.sessionId}
            onChange={(e: any) => handleFormChange('sessionId', e.target.value)}
          >
            <MenuItem value={0} disabled>
              Select {process.env.REACT_APP_SESSION_NAME}
            </MenuItem>
            {summaries.data.map((openDay) => (
              <MenuItem key={`option_${openDay.id}`} value={openDay.id}>
                {`${openDay.name} - ${moment(openDay.date).format('D MMMM yyyy')}`}
              </MenuItem>
            ))}
          </TextField>

          {!reportGenerateRequest.sessionId && (
            <p className={styles.error}>* {process.env.REACT_APP_SESSION_NAME} is required</p>
          )}
        </FormControl>
      </Grid>
    );
  };

  return (
    <>
      <Modal open={true} onClose={handleClose}>
        <Box className={styles.dialog}>
          <div className={styles.header}>
            <Grid container>
              <Grid item xs={4}>
                <b className={styles.subTitle}>Generate Export</b>
              </Grid>
              <Grid item xs={4} className="textCenter"></Grid>
              <Grid item xs={4}>
                <Close onClick={handleClose} className={styles.iconClose} />
              </Grid>
            </Grid>
          </div>
          <div className={styles.content}>
            <Box
              style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                height: '100%'
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  {renderInputForm()}
                </Grid>
              </Grid>
            </Box>
          </div>
          <div className={styles.footer}>
            <Button variant="outlined" className={styles.buttonCancel} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" className={styles.buttonSubmit} onClick={handleSubmit} disabled={!isValid}>
              Generate
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default GenerateExportModal;

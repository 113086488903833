import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { IOpenDay } from 'modules/feedback/operations/models/feedbackModel';
import getConfiguration from 'modules/shared/helpers/configuration';
import { debounce, put } from 'redux-saga/effects';
import { changeSessionById, changeSessionTeams } from '../actions/sessionsActions';
import * as OpTypes from '../types/sessionOperationTypes';

function* getSessionById(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    const data: IOpenDay = response.data as IOpenDay;
    yield put(changeSessionById(data));
    yield put(changeSessionTeams(data.teams));
  }
}

// Debounced getSessionById to deal with multiple calls
export function* getSessionByIdSaga() {
  yield debounce(getConfiguration().apiDebounceMs, OpTypes.GET_SESSION_BY_ID, getSessionById);
}

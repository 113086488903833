export const toProperCase = (value: string): string => {
  return value
    .toLowerCase()
    .split(' ')
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const numberToStringWithOrdinal = (value: number): string => {
  const ordinal = ['th', 'st', 'nd', 'rd'],
    dec = value % 100;
  return value + (ordinal[(dec - 20) % 10] || ordinal[dec] || ordinal[0]);
};

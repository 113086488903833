import { AccountCircle, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import CandidateProfileDialog from 'common/components/CandidateProfileModal/CandidateProfileModal';
import CandidateTrackerLink from 'common/components/CandidateTrackerLink/CandidateTrackerLink';
import { getCandidate } from 'modules/candidates/operations/actions/candidatesOperationActions';
import { ICandidateMini } from 'modules/candidates/operations/models/candidatesModel';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import styles from './FeedbackModal.module.scss';

interface IProps {
  candidate: ICandidateMini;
}

const CalibrationNotes: React.FC<IProps> = ({ candidate }: IProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [openProfile, setOpenProfile] = useState<boolean>(false);
  const profile = useSelector((state: IGlobalState) => state.candidatesState.candidates.candidate);

  const fetchCandidate = () => {
    dispatch(getCandidate(`${candidate.id}/edit`));
    setOpenProfile(true);
  };

  const handleAccordionChange = (event: React.SyntheticEvent, expanded: boolean) => {
    const ignoreTargets = ['img', 'path'];
    if (ignoreTargets.includes((event.target as HTMLElement).localName)) return;
    setOpen(expanded);
  };

  return (
    <>
      {openProfile && profile && (
        <CandidateProfileDialog candidate={profile} handleClose={() => setOpenProfile(false)} />
      )}
      <Accordion className={styles.accordion} expanded={open} onChange={handleAccordionChange}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <b>Candidate Notes</b>
          <span className="flex-left">
            <span className="d-flex">
              <AccountCircle className="cursorPointer mr-05 colorPrimary" onClick={fetchCandidate} />
              <CandidateTrackerLink candidateTrackerLink={candidate.candidateTrackerLink} />
            </span>
          </span>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingBottom: 0 }}>
          {open && (
            <Grid item xs={12}>
              <div className={styles.accordionText}>
                <div>{candidate.comments}</div>
              </div>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CalibrationNotes;

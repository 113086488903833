export enum CalibrationFields {
  CandidateName = 'Candidate Name',
  Team = 'Team',
  TeamCaptain = 'Team Captain',
  Qualifications = 'Qualifications ',
  University = 'University',
  Offer = 'Offer',
  Interviewer = 'Interviewer',
  None = 'None'
}

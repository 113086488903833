import { serialize } from 'common/helpers/serializationHelpers';
import { IPaginationRequest } from 'common/models/IPaginationRequest';
import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { HttpMethod } from '../../../../common/enums/appEnums';
import * as Types from '../../../manage-users/operations/types';
import { IUserFilters, IUserModel } from '../models';

export const getUsers = (
  payload: IPaginationRequest<IUserFilters> = {
    pageSize: 10,
    sortBy: 'id',
    ascending: false,
    filters: { statuses: true }
  }
): IActionRequest<string> => {
  return {
    type: Types.FETCH_USERS,
    httpMethod: HttpMethod.Get,
    url: `/user`,
    payload: serialize({ ...payload }),
    onSuccess: fetchedUsers
  };
};

export const fetchedUsers = (payload: any): IActionType<any> => ({
  type: Types.FETCHED_USERS,
  payload
});

export const saveUser = (userModel: IUserModel): IActionRequest<any> => {
  const userClaims = (userModel?.userClaims || []).map((userClaim) => userClaim.claim?.id).filter((f) => f);
  const userRole = userModel.userRole?.id;
  const payload = { ...userModel, userRole, userClaims };
  return {
    type: Types.SAVE_USER,
    httpMethod: payload.id ? HttpMethod.Put : HttpMethod.Post,
    url: payload.id ? `/user/${payload.id}` : '/user',
    payload: payload,
    onSuccess: saveUserSuccess
  };
};

export const saveUserSuccess = (): IActionType<undefined> => ({
  type: Types.SAVE_USER_SUCCESS
});

export const saveUserFailed = (payload: any): IActionType<any> => ({
  type: Types.SAVE_USER_FAILED,
  payload
});

export const deleteUser = (payload: number): IActionRequest<undefined> => {
  return {
    type: Types.DELETE_USER,
    httpMethod: HttpMethod.Delete,
    url: `/user/${payload}`
  };
};

import { AuthenticationResult } from '@azure/msal-browser';
import { tryParseLocalStorage } from '../../../../common/helpers/dataHelpers';
import IAuthUserModel from './authUserModel';

export interface IAuthState {
  userName?: string;
  token?: string;
  isAuthenticated?: boolean;
  isPending?: boolean;
  authUser?: IAuthUserModel;
}

const getValidToken = (): string | undefined => {
  const auth: AuthenticationResult | undefined = tryParseLocalStorage('auth');
  const validExpiry = auth?.expiresOn && new Date(auth?.expiresOn) > new Date();
  return validExpiry ? auth.accessToken : undefined;
};

export const getDefault = (): IAuthState => {
  const authUser: IAuthUserModel | undefined = tryParseLocalStorage('authUser');
  const token = getValidToken();
  return { authUser, token: getValidToken(), isAuthenticated: !!(token && authUser) };
};

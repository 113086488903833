import { IClaimModel, IClaimSearch } from 'modules/manage-users/operations/models';
import { useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';

export const CheckPermissions = (scopes: IClaimSearch[]) => {
  const authUser = useSelector((state: IGlobalState) => state.authState.authUser);

  if (authUser) {
    const allowedClaims: IClaimModel[] | undefined = authUser.claims;
    return scopes.every((claim: IClaimSearch) => {
      return !!allowedClaims.find(
        (allowedClaim) => allowedClaim.actionType === claim.actionType && allowedClaim.claimType === claim.claimType
      );
    });
  }
  return false;
};

export const useCheckPermissions = (scopes: IClaimSearch[]) => {
  return CheckPermissions(scopes);
};

export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
  array.reduce(
    (acc, value, index, array) => {
      (acc[predicate(value, index, array)] ||= []).push(value);
      return acc;
    },
    {} as { [key: string]: T[] }
  );

export const updateItemByKeyInArray = <T>(objArray: T[], updatedObj: T, key: keyof T) => {
  return objArray.map((item) => {
    if (updatedObj[key] === item[key]) {
      return updatedObj;
    } else {
      return item;
    }
  });
};

export const tryParseLocalStorage = <T>(key: string): T | undefined => {
  try {
    const json = localStorage.getItem(key);
    if (json && json.length) return JSON.parse(json);
    return undefined;
  } catch (e) {
    return undefined;
  }
};

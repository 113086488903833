import { Typography } from '@mui/material';
import { flattenQualifications } from '../../../modules/candidates/operations/helpers/candidateHelpers';
import { ICandidate } from '../../../modules/candidates/operations/models/candidatesModel';
import styles from '../../styles/colors.module.scss';
import { BootstrapTooltip } from '../OverflowTooltip/OverflowTooltip';

interface IProps {
  candidate: ICandidate;
}

const Qualifications = ({ candidate }: IProps) => {
  const qualificationDisplay = flattenQualifications(candidate.candidateQualifications || []);
  return (
    <Typography sx={{ fontSize: 14, display: 'flex', alignItems: 'center' }} component={'span'} variant={'body2'}>
      {qualificationDisplay.first || '-'}
      {qualificationDisplay.additionalCount ? (
        <BootstrapTooltip title={qualificationDisplay.additionalText} arrow placement={'top-end'} enterTouchDelay={0}>
          <Typography
            sx={{
              color: styles.entelectBlue
            }}
            component={'span'}
          >
            ,+{qualificationDisplay.additionalCount}{' '}
          </Typography>
        </BootstrapTooltip>
      ) : (
        ''
      )}
      {candidate.jobTitle && ' - ' + candidate.jobTitle}
    </Typography>
  );
};

export default Qualifications;

import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { changeReportTypes, changeReports } from '../actions';
import { IThingCode } from '../models';
import * as Types from '../types/index';

// Get Reports
function* getReports(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    yield put(changeReports(response.data));
  }
}

export function* getReportsSaga() {
  yield takeLatest(Types.GET_EXPORTS, getReports);
}

// Delete Reports
function* deleteReport(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* deleteReportSaga() {
  yield takeLatest(Types.DELETE_EXPORT, deleteReport);
}

// Generate Report
function* generateReport(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  if (success) {
    yield put(changeReports(response.data));
  }
}

export function* generateReportSaga() {
  yield takeLatest(Types.GENERATE_EXPORT, generateReport);
}

// Report Types
function* getReportTypes(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    const data: IThingCode[] = response.data as IThingCode[];
    yield put(changeReportTypes(data));
  }
}
export function* getReportTypesSaga() {
  yield takeLatest(Types.GET_EXPORT_TYPES, getReportTypes);
}

import ConfirmDialog from 'common/components/ConfirmDialog/ConfirmDialog';
import GuardedDelete from 'common/components/GuardedDelete/GuardedDelete';
import { ClaimActionType } from 'modules/manage-users/operations/enums/claimActionType';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteReport } from '../operations/actions';
import { IReport } from '../operations/models';

const DeleteExport = (props: { report: IReport }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { report } = props;
  const dispatch = useDispatch();

  const handleDialogClose = (result: boolean) => {
    setIsDialogOpen(false);
    if (result) {
      dispatch(deleteReport(report));
    }
  };

  return (
    <>
      <GuardedDelete
        onClick={() => setIsDialogOpen(true)}
        scopes={[
          {
            actionType: ClaimActionType.EDIT,
            claimType: ClaimTypes.MANAGE_APPLICATIONS
          }
        ]}
        renderErrorItem={() => <></>}
        editStyle="outlined"
      >
        Delete
      </GuardedDelete>
      <ConfirmDialog
        message={`You are about to delete report "${report.name}".\n This cannot be undone.`}
        title={`Delete Generated Export`}
        isOpen={isDialogOpen}
        positiveLabel="Yes, Delete"
        negativeLabel="No, Cancel"
        handleClose={handleDialogClose}
      ></ConfirmDialog>
    </>
  );
};

export default DeleteExport;

import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { roleActions } from '../actions';
import * as RoleTypes from '../types';

function* getRoles(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);
  if (success) {
    yield put(roleActions.fetchedRoles(response.data));
  }
}

export function* getRolesSaga() {
  yield takeLatest(RoleTypes.FETCH_ROLES, getRoles);
}

import { Box, LinearProgress } from '@mui/material';
import { CheckPermissions } from 'common/hooks/useCheckPermissions';
import { IClaimSearch } from 'modules/manage-users/operations/models';
import React, { ReactElement, cloneElement } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../startup/store/globalState';

interface IProps {
  children: ReactElement;
  scopes: IClaimSearch[];
  errorProps?: {};
  renderErrorItem?: (props?: any) => ReactElement;
}

export const GuardedView: React.FC<IProps> = (props: IProps) => {
  const { isPending, isAuthenticated } = useSelector((state: IGlobalState) => state.authState);
  const { children, scopes, errorProps, renderErrorItem } = props;

  const hasPermission = CheckPermissions(scopes);

  if (hasPermission) return <>{children}</>;

  if (!hasPermission && renderErrorItem) return renderErrorItem();

  if (!hasPermission && errorProps) return cloneElement(children, { ...errorProps });

  if (isPending && isAuthenticated)
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );

  return <span>Restricted!. You do not have the required permissions to view this page.</span>;
};

import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Chip, Grid } from '@mui/material';
import { CalibrationType } from 'modules/calibration/operations/enums';
import { ICandidateCalibration } from 'modules/calibration/operations/models';
import React, { useState } from 'react';
import styles from './FeedbackModal.module.scss';

interface IProps {
  showContent: boolean;
  calibrationType: CalibrationType;
  candidateCalibrations: ICandidateCalibration[];
}

const CalibrationNotesToggleView: React.FC<IProps> = (props: IProps) => {
  const { calibrationType, candidateCalibrations } = props;

  const [showContent, setShowContent] = useState<boolean>(props.showContent);
  const displayName = calibrationType == CalibrationType.Interview ? 'Interview' : 'Pre-Interview';
  const notes = candidateCalibrations.find((f) => f.interviewTypeCode == calibrationType);

  const noteSection = notes?.calibrationNotes.map((post, i) => {
    return (
      <Grid item key={`note_${post.id}`}>
        <div className={styles.feedbackCard}>
          <b>{post.createdBy?.displayName || 'Anonymous'}</b>
          <br />
          <span className={styles.feedbackNotes}>{post.note}</span>
        </div>
      </Grid>
    );
  });

  const noteContainer = (
    <Grid container spacing={0.5}>
      <Grid item xs={6}>
        {noteSection}
      </Grid>
      <Grid item xs={6}>
        <div className={`${styles.feedbackCard} ${styles.feedbackCardTags}`}>
          <br />
          {(notes?.tags || []).map((tag) => {
            const value = isNaN(parseInt(tag.rating)) ? tag.rating : tag.rating + '/' + (tag.tag?.rating?.end ?? 10);
            const displayValue = value ? `(${value})` : '';
            return (
              <Chip
                key={tag.id}
                disabled={!value}
                label={`${tag.tag?.name ?? ''} ${displayValue}`}
                variant="filled"
                size="small"
                className={styles.chip}
              />
            );
          })}
        </div>
      </Grid>
    </Grid>
  );

  const displayGrid = noteSection?.length ? noteContainer : <p className="textCenter w-100">Notes not captured</p>;
  return (
    <Accordion className={styles.accordion} expanded={showContent} onChange={(_, v) => setShowContent(v)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <b>{displayName} Notes</b>
      </AccordionSummary>
      <AccordionDetails>{showContent ? displayGrid : ''}</AccordionDetails>
    </Accordion>
  );
};

export default CalibrationNotesToggleView;

import { IClaimModel } from './claimModel';

export interface IClaimState {
  claims: Array<IClaimModel>;
  operationSuccess?: boolean | null;
}

export const defaultClaimsState: IClaimState = {
  claims: [],
  operationSuccess: null
};

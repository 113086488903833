import { HttpStatus } from 'common/enums/appEnums';
import { IPaginatedResponse } from 'common/models/paginatedResponse';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import { changeSessionSummaries } from '../actions/sessionsActions';
import { IOpenDaySummary } from '../models/sessionsModel';
import * as OpTypes from '../types/sessionOperationTypes';

function* getOpenDaySessionSummaries(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    const data: IPaginatedResponse<IOpenDaySummary> = response.data as IPaginatedResponse<IOpenDaySummary>;
    yield put(changeSessionSummaries(data));
  }
}

export function* getOpenDaySessionSummariesSaga() {
  yield takeLatest(OpTypes.GET_OPEN_DAY_SESSION_SUMMARIES, getOpenDaySessionSummaries);
}

import { AddBoxOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Modal,
  TextField
} from '@mui/material';
import GuardedEdit from 'common/components/GuardedEdit/GuardedEdit';
import { ClaimActionType } from 'modules/manage-users/operations/enums/claimActionType';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GuardedButton from '../../../../common/components/GuardedButton/GuardedButton';
import { useSnackbar } from '../../../../common/hooks/useSnackbar';
import { IFeedbackCategory } from '../../../../modules/manage-app/operations/models/feedbackCategoryModel';
import { IGlobalState } from '../../../../startup/store/globalState';
import { getFeedbackCategories, saveFeedbackCategory } from '../../operations/actions/feedbackCategoryActions';
import styles from '../../styles/ManageApp.module.scss';

const initialCategory = { name: '' } as IFeedbackCategory;

const SaveFeedbackCategory = (props: { category?: IFeedbackCategory }) => {
  const [open, setOpen] = useState(false);
  const { snackbar } = useSnackbar();
  const [category, setCategory] = useState(props.category || initialCategory);
  const dispatch = useDispatch();

  let { loading, operationSuccess, categories } = useSelector((state: IGlobalState) => {
    return { ...state.feedbackCategoryState, ...state.appState.app };
  });

  useEffect(() => {
    if (!open || operationSuccess == null) {
      return;
    }

    if (operationSuccess == true) {
      const message = category.id
        ? 'Feedback category was successfully updated.'
        : 'New feedback category was successfully added.';
      snackbar({ message, variant: 'success', action: true });
      !category.id && setCategory(initialCategory);
      dispatch(getFeedbackCategories());
      setOpen(false);
      return;
    }

    if (operationSuccess == false) {
      const message = 'Feedback tag could not be saved';
      snackbar({ message, variant: 'error', action: true });
      return;
    }
  }, [operationSuccess]);

  useEffect(() => {
    if (props.category) {
      setCategory(props.category);
    }
  }, [props.category]);

  const updateForm = (name: string, value: any) => {
    setCategory({ ...category, [name]: value });
  };

  const isValid = (): boolean => {
    return [category.name, category.description].every(
      (f) => !(f == undefined || f == null || f.toString().length == 0)
    );
  };

  const hasExistingDuplicateDefault = () => {
    let defaultCategories = categories.filter((c) => c.id != category.id && c.default);
    if (defaultCategories.length > 0) {
      snackbar({
        message: `The Default Feedback category has been changed to ${category.name}`,
        variant: 'success',
        action: true
      });
    }
  };

  const submitCategory = async () => {
    if (category.default) {
      hasExistingDuplicateDefault();
    }
    dispatch(saveFeedbackCategory(category));
  };

  return (
    <>
      {category && category.id ? (
        <GuardedEdit
          editStyle="outlined"
          sx={{ marginTop: '2px' }}
          scopes={[
            {
              actionType: ClaimActionType.EDIT,
              claimType: ClaimTypes.MANAGE_APPLICATIONS
            }
          ]}
          renderErrorItem={() => <></>}
          onClick={() => setOpen(true)}
        ></GuardedEdit>
      ) : (
        <GuardedButton
          label={'ADD NEW CATEGORY'}
          onClick={() => setOpen(true)}
          className={styles.addButton}
          color="success"
          variant="contained"
          startIcon={<AddBoxOutlined />}
          errorProps={{ disabled: true }}
          scopes={[
            {
              actionType: ClaimActionType.ADD,
              claimType: ClaimTypes.MANAGE_APPLICATIONS
            }
          ]}
        />
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box className={styles.modalBox}>
          <Card className={styles.modal}>
            <CardHeader title={category.id ? 'Edit' : 'Add' + ` Category`} />
            <CardContent>
              <FormControl fullWidth>
                <TextField
                  value={category.name}
                  onChange={(e) => updateForm('name', e.target.value)}
                  label="Category Name*"
                  variant="outlined"
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <TextField
                  value={category.description}
                  onChange={(e) => updateForm('description', e.target.value)}
                  label="Category Description*"
                  variant="outlined"
                />
              </FormControl>
              <FormGroup sx={{ mt: 3 }}>
                <FormControlLabel
                  control={<Checkbox checked={category.default} onChange={(e, v) => updateForm('default', v)} />}
                  label="Make the Default Category"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={category.onlySuperUsers} onChange={(e, v) => updateForm('onlySuperUsers', v)} />
                  }
                  label="Only visible to Super Users"
                />
              </FormGroup>
            </CardContent>
            <CardActions sx={{ float: 'right' }}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                CANCEL
              </Button>
              <Button variant="contained" onClick={submitCategory} disabled={!isValid() || loading}>
                SAVE CATEGORY
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default SaveFeedbackCategory;

import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { HttpMethod } from '../../../../common/enums/appEnums';
import { ICandidateStatus } from '../models/candidateStatusModel';
import * as Types from '../types';

export const getCandidateStatuses = (): IActionRequest<undefined> => {
  return {
    type: Types.GET_CANDIDATE_STATUSES,
    httpMethod: HttpMethod.Get,
    url: `/candidateStatus`,
    onSuccess: getCandidateStatusesSucceed
  };
};

export const getCandidateStatusesSucceed = (payload: any): IActionType<ICandidateStatus[]> => ({
  type: Types.GET_CANDIDATE_STATUSES_SUCCEED,
  payload
});

export const saveCandidateStatus = (payload: ICandidateStatus): IActionRequest<ICandidateStatus> => {
  return {
    type: Types.SAVE_CANDIDATE_STATUS,
    httpMethod: payload.id ? HttpMethod.Put : HttpMethod.Post,
    url: `/candidateStatus`,
    onSuccess: saveCandidateStatusSuccess,
    payload: payload
  };
};

export const saveCandidateStatusSuccess = (payload: any): IActionType<any> => ({
  type: Types.SAVE_CANDIDATE_STATUS_SUCCEED,
  payload
});

export const removeCandidateStatus = (id: number): IActionRequest<undefined> => {
  return {
    type: Types.REMOVE_CANDIDATE_STATUS,
    httpMethod: HttpMethod.Delete,
    url: `/candidateStatus/${id}`,
    onSuccess: removeCandidateStatusSuccess
  };
};

export const removeCandidateStatusSuccess = (payload: any): IActionType<any> => ({
  type: Types.REMOVE_CANDIDATE_STATUS_SUCCEED,
  payload
});

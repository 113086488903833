import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { takeLatest } from 'redux-saga/effects';
import * as types from '../types';

function* createCandidateCalibration(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status201Created]);
}

export function* createCalibrationSaga() {
  yield takeLatest(types.CREATE_OPEN_DAY_CALIBRATIONS, createCandidateCalibration);
}

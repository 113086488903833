import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

interface IProps {
  fileUploaded: (fileUrl: Blob | MediaSource) => void;
}

const baseStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  color: '#bdbdbd',
  transition: 'border .3s ease-in-out',
  width: '100%',
  height: '260px'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const FormDropzone: React.FC<IProps> = ({ fileUploaded }) => {
  const onDrop = useCallback((acceptedFiles: (Blob | MediaSource)[]) => {
    const file = acceptedFiles[0];
    fileUploaded(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg']
    }
  });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} multiple={false} />
        <div style={{ width: '100%', textAlign: 'center' }}>
          Drag and drop <br /> Photo here.
        </div>
      </div>
    </div>
  );
};

export default FormDropzone;

import { Grid } from '@mui/material';
import { IOpenDay } from 'modules/feedback/operations/models/feedbackModel';
import { useSelector } from 'react-redux';
import { IGlobalState } from '../../../../startup/store/globalState';
import { OpenDaySection } from './OpenDaySection';

const OpenDayDetails: React.FC = () => {
  const { candidate, sessions } = useSelector((state: IGlobalState) => ({
    ...state.candidatesState.candidates,
    ...state.sessionsState.sessions
  }));

  if (!candidate) return <></>;

  const getSession = (openDay: IOpenDay) => sessions?.data?.find((session) => session.id == openDay.id);
  const candidateOpenDays = candidate?.sessions?.map(getSession).filter((f) => f) ?? [];

  return (
    <Grid container xs={12}>
      {(candidateOpenDays as IOpenDay[]).map((openDay) => (
        <Grid item padding={2} xs={12} columnGap={2}>
          <OpenDaySection selectedCandidate={candidate} openDay={openDay} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OpenDayDetails;

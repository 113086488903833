import { Grid, TextField, Typography } from '@mui/material';
import { getQualifications } from 'common/operations/actions/commonOperationActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../../../startup/store/globalState';
import styles from '../../styles/ManageApp.module.scss';
import { DeleteModal } from './DeleteModal';
import { SaveModal } from './SaveModal';

export const Qualifications = () => {
  const { data } = useSelector((state: IGlobalState) => ({ ...state.qualificationsState }));
  const [rows, setRows] = useState(data ?? []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getQualifications());
  }, []);

  useEffect(() => {
    setRows(data ?? []);
  }, [data]);

  const filterData = (value: string) => {
    const filteredData = data.filter((item) => !value?.length || item.name.toLowerCase().includes(value.toLowerCase()));
    setRows(filteredData);
  };

  return (
    <>
      <p className={styles.title}>
        <b>Qualifications</b>
        <SaveModal />
      </p>
      <TextField
        onChange={(data) => filterData(data.target.value)}
        fullWidth
        label="Search Qualifications"
        variant="outlined"
        size="small"
        className="mb-05"
      />
      <Grid container className="max-height-60  mt-05">
        {(rows ?? []).map((item) => {
          return (
            <Grid className={styles.row} key={`item_${item.id}`} xs={12} item>
              <Grid container>
                <Grid item md={9} lg={10} xs={8}>
                  <Typography>
                    <span />
                    <span className="title">{item.name}</span>
                  </Typography>
                </Grid>
                <Grid item md={3} lg={2} xs={4} className={styles.actionButtons}>
                  <SaveModal item={item} />
                  <DeleteModal item={item} />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

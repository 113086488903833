import { BasicModelState, IBasicModel } from 'common';
import produce from 'immer';
import * as ActionTypes from '../types';

export default function ethnicitiesReducer(state: BasicModelState = { data: [] }, action: any): BasicModelState {
  switch (action.type) {
    case ActionTypes.GET_ETHNICITIES_SUCCEED: {
      return produce(state, (draft) => {
        draft.data = ((action.payload as IBasicModel[]) ?? []).sort((a, b) => a.name?.localeCompare(b.name ?? ''));
      });
    }
    case ActionTypes.SAVE_ETHNICITY_SUCCEED: {
      return produce(state, (draft) => {
        draft.success = true;
      });
    }
    case ActionTypes.SAVE_ETHNICITY:
    case ActionTypes.REMOVE_ETHNICITY: {
      return produce(state, (draft) => {
        draft.success = undefined;
      });
    }
    default:
      return state;
  }
}

import getConfiguration from 'modules/shared/helpers/configuration';
import { debounce, put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { candidatesActions } from '../actions/index';
import { ICandidatePaginatedResponse, IRecruiter } from '../models/candidatesModel';
import * as OpTypes from '../types/candidatesOperationTypes';

function* getCandidates(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    const data: ICandidatePaginatedResponse = response.data as ICandidatePaginatedResponse;
    yield put(candidatesActions.changeCandidates(data));
  }
}

// Debounced getCandidates to deal with multiple calls
export function* getCandidatesSaga() {
  yield debounce(getConfiguration().apiDebounceMs, OpTypes.GET_CANDIDATES, getCandidates);
}

function* getCandidateRecruiters(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    const data: IRecruiter[] = response.data as IRecruiter[];
    yield put(candidatesActions.changeCandidateRecruiters(data));
  }
}

export function* getCandidateRecruitersSaga() {
  yield takeLatest(OpTypes.GET_CANDIDATE_RECRUITERS, getCandidateRecruiters);
}

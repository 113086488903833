import OpenDaysContainer from '../../modules/feedback/container/OpenDaysContainer';
import ManageAppContainer from '../../modules/manage-app/container/ManageAppContainer';
import ManageUsersContainer from '../../modules/manage-users/container/ManageUsersContainer';
import { ExportContainer } from '../../modules/report/container/ExportContainer';
import SessionDetailsContainer from '../../modules/sessions/container/SessionDetailsContainer';
import SessionsContainer from '../../modules/sessions/container/SessionsContainer';

import { CalibrationContainer } from 'modules/calibration/container/CalibrationContainer';
import { CalibrationMatrixContainer } from 'modules/calibration/container/CalibrationMatrixContainer';
import CandidateDetailsContainer from 'modules/candidates/container/CandidateDetailsContainer';
import CandidatesContainer from 'modules/candidates/container/CandidatesContainer';
import CandidatesImportContainer from 'modules/candidates/container/CandidatesImportContainer';
import OpenDayCandidatesContainer from 'modules/candidates/container/OpenDayCandidatesContainer';
import FeedbackContainer from 'modules/feedback/container/FeedbackContainer';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType, IClaimSearch } from 'modules/manage-users/operations/models';

// TO-DO: DISABLED DASHBOARD AND REPORTS UNTIL THEY ARE READY
export const ROUTES = {
  OpenDayCandidates: '/open-day-candidates',
  Feedback: '/feedback',
  OpenDays: '/open-days',
  Verification: '/verification',
  Candidate: '/candidate',
  ManageApp: '/manage-app',
  ManageUsers: '/manage-users',
  Calibration: '/calibration',
  // Dashboard: '/dashboard',
  CalibrationMatrix: '/calibration-matrix',
  Exports: '/exports',
  Generate: '/generate'
};

interface AppRoutes {
  path: string;
  scopes: IClaimSearch[];
  component: React.FC;
}

export const APP_ROUTES: AppRoutes[] = [
  {
    path: ROUTES.OpenDayCandidates,
    scopes: [{ claimType: ClaimTypes.CANDIDATES, actionType: ClaimActionType.VIEW_ONLY }],
    component: CandidatesContainer
  },
  {
    path: `${ROUTES.OpenDayCandidates}/:sessionId`,
    scopes: [{ claimType: ClaimTypes.CANDIDATES, actionType: ClaimActionType.VIEW_ONLY }],
    component: OpenDayCandidatesContainer
  },
  {
    path: `${ROUTES.OpenDayCandidates}/:sessionId/import`,
    scopes: [
      { claimType: ClaimTypes.CANDIDATES, actionType: ClaimActionType.ADD },
      { claimType: ClaimTypes.CANDIDATES, actionType: ClaimActionType.EDIT }
    ],
    component: CandidatesImportContainer
  },
  // {
  //   path: ROUTES.Dashboard,
  //   scopes: [{ claimType: ClaimTypes.FEEDBACK, actionType: ClaimActionType.VIEW_ONLY }],
  //   component: DashboardContainer
  // },
  {
    path: `${ROUTES.Candidate}/:id`,
    scopes: [{ claimType: ClaimTypes.CANDIDATES, actionType: ClaimActionType.VIEW_ONLY }],
    component: CandidateDetailsContainer
  },
  {
    path: ROUTES.OpenDays,
    scopes: [{ claimType: ClaimTypes.FEEDBACK, actionType: ClaimActionType.VIEW_ONLY }],
    component: OpenDaysContainer
  },
  {
    path: `${ROUTES.OpenDays}/:sessionId`,
    scopes: [{ claimType: ClaimTypes.FEEDBACK, actionType: ClaimActionType.VIEW_ONLY }],
    component: FeedbackContainer
  },
  {
    path: ROUTES.Feedback,
    scopes: [{ claimType: ClaimTypes.MANAGE_OPEN_DAYS, actionType: ClaimActionType.VIEW_ONLY }],
    component: SessionsContainer
  },
  {
    path: `${ROUTES.OpenDays}/edit/:sessionId`,
    scopes: [{ claimType: ClaimTypes.MANAGE_OPEN_DAYS, actionType: ClaimActionType.VIEW_ONLY }],
    component: SessionDetailsContainer
  },
  {
    path: ROUTES.ManageApp,
    scopes: [{ claimType: ClaimTypes.MANAGE_APPLICATIONS, actionType: ClaimActionType.VIEW_ONLY }],
    component: ManageAppContainer
  },
  {
    path: ROUTES.ManageUsers,
    scopes: [{ claimType: ClaimTypes.MANAGE_USERS, actionType: ClaimActionType.VIEW_ONLY }],
    component: ManageUsersContainer
  },
  {
    path: `${ROUTES.Calibration}/:openDayId`,
    scopes: [{ claimType: ClaimTypes.FEEDBACK_CALIBRATION, actionType: ClaimActionType.VIEW_ONLY }],
    component: CalibrationContainer
  },
  {
    path: `${ROUTES.CalibrationMatrix}/:openDayId?`,
    scopes: [],
    component: CalibrationMatrixContainer
  },
  {
    path: ROUTES.Exports,
    scopes: [{ claimType: ClaimTypes.EXPORTING, actionType: ClaimActionType.VIEW_ONLY }],
    component: ExportContainer
  }
];

import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { SelectOptionItem } from 'common/models/filterModels';

interface IProps {
  label: string;
  values: string[];
  items: SelectOptionItem[];
  onChanges: (event: SelectChangeEvent<string[]>) => void;
}

export const BasicSelect = (props: IProps) => {
  const getSelectMenuItems = (items: SelectOptionItem[] = []) => {
    return items.map((item, i) => {
      const name = typeof item === 'string' ? item : item.label;
      const value = typeof item === 'string' ? item : item.value;
      return (
        <MenuItem key={'_option_' + value} value={value}>
          {name}
        </MenuItem>
      );
    });
  };

  const SelectMenuItems = getSelectMenuItems(props.items);
  return (
    <FormControl>
      <InputLabel id="basic-select-label">{props.label}</InputLabel>
      <Select
        labelId="basic-selec-label"
        id="basic-select"
        value={props.values.length > 0 ? props.values : ''}
        label={props.label}
        onChange={props.onChanges}
      >
        {SelectMenuItems}
      </Select>
    </FormControl>
  );
};

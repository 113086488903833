import { MAX_TEAM_NAME_LENGTH } from 'common/constants/appContants';
import { ISessionTeamRequestDto } from '../models/sessionsModel';

export const validateTeams = (teams: ISessionTeamRequestDto[]): { name: string; message: string } | undefined => {
  const validTeams = teams.filter((team) => !team.isDeleted).map((team) => ({ ...team, name: team.name?.trim() }));
  for (const team of validTeams) {
    const validateTeamError = validateTeam(team, validTeams);
    if (validateTeamError) return validateTeamError;
  }

  return undefined;
};

export const validateTeam = (
  team: ISessionTeamRequestDto,
  teams: ISessionTeamRequestDto[]
): { name: string; message: string } | undefined => {
  if (team.isDeleted) return undefined;
  if (!team.teamCaptainId) return { name: 'captain', message: 'Team captain is required.' };
  if (!team?.name?.length)
    return {
      name: 'name',
      message: 'Team name is required.'
    };
  if (team.name?.length > MAX_TEAM_NAME_LENGTH)
    return {
      name: 'name',
      message: 'Team name must be less than 50 characters.'
    };
  const duplicateTeams = teams.filter(
    (f) => f.name?.trim().toLowerCase() === team.name?.trim().toLowerCase() && !f.isDeleted
  );
  if (duplicateTeams.length > 1)
    return {
      name: 'name',
      message: 'Team names must be unique.'
    };
  return undefined;
};

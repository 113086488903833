import { put, takeLatest } from 'redux-saga/effects';
import * as ApiHandler from '../../../../common/api/apiRequest';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { candidatesActions } from '../actions/index';
import { ICandidate } from '../models/candidatesModel';
import * as OpTypes from '../types/candidatesOperationTypes';

function* getCandidate(action: ApiHandler.IRequest): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);

  if (success) {
    const data: ICandidate = response.data as ICandidate;
    yield put(candidatesActions.changeCandidate(data));
  }
}

export function* getCandidateSaga() {
  yield takeLatest(OpTypes.GET_CANDIDATE, getCandidate);
}

import {
  AuthenticationResult,
  BrowserAuthError,
  ClientAuthError,
  InteractionRequiredAuthError,
  RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { protectedResources } from '../../../../authConfig';
import { msalInstance } from '../../../../index';
import { changeHttpError } from '../../../app/operations/actions/appActions';
import { authActions } from '../actions';
import { changeIsAuthenticated, changeToken, changeUserName, getLoggedInUser } from '../actions/authActions';
import * as Types from '../types/authTypes';

function* signIn(): any {
  const signInOptions: RedirectRequest = {
    scopes: protectedResources.api.scopes
  };
  yield call(() => Promise.resolve(msalInstance.loginRedirect(signInOptions)));
}

export function* signInSaga(): any {
  yield takeLatest(Types.SIGN_IN, signIn);
}

function* signOut(): any {
  sessionStorage.clear();
  yield put(changeIsAuthenticated(false));
  yield put(changeToken());
  yield put(changeUserName());
}

export function* signOutSaga(): any {
  yield takeLatest(Types.SIGN_OUT, signOut);
}

function* getToken(): any {
  try {
    const { accessToken, account, expiresOn }: AuthenticationResult = yield call(() => requestSilentToken());
    localStorage.setItem('auth', JSON.stringify({ accessToken, expiresOn }));
    yield put(changeIsAuthenticated(true));
    yield put(changeToken(accessToken));
    if (account) {
      yield put(getLoggedInUser(account?.username || ''));
    }
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      yield put(changeHttpError(error.message));
      return;
    }
    if (error instanceof ClientAuthError) {
      if (error.errorCode === 'block_token_requests') {
        yield put(changeHttpError(error.message));
        return;
      }
      console.warn('ClientAuthError: error code = ', error.errorCode);
    }
    if (error instanceof BrowserAuthError) {
      yield put(changeHttpError(error.errorMessage));
      return;
    }
    throw error;
  }
}

export function* getTokenSaga(): any {
  yield takeLatest(Types.GET_TOKEN, getToken);
}

function* getUser(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  if (success) {
    yield put(changeUserName(response.data.email));
  } else {
    yield put(authActions.signOut());
  }
}

export function* getLoggedInUserSaga(): any {
  yield takeLatest(Types.GET_LOGGEDIN_USER, getUser);
}

export const requestSilentToken = async (): Promise<AuthenticationResult | void> => {
  try {
    const account = msalInstance.getActiveAccount();
    const tokenRequest: SilentRequest = {
      scopes: protectedResources.api.scopes,
      account: account ?? msalInstance.getAllAccounts()[0]
    };
    return await msalInstance.acquireTokenSilent(tokenRequest);
  } catch (error) {
    console.error(error);
  }
  await msalInstance.loginRedirect({ scopes: protectedResources.api.scopes });
};

import produce from 'immer';
import { IFeedbackCategoryState } from '../models/feedbackCategoryState';
import * as ActionTypes from '../types';

export default function feedbackCategoryReducer(
  state: IFeedbackCategoryState = { categories: [], operationSuccess: null },
  action: any
): IFeedbackCategoryState {
  switch (action.type) {
    case ActionTypes.GET_FEEDBACK_CATEGORIES_SUCCEED: {
      return produce(state, (draft) => {
        draft.categories = action.payload;
      });
    }
    case ActionTypes.GET_FEEDBACK_CATEGORIES: {
      return produce(state, (draft) => {
        draft.operationSuccess = null;
      });
    }
    case ActionTypes.SAVE_FEEDBACK_CATEGORY_SUCCEED: {
      return produce(state, (draft) => {
        draft.operationSuccess = true;
      });
    }
    case ActionTypes.REMOVE_FEEDBACK_CATEGORY_SUCCEED: {
      return produce(state, (draft) => {
        draft.operationSuccess = true;
      });
    }
    default:
      return state;
  }
}

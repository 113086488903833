import { IActionType } from 'common/operations/types/actionTypes';
import { CandidateStatus } from '../enums/candidateEnums';
import { ICandidate, ICandidatePaginatedResponse, IThing, SearchType } from '../models/candidatesModel';
import IRecruiter from '../models/recruiterModel';
import * as Types from '../types/candidatesTypes';

export const changeCandidates = (candidates: ICandidatePaginatedResponse): IActionType<ICandidatePaginatedResponse> => {
  return {
    type: Types.CHANGED_CANDIDATES,
    payload: candidates
  };
};

export const changeCandidatesSearchFilter = (
  candidates: ICandidatePaginatedResponse
): IActionType<ICandidatePaginatedResponse> => {
  return {
    type: Types.CHANGED_CANDIDATES_SEARCH_FILTER,
    payload: candidates
  };
};

export const changeGenders = (payload: IThing[]): IActionType<IThing[]> => {
  return {
    type: Types.CHANGED_GENDERS,
    payload: payload
  };
};

export const changeEthnicities = (payload: IThing[]): IActionType<IThing[]> => {
  return {
    type: Types.CHANGED_ETHNICITIES,
    payload: payload
  };
};

export const addedCandidate = (candidate: ICandidate): IActionType<ICandidate> => {
  return {
    type: Types.ADDED_CANDIDATE,
    payload: candidate
  };
};

export const updatedCandidate = (candidate: ICandidate): IActionType<ICandidate> => {
  return {
    type: Types.UPDATED_CANDIDATE,
    payload: candidate
  };
};

export const changeCandidate = (candidate: ICandidate): IActionType<ICandidate> => {
  return {
    type: Types.CHANGED_CANDIDATE,
    payload: candidate
  };
};

export const changeCandidateView = (type: 'list' | 'pipeline'): IActionType<'list' | 'pipeline'> => {
  return {
    type: Types.CHANGED_CANDIDATE_VIEW,
    payload: type
  };
};

export const changeCandidateSearchType = (searchType: SearchType): IActionType<SearchType> => {
  return {
    type: Types.CHANGED_CANDIDATE_SEARCH,
    payload: searchType
  };
};

export const changeCandidateRecruiters = (recruiters: IRecruiter[]): IActionType<IRecruiter[]> => {
  return {
    type: Types.CHANGED_CANDIDATE_RECRUITERS,
    payload: recruiters
  };
};

import { Feature } from 'common/enums/appEnums';

export const USER_NAME_CHANGED = `USER_NAME_CHANGED${Feature.App}`;
export const TOKEN_CHANGED = `TOKEN_CHANGED${Feature.App}`;
export const AUTH_CHANGED = `AUTH_CHANGED${Feature.App}`;
export const GET_TOKEN = `GET_TOKEN`;
export const SIGN_IN = `SIGN_IN`;
export const SIGN_OUT = `SIGN_OUT`;
export const GET_LOGGEDIN_USER = `GET_LOGGED_IN_USER`;
export const GET_LOGGED_IN_USER_SUCCESS = `GET_LOGGED_IN_USER_SUCCESS${Feature.Users}`;
export const GET_LOGGED_IN_USER_FAILED = `GET_LOGGED_IN_USER_FAILED${Feature.Users}`;

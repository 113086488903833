import { defaultUsersState, IUserState } from './IUserState';
import { defaultClaimsState, IClaimState } from './IClaimState';
import { defaultRoleState, IRoleState } from './IRoleState';

export interface IUserManagementState {
  claimState: IClaimState;
  userState: IUserState;
  roleState: IRoleState;
}

export const userManagementStateDefault: IUserManagementState = {
  claimState: defaultClaimsState,
  userState: defaultUsersState,
  roleState: defaultRoleState
};

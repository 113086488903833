import { Grid, Typography } from '@mui/material';
import { getAll } from 'modules/manage-app/operations/actions/ethnicityActions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from '../../../../startup/store/globalState';
import styles from '../../styles/ManageApp.module.scss';
import { DeleteModal } from './DeleteModal';
import { SaveModal } from './SaveModal';

export const Ethnicities = () => {
  const { data } = useSelector((state: IGlobalState) => ({ ...state.ethnicitiesState }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAll());
  }, []);

  return (
    <Grid container>
      <Grid item xs={6}>
        <br />
        <p className={styles.title}>
          <b>Ethnicities</b>
          <SaveModal />
        </p>
        <Grid container>
          {(data ?? []).map((item) => {
            return (
              <Grid className={styles.row} key={`item_${item.id}`} xs={12} item>
                <Grid container>
                  <Grid item md={9} lg={10} xs={8}>
                    <Typography>
                      <span />
                      <span className="title">
                        <b>{item.name}</b>
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item md={3} lg={2} xs={4} className={styles.actionButtons}>
                    <SaveModal item={item} />
                    <DeleteModal item={item} />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

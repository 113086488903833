import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import { feedbackActions } from '../actions';
import { changeRemoveCandidateFromTeamStatus } from '../actions/feedbackOperationActions';
import { RemoveCandidateFromTeamStatus } from '../enums/feedbackEnums';
import * as OpTypes from '../types/feedbackOperationTypes';

function* removeCandidateFromTeam(action: any): any {
  yield put(changeRemoveCandidateFromTeamStatus(RemoveCandidateFromTeamStatus.Adding));

  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);

  if (success) {
    yield put(feedbackActions.candidateRemovedFromTeams(response.data));
    yield put(changeRemoveCandidateFromTeamStatus(RemoveCandidateFromTeamStatus.Success));
  } else {
    yield put(changeRemoveCandidateFromTeamStatus(RemoveCandidateFromTeamStatus.Failure));
  }
}

export function* removeCandidateFromTeamSaga() {
  yield takeLatest(OpTypes.REMOVE_CANDIDATE_FROM_TEAM, removeCandidateFromTeam);
}

import {
  AddCandidatesToTeamStatus,
  FeedbackCreationStatus,
  FeedbackDeletionStatus,
  FeedbackUpdateStatus,
  RemoveCandidateFromTeamStatus
} from '../enums/feedbackEnums';

export default interface IFeedbackOperation {
  addCandidatesToTeamStatus: AddCandidatesToTeamStatus;
  removeCandidateFromTeamStatus: RemoveCandidateFromTeamStatus;
  creationStatus: FeedbackCreationStatus;
  deletionStatus: FeedbackDeletionStatus;
  updateStatus: FeedbackUpdateStatus;
}

export const feedbackOperationDefault: IFeedbackOperation = {
  addCandidatesToTeamStatus: AddCandidatesToTeamStatus.Idle,
  removeCandidateFromTeamStatus: RemoveCandidateFromTeamStatus.Idle,
  creationStatus: FeedbackCreationStatus.Idle,
  deletionStatus: FeedbackDeletionStatus.Idle,
  updateStatus: FeedbackUpdateStatus.Idle
};

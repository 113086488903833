import { Feature } from 'common/enums/appEnums';

export const GET_OPEN_DAY_CALIBRATIONS = `GET_OPEN_DAY_CALIBRATIONS${Feature.Calibration}`;
export const GET_OPEN_DAY_CALIBRATIONS_SUCCEEDED = `GET_OPEN_DAY_CALIBRATIONS_SUCCEEDED${Feature.Calibration}`;
export const GET_OPEN_DAY_CALIBRATIONS_FAILED = `GET_OPEN_DAY_CALIBRATIONS_FAILED${Feature.Calibration}`;
export const OPEN_DAY_CALIBRATIONS_RETRIEVED = `OPEN_DAY_CALIBRATIONS_RETRIEVED${Feature.Calibration}`;
export const SAVE_CALIBRATION_OFFER = `SAVE_CALIBRATION_OFFER${Feature.Calibration}`;
export const SAVE_CALIBRATION_OFFER_SUCCESS = `SAVE_CALIBRATION_OFFER_SUCCESS${Feature.Calibration}`;
export const SAVE_CALIBRATION_OFFER_FAILED = `SAVE_CALIBRATION_OFFER_FAILED${Feature.Calibration}`;
export const UPDATE_CALIBRATION_OFFER = `UPDATE_CALIBRATION_OFFER${Feature.Calibration}`;
export const UPDATE_OPERATION_CHANGED = `UPDATE_OPERATION_CHANGED${Feature.App}`;
export const GET_CANDIDATE_CALIBRATIONS = `GET_CANDIDATE_CALIBRATIONS${Feature.Calibration}`;
export const GET_CANDIDATE_CALIBRATIONS_SUCCEEDED = `GET_CANDIDATE_CALIBRATIONS_SUCCEEDED${Feature.Calibration}`;
export const GET_CANDIDATE_CALIBRATIONS_FAILED = `GET_CANDIDATE_CALIBRATIONS_FAILED${Feature.Calibration}`;
export const CREATE_OPEN_DAY_CALIBRATIONS = `CREATE_OPEN_DAY_CALIBRATIONS${Feature.Calibration}`;
export const CREATE_OPEN_DAY_CALIBRATIONS_SUCCEEDED = `CREATE_OPEN_DAY_CALIBRATIONS_SUCCEEDED${Feature.Calibration}`;
export const CREATE_OPEN_DAY_CALIBRATIONS_FAILED = `CREATE_OPEN_DAY_CALIBRATIONS_FAILED${Feature.Calibration}`;

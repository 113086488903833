import { GuardedView } from 'common/components/GuardedView/GuardedView';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { APP_ROUTES, ROUTES } from '../../../../common/constants/routesConstants';
import { IGlobalState } from '../../../../startup/store/globalState';
import AuthContainer from '../../../auth/container/AuthContainer';
import GuardedRoute from '../GuardedRoute/GuardedRoute';

const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useSelector((state: IGlobalState) => state.authState);
  const guardedElement = <GuardedRoute isRouteAccessible={isAuthenticated} redirectRoute={ROUTES.Verification} />;
  return (
    <Routes>
      <Route path={ROUTES.Verification} Component={AuthContainer} />
      <Route path="*" element={<Navigate to={ROUTES.Verification} replace />} />
      <Route element={guardedElement}>
        {APP_ROUTES.map((route) => (
          <Route path={route.path} element={<GuardedView scopes={route.scopes}>{<route.component />}</GuardedView>} />
        ))}
      </Route>
    </Routes>
  );
};

export default AppRoutes;

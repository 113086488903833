import { Close } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Checkbox,
  Container,
  Divider,
  FormControl,
  Grid,
  Modal,
  Stack,
  TextField
} from '@mui/material';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import { ICandidate } from 'modules/candidates/operations/models/candidatesModel';
import { useEffect, useState } from 'react';
import styles from './AddDialog.module.scss';

interface IProps {
  handleClose: () => void;
  handleSubmit: (_: number[]) => void;
  candidates: ICandidate[];
}

const AddDialog: React.FC<IProps> = ({ handleClose, handleSubmit, candidates }: IProps) => {
  const [candidateIds, setCandidateIds] = useState<number[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  useEffect(() => {}, []);

  const candidateSearch = (name?: string): boolean => {
    const nameTrimmed = name?.toLowerCase().trim() ?? '';
    const searchTrimmed = searchValue?.toLowerCase().trim() ?? '';
    return nameTrimmed.includes(searchTrimmed);
  };

  const handleChange = (id: number, checked: boolean) => {
    if (checked) {
      setCandidateIds([...candidateIds, id]);
    } else {
      setCandidateIds(candidateIds.filter((f) => f !== id));
    }
  };

  const buttonLabel = `Add ${candidateIds.length ? candidateIds.length : ''} 
  Candidate${candidateIds.length == 1 ? '' : 's'}`;

  return (
    <Modal open={true} onClose={handleClose}>
      <Box className={styles.modalBox}>
        <Card className={styles.modal}>
          <h2 className="textCenter">
            Add Candidate
            <Close className={styles.iconClose} onClick={handleClose} />
          </h2>
          {candidates.length > 0 ? (
            <div>
              <FormControl fullWidth>
                <TextField
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  label="Find candidate by name"
                  variant="outlined"
                />
              </FormControl>

              <br />
              <Divider />
              <ul className={styles.list}>
                {candidates
                  .filter((f) => candidateSearch(f.name))
                  .map((option) => (
                    <li
                      key={option.id + '_option'}
                      style={{ padding: '6px 0px', cursor: 'pointer' }}
                      onClick={() => handleChange(option.id, !candidateIds.includes(option.id))}
                    >
                      <Grid item xs={12} key={option.id} className={styles.listItems}>
                        <div>
                          <Checkbox checked={candidateIds.includes(option.id)} color="success" />
                        </div>
                        <div>
                          {option.photo && option.photo !== '' ? (
                            <CardMedia className={styles.avatar} src={option.photo} component="img" />
                          ) : (
                            <Container className={styles.avatar}>
                              <Avatar />
                            </Container>
                          )}
                        </div>

                        <div>
                          <span>{option.name}</span>
                        </div>
                      </Grid>
                    </li>
                  ))}
              </ul>
              <Divider />
            </div>
          ) : (
            <div className={styles.noCandidates}>
              <span>There are no Candidates in this Open Day</span>
              <br />
            </div>
          )}
          <br />
          <Stack spacing={2} direction="row-reverse" sx={{ textAlign: 'right' }}>
            <EntelectButton variant={ButtonType.Link} label="Cancel" handleClick={() => handleClose()} />
            <EntelectButton
              variant={ButtonType.Primary}
              label={buttonLabel}
              handleClick={() => handleSubmit(candidateIds)}
              disabled={candidateIds.length === 0}
            />
          </Stack>
          <br />
        </Card>
      </Box>
    </Modal>
  );
};

export default AddDialog;

import { CheckCircle } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../../common/styles/gridTable.module.scss';
import { IGlobalState } from '../../../../startup/store/globalState';
import { getFeedbackCategories } from '../../operations/actions/feedbackCategoryActions';
import DeleteFeedbackCategory from './DeleteFeedbackCategory';
import SaveFeedbackCategory from './SaveFeedbackCategory';

const FeedbackCategories = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFeedbackCategories());
  }, []);

  const { categories } = useSelector((state: IGlobalState) => ({ ...state.feedbackCategoryState }));

  return (
    <>
      <SaveFeedbackCategory />
      <h2>Feedback Categories</h2>
      <Grid className={styles.header} container spacing={2}>
        <Grid item xs={3}>
          CATEGORY NAME
        </Grid>
        <Grid item xs={4}>
          DESCRIPTION
        </Grid>
        <Grid item xs={3}>
          DEFAULT SELECTION
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      {(categories || []).map((category, key) => {
        return (
          <Grid key={`categories_${key}`} className={styles.row} container spacing={2}>
            <Grid item xs={12} md={3}>
              <span />
              <span className="title">
                <b>{category.name}</b>
              </span>
            </Grid>
            <Grid item xs={12} md={4}>
              <span>Description</span>
              <span>{category.description}</span>
            </Grid>
            <Grid item xs={12} md={2}>
              <span>Default Selection</span>
              <span>{category.default ? <CheckCircle color="success" /> : 'No'}</span>
            </Grid>
            <Grid item xs={12} md={1} className={styles.actionButtons}>
              <SaveFeedbackCategory category={category} />
              <DeleteFeedbackCategory category={category} />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default FeedbackCategories;

import { takeLatest } from 'redux-saga/effects';
import * as ApiHandler from '../../../../common/api/apiRequest';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import * as OpTypes from '../types';

function* getCandidateStatuses(action: ApiHandler.IRequest) {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

function* saveCandidateStatus(action: ApiHandler.IRequest) {
  yield ApiSagaRequest(action, [HttpStatus.Status201Created, HttpStatus.Status200OK]);
}

function* removeCandidateStatus(action: ApiHandler.IRequest) {
  yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
}

export function* getCandidateStatusesSaga() {
  yield takeLatest(OpTypes.GET_CANDIDATE_STATUSES, getCandidateStatuses);
}

export function* saveCandidateStatusSaga() {
  yield takeLatest(OpTypes.SAVE_CANDIDATE_STATUS, saveCandidateStatus);
}

export function* removeCandidateStatusSaga() {
  yield takeLatest(OpTypes.REMOVE_CANDIDATE_STATUS, removeCandidateStatus);
}

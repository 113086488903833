import produce from 'immer';
import * as appStorageService from '../../../../common/helpers/appStorageService';
import { getDefault, IAuthState } from '../models/authState';
import {
  AUTH_CHANGED,
  GET_LOGGED_IN_USER_FAILED,
  GET_LOGGED_IN_USER_SUCCESS,
  GET_LOGGEDIN_USER,
  SIGN_IN,
  TOKEN_CHANGED,
  USER_NAME_CHANGED
} from '../types/authTypes';

export default function authStateReducer(state: IAuthState = getDefault(), action: any): IAuthState {
  switch (action.type) {
    case USER_NAME_CHANGED: {
      return produce(state, (draft) => {
        draft.userName = action.payload;
      });
    }
    case TOKEN_CHANGED: {
      return produce(state, (draft) => {
        draft.token = action.payload;
      });
    }
    case AUTH_CHANGED: {
      return produce(state, (draft) => {
        draft.isAuthenticated = action.payload;
        if (!draft.isAuthenticated) {
          appStorageService.save('redirect', location.pathname + location.search);
        }
      });
    }
    case GET_LOGGEDIN_USER:
    case SIGN_IN:
      return produce(state, (draft) => {
        draft.isPending = true;
      });
    case GET_LOGGED_IN_USER_SUCCESS:
      return produce(state, (draft) => {
        localStorage.setItem('authUser', JSON.stringify(action.payload));
        draft.authUser = action.payload;
        draft.isPending = false;
      });
    case GET_LOGGED_IN_USER_FAILED:
      return produce(state, (draft) => {
        draft.isPending = false;
      });
    default:
      return state;
  }
}

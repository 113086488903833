import { Box } from '@mui/material';
import useScreenSize from 'common/hooks/useScreenSize';
import { useSnackbar } from 'common/hooks/useSnackbar';
import { changeUserDeleteStatus } from 'modules/manage-users/operations/actions/userOperationActions';
import { UserDeletionStatus } from 'modules/manage-users/operations/enums/userDeletionStatus';
import { IUserModel } from 'modules/manage-users/operations/models';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import UserCard from '../UserCard/UserCard';
import styles from './UserList.module.scss';

interface IProps {
  users: IUserModel[];
}

const UserList: React.FC<IProps> = (props) => {
  const { users } = props;

  const { isMobile } = useScreenSize();
  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const { deletionStatus } = useSelector((state: IGlobalState) => {
    return {
      deletionStatus: state.userManagementState.userState.userOperation.deletetionStatus
    };
  });

  useEffect(() => {
    if ([UserDeletionStatus.Success, UserDeletionStatus.Failure].includes(deletionStatus)) {
      snackbar.snackbar({
        message: deletionStatus,
        duration: 3000,
        variant: deletionStatus === UserDeletionStatus.Success ? 'success' : 'error'
      });
      dispatch(changeUserDeleteStatus(UserDeletionStatus.Idle));
    }
  }, [deletionStatus]);

  const renderList = (users: IUserModel[]) => {
    return (
      <>
        {!isMobile && (
          <div className={styles.usersListHeaders}>
            <span className="flexBasis15">FULL NAME</span>
            <span className="flexBasis20">EMAIL ADDRESS</span>
            <span className="flexBasis15">ROLE</span>
            <span className="flexBasis25">ADDITIONAL ROLE PERMISSIONS</span>
            <span className="flexBasis15">STATUS</span>
            <span className="flexBasis10"></span>
          </div>
        )}
        {(users || []).map((user: IUserModel, i) => (
          <UserCard key={user.id} user={user} />
        ))}
      </>
    );
  };

  return (
    <>
      <Box>
        <div>{renderList(users)}</div>
      </Box>
    </>
  );
};

export default UserList;

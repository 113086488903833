import { IFormData } from '../../../../common/models/formDataModels';
import { IOpenDay } from '../models/feedbackModel';

export const filterForPastDays = (openDay: IOpenDay) => {
  return openDay.status === 'Completed';
};

export const filterForToday = (openDay: IOpenDay) => {
  return openDay.status === 'Now';
};

export const validateFeedbackNote = (note: IFormData): boolean => {
  const hasFeedback = note.tags.length || note.note;
  return note.category && hasFeedback;
};

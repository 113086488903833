import { Feature } from '../../../../common/enums/appEnums';

export const FEEDBACK_CHANGED = `FEEDBACK_CHANGED${Feature.Feedback}`;
export const OPEN_DAYS_CHANGED = `OPEN_DAYS_CHANGED${Feature.Feedback}`;
export const COMPLETED_OPEN_DAYS_CHANGED = `COMPLETED_OPEN_DAYS_CHANGED${Feature.Feedback}`;
export const FEEDBACK_POSTS_CHANGED = `FEEDBACK_POSTS_CHANGED${Feature.Feedback}`;
export const TEAM_FEEDBACK_POSTS_CHANGED = `TEAM_FEEDBACK_POSTS_CHANGED${Feature.Feedback}`;
export const CANDIDATES_ADDED_TO_TEAM = `CANDIDATES_ADDED_TO_TEAM${Feature.Candidates}`;
export const CANDIDATE_REMOVED_FROM_TEAM = `CANDIDATE_REMOVED_FROM_TEAM${Feature.Candidates}`;
export const FEEDBACK_POST_UPDATED = `FEEDBACK_POST_UPDATED${Feature.Feedback}`;
export const CHANGE_FEEDBACK_TAB = `CHANGE_FEEDBACK_TAB${Feature.Feedback}`;

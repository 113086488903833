export enum FeedbackCategory {
  None = 0
}

export enum AddCandidatesToTeamStatus {
  Idle = 'Idle',
  Adding = 'Adding candidate(s) to team',
  Success = 'Successfully added candidate(s) to team',
  Failure = 'Error adding candidate(s) to team'
}

export enum RemoveCandidateFromTeamStatus {
  Idle = 'Idle',
  Adding = 'Removing candidate from team',
  Success = 'Successfully removed candidate from team',
  Failure = 'Error removing candidate from team'
}
export enum FeedbackCreationStatus {
  Idle = 'Idle',
  Adding = 'Adding feedback post',
  Success = 'Successfully added feedback post',
  Failure = 'Error adding feedback post'
}

export enum FeedbackDeletionStatus {
  Idle = 'Idle',
  Deleting = 'Deleting feedback post',
  Success = 'Successfully deleted feedback post',
  Failure = 'Error deleting feedback post'
}

export enum FeedbackUpdateStatus {
  Idle = 'Idle',
  Updating = 'Updating feedback post',
  Success = 'Successfully updated feedback post',
  Failure = 'Error updating feedback post'
}

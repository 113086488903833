import { IBasicModel } from 'common';
import { IActionRequest, IActionType } from 'common/operations/types/actionTypes';
import { HttpMethod } from '../../../../common/enums/appEnums';
import * as Types from '../types';

export const getAll = (): IActionRequest<undefined> => {
  return {
    type: Types.GET_INSTITUTIONS,
    httpMethod: HttpMethod.Get,
    url: `/institution`,
    onSuccess: getSucceeded
  };
};

export const getSucceeded = (payload: IBasicModel[]): IActionType<IBasicModel[]> => ({
  type: Types.GET_INSTITUTIONS_SUCCEED,
  payload
});

export const save = (payload: IBasicModel): IActionRequest<IBasicModel> => {
  return {
    type: Types.SAVE_INSTITUTION,
    httpMethod: HttpMethod.Post,
    url: `/institution`,
    onSuccess: saveSuccess,
    payload: payload
  };
};

export const saveSuccess = (payload: any): IActionType<any> => ({
  type: Types.SAVE_INSTITUTION_SUCCEED,
  payload
});

export const remove = (id: number): IActionRequest<undefined> => {
  return {
    type: Types.REMOVE_INSTITUTION,
    httpMethod: HttpMethod.Delete,
    url: `/institution/${id}`,
    onSuccess: saveSuccess
  };
};

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Chip, MenuItem, Popover } from '@mui/material';
import { IRatingConfig, ITagOption } from 'modules/manage-app/operations/models/tagModel';
import { useEffect, useState } from 'react';
import { ITag } from '../../../../modules/manage-app/operations/models/tagModel';
import styles from './FeedbackTag.module.scss';

interface IProps {
  label: string;
  value?: string;
  variant?: 'outlined' | 'filled';
  size?: 'small' | 'medium';
  options?: ITagOption[];
  rating?: IRatingConfig;
  defaultValue?: number;
  isRequired?: boolean;
  handleChange: (value: string) => void;
  tag?: ITag;
}

export const FeedbackTag: React.FC<IProps> = ({ label, value, variant, size, handleChange, tag }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selection, setSelection] = useState<string | null>(value ?? null);
  const [ratingOptions, setRatingOptions] = useState<any[]>([]);

  useEffect(() => {
    setSelection(value ?? null);
  }, [value]);

  useEffect(() => {
    if (!value && tag?.required && tag.defaultSelected) {
      const option = tag.options?.find((o) => o.id === tag.defaultSelected);
      const rating = ratingOptions.find((r) => r === tag.defaultSelected);
      const defaultSelection = rating ?? option?.description;
      defaultSelection && handleSelect(defaultSelection);
    }
  }, [ratingOptions]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (option: string) => {
    setSelection(option);
    setAnchorEl(null);
    handleChange(option);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const calcRating = (tagRating: IRatingConfig): any[] => {
    const start: number = tagRating.start;
    const end: number = tagRating.end;
    const direction = start > end ? 'down' : 'up';
    const ratingArray = [];
    for (let index = start; index <= end; ) {
      ratingArray.push(index);
      index = direction === 'up' ? index + 1 : index - 1;
    }
    return [...ratingArray];
  };

  useEffect(() => {
    const menuRating = tag?.rating ? calcRating(tag?.rating) : [];
    setRatingOptions([...menuRating]);
  }, [tag?.rating]);

  const selectionText = selection && +selection ? selection + `/${tag?.rating?.end}` : selection;
  const title = (
    <span>
      {selection ? <b>({selectionText})</b> : ''}
      {label}
    </span>
  );

  return (
    <>
      <Chip
        label={title}
        onClick={handleClick}
        variant={variant ?? 'filled'}
        size={size ?? 'small'}
        icon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        className={styles.chip}
        title={label}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        className={styles.popover}
      >
        <p className={styles.title}>
          <small>{label}</small>
        </p>
        {tag?.rating
          ? ratingOptions.map((n, i) => {
              let item = n;
              if (i === 0) {
                item = n + ` ${tag?.rating?.startDescription}`;
              } else if (i === ratingOptions.length - 1) {
                item = n + ` ${tag?.rating?.endDescription}`;
              }

              return (
                <MenuItem key={n} className={styles.menuItem} onClick={() => handleSelect(n)}>
                  {item}
                </MenuItem>
              );
            })
          : [...(tag?.options ?? [])]
              ?.sort((a, b) => a.priority - b.priority)
              .map((n) => (
                <MenuItem
                  key={n.id}
                  className={styles.menuItem}
                  selected={value ? value === n.description : tag?.defaultSelected === n.id}
                  onClick={() => handleSelect(n.description)}
                >
                  {n.description}
                </MenuItem>
              ))}
      </Popover>
    </>
  );
};

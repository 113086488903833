import { IPaginatedResponse } from '../../../../common/models/paginatedResponse';
import { IThing } from '../../../candidates/operations/models/candidatesModel';
import { IUserModel, IUserSearchModel } from './userModel';

import IUsersOperation, { defaultUserOperation } from './userOperationModel';

export interface IUserState {
  usersPagination: IPaginatedResponse<IUserModel> | null;
  users: Array<IUserModel>;
  filteredUsers: IUserModel[];
  adUsers: IUserSearchModel[];
  filteredUsersPagination: IPaginatedResponse<IUserModel> | null;
  totalRecords: number;
  roles: IThing[];
  operationSuccess?: boolean | null;
  userOperation: IUsersOperation;
}

export const defaultUsersState: IUserState = {
  users: [],
  filteredUsers: [],
  totalRecords: 0,
  roles: [],
  operationSuccess: null,
  usersPagination: {
    data: [],
    pageNo: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    totalRecordsOnPage: 0
  },
  filteredUsersPagination: {
    data: [],
    pageNo: 0,
    pageSize: 0,
    totalPages: 0,
    totalRecords: 0,
    totalRecordsOnPage: 0
  },
  adUsers: [],
  userOperation: defaultUserOperation
};

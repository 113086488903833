import { PhotoCameraOutlined } from '@mui/icons-material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Avatar, Card, CardActionArea, CardContent, CardMedia, Chip, Container, Typography } from '@mui/material';
import GuardedEdit from 'common/components/GuardedEdit/GuardedEdit';
import { ROUTES } from 'common/constants/routesConstants';
import { resizeImage } from 'common/helpers/imageHelpers';
import { useCheckPermissions } from 'common/hooks/useCheckPermissions';
import { candidatesOpActions } from 'modules/candidates/operations/actions';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IGlobalState } from 'startup/store/globalState';
import { BootstrapTooltip } from '../../../../common/components/OverflowTooltip/OverflowTooltip';
import { formatShortDate } from '../../../../common/helpers/dateHelpers';
import { CandidateUpdatingStatus } from '../../operations/enums/candidateEnums';
import { getCandidateStatusMetadata } from '../../operations/helpers/candidateHelpers';
import { ICandidate } from '../../operations/models/candidatesModel';
import styles from './CandidateCard.module.scss';

interface IProps {
  candidate: ICandidate;
}

export const CandidateCard: React.FC<IProps> = (props: IProps) => {
  const { candidate } = props;
  const dispatch = useDispatch();
  const updatingStatus = useSelector((state: IGlobalState) => state.candidatesState.candidatesOperation.updatingStatus);
  const { locations, openDay, statuses } = useSelector((state: IGlobalState) => {
    return {
      ...state.commonState.common,
      ...state.sessionsState.sessions,
      ...state.sessionsState.sessionsOperation,
      ...state.candidateStatusState
    };
  });

  const [tempPicUrl, setTempPicUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if ([CandidateUpdatingStatus.Success, CandidateUpdatingStatus.Failure].includes(updatingStatus)) {
      dispatch(candidatesOpActions.changeCandidateUpdateStatus(CandidateUpdatingStatus.Idle));
    }
  }, [updatingStatus]);

  const fileToBlob = async (file: File) => new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });

  const handlePicChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const originalImage = event.target.files[0];
      const resizedImage = await resizeImage(originalImage);

      setTempPicUrl(URL.createObjectURL(resizedImage));

      const formData = new FormData();
      formData.append('file', await fileToBlob(resizedImage));

      dispatch(candidatesOpActions.updateCandidatePhoto(candidate.id, formData, { attended: true }));
    }
  };

  const qualifications = candidate.candidateQualifications ?? [];
  const latestQualification = qualifications[qualifications.length - 1];
  const photoUrl = tempPicUrl ?? candidate.photo;
  const hasEditCandidatePermission = useCheckPermissions([
    {
      actionType: ClaimActionType.EDIT,
      claimType: ClaimTypes.CANDIDATES
    }
  ]);

  const candidateStatusMetadata = getCandidateStatusMetadata(statuses, candidate.status || 0);

  return (
    <Card className={styles.card}>
      <div className={styles.cardPicture}>
        {photoUrl && photoUrl !== '' ? (
          <CardMedia component="img" className={styles.cardPicture} src={photoUrl} />
        ) : (
          <Container disableGutters className={styles.cardPicture}>
            <Avatar />
          </Container>
        )}
      </div>
      {hasEditCandidatePermission ? (
        <>
          <PhotoCameraOutlined className={styles.uploadIcon} />
          <input
            className={styles.imageUploader}
            capture="environment"
            accept="image/*"
            type="file"
            onChange={handlePicChange}
          />
        </>
      ) : null}
      <CardActionArea component={Link} to={`${ROUTES.Candidate}/${candidate.id}`}>
        <CardContent className={styles.cardContent}>
          <Typography className="m-0" color="#5D5D5D" variant="h6">
            <span className={styles.candidateName}>{candidate.name}</span>
            <GuardedEdit
              editStyle="outlined"
              iconProps={{ fontSize: 'small' }}
              sx={{ height: 18, width: 18, cursor: 'pointer' }}
              className="float-right"
              scopes={[
                {
                  actionType: ClaimActionType.EDIT,
                  claimType: ClaimTypes.CANDIDATES
                }
              ]}
              renderErrorItem={() => <></>}
            />
          </Typography>
          <Container disableGutters sx={{ display: 'flex', gap: '4px' }}>
            <Chip
              size={'small'}
              sx={{
                margin: 0,
                border: '1px solid #009499',
                backgroundColor: '#DFF7F7'
              }}
              component={'span'}
              label={
                candidate.willingToRelocate ? (
                  <BootstrapTooltip
                    title={`Willing to relocate from ${candidate.location} to ${
                      locations.find((x) => x.id === candidate.relocationId)?.name
                    }.`}
                    arrow
                    placement={'top-end'}
                    enterTouchDelay={0}
                  >
                    <span
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center'
                      }}
                    >
                      {candidate.location}
                      <ArrowRightAltIcon fontSize={'small'} />
                      {locations.find((x) => x.id === candidate.relocationId)?.name}
                    </span>
                  </BootstrapTooltip>
                ) : (
                  <span>{candidate.location}</span>
                )
              }
            />
            <Chip
              size={'small'}
              sx={{ margin: 0, border: '1px solid #8081BF' }}
              style={{
                backgroundColor: candidateStatusMetadata.displayBackgroundColor,
                border: candidateStatusMetadata.displayBorder
              }}
              component={'p'}
              label={candidateStatusMetadata.displayText}
            />
          </Container>
          <Container disableGutters sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Container disableGutters sx={{ display: 'flex', gap: '8px' }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 14 }} component={'p'}>
                {process.env.REACT_APP_SESSION_NAME}:
              </Typography>
              <Typography sx={{ fontSize: 14 }} component={'p'}>
                {formatShortDate(openDay?.date) ?? '-'}
              </Typography>
            </Container>
            <Container disableGutters sx={{ display: 'flex', gap: '8px' }}>
              <Typography sx={{ fontSize: 14 }} component={'p'}>
                {candidate.aic ?? '-'}
              </Typography>
              |
              <Typography sx={{ fontSize: 14 }} component={'p'}>
                {candidate.gender ?? '-'}
              </Typography>
            </Container>
            <Typography sx={{ fontSize: 14, display: 'flex', alignItems: 'center' }} component={'p'}>
              <Typography
                sx={{ fontSize: 14, display: 'flex', alignItems: 'center' }}
                component={'span'}
                variant={'body2'}
              >
                {latestQualification?.qualificationName || '-'}
              </Typography>
            </Typography>
          </Container>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
export default CandidateCard;

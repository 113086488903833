import { addCandidatesToTeamSaga } from './addCandidatesToTeamSaga';
import { deleteFeedbackPostSaga } from './deleteFeedbackPostSaga';
import { deleteTeamFeedbackPostSaga } from './deleteTeamFeedbackPostSaga';
import { getCompletedOpenDaysSaga } from './getCompletedOpenDaysSaga';
import { getFeedbackPostsSaga } from './getFeedbackPostsSaga';
import { getOpenDaysSaga } from './getOpenDaysSaga';
import { getTeamFeedbackPostsSaga } from './getTeamFeedbackPostsSaga';
import { removeCandidateFromTeamSaga } from './removeCandidateFromTeamSaga';
import { submitFeedbackPostSaga } from './submitFeedbackPostSaga';
import { updateFeedbackPostSaga } from './updateFeedbackPostSaga';

export const feedbackSagas = [
  submitFeedbackPostSaga(),
  getFeedbackPostsSaga(),
  getTeamFeedbackPostsSaga(),
  deleteFeedbackPostSaga(),
  deleteTeamFeedbackPostSaga(),
  getOpenDaysSaga(),
  getCompletedOpenDaysSaga(),
  updateFeedbackPostSaga(),
  addCandidatesToTeamSaga(),
  removeCandidateFromTeamSaga()
];

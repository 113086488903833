import { SxProps, Theme, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    minHeight: '36px',
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    paddingInline: '14px'
  }
}));

interface IProps {
  name: string | undefined;
  sx: SxProps<Theme>;
  variant: 'h5' | 'h4';
}

// https://stackoverflow.com/questions/56588625/react-show-material-ui-tooltip-only-for-text-that-has-ellipsis
export const OverflowTooltip = (props: IProps) => {
  const [hover, setHover] = useState(false);
  const typographyRef = useRef<HTMLElement>(null);
  const compareSize = () => {
    if (typographyRef?.current) {
      const element = typographyRef.current;
      const compare = element.scrollWidth > element.clientWidth;
      setHover(compare);
    }
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
  }, []);

  // remove resize listener again on "componentWillUnmount"
  useEffect(
    () => () => {
      window.removeEventListener('resize', compareSize);
    },
    []
  );
  return (
    <BootstrapTooltip
      title={props.name}
      disableHoverListener={!hover}
      arrow
      placement={'bottom-end'}
      enterTouchDelay={0}
    >
      <Typography ref={typographyRef} sx={props.sx} variant={props.variant} noWrap>
        {props.name}
      </Typography>
    </BootstrapTooltip>
  );
};

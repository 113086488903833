import { Card, CardActionArea, CardContent } from '@mui/material';
import { ROUTES } from 'common/constants/routesConstants';
import dayjs from 'dayjs';
import { locationFullNames } from 'modules/candidates/operations/helpers/candidateHelpers';
import { IOpenDaySummary } from 'modules/sessions/operations/models/sessionsModel';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styles from './OpenDayCard.module.scss';

interface IProps {
  openDay: IOpenDaySummary;
}

export const OpenDayCard: React.FC<IProps> = ({ openDay }) => {
  const getLocationFullName = (location: string) => {
    return locationFullNames[location] || '';
  };

  const dateDiff = dayjs().startOf('D').diff(dayjs(openDay.date).startOf('D'), 'days');

  return (
    <>
      <Card
        className={`${styles.card} ${
          dateDiff > 0 ? styles.statusComplete : dateDiff == 0 ? styles.statusToday : styles.statusFuture
        }`}
      >
        <CardActionArea component={Link} to={`${ROUTES.OpenDayCandidates}/${openDay.id}`}>
          <CardContent className={styles.cardContent}>
            <div className={styles.headingCenter}>
              <b className={openDay.status === 'Completed' ? styles.headingColorGreen : styles.headingColorBlue}>
                {openDay.name}
              </b>
            </div>
            <div style={{ display: 'grid' }}>
              <b className={styles.location}>
                {moment(openDay.date).format('D MMMM yyyy')} - {getLocationFullName(openDay.location?.name)}
              </b>
            </div>
            {openDay.status === 'Completed' && (
              <div>
                <div style={{ display: 'flex' }}>
                  <b className={`flexBasis70 ${styles.candidateInfo}`}>
                    Candidates Attended: {openDay.candidatesPresent}
                  </b>
                  <b className={`flexBasis30 ${styles.candidateInfo}`}>Absent: {openDay.candidatesAbsent}</b>
                </div>
                <div>
                  <b className={styles.candidateInfo}>Offers: {openDay.offersSent}</b>
                </div>
              </div>
            )}
            {!(openDay.status === 'Completed') && (
              <div>
                <div>
                  <b className={styles.candidateInfo}>Candidates Invited: {openDay.noOfCandidates}</b>
                </div>
                <div>
                  <b className={styles.candidateInfo}>&nbsp;</b>
                </div>
              </div>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};
export default OpenDayCard;

export interface IConfiguration {
  apiDebounceMs: number;
}

/** Exposes the App configuration via an interface. */
export default function getConfiguration(): IConfiguration {
  const apiDebounceMs = +(process.env.REACT_APP_DEFAULT_API_DEBOUNCE_MS ?? '500');
  return {
    apiDebounceMs
  };
}

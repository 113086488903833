import { Container, Grid } from '@mui/material';
import { ICandidate } from '../../operations/models/candidatesModel';
import CandidateCard from '../CandidateCard/CandidateCard';

interface IProps {
  candidates: ICandidate[];
}

const CandidateGridView: React.FC<IProps> = (props) => {
  if (!props.candidates?.length) return <></>;

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          height: '100%',
          display: 'flex',
          flex: '1 0 0'
        }}
      >
        <Grid
          container
          sx={{
            height: 'fit-content',
            gridAutoFlow: 'column'
          }}
          spacing={2}
        >
          {[...props.candidates]
            .sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0)
            .map((candidate: ICandidate) => (
              <Grid key={candidate.id} item xs={12} sm={6} md={6} lg={4} xl={3}>
                <CandidateCard candidate={candidate} />
              </Grid>
            ))}
        </Grid>
      </Container>
      <br />
    </>
  );
};

export default CandidateGridView;

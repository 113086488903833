import useScreenSize from 'common/hooks/useScreenSize';
import { changeHttpError } from 'modules/app/operations/actions/appActions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../../common/constants/routesConstants';
import * as appStorageService from '../../../common/helpers/appStorageService';
import { IGlobalState } from '../../../startup/store/globalState';
import Auth from '../components/Auth';

export const AuthContainer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useScreenSize();
  const isAuthenticated = useSelector((state: IGlobalState) => state.authState.isAuthenticated);
  const landingPage = isMobile || isTablet ? ROUTES.OpenDays : ROUTES.OpenDayCandidates;

  useEffect(() => {
    dispatch(changeHttpError());
  }, []);
  useEffect(() => {
    if (isAuthenticated) {
      const redirect = appStorageService.get<string | undefined>('redirect');
      navigate(redirect?.length ? redirect : landingPage);
      redirect?.length && setTimeout(() => appStorageService.remove('redirect'), 1000);
    }
  }, [isAuthenticated]);

  return <Auth />;
};

export default AuthContainer;

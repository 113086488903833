import FilterButton, { IGenericFilter } from 'common/components/FilterComponent/FilterButton';

import { IUserFilters } from '../../operations/models';

import { FilterTypeEnum } from 'common/enums/filterTypeEnum';
import { ChangeType } from 'modules/manage-users/container/ManageUsersContainer';
import { claimsBuilder } from 'modules/manage-users/operations/functions/claimsBuilder';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';

export const filterParams: IGenericFilter[] = [
  {
    label: 'Role',
    reduxData: [],
    selectedOptions: [],
    filterType: FilterTypeEnum.SELECT
  },
  {
    label: 'Additional Role Permissions',
    reduxData: [],
    selectedOptions: [],
    filterType: FilterTypeEnum.SELECT
  },
  {
    label: 'Status',
    reduxData: ['Active', 'Inactive'],
    selectedOptions: [],
    filterType: FilterTypeEnum.BASIC_SELECT
  }
];

interface IProps {
  onChange: (changeType: ChangeType.FILTER | ChangeType.RESET, filters: IUserFilters) => void;
}

const UserListFilterButton: React.FC<IProps> = (props) => {
  const { onChange } = props;
  const [filters, setFilters] = useState<IGenericFilter[]>(filterParams);
  const [claims, setClaims] = useState({});

  const { allClaims, roles } = useSelector((state: IGlobalState) => {
    return {
      allClaims: state.userManagementState.claimState.claims,
      roles: state.userManagementState.roleState.roles
    };
  });

  const onFilterPressed = (selectedFilters: IGenericFilter[]) => {
    const changeType = getActiveFilterCount(selectedFilters) > 0 ? ChangeType.FILTER : ChangeType.RESET;
    const filters = getActiveFilterCount(selectedFilters) > 0 ? getActiveFilters(selectedFilters) : { statuses: true };
    onChange(changeType, filters);
  };

  const getActiveFilterCount = (filters: IGenericFilter[]) => {
    return Object.values(filters)
      .map((filter) => filter.selectedOptions)
      .flat(1).length;
  };

  const getActiveFilters = (filters: IGenericFilter[]) => {
    const getSelectedClaimIds = (selectedClaims: string[]) =>
      selectedClaims.map((selectedClaim) => claims[selectedClaim as keyof typeof claims]);

    const getSelectedStatus = () => {
      if (filters[2].selectedOptions.length === 0) return true;
      return filters[2].selectedOptions[0] === 'Active';
    };

    return {
      userRoles: filters[0].selectedOptions,
      userClaims: getSelectedClaimIds(filters[1].selectedOptions),
      statuses: getSelectedStatus()
    };
  };

  useEffect(() => {
    const newState = [...filters];
    if (allClaims.length > 0) {
      const claims = claimsBuilder(allClaims);
      const index = newState.findIndex((filter) => filter.label === 'Additional Role Permissions');
      newState[index] = { ...newState[index], reduxData: Object.keys(claims) };
      setClaims(claims);
    }

    if (roles.length > 0) {
      const roleLabels = roles.map((role) => role.name);
      const index = newState.findIndex((filter) => filter.label === 'Role');
      newState[index] = { ...newState[index], reduxData: roleLabels };
    }
    setFilters(newState);
  }, [allClaims, roles]);

  return (
    <>
      <FilterButton filterTypes={filters} onFilterClicked={onFilterPressed}></FilterButton>
    </>
  );
};

export default UserListFilterButton;

import { commonSagas } from 'common/operations/sagas';
import { calibrationSagas } from 'modules/calibration/operations/sagas';
import { all } from 'redux-saga/effects';
import { authSagas } from '../../modules/auth/operations/sagas';
import { candidatesSagas } from '../../modules/candidates/operations/sagas';
import { feedbackSagas } from '../../modules/feedback/operations/sagas';
import { manageAppSagas } from '../../modules/manage-app/operations/sagas';
import { userManagementSaga } from '../../modules/manage-users/operations/sagas';
import { reportSagas } from '../../modules/report/operations/sagas';
import { sessionsSagas } from '../../modules/sessions/operations/sagas';

function* rootSaga() {
  yield all([
    ...candidatesSagas,
    ...feedbackSagas,
    ...sessionsSagas,
    ...authSagas,
    ...manageAppSagas,
    ...commonSagas,
    ...userManagementSaga,
    ...calibrationSagas,
    ...reportSagas
  ]);
}

export default rootSaga;

import { Feature } from 'common/enums/appEnums';

export const CREATE_SESSION = `CREATE_SESSION${Feature.Sessions}`;
export const DELETE_SESSION = `DELTE_SESSION${Feature.Sessions}`;
export const UPDATE_SESSION = `UPDATE_SESSION${Feature.Sessions}`;
export const CHANGE_SESSION_CREATION_STATUS = `CHANGE_SESSION_CREATION_STATUS${Feature.Sessions}`;
export const CHANGE_SESSION_DELETION_STATUS = `CHANGE_SESSION_DELETION_STATUS${Feature.Sessions}`;
export const CHANGE_SESSION_UPDATE_STATUS = `CHANGE_SESSION_UPDATE_STATUS${Feature.Sessions}`;
export const GET_TEAM_CAPTAINS = `GET_TEAM_CAPTAINS${Feature.Sessions}`;
export const GET_OPEN_DAY_SESSIONS = `GET_OPEN_DAY_SESSIONS${Feature.Sessions}`;
export const GET_OPEN_DAY_SESSION_TAGS = `GET_OPEN_DAY_SESSION_TAGS${Feature.Sessions}`;
export const GET_OPEN_DAY_SESSION_SUMMARIES = `GET_OPEN_DAY_SESSION_SUMMARIES${Feature.Sessions}`;
export const CHANGE_ACTIVE_SESSION_IN_EDIT = `CHANGE_ACTIVE_SESSION_IN_EDIT${Feature.Sessions}`;
export const ADD_TEAM_TO_SESSION = `ADD_TEAM_TO_SESSION${Feature.Sessions}`;
export const CHANGE_ADD_TEAM_TO_SESSION_STATUS = `CHANGE_ADD_TEAM_TO_SESSION_STATUS${Feature.Sessions}`;
export const GET_SESSION_BY_ID = `GET_SESSION_BY_ID${Feature.Sessions}`;
export const GET_SESSION_BY_ID_SUCCEED = `GET_SESSION_BY_ID_SUCCEED${Feature.Sessions}`;
export const GET_UNAVAILABLE_SESSION_DATES = `GET_UNAVAILABLE_SESSION_DATES${Feature.Sessions}`;
export const GET_UNAVAILABLE_SESSION_DATES_SUCCEED = `GET_UNAVAILABLE_SESSION_DATES_SUCCEED${Feature.Sessions}`;
export const GET_UNAVAILABLE_SESSION_DATES_FAILED = `GET_UNAVAILABLE_SESSION_DATES_FAILED${Feature.Sessions}`;
export const GET_OPEN_DAY_SESSION_TEAMS = `GET_OPEN_DAY_SESSION_TEAMS${Feature.Sessions}`;

import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField
} from '@mui/material';
import EntelectButton from 'common/components/EntelectButton/EntelectButton';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import { validateTeam } from 'modules/sessions/operations/helpers/validation';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { IGlobalState } from 'startup/store/globalState';
import { ISessionTeamRequestDto } from '../../../sessions/operations/models/sessionsModel';
import styles from './index.module.scss';

interface IProps {
  handleClose: () => void;
  handleSubmit: (data: ISessionTeamRequestDto) => void;
}

const AddTeamDialog: React.FC<IProps> = ({ handleClose, handleSubmit }: IProps) => {
  const { teamCaptains, openDay } = useSelector((state: IGlobalState) => ({
    ...state.sessionsState.sessions,
    ...state.sessionsState.sessionsOperation
  }));
  const [team, setTeam] = useState<ISessionTeamRequestDto>({});
  const [errors, setErrors] = useState<string | undefined>();

  const onUpdateTeamValues = (key: string, value: string | number) => {
    let tempTeam = { ...team };
    (tempTeam as any)[key] = value;
    if (key === 'teamCaptainId') {
      tempTeam.name = teamCaptains.find((captain) => captain.id === value)?.name + "'s Team";
    }
    const teams = openDay?.teams ?? [];
    setErrors(validateTeam(tempTeam, [...teams, tempTeam])?.message);
    setTeam(tempTeam);
  };

  return (
    <Modal open={true} onClose={handleClose}>
      <Box className={styles.modalBox}>
        <Card className={styles.modal}>
          <CardContent>
            <h2 className="textCenter">Add Team</h2>

            <FormGroup>
              <FormControl fullWidth>
                <InputLabel id="demo-select-small-label">Team Captain*</InputLabel>
                <Select
                  value={team.teamCaptainId}
                  label="Team Captain"
                  onChange={(e) => onUpdateTeamValues('teamCaptainId', e.target.value)}
                >
                  {teamCaptains.map((captain) => (
                    <MenuItem key={`captain_${captain.id}`} value={captain.id}>
                      {captain.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <br />
              <FormControl fullWidth>
                <TextField
                  value={team.name}
                  onChange={(e) => onUpdateTeamValues('name', e.target.value)}
                  label="Team Name*"
                  variant="outlined"
                  error={!!errors}
                  helperText={errors}
                  InputLabelProps={{ shrink: !!team.name?.length }}
                />
              </FormControl>
            </FormGroup>

            <Stack spacing={2} direction="row" sx={{ marginTop: '50px', marginBottom: '10px' }}>
              <EntelectButton variant={ButtonType.Secondary} handleClick={handleClose} label="Cancel" />
              <EntelectButton
                variant={ButtonType.Primary}
                handleClick={() => handleSubmit(team)}
                label="Save"
                disabled={!!errors}
              />
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default AddTeamDialog;

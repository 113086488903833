import { TableBody, TableCell, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import EntelectCheckBox from "../../../common/components/EntelectCheckBox/EntelectCheckBox";
import EntelectCheckBox from 'common/components/EntelectCheckBox/EntelectCheckBox';
import { CLAIM_ACTION_TYPES } from 'common/constants/appContants';
import { groupBy } from 'common/helpers/dataHelpers';
import { getAllClaims } from 'modules/manage-users/operations/actions/claimActions';
import { mapToClaimModels, mapToClaimObject } from 'modules/manage-users/operations/functions/claimsTransformation';
import { ClaimActionType, IClaimModel, IClaimModelRole, IUserClaimModel } from 'modules/manage-users/operations/models';
import { IRole } from 'modules/manage-users/operations/models/IRole';
import { ClaimsViewModel } from 'modules/manage-users/operations/models/claimsViewModel';
import { IGlobalState } from 'startup/store/globalState';

interface IPermissionMatrixProps {
  loadMatrix: boolean;
  userClaims: IUserClaimModel[];
  role: IRole;
  onChange: (updatedClaims: IUserClaimModel[]) => void;
}

const PermissionMatrix = (props: IPermissionMatrixProps) => {
  const [loadMatrix] = useState(!!props.loadMatrix);
  const [claimsByType, setClaimsByType] = useState({} as { [key: string]: IClaimModel[] });
  const [userClaim, setUserClaim] = useState({} as ClaimsViewModel);

  const dispatch = useDispatch();

  const { allClaims } = useSelector((state: IGlobalState) => {
    return {
      allClaims: state.userManagementState.claimState.claims
    };
  });

  useEffect(() => {
    if (loadMatrix) dispatch(getAllClaims());
  }, [loadMatrix]);

  useEffect(() => {
    setClaimsByType(groupBy(allClaims, (x) => x.claimType));
  }, [allClaims]);

  useEffect(() => {
    const userClaims: IClaimModelRole[] = (props.userClaims || []).map((claim) => ({
      ...claim,
      isRole: false
    }));
    const roleClaims: IClaimModelRole[] = (props.role?.userRoleClaims || []).map((claim) => ({
      ...claim,
      isRole: true
    }));
    const userClaimSet = mapToClaimObject([...userClaims, ...roleClaims]);
    setUserClaim(userClaimSet);
  }, [props.role]);

  const onPermissionsSelected = (claimType: string, actionType: ClaimActionType, claimId: number, value: boolean) => {
    const tempClaim = { ...userClaim };
    if (!tempClaim[claimType]) {
      tempClaim[claimType] = {};
    }
    const existingClaim = userClaim[claimType]?.[actionType];
    tempClaim[claimType][actionType] = {
      value,
      claimId,
      isRoleClaim: existingClaim?.isRoleClaim,
      claimMapId: existingClaim?.claimMapId || 0
    };
    setUserClaim({ ...tempClaim });

    props.onChange(mapToClaimModels(userClaim));
  };

  return (
    <TableBody>
      {Object.keys(claimsByType).map((claimType) => {
        return (
          <TableRow>
            <TableCell>{claimType}</TableCell>
            {CLAIM_ACTION_TYPES.map((actionType) => {
              const selectedClaim = userClaim[claimType]?.[actionType.key];
              const initialClaim = claimsByType[claimType].find((f) => f.actionType == actionType.key);
              return (
                <TableCell>
                  <EntelectCheckBox
                    handleClick={(e: any, value: boolean) =>
                      onPermissionsSelected(claimType, actionType.key, initialClaim?.id || 0, value)
                    }
                    disabled={selectedClaim?.isRoleClaim}
                    checked={selectedClaim?.value}
                  />
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default PermissionMatrix;

import { AuthenticationResult } from '@azure/msal-browser';
import { AxiosResponse } from 'axios';
import { ROUTES } from 'common/constants/routesConstants';
import { onActionFailed, onActionSuccess } from 'common/operations/actions/commonActions';
import { jwtDecode } from 'jwt-decode';
import { requestSilentToken } from 'modules/auth/operations/sagas/authSaga';
import { call, delay, getContext, put, select } from 'redux-saga/effects';
import {
  changeHttpActionEnded,
  changeHttpActionStarted,
  changeHttpError,
  changeLoading
} from '../../modules/app/operations/actions/appActions';
import { changeIsAuthenticated, getToken } from '../../modules/auth/operations/actions/authActions';
import { IGlobalState } from '../../startup/store/globalState';
import * as ApiHandler from '../api/apiRequest';
import { IRequest } from '../api/apiRequest';
import { HttpClient } from '../api/httpClient';
import { HTTP_FAILURE_MESSAGE } from '../constants/appContants';
import { HttpMethod, HttpStatus } from '../enums/appEnums';

interface IResponse {
  success: boolean;
  response: any;
}

interface ISagaArguments {
  url: string;
  httpMethod: HttpMethod;
  payload?: any;
  headers?: any;
}

function* ApiSaga(
  httpSuccessStatus: HttpStatus[],
  apiHandler: (action: IRequest) => Promise<AxiosResponse<any>>,
  req: any,
  onSuccess: any,
  onFailure: any,
  operation: string
): any {
  try {
    yield put(changeHttpActionStarted(operation));
    yield put(changeLoading(true));
    const response = yield call(apiHandler, { ...req });
    yield put(changeHttpActionEnded(operation));

    if (httpSuccessStatus.find((s) => s === response.status)) {
      yield put(onSuccess(response.data));
      return { success: true, response };
    } else {
      if (response?.error?.response?.data.path !== '/api/v1/candidate/bulk-import') {
        yield put(
          changeHttpError(response?.data?.title || response?.error?.response?.data?.message || HTTP_FAILURE_MESSAGE)
        );
      }

      yield put(onFailure(response?.data?.title || response?.error?.response?.data?.message));
      return { success: false, response };
    }
  } catch (error) {
    yield put(changeHttpActionEnded(operation));
    yield put(changeHttpError((error as string) || HTTP_FAILURE_MESSAGE));
    if (onFailure) {
      yield put(onFailure({ payload: req.body, error }));
    }

    return { success: false, e: error };
  } finally {
    yield delay(500);
    yield put(changeLoading(false));
  }
}

export function* ApiSagaRequest(action: ApiHandler.IRequest, httpSuccessStatus: HttpStatus[], query?: string) {
  const { payload, url, httpMethod, onSuccess, onFailure, type } = action;
  const httpClient: HttpClient = yield getContext('httpClient');
  let token: string | null = yield select((state: IGlobalState) => state.authState.token);
  let result: AuthenticationResult;

  const request: ISagaArguments = {
    url: url + (query ? '?' + query : ''),
    httpMethod: httpMethod
  };

  try {
    if (!isTokenValid(token)) {
      result = yield call(() => requestSilentToken());
      token = result.accessToken;
      yield put(getToken());
    }
  } catch (error) {
    if (location.pathname !== ROUTES.Verification)
      yield put(changeHttpError('We apologize for the inconvenience, please relogin to continue.'));
    yield put(changeIsAuthenticated(false));
    return { success: false, response: error };
  }

  if (payload) {
    request['payload'] = payload;
  }

  yield put(changeHttpError());
  const response: IResponse = yield ApiSaga(
    httpSuccessStatus,
    ApiHandler.request(httpClient, token as string),
    request,
    onSuccess ?? onActionSuccess,
    onFailure ?? onActionFailed,
    type
  );

  return response || { success: false };
}

function isTokenValid(token: string | null): boolean {
  if (token) {
    return (jwtDecode(token)?.exp ?? 0) * 1000 > Date.now();
  }
  return false;
}

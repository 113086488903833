import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  secure?: boolean;
  path: string;
  type?: ContentType;
  query?: QueryParamsType;
  format?: ResponseType;
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded'
}

export class HttpClient {
  public instance: AxiosInstance;
  private _format?: ResponseType;

  constructor(baseUrl: string) {
    axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'any value';
    this.instance = axios.create({ baseURL: baseUrl });
  }

  set format(value: ResponseType) {
    this._format = value;
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const responseFormat = (format && this._format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object' && params.headers) {
      params.headers.common = { Accept: '*/*' };
      params.headers.post = {};
      params.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...params,
      headers: {
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
        ...(params.headers || {})
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path
    });
  };
}

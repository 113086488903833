import { put, takeLatest } from 'redux-saga/effects';
import { HttpStatus } from '../../../../common/enums/appEnums';
import { ApiSagaRequest } from '../../../../common/saga/apiSaga';
import * as UserTypes from '../../../manage-users/operations/types';
import { userActions } from '../actions';
import { changeUserDeleteStatus } from '../actions/userOperationActions';
import { UserDeletionStatus } from '../enums/userDeletionStatus';

function* getUsers(action: any): any {
  const { success, response } = yield ApiSagaRequest(action, [HttpStatus.Status200OK], action.payload);
  if (success) {
    yield put(userActions.fetchedUsers(response.data));
  }
}

export function* getUsersSaga() {
  yield takeLatest(UserTypes.FETCH_USERS, getUsers);
}

function* saveUser(action: any): any {
  yield ApiSagaRequest(action, [HttpStatus.Status201Created, HttpStatus.Status200OK]);
}

export function* saveUserSaga() {
  yield takeLatest(UserTypes.SAVE_USER, saveUser);
}

function* deleteUser(action: any): any {
  const { success } = yield ApiSagaRequest(action, [HttpStatus.Status200OK]);
  if (success) {
    yield put(changeUserDeleteStatus(UserDeletionStatus.Success));
  } else {
    yield put(changeUserDeleteStatus(UserDeletionStatus.Failure));
  }
}

export function* deleteUserSaga() {
  yield takeLatest(UserTypes.DELETE_USER, deleteUser);
}

import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';

export const manageAppClaims = [
  {
    actionType: ClaimActionType.EDIT,
    claimType: ClaimTypes.MANAGE_APPLICATIONS
  }
];

export const hasEditFeedbackCalibration = [
  {
    actionType: ClaimActionType.EDIT,
    claimType: ClaimTypes.FEEDBACK_CALIBRATION
  }
];

import { Box, CardMedia, Fade, Modal } from '@mui/material';
import React from 'react';
import styles from './CandidateImageModal.module.scss';

interface IProps {
  photoUrl: string;
  handleClose: () => void;
}

const CandidateImageModal: React.FC<IProps> = ({ photoUrl, handleClose }) => {
  return (
    <Modal open={true} onClose={handleClose}>
      <Fade in={true}>
        <Box className={styles.dialog}>
          <CardMedia className={styles.image} component="img" image={photoUrl} alt="Candidate Image" />
        </Box>
      </Fade>
    </Modal>
  );
};

export default CandidateImageModal;

import { HttpStatus } from 'common/enums/appEnums';
import { ApiSagaRequest } from 'common/saga/apiSaga';
import { put, takeLatest } from 'redux-saga/effects';
import { changeFeedBackCreateStatus } from '../actions/feedbackOperationActions';
import { FeedbackCreationStatus } from '../enums/feedbackEnums';
import * as OpTypes from '../types/feedbackOperationTypes';

function* submitFeedbackPost(action: any): any {
  yield put(changeFeedBackCreateStatus(FeedbackCreationStatus.Adding));
  const { success } = yield ApiSagaRequest(action, [HttpStatus.Status201Created]);

  if (success) {
    yield put(changeFeedBackCreateStatus(FeedbackCreationStatus.Success));
  } else {
    yield put(changeFeedBackCreateStatus(FeedbackCreationStatus.Failure));
  }
}

export function* submitFeedbackPostSaga() {
  yield takeLatest(OpTypes.SUBMIT_FEEDBACK_POST, submitFeedbackPost);
}

import produce from 'immer';
import ISessionsOperation, { sessionsOperationDefault } from '../models/sessionsOperationModel';
import * as Types from '../types/sessionOperationTypes';

export default function sessionsOperationReducer(
  state: ISessionsOperation = sessionsOperationDefault,
  action: any
): ISessionsOperation {
  switch (action.type) {
    case Types.CHANGE_SESSION_CREATION_STATUS:
      return produce(state, (draft) => {
        draft.creationStatus = action.payload;
      });
    case Types.CHANGE_SESSION_DELETION_STATUS:
      return produce(state, (draft) => {
        draft.deletionStatus = action.payload;
      });
    case Types.CHANGE_SESSION_UPDATE_STATUS:
      return produce(state, (draft) => {
        draft.updateStatus = action.payload;
      });
    case Types.CHANGE_ACTIVE_SESSION_IN_EDIT:
      return produce(state, (draft) => {
        draft.activeSessionInUpdate = action.payload;
      });
    case Types.CHANGE_ADD_TEAM_TO_SESSION_STATUS:
      return produce(state, (draft) => {
        draft.addTeamToSessionStatus = action.payload;
      });

    case Types.GET_SESSION_BY_ID_SUCCEED:
      return produce(state, (draft) => {
        draft.openDay = action.payload;
      });
    case Types.GET_UNAVAILABLE_SESSION_DATES_SUCCEED:
      return produce(state, (draft) => {
        draft.unavailableSessions = action.payload;
      });
    default:
      return state;
  }
}

import { ArrowBack } from '@mui/icons-material';
import { Avatar, CardMedia, Chip, Container, Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import CandidateProfileDialog from '../../../../common/components/CandidateProfileModal/CandidateProfileModal';
import Qualifications from '../../../../common/components/Qualifications/Qualifications';
import useScreenSize from '../../../../common/hooks/useScreenSize';

import CandidateTrackerLink from 'common/components/CandidateTrackerLink/CandidateTrackerLink';
import { ICandidate } from '../../../candidates/operations/models/candidatesModel';
import { ITeam } from '../../operations/models/feedbackModel';
import styles from './FeedbackContent.module.scss';

interface IProps {
  candidate?: ICandidate;
  candidates: ICandidate[];
  team?: ITeam;
  goBack: () => void;
  handleCandidateChange: (candidate: ICandidate) => void;
  handleTeamChange: (team: ITeam) => void;
}

export const FeedbackHeader: React.FC<IProps> = (props: IProps) => {
  const { candidate, candidates, team, goBack, handleCandidateChange, handleTeamChange } = props;

  const screenSize = useScreenSize();
  const isMobile = screenSize.isMobile || screenSize.isTablet;
  const candidateTabRef = useRef<HTMLInputElement>(null);
  const [showProfileDialog, setShowProfileDialog] = useState<boolean>(false);

  const queryParams = new URLSearchParams(window.location.search);
  const urlTeamId = queryParams.get('teamId');

  useEffect(() => {
    candidateTabRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center'
    });
  }, [candidate]);

  const renderTeamHeader = () => {
    return (
      <div className={styles.teamHeaderDetails}>
        <div>
          <span className={styles.fontSize24 + ' textEllipsis d-block'}>{team?.name}</span>
          <span className={styles.fontSize15}>Team Feedback</span>
        </div>
        <div className={styles.meta}></div>
      </div>
    );
  };

  const renderCandidateList = () => {
    return candidates.map((current) => {
      return (
        <div
          key={current.id}
          className={current.id == candidate?.id ? styles.selectedCandidate : undefined}
          onClick={() => handleCandidateChange(current)}
          ref={current.id == candidate?.id ? candidateTabRef : undefined}
        >
          {current.photo && current.photo !== '' ? (
            <CardMedia component="img" className={isMobile ? styles.avatarMobile : styles.avatar} src={current.photo} />
          ) : (
            <Container className={isMobile ? styles.avatarMobile : styles.avatar}>
              <Avatar />
            </Container>
          )}
          <span className={styles.candidateName}>
            <b>{current.id == candidate?.id ? '' : current.name}</b>
          </span>
        </div>
      );
    });
  };

  const renderCandidateHeader = (candidate: ICandidate) => {
    return (
      <div className={styles.headerDetails}>
        <div className="w-90" onClick={() => setShowProfileDialog(true)}>
          <span className={styles.fontSize24}>{candidate.name}</span>
          <br />
          <span className={styles.fontSize15}>
            <Qualifications candidate={candidate} />
          </span>
        </div>
        <div className={styles.meta}>
          <Chip size="small" label={candidate.location} className={styles.location} />
          <CandidateTrackerLink candidateTrackerLink={candidate.candidateTrackerLink} />
        </div>
      </div>
    );
  };

  return (
    <>
      {showProfileDialog && candidate && (
        <CandidateProfileDialog candidate={candidate} handleClose={() => setShowProfileDialog(false)} />
      )}
      <div className={styles.headerMobile}>
        {isMobile && (
          <div className={styles.headerTop}>
            <Grid container>
              <Grid item xs={1} className={styles.headerGoBackLink} onClick={goBack}>
                <ArrowBack />
              </Grid>
              <Grid item xs={10} className={styles.headerTeamCaptain}>
                {`${team?.name ?? 'Unassigned'}`}
              </Grid>
            </Grid>
          </div>
        )}

        <div className={styles.candidateList}>
          {team && (
            <div onClick={() => handleTeamChange(team)} className={(urlTeamId && styles.selectedTeam) || ''}>
              <Container className={`${isMobile ? styles.avatarMobile : styles.avatar} ${styles.teamAvatar}`}>
                <b>Tf</b>
              </Container>
              {!urlTeamId && (
                <span className={styles.candidateName}>
                  <b>{team.name}</b>
                </span>
              )}
            </div>
          )}
          {renderCandidateList()}
        </div>
        {candidate && renderCandidateHeader(candidate)}
        {urlTeamId && team && renderTeamHeader()}
      </div>
    </>
  );
};

export default FeedbackHeader;

import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useState } from 'react';

import { ICandidate } from '../../../candidates/operations/models/candidatesModel';

interface IProps {
  candidate: ICandidate;
}

export const FeedbackRecruitmentNote: React.FC<IProps> = ({ candidate }: IProps) => {
  const [showRecruitmentNote, setShowRecruitmentNote] = useState<boolean>(false);

  return (
    <Accordion expanded={showRecruitmentNote} onChange={(_, v) => setShowRecruitmentNote(v)}>
      <AccordionSummary expandIcon={<ExpandMore />} style={{ backgroundColor: '#f0f0f0' }}>
        <b>Candidate Notes</b>
      </AccordionSummary>
      <AccordionDetails>
        {showRecruitmentNote && (
          <span>{candidate.comments ? candidate.comments : 'There are no candidate notes made.'}</span>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default FeedbackRecruitmentNote;

import produce from 'immer';
import { defaultUsersState, IUserState } from '../models/IUserState';
import {
  CHANGE_USER_DELETION_STATUS,
  CHANGE_USERS_FILTERED,
  FETCHED_USERS,
  SAVE_USER,
  SAVE_USER_FAILED,
  SAVE_USER_SUCCESS,
  SEARCH_AD_USERS_SUCCEED
} from '../types';

export default function userReducer(state: IUserState = defaultUsersState, action: any): IUserState {
  switch (action.type) {
    case FETCHED_USERS:
      return produce(state, (draft) => {
        draft.usersPagination = action.payload;
        draft.users = draft.usersPagination?.data || [];
      });
    case SAVE_USER:
      return produce(state, (draft) => {
        draft.operationSuccess = null;
      });
    case SAVE_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.operationSuccess = true;
      });
    case SAVE_USER_FAILED:
      return produce(state, (draft) => {
        draft.operationSuccess = false;
      });

    case CHANGE_USERS_FILTERED:
      return produce(state, (draft) => {
        draft.filteredUsersPagination = action.payload;
        draft.filteredUsers = draft.filteredUsersPagination?.data || [];
      });

    case CHANGE_USER_DELETION_STATUS:
      return produce(state, (draft) => {
        draft.userOperation.deletetionStatus = action.payload;
      });
    case SEARCH_AD_USERS_SUCCEED:
      return produce(state, (draft) => {
        draft.adUsers = action.payload;
      });
    default:
      return state;
  }
}

import OpenDayGrid from '../components/OpenDayGridView/OpenDayGrid';

export enum ChangeType {
  CLEAR_SEARCH = 'clear_search ',
  CLEAR_FILTERS = 'clear_filters',
  RESET = 'reset'
}

export const CandidatesContainer: React.FC = () => {
  return <OpenDayGrid />;
};

export default CandidatesContainer;

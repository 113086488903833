import { Search } from '@mui/icons-material';
import { Box as Container, InputAdornment, OutlinedInput } from '@mui/material';
import FilterButton, { IGenericFilter } from 'common/components/FilterComponent/FilterButton';
import GuardedButton from 'common/components/GuardedButton/GuardedButton';
import { ROUTES } from 'common/constants/routesConstants';
import { FilterTypeEnum } from 'common/enums/filterTypeEnum';
import { serialize } from 'common/helpers/serializationHelpers';
import { IPaginationRequest } from 'common/models/IPaginationRequest';
import { getLocations } from 'common/operations/actions/commonOperationActions';
import { getOpenDays } from 'modules/feedback/operations/actions/feedbackOperationActions';
import { ClaimTypes } from 'modules/manage-users/operations/enums/claimTypes';
import { ClaimActionType } from 'modules/manage-users/operations/models';
import { getTeamCaptains } from 'modules/sessions/operations/actions/sessionsOperationActions';
import { ISessionFilters } from 'modules/sessions/operations/models/sessionsModel';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { IGlobalState } from 'startup/store/globalState';
import useScreenSize from '../../../../common/hooks/useScreenSize';
import styles from './OpenDayList.module.scss';

interface IProps {
  pageNo: number;
}

const defaultGenericFilters: IGenericFilter[] = [
  {
    label: 'Location',
    reduxData: [],
    selectedOptions: [],
    filterType: FilterTypeEnum.SELECT
  },
  {
    label: 'Date',
    reduxData: [],
    selectedOptions: [],
    filterType: FilterTypeEnum.DATE_PICKER
  },
  {
    label: 'Status',
    reduxData: ['Today', 'Upcoming', 'Completed'],
    selectedOptions: [],
    filterType: FilterTypeEnum.BASIC_SELECT
  },
  {
    label: 'Assigned Attendees',
    reduxData: [],
    selectedOptions: [],
    filterType: FilterTypeEnum.SELECT
  }
];

export const FilterOpenDayList: React.FC<IProps> = ({ pageNo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useScreenSize();
  const [genericFilters, setGenericFilters] = useState<IGenericFilter[]>(defaultGenericFilters);

  const { common, teamCaptains, deletionStatus } = useSelector((state: IGlobalState) => {
    return {
      ...state.commonState,
      ...state.sessionsState.sessions,
      ...state.sessionsState.sessionsOperation
    };
  });

  const [filters, setFilters] = useState<ISessionFilters>({
    statuses: [],
    locations: [],
    dates: [],
    attendees: []
  });

  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    dispatch(getTeamCaptains());
    dispatch(getLocations());
  }, []);

  useEffect(() => {
    fetchOpenDays();
  }, [searchTerm, filters, deletionStatus, pageNo]);

  useEffect(() => {
    let newState = [...genericFilters];

    if (common.locations.length > 0) {
      let index = newState.findIndex((x) => x.label === 'Location');
      newState[index] = {
        ...newState[index],
        reduxData: common.locations?.map((l) => l.name)
      };
    }
    if (teamCaptains.length > 0) {
      let index = newState.findIndex((x) => x.label === 'Assigned Attendees');
      newState[index] = {
        ...newState[index],
        reduxData: teamCaptains?.map((x) => ({ label: x.name, value: `${x.id}` }))
      };
    }

    setGenericFilters(newState);
  }, [common, teamCaptains]);

  const fetchOpenDays = () => {
    const pageRequest: IPaginationRequest<ISessionFilters> = {
      filters,
      searchTerm,
      sortBy: 'dateTime',
      ascending: false,
      pageSize: isMobile ? 8 : 9,
      pageNo
    };
    dispatch(getOpenDays(serialize(pageRequest)));
  };

  const fetchDataFromApi = (genericFilters: IGenericFilter[]) => {
    const locationFilters = genericFilters.find((f) => (f.label = 'Location'));
    const teamCaptainFilters = genericFilters.find((f) => f.label === 'Assigned Attendees');
    const dateFilters = genericFilters.find((f) => f.label === 'Date');
    const statusFilter = genericFilters.find((f) => f.label === 'Status');
    setFilters({
      ...filters,
      locations: locationFilters?.selectedOptions ?? [],
      attendees: teamCaptainFilters?.selectedOptions.map((o) => +o) ?? [],
      dates: dateFilters?.selectedOptions ?? [],
      statuses: statusFilter?.selectedOptions ?? []
    });
  };

  return (
    <Container className={styles.searchContainer}>
      <div className={styles.searchBar}>
        <OutlinedInput
          sx={{ backgroundColor: 'white' }}
          fullWidth={true}
          type="text"
          onChange={(e) => setSearchTerm(e.target.value)}
          endAdornment={
            <InputAdornment position="end" sx={{ paddingRight: '1rem' }}>
              <Search />
            </InputAdornment>
          }
          placeholder={`Find ${process.env.REACT_APP_SESSION_NAME}`}
        />
      </div>
      {genericFilters && (
        <div className={styles.filterButton}>
          <FilterButton filterTypes={genericFilters} onFilterClicked={fetchDataFromApi}></FilterButton>
        </div>
      )}

      {!isMobile && (
        <div className={styles.filterButton}>
          <GuardedButton
            variant={'contained'}
            label={`New ${process.env.REACT_APP_SESSION_NAME}`}
            className={styles.actionButton}
            sx={{
              width: { xs: '100%', sm: 'auto' }
            }}
            onClick={() => navigate(`${ROUTES.OpenDays}/edit/0`)}
            errorProps={{ disabled: true }}
            scopes={[
              {
                actionType: ClaimActionType.ADD,
                claimType: ClaimTypes.MANAGE_OPEN_DAYS
              }
            ]}
          />
        </div>
      )}
    </Container>
  );
};

export default FilterOpenDayList;

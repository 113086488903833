import { CloseOutlined } from '@mui/icons-material';
import { Container, Drawer, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ButtonType } from 'common/enums/buttonTypeEnum';
import { FilterTypeEnum } from 'common/enums/filterTypeEnum';
import dayjs from 'dayjs';
import EntelectButton from '../EntelectButton/EntelectButton';
import { BasicSelect } from './BasicSelect';
import { IGenericFilter } from './FilterButton';
import styles from './FilterButton.module.scss';
import { MultiChipSelect } from './MultiChipSelect';

interface IProps {
  filters: IGenericFilter[];
  setFilters: (filters: IGenericFilter[]) => void;
  onFilterClicked: (filters: IGenericFilter[]) => void;
  setOpen: (open: boolean) => void;
  toggleFilterDrawer: (open: boolean) => void;
  open: boolean;
}

export const FilterDrawer = ({ filters, setFilters, onFilterClicked, setOpen, toggleFilterDrawer, open }: IProps) => {
  const handleChanges = (event: any, label: string, type: FilterTypeEnum) => {
    let value: any;
    if (type === FilterTypeEnum.DATE_PICKER) {
      value = dayjs(event).isValid() ? dayjs(event).format('YYYY-MM-DD') : [];
    } else {
      value = (event.target as HTMLInputElement).value;
    }

    let newState = [...filters];
    let index = filters.findIndex((x) => x.label == label);
    newState[index] = {
      ...newState[index],
      selectedOptions: typeof value === 'string' ? value.split(',') : value
    };
    setFilters(newState);
  };

  const handleOnDelete = (label: string, value: string) => {
    const newState = [...filters];
    const index = filters.findIndex((x) => x.label == label);
    const selectedOptions = newState[index].selectedOptions.filter((x) => x !== value);
    newState[index] = { ...newState[index], selectedOptions };
    setFilters(newState);
  };

  const renderFilterOptions = (filter: IGenericFilter, key: number) => {
    switch (filter.filterType) {
      case FilterTypeEnum.SELECT:
        return (
          <MultiChipSelect
            key={filter.label + key}
            label={filter.label}
            values={filter.selectedOptions}
            items={filter.reduxData}
            onChanges={(e) => handleChanges(e, filter.label, filter.filterType)}
            onDelete={(val) => handleOnDelete(filter.label, val)}
          />
        );
      case FilterTypeEnum.BASIC_SELECT:
        return (
          <BasicSelect
            key={filter.label + key}
            label={filter.label}
            values={filter.selectedOptions}
            items={filter.reduxData}
            onChanges={(e) => handleChanges(e, filter.label, filter.filterType)}
          />
        );
      case FilterTypeEnum.DATE_PICKER:
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label={filter.label}
              value={filter.selectedOptions.length > 0 ? dayjs(filter.selectedOptions[0]) : null}
              onChange={(e: any) => handleChanges(e, filter.label, filter.filterType)}
            />
          </LocalizationProvider>
        );
      default:
        return <></>;
    }
  };

  const handleClearFilters = () => {
    let newState = [...filters];

    newState.forEach((filter) => {
      filter.selectedOptions = [];
    });
    setFilters(newState);
    onFilterClicked(filters);
    setOpen(false);
  };

  const handleOnClickFilter = () => {
    onFilterClicked(filters);
    setOpen(false);
  };

  return (
    <Drawer
      anchor={'right'}
      open={open}
      sx={{
        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '323px' }
      }}
      onClose={() => toggleFilterDrawer(false)}
    >
      <Container disableGutters className={styles.filterDrawerContainer}>
        <Container disableGutters className={styles.filterByHeaderContainer}>
          <Typography variant={'h4'} className={styles.filterByHeader}>
            Filter by:
          </Typography>
          <CloseOutlined className={styles.closeFilterDrawerButton} onClick={() => toggleFilterDrawer(false)} />
        </Container>
        <Container disableGutters className={styles.filterByContainer}>
          {filters.map((x: IGenericFilter, i: number) => {
            return (
              <div className={styles.filterOptionsContainer} key={x.label}>
                {renderFilterOptions(x, i)}
              </div>
            );
          })}
        </Container>

        <EntelectButton
          variant={ButtonType.Primary}
          handleClick={handleOnClickFilter}
          label={'Filter'}
        ></EntelectButton>
        <EntelectButton
          variant={ButtonType.Secondary}
          handleClick={handleClearFilters}
          label={'Clear'}
        ></EntelectButton>
      </Container>
    </Drawer>
  );
};
